import { TaskTemplate } from 'utils/customTypes';
import orderBy from 'lodash/orderBy';

const mapTaskTemplateToString = (array: TaskTemplate[]) => {
  return array.map((item) => item.id);
};

const extractTemplatesToBeDeleted = ({
  originalTemplateData,
  updatedTemplateData,
}: {
  originalTemplateData: TaskTemplate[];
  updatedTemplateData: TaskTemplate[];
}) => {
  const originalTemplateIds = mapTaskTemplateToString(originalTemplateData);
  const currentTemplateIds = mapTaskTemplateToString(updatedTemplateData);

  const templatesToBeDeleted = originalTemplateIds.filter((templateId) => {
    return !currentTemplateIds.includes(templateId);
  });

  return templatesToBeDeleted as string[];
};

export const getTemplatesToBeDeleted = ({
  originalTemplateData,
  updatedTemplateData,
}: {
  originalTemplateData: TaskTemplate[];
  updatedTemplateData: TaskTemplate[];
}) => {
  const templatesToDelete = extractTemplatesToBeDeleted({
    originalTemplateData,
    updatedTemplateData,
  });

  return templatesToDelete;
};

export const getTemplatesToBeCreatedOrUpdated = ({
  originalTemplateData,
  updatedTemplateData,
  newAndUpdatedTemplatesIds,
}: {
  originalTemplateData: TaskTemplate[];
  updatedTemplateData: TaskTemplate[];
  newAndUpdatedTemplatesIds: Set<string>;
}) => {
  const originalTemplateIds = mapTaskTemplateToString(originalTemplateData);
  const templatesToBeCreatedIds = Array.from(newAndUpdatedTemplatesIds).filter(
    (id) => !originalTemplateIds.includes(id)
  );
  const templatesToBeUpdatedIds = Array.from(newAndUpdatedTemplatesIds).filter(
    (id) => originalTemplateIds.includes(id)
  );

  const templatesToBeCreated = templatesToBeCreatedIds.map((templateId) => {
    const template = updatedTemplateData.find(
      (template) => template.id === templateId
    );
    return template as TaskTemplate;
  });

  const templatesToBeUpdated = templatesToBeUpdatedIds.map((templateId) => {
    const template = updatedTemplateData.find(
      (template) => template.id === templateId
    );
    return template as TaskTemplate;
  });

  const templatesToBeCreatedOrUpdated = templatesToBeCreated
    .concat(templatesToBeUpdated)
    .map((template) => {
      const action_type = originalTemplateIds.includes(template.id)
        ? 'update'
        : 'create';
      const newIndex = updatedTemplateData.findIndex(
        (currentTempalte) => currentTempalte.id === template.id
      );

      return {
        name: template.name,
        description: template.description,
        action_type,
        index: newIndex,
        ...(action_type === 'update' && { id: template.id }),
      };
    });
  const orderedTemplates = orderBy(
    templatesToBeCreatedOrUpdated,
    ['index'],
    ['asc']
  );
  return orderedTemplates;
};
