import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { TableOperationHeader } from '@getsynapse/design-system';
import { TableRowDensity } from 'utils/customTypes';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';

const TableHeader: React.FC<{
  onSearch: (value: string) => void;
  shouldClearInput: boolean;
  selectRowDensity: (rowDensity: TableRowDensity) => void;
  canChangeRowDensity?: boolean;
  tableIdentifier?: string;
}> = ({
  onSearch,
  shouldClearInput,
  selectRowDensity,
  canChangeRowDensity = false,
  tableIdentifier = '',
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debounceCallback = debounce(onSearch, 500);
  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceCallback(value);
  };

  useEffect(() => {
    if (shouldClearInput) {
      setSearchValue('');
    }
  }, [shouldClearInput]);

  return (
    <TableOperationHeader
      className='border-b-0'
      inputValue={searchValue}
      setInputValue={handleSearch}
      searchProps={{
        'data-testid': `${tableIdentifier}__search-input`,
      }}
      actionButtons={
        <RowDensityPopup
          onSelectRowDensity={selectRowDensity}
          disabled={!canChangeRowDensity}
          tableTestId={tableIdentifier}
        />
      }
    />
  );
};

export default TableHeader;
