import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import {
  Table,
  Typography,
  Icon,
  Tooltip,
  AvatarGroup,
} from '@getsynapse/design-system';
import { DATE, PATHS, USER_TYPES } from 'utils/constants';
import { TableRowDensity } from 'utils/customTypes';
import { useHistory, Link } from 'react-router-dom';
import {
  getLearningTeams,
  selectOrderedLearningTeams,
  setLearningTeamsOrder,
  setSearchParam,
} from 'state/LearningTeams/learningTeamsSlice';
import LearningTeamsTableHead from './LearningTeamsTableHead';
import TableHeader from '../TableHeader/TableHeader';

const LearningTeams: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ldTeams = useSelector(selectOrderedLearningTeams);
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');

  useEffect(() => {
    dispatch(
      setLearningTeamsOrder({
        order: 'asc',
        orderBy: ['title'],
      })
    );
    dispatch(setSearchParam({ searchParam: '' }));
    dispatch(getLearningTeams());
  }, [dispatch]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(
        setLearningTeamsOrder({
          order: 'asc',
          orderBy: ['title'],
        })
      );
      dispatch(setSearchParam({ searchParam: '' }));
    }
  }, [isVisible, dispatch]);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setLearningTeamsOrder({ order, orderBy: orderByParam }));
  };

  const handleSearch = (searchParam: string) =>
    dispatch(setSearchParam({ searchParam }));

  return (
    <>
      <TableHeader
        onSearch={handleSearch}
        shouldClearInput={!isVisible}
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
        canChangeRowDensity={ldTeams.length > 0}
        tableIdentifier='learning-teams-table'
      />
      <Table
        data-testid='learning-teams-table'
        className='max-h-table w-full relative mb-5'
        canSelectRows={false}
        rowDensity={rowDensity}
        bodyProps={{
          className: classnames('max-h-table-body overflow-y-auto', {
            block: ldTeams.length > 0,
          }),
        }}
        data={{
          headData: {
            handleSort,
            className: 'w-full block h-auto min-h-10',
            rowProps: {
              className: 'table w-full table-fixed h-auto min-h-10',
            },
            headCells: LearningTeamsTableHead(),
          },
          rows:
            ldTeams.length > 0
              ? ldTeams.map((team) => {
                  const managerAvatars = team.teamManagers
                    ? team.teamManagers.map((manager) => {
                        const firstName = get(manager, 'data.firstName', ' ');
                        const lastName = get(manager, 'data.lastName', ' ');
                        return {
                          imageSrc: get(manager, 'avatar_url'),
                          initial: `${get(firstName, '[0]')} ${get(
                            lastName,
                            '[0]'
                          )}`,
                          name: `${firstName} ${lastName}`,
                        };
                      })
                    : [];

                  return {
                    id: team.id,
                    'data-testid': `ld-team_row-${team.id}`,
                    onClick: () =>
                      history.push(
                        `${PATHS.EDIT_TEAM}/${USER_TYPES.L_D}/${team.id}`
                      ),
                    className: 'cursor-pointer table w-full table-fixed',
                    cells: [
                      {
                        content: (
                          <Link
                            to={`${PATHS.EDIT_TEAM}/${USER_TYPES.L_D}/${team.id}`}
                          >
                            {team.name}
                          </Link>
                        ),
                        className: 'truncate',
                      },
                      {
                        content:
                          get(team, 'teamManagers.length', 0) > 0 ? (
                            get(team, 'teamManagers.length') === 1 ? (
                              <div className='flex items-center'>
                                <AvatarGroup avatars={managerAvatars} />
                                <span className='ml-2.5'>
                                  {managerAvatars[0].name}
                                </span>
                              </div>
                            ) : (
                              <AvatarGroup
                                avatars={managerAvatars}
                                data-cy='ld-managers_avatars'
                              />
                            )
                          ) : (
                            <div className='flex items-center'>
                              <Typography
                                variant='body2'
                                className='text-neutral-black'
                              >
                                {intl.get(
                                  'ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.NO_MANAGER_ASSIGNED'
                                )}
                              </Typography>
                              <Tooltip
                                className='pl-2.5'
                                timeout={0}
                                trigger={
                                  <div>
                                    <Icon
                                      name='information-circle'
                                      className='text-2xl text-warning-dark'
                                    />
                                  </div>
                                }
                                openMode='hover1'
                                ariaId='manager-more-info'
                                position='topCenter'
                                hasMaxWidth={false}
                                showPopper
                                usePortal
                                contentProps={{
                                  className: classnames(
                                    'bg-warning-lighter',
                                    'text-warning-dark',
                                    'rounded px-4 py-3.5',
                                    'w-max absolute',
                                    'font-normal'
                                  ),
                                }}
                              >
                                <span>
                                  {intl.get(
                                    'ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.A_MANAGER_IS_NEEDED'
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          ),
                        className: 'font-semibold',
                      },
                      {
                        content: get(team, 'parentTeam.name', '-'),
                        className: 'truncate',
                      },
                      {
                        content: team.createdAt
                          ? moment(team.createdAt).format(
                              DATE.MONTH_DATE_YEAR_FORMAT
                            )
                          : '-',
                      },
                    ],
                  };
                })
              : [
                  {
                    'data-testid': 'teams_table-empty',
                    id: '0',
                    cells: [
                      {
                        colSpan: '4',
                        content: (
                          <div className='flex justify-center'>
                            <Typography
                              variant='body2'
                              className='text-neutral'
                            >
                              {intl.get(
                                'ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.NO_TEAM_ADDED'
                              )}
                            </Typography>
                          </div>
                        ),
                      },
                    ],
                  },
                ],
        }}
      />
    </>
  );
};

export default LearningTeams;
