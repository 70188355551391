import { TableOperationHeader } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import { TableRowDensity } from 'utils/customTypes';
import {
  selectTaskBundleTableSearch,
  setTaskBundleTableSearch,
} from 'state/TasksBundle/tasksBundleSlice';

const TaskBundleTableFilters = ({
  selectRowDensity,
  totalTaskBundles,
}: {
  selectRowDensity: (rowDensity: TableRowDensity) => void;
  totalTaskBundles: number;
}) => {
  const dispatch = useDispatch();
  const searchValue = useSelector(selectTaskBundleTableSearch);

  const handleSearch = (value: string) => {
    dispatch(setTaskBundleTableSearch(value));
  };

  return (
    <div>
      <TableOperationHeader
        className='border-b-0 bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearch}
        hideExportButton
        filterComponent={[]}
        actionButtons={
          <>
            <RowDensityPopup
              onSelectRowDensity={selectRowDensity}
              disabled={totalTaskBundles === 0}
            />
          </>
        }
      />
    </div>
  );
};

export default TaskBundleTableFilters;
