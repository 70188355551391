import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import { Request, RequestsTable, SortingType, Status } from 'utils/customTypes';
import { RequestFilters } from 'utils/types/filters';
import { SLICE_STATUS } from 'utils/constants';
import { RootState } from 'state/store';
import { orderRequestsBy, filterRequestsBy } from './helpers';
import RequestAPI from './requestAPI';
import exportAPI from 'Services/exportAPI';
interface RequestsState {
  requests: Request[];
  userRequests: Request[];
  search: {
    title?: string;
  };
  sorting: {
    orderBy: string;
    order: SortingType;
  };
  teamRequests: {
    limit: number;
    offset: number;
    filters: RequestFilters;
    searchParam: string;
  };
  myRequests: {
    limit: number;
    offset: number;
    filters: RequestFilters;
    searchParam: string;
  };
  exportStatus: Status;
  exportByFormStatus: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: RequestsState = {
  requests: [],
  userRequests: [],
  search: {
    title: '',
  },
  sorting: {
    order: 'asc',
    orderBy: '',
  },
  teamRequests: {
    limit: 15,
    offset: 0,
    searchParam: '',
    filters: {},
  },
  myRequests: {
    limit: 15,
    offset: 0,
    searchParam: '',
    filters: {},
  },
  exportStatus: SLICE_STATUS.IDLE,
  exportByFormStatus: SLICE_STATUS.IDLE,
};

const requestAPI = RequestAPI;

/* ============================== REDUX THUNK =============================== */
export const getAllRequests = createAsyncThunk(
  'requests/GET_REQUESTS',
  async (params?: { status?: string }) => {
    const response = await requestAPI.fetchAllRequests({
      status:
        'submitted,approved,rejected,canceled,pending_cancellation,waitlisted',
      ...params,
    });
    return response.data;
  }
);

export const getUserRequests = createAsyncThunk(
  'requests/GET_USER_REQUESTS',
  async (params?: { status?: string }) => {
    const response = await requestAPI.fetchUserRequests({
      status:
        'submitted,approved,rejected,canceled,pending_cancellation,waitlisted',
      ...params,
    });
    return response.data;
  }
);

export const deleteRequest = createAsyncThunk(
  'requests/DELETE_REQUEST',
  async (requestId: string) => {
    const response = await requestAPI.deleteRequest(requestId);
    return response.id;
  }
);

export const exportRequests = createAsyncThunk(
  'requests/EXPORT_LIST',
  async (requestIds: string[], { rejectWithValue }) => {
    try {
      const response = await exportAPI.exportRequests(requestIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportRequestsByForm = createAsyncThunk(
  'requests/EXPORT_LIST_BY_FORM',
  async (requestIds: string[], { rejectWithValue }) => {
    try {
      const response = await exportAPI.exportRequestsByForm(requestIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadRequest = createAsyncThunk(
  'requests/DOWNLOAD_REQUEST',
  async (exportData: { requestId: string; fileName: string }) => {
    const { requestId, fileName } = exportData;
    const response = await exportAPI.exportRequestPdf(requestId, fileName);
    return response.data.fileLocation;
  }
);

export const importRequests = createAsyncThunk(
  'requests/IMPORT_REQUESTS',
  async ({
    importData,
    userId,
  }: {
    importData: Partial<Request>[];
    userId: string;
  }) => {
    const { data } = await requestAPI.bulkCreate(importData);
    return { data, userId };
  }
);

/* ================================ ACTIONS ================================= */
export const searchByTitle = createAction<{
  searchParam: string;
  table: RequestsTable;
}>('requests/SEARCH_BY_TITLE');

export const setOrder = createAction<{
  order: SortingType;
  orderBy: string;
}>('requests/SET_ORDER');

export const updateTeamRequestPagination = createAction<{
  limit: number;
  offset: number;
}>('requests/UPDATE_TEAM_PAGINATION');

export const updateMyRequestPagination = createAction<{
  limit: number;
  offset: number;
}>('requests/UPDATE_MY_PAGINATION');

export const addRequest = createAction<Request>('requests/ADD_REQUEST');

export const setFilters = createAction(
  'requests/SET_FILTERS',
  (filters: RequestFilters, table: RequestsTable) => {
    return { payload: { filters, table } };
  }
);

/* ================================= REDUCER ================================ */
const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRequests.fulfilled, (state, action) => {
        state.requests = action.payload;
      })
      .addCase(getUserRequests.fulfilled, (state, action) => {
        state.userRequests = action.payload;
      })
      .addCase(addRequest, (state, action) => {
        state.userRequests = state.userRequests.concat(action.payload);
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.requests = state.requests.filter(
          (request) => request.id !== action.payload
        );
        state.userRequests = state.userRequests.filter(
          (request) => request.id !== action.payload
        );
      })
      .addCase(exportRequests.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportRequests.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportRequests.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      })
      .addCase(exportRequestsByForm.pending, (state) => {
        state.exportByFormStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportRequestsByForm.fulfilled, (state, action) => {
        state.exportByFormStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportRequestsByForm.rejected, (state) => {
        state.exportByFormStatus = SLICE_STATUS.FAILED;
      })
      .addCase(searchByTitle, (state, action) => {
        state[action.payload.table].searchParam = action.payload.searchParam;
      })
      .addCase(setOrder, (state, action) => {
        state.sorting.order = action.payload.order;
        state.sorting.orderBy = action.payload.orderBy;
      })
      .addCase(downloadRequest.fulfilled, (state, action) => {
        window.location.href = action.payload;
      })
      .addCase(importRequests.fulfilled, (state, action) => {
        state.userRequests = [
          ...state.userRequests,
          ...action.payload.data.filter(
            (request: Request) => request.requester_id === action.payload.userId
          ),
        ];
      })
      .addCase(updateTeamRequestPagination, (state, action) => {
        state.teamRequests.limit = action.payload.limit;
        state.teamRequests.offset = action.payload.offset;
      })
      .addCase(updateMyRequestPagination, (state, action) => {
        state.myRequests.limit = action.payload.limit;
        state.myRequests.offset = action.payload.offset;
      })
      .addCase(setFilters, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          filters: action.payload.filters,
        };
      });
  },
});

/* =============================== SELECTORS ================================ */
const teamRequests = (state: RootState) => state.requestsState.requests;
const userRequests = (state: RootState) => state.requestsState.userRequests;
const sorting = (state: RootState) => state.requestsState.sorting;
export const exportRequestsStatus = (state: RootState) =>
  state.requestsState.exportStatus;
export const exportRequestsByFormStatus = (state: RootState) =>
  state.requestsState.exportByFormStatus;

const teamRequesPagination = (state: RootState) =>
  state.requestsState.teamRequests;
const myRequestsPagination = (state: RootState) =>
  state.requestsState.myRequests;

export const teamRequestFilters = (state: RootState) =>
  state.requestsState.teamRequests.filters;

export const userRequestFilters = (state: RootState) =>
  state.requestsState.myRequests.filters;

export const teamRequestSearchParam = (state: RootState) =>
  state.requestsState.teamRequests.searchParam;
export const userRequestSearchParam = (state: RootState) =>
  state.requestsState.myRequests.searchParam;

export const compare = (
  criteria: boolean,
  complies: boolean | undefined,
  logic?: string
) => {
  if (complies !== undefined) {
    return logic === 'AND' ? complies && criteria : complies || criteria;
  } else {
    return logic === 'AND' ? criteria : criteria;
  }
};

export const selectTeamRequests = createSelector(
  [
    teamRequests,
    teamRequestSearchParam,
    sorting,
    teamRequesPagination,
    teamRequestFilters,
  ],
  (
    requests: Request[],
    search: string,
    sorting: {
      orderBy: string;
      order: SortingType;
    },
    pagination: { limit: number; offset: number },
    filters: RequestFilters
  ) => {
    let teamRequests, total;
    const hasFilters = !isEmpty(filters);
    if (hasFilters || search) {
      teamRequests = filterRequestsBy(requests, search, filters);
      total = teamRequests.length;
    } else {
      teamRequests = requests;
      total = requests.length;
    }
    teamRequests = teamRequests.map((request) => {
      if (request.requestProjects?.length) {
        return {
          ...request,
          linkedProject: `${request.requestProjects?.length} ${intl
            .get('ENTITIES.PROJECT', { num: request.requestProjects?.length })
            .toLowerCase()}`,
          linkedProjectStatus: '',
        };
      } else {
        return request;
      }
    });
    const orderedRequests = orderRequestsBy(
      teamRequests,
      sorting.orderBy,
      sorting.order
    );
    return {
      data: orderedRequests.slice(pagination.offset, pagination.limit),
      total,
      all: teamRequests,
    };
  }
);

export const selectUserRequests = createSelector(
  [
    userRequests,
    userRequestSearchParam,
    sorting,
    myRequestsPagination,
    userRequestFilters,
  ],
  (
    requests: Request[],
    search: string,
    sorting: {
      orderBy: string;
      order: SortingType;
    },
    pagination: { limit: number; offset: number },
    filters: RequestFilters
  ) => {
    let userRequests, total;
    const hasFilters = !isEmpty(filters);
    if (hasFilters || search) {
      userRequests = filterRequestsBy(requests, search, filters);

      total = userRequests.length;
    } else {
      userRequests = requests;
      total = requests.length;
    }
    userRequests = userRequests.map((request) => {
      if (request.requestProjects?.length) {
        return {
          ...request,
          linkedProject: `${request.requestProjects?.length} ${intl
            .get('ENTITIES.PROJECT', { num: request.requestProjects?.length })
            .toLowerCase()}`,
          linkedProjectStatus: '',
        };
      } else {
        return request;
      }
    });
    const orderedRequests = orderRequestsBy(
      userRequests,
      sorting.orderBy,
      sorting.order
    );
    return {
      data: orderedRequests.slice(pagination.offset, pagination.limit),
      total,
      all: userRequests,
    };
  }
);

export const selectRequestsByStatus =
  (status: string) => (state: RootState) => {
    return unionBy(
      state.requestsState.requests,
      state.requestsState.userRequests,
      'id'
    ).filter((request) => request.status === status);
  };

export const selectTableSearchParam =
  (table: RequestsTable) => (state: RootState) =>
    state.requestsState[table].searchParam;

export default requestSlice.reducer;
