import React from 'react';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { Task, Task_Status } from 'utils/customTypes';
import { TaskLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { Column } from '../../../Timeline/Timeline';
import { formatter } from 'utils/functions';

type Style = {
  bgColor: string;
  textColor: string;
  borderColor: string;
};

const taskStatusToStyle: Record<Task_Status, Style> = {
  new: {
    bgColor: 'bg-purple-lighter',
    textColor: 'text-purple-darker',
    borderColor: 'border-purple-darker',
  },
  in_progress: {
    bgColor: 'bg-teal-lightest',
    textColor: 'text-teal-dark',
    borderColor: 'border-teal-dark',
  },
  completed: {
    bgColor: 'bg-success-lighter',
    textColor: 'text-success-darker',
    borderColor: 'border-success-darker',
  },
  on_hold: {
    bgColor: 'bg-tertiary-lightest',
    textColor: 'text-tertiary-darker',
    borderColor: 'border-tertiary-darker',
  },
};

const Estimation: React.FC<{
  task: Task;
  isCurrentWeek: boolean;
  estimation: TaskLevelAllocation;
}> = ({ isCurrentWeek, task, estimation, ...otherProps }) => {
  const className = taskStatusToStyle[task.status];
  return (
    <Column isCurrentWeek={isCurrentWeek} {...otherProps}>
      <div
        className={tailwindOverride(
          'w-full h-6 flex justify-start items-center border-b-2 px-2.5',
          className.bgColor,
          className.borderColor
        )}
      >
        <Typography
          variant='caption'
          weight='medium'
          className={className.textColor}
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
            allocation: `${formatter.format(estimation.allocation)} `,
          })}
        </Typography>
      </div>
    </Column>
  );
};

export default Estimation;
