import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  searchByTitle,
  setFilters,
  exportProjectsStatus,
  selectTableSearchParam,
} from 'state/Projects/projectsSlice';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { selectUserType } from 'state/User/userSlice';
import { ProjectsTableTab, TableRowDensity } from 'utils/customTypes';
import { ProjectFilters } from 'utils/types/filters';
import { SLICE_STATUS, USER_TYPES } from 'utils/constants';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import ImportProjectModal from './ImportProjectModal/ImportProjectModal';
import ProjectsFiltersSidePanel from './ProjectsFiltersSidePanel/ProjectsFiltersSidePanel';
import AppliedFiltersTags from './ProjectsFiltersSidePanel/AppliedFiltersTags';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import ExportIcon from 'assets/icons/export.svg';
import ImportIcon from 'assets/icons/import.svg';

const TableHeaderFilters: React.FC<{
  table: ProjectsTableTab;
  onExport: (callback: () => void) => void;
  exportEnabled?: Boolean;
  filterButtonEnabled?: Boolean;
  selectRowDensity: (rowDensity: TableRowDensity) => void;
  totalProjects?: number;
}> = ({
  table,
  onExport,
  exportEnabled = false,
  filterButtonEnabled = false,
  selectRowDensity,
  totalProjects = 0,
}) => {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const exportStatus = useSelector(exportProjectsStatus);
  const filtersSettings = useSelector(selectFiltersSettingsByType(table));
  const searchParam = useSelector(selectTableSearchParam(table));
  const isLDUser = useMemo(() => userType === USER_TYPES.L_D, [userType]);
  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as ProjectFilters)
        : ({} as ProjectFilters),
    [filtersSettings]
  );

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setFilters(appliedFilters, table));
    }
  }, [appliedFilters, dispatch, table]);

  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [displayImportProjectModal, setDisplayImportProjectModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (searchParam !== value) {
      setValue(searchParam);
    }
  }, [searchParam, value]);

  const handleSearch = (value: string) => {
    setValue(value);
    dispatch(searchByTitle(value, table));
  };

  const handleUpdateFilters = (filters: ProjectFilters) => {
    dispatch(setFilters(filters, table));
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({ filter_type: table, filter_settings: filters })
      );
    }
  };

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prevState) => !prevState);

  const moreActionsList: OptionProps[] = [
    ...(isLDUser
      ? [
          { label: intl.get('IMPORT'), isSectionTitle: true },
          {
            label: intl.get('IMPORT_PROJECTS.IMPORT'),
            selectOption: () => setDisplayImportProjectModal(true),
            iconSrc: ImportIcon,
          },
        ]
      : []),
    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('PROJECTS_LIST_PAGE.EXPORT_PROJECTS'),
      selectOption: onExport,
      disabled: !exportEnabled,
      tooltip: {
        text: !exportEnabled
          ? intl.get('PROJECTS_LIST_PAGE.EXPORT_TOOLTIP')
          : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'table-header__export-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
  ];

  return (
    <div className='px-px'>
      <ProjectsFiltersSidePanel
        open={shouldDisplayFilterComponent || false}
        onClose={toggleFilterComponent}
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
      />
      <ImportProjectModal
        showModal={displayImportProjectModal}
        onClose={() => setDisplayImportProjectModal(false)}
      />
      <TableOperationHeader
        className='border-b-0'
        inputValue={value}
        setInputValue={handleSearch}
        actionButtons={
          <>
            <RowDensityPopup
              onSelectRowDensity={selectRowDensity}
              disabled={totalProjects === 0}
              tableTestId={table}
            />
            <FilterButton
              onToggleFilter={() => {
                setShouldDisplayFilterComponent((prevState) => !prevState);
              }}
              disabled={!filterButtonEnabled}
              aria-pressed={shouldDisplayFilterComponent}
              data-cy='projects-table__filter-button'
            />
            <MoreActionsPopup options={moreActionsList} />
          </>
        }
      />
      <AppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
        className='border-b-0'
      />
    </div>
  );
};

export default TableHeaderFilters;
