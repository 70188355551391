import React, { useMemo, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  Popup,
  List,
  Typography,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import { MoreActionsOption } from 'utils/customTypes';
import { SUPPORT_POPUP_OPTIONS } from 'utils/constants';
import { selectUser } from 'state/User/userSlice';
import { selectOrganization } from 'state/Organization/organizationSlice';
import { init, showMessenger } from 'Services/intercom';
import { HELPER_CENTER_LINK } from 'utils/constants';
import bookCloCoachIcon from 'assets/icons/book-clo-coach.svg';
import learnOpsCommunity from 'assets/icons/learnops-community.svg';

type SupportOption = {
  isLink?: boolean;
  isExternalLink?: boolean;
  href?: string;
} & MoreActionsOption;

const OptionContent: React.FC<{ option: SupportOption }> = ({ option }) => (
  <React.Fragment>
    <Icon
      className={tailwindOverride(
        'mr-2 text-base',
        { 'group-hover:text-primary': !option.disabled },
        option.iconClassName
      )}
      name={option.iconName}
      src={option.iconSrc}
    />
    <Typography
      className={tailwindOverride(
        'cursor-pointer whitespace-nowrap',
        { 'text-neutral-light': option.disabled },
        {
          'group-hover:text-primary': !option.disabled,
        }
      )}
      variant='label'
    >
      {option.label}
    </Typography>
  </React.Fragment>
);

const Option = ({
  close,
  option,
  selectOption,
}: {
  close: () => void;
  option: SupportOption;
  selectOption: () => void;
}) => (
  <li
    onClick={() => {
      if (option.disabled !== true) {
        selectOption();
        close();
      }
    }}
    className={tailwindOverride(
      'group p-2 flex justify-start items-center cursor-pointer rounded h-10',
      { 'hover:bg-primary-lighter': !option.disabled },
      {
        'text-neutral-light cursor-not-allowed': option.disabled,
      }
    )}
    data-cy={option.dataCy}
  >
    {option.isLink ? (
      <a
        href={option.href!}
        className='flex items-center h-10'
        target={option.isExternalLink ? '_blank' : '_same'}
        rel='noreferrer'
      >
        <OptionContent option={option} />
      </a>
    ) : (
      <OptionContent option={option} />
    )}
  </li>
);

const SupportButton: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  const currentUser = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const [isIntercomInitialized, setIsIntercomInitialized] =
    useState<boolean>(false);

  useEffect(() => {
    if (currentUser && organization && !isIntercomInitialized) {
      init(currentUser, organization);
      setIsIntercomInitialized(true);
    }
  }, [currentUser, organization, isIntercomInitialized]);

  const options = useMemo<SupportOption[]>(
    () => [
      {
        value: SUPPORT_POPUP_OPTIONS.CHAT,
        label: intl.get(
          `SIDEBAR.SUPPORT_POPUP_OPTIONS.${SUPPORT_POPUP_OPTIONS.CHAT}`
        ),
        iconName: 'chatbox',
      },
      {
        value: SUPPORT_POPUP_OPTIONS.LEARNING,
        label: intl.get(
          `SIDEBAR.SUPPORT_POPUP_OPTIONS.${SUPPORT_POPUP_OPTIONS.LEARNING}`
        ),
        iconName: 'albums',
        isExternalLink: true,
        isLink: true,
        href: HELPER_CENTER_LINK,
      },
      {
        value: SUPPORT_POPUP_OPTIONS.SHOW,
        label: intl.get(
          `SIDEBAR.SUPPORT_POPUP_OPTIONS.${SUPPORT_POPUP_OPTIONS.SHOW}`
        ),
        iconName: 'pin',
      },
      {
        value: SUPPORT_POPUP_OPTIONS.BOOK_CLO,
        label: intl.get(
          `SIDEBAR.SUPPORT_POPUP_OPTIONS.${SUPPORT_POPUP_OPTIONS.BOOK_CLO}`
        ),
        iconName: '',
        iconSrc: bookCloCoachIcon,
        isExternalLink: true,
        isLink: true,
        href: 'https://cognota.com/clo-partners/',
      },
      {
        value: SUPPORT_POPUP_OPTIONS.LEARNOPS_COMMUNITY,
        label: intl.get(
          `SIDEBAR.SUPPORT_POPUP_OPTIONS.${SUPPORT_POPUP_OPTIONS.LEARNOPS_COMMUNITY}`
        ),
        iconName: '',
        iconSrc: learnOpsCommunity,
        isExternalLink: true,
        isLink: true,
        href: 'https://learnops.com',
      },
    ],
    []
  );

  const handleSelectOption = (option: SupportOption) => {
    if (option.value === SUPPORT_POPUP_OPTIONS.CHAT) {
      showMessenger();
    }
  };

  return (
    <Popup
      triggerProps={{ className: 'max-w-full mb-2' }}
      popperModifiers={[
        {
          name: 'offset',
          enabled: true,
          options: { offset: [48, 8] },
        },
      ]}
      position='right-end'
      trigger={({ isOpen }) => (
        <button className='flex h-10 items-center w-full text-neutral-white hover:bg-primary-darker hover:bg-opacity-50 focus:bg-primary-darker focus:bg-opacity-50 rounded text-label border-l-4 border-transparent px-2 focus:outline-none space-x-2'>
          <Icon
            name='help-circle'
            className='h-5 w-5 text-neutral-white'
            aria-label={intl.get('SIDEBAR.LOGOUT')}
          />
          {isExpanded && (
            <Typography
              className='text-neutral-white cursor-pointer'
              variant='label'
            >
              {intl.get('SIDEBAR.SUPPORT')}
            </Typography>
          )}
        </button>
      )}
    >
      {({ close }) => (
        <List
          options={options}
          onSelectOption={handleSelectOption}
          className='p-2'
          renderOption={(
            option: SupportOption,
            isSelected,
            selectOption: () => void
          ) => (
            <Option close={close} option={option} selectOption={selectOption} />
          )}
        />
      )}
    </Popup>
  );
};

export default SupportButton;
