import React, { useMemo } from 'react';
import useRequestFilters from 'Pages/RequestsListPage/hooks/useRequestFilters';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  RequestFilters,
  RequestFiltersKey,
  AppliedFilter,
  RangeFilter,
} from 'utils/types/filters';
import { isRangeFilter } from 'utils/typeGuards';
import { DATE } from 'utils/constants';

const AppliedFiltersTags: React.FC<{
  filters: RequestFilters;
  onUpdateFilters: (filters: RequestFilters) => void;
  onDisplayAllFilters: () => void;
}> = ({ filters, onUpdateFilters, onDisplayAllFilters }) => {
  const { getDateFilterLabel, getFilterOptionLabelByKeyAndValue } =
    useRequestFilters();
  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const key of Object.keys(filters)) {
      const filterKey = key as RequestFiltersKey;
      if (isRangeFilter(filters[filterKey])) {
        const filterValue = filters[filterKey] as RangeFilter;
        const label = getDateFilterLabel(filterValue, DATE.TASK_TABLE_FORMAT);
        tags.push({ label, value: '', key });
      } else {
        const filterValues = filters[filterKey] as string[];
        filterValues.forEach((value: string) => {
          const label = getFilterOptionLabelByKeyAndValue(filterKey, value);
          tags.push({ label, value, key });
        });
      }
    }
    return tags;
  }, [filters, getDateFilterLabel, getFilterOptionLabelByKeyAndValue]);
  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value } = filter;
    const filterKey = key as RequestFiltersKey;
    const updatedFilters = { ...filters };
    if (isRangeFilter(filters[filterKey])) {
      delete updatedFilters[filterKey];
    } else {
      const filterValues = updatedFilters[filterKey] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[filterKey];
      } else {
        updatedFilters[filterKey] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };
  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={() => onUpdateFilters({})}
      onDisplayAllFilters={onDisplayAllFilters}
      className={tailwindOverride('border-primary-lighter-two z-20')}
      data-testid='applied-requests-filters-tags'
    />
  );
};

export default AppliedFiltersTags;
