import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { HELPER_CENTER_LINK } from 'utils/constants';
import failedEmpty from 'assets/images/failed-empty.svg';

const TableEmptyErrorState = () => (
  <RenderNoRecords imageSrc={failedEmpty} className='h-empty-table-body'>
    <div className='flex items-center flex-col'>
      <Typography variant='body' className='text-xl font-semibold'>
        {intl.get('EMPTY_TABLE.WRONG')}
      </Typography>
      <span className='text-base w-96 mt-2 whitespace-normal text-center text-neutral-black'>
        {intl.get('EMPTY_TABLE.TRY_AGAIN')}
      </span>
      <a
        href={HELPER_CENTER_LINK}
        target='_blank'
        rel='noreferrer'
        className='text-base text-purple-darker mt-2'
      >
        {intl.get('EMPTY_TABLE.HELP')}
      </a>
    </div>
  </RenderNoRecords>
);

export default TableEmptyErrorState;
