import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  PATHS,
  LICENSE_TIER,
  LICENSE_TIER_FOR_PENDO_DISPLAY,
} from 'utils/constants';
import useAuthentication from 'Hooks/useAuthentication';
import RequestPage from 'Pages/RequestPage/RequestPage';
import RequestsListPage from 'Pages/RequestsListPage/RequestsListPage';
import Loader from 'Molecules/Loader/Loader';
import SideBar from 'Organisms/SideBar/SideBar';
import Sidekick from 'Organisms/Sidekick/Sidekick';
import {
  getOrganization,
  selectOrganizationLicense,
} from 'state/Organization/organizationSlice';
import {
  selectOrganizationId,
  selectUserEmail,
  selectIsUserLd,
  selectUserType,
  selectUserName,
  selectIsFirstUser,
  selectUser
} from 'state/User/userSlice';
import { selectSidekickData } from 'state/Sidekick/sidekickSlice';
import { License } from 'utils/customTypes';
import { initPendo, trackEvent } from 'Services/pendo';
import { PENDO_EVENTS, USER_TYPES } from 'utils/constants';
import InsightsPage from 'Pages/InsightsPage/InsightsPage';
import DesignPage from 'Pages/DesignPage';
import TeamsPage from 'Pages/TeamsPage/TeamsPage';
import ProjectsListPage from 'Pages/ProjectsListPage/ProjectsListPage';
import TasksListPage from 'Pages/TasksListPage/TasksListPage';
import NewProjectPage from 'Pages/NewProjectPage/NewProjectPage';
import ProjectPage from 'Pages/ProjectPage/ProjectPage';
import Notifications from './Notifications';
import Header from 'Pages/Components/Header';
import SettingsPage from 'Pages/SettingsPage/SettingsPage';
import CustomFieldPage from 'Pages/CustomFieldPage/CustomFieldPage';
import NewCustomFieldPage from 'Pages/NewCustomFieldPage/NewCustomFieldPage';
import TaskPage from 'Pages/ProjectPage/tabs/Tasks/TaskPage';
import SidePanel from 'Organisms/SidePanel/SidePanel';
import UserPage from 'Pages/SettingsPage/UsersListPage/UserPage/UserPage';
import TemplatePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TemplatePage/TemplatePage';
import EditTeam from 'Pages/SettingsPage/TeamsSettings/Teams/EditTeam/EditTeam';
import FormPage from 'Pages/SettingsPage/Configurations/IntakeSettings/components/FormPage/FormPage';
import NewForm from 'Pages/SettingsPage/Configurations/IntakeSettings/components/FormPage/NewForm';
import VendorPage from 'Pages/SettingsPage/TeamsSettings/Vendor/VendorPage';
import LandingPage from 'Pages/LandingPage/LandingPage';
import EditProfilePage from 'Pages/EditProfilePage/EditProfilePage';
import MyTimeOff from 'Pages/UserTimeOff/MyTimeOff';
import usePathPermission from 'Hooks/usePathPermission';
import useTrialPermission from 'Hooks/useTrialPermission';
import { getDesigns } from 'state/Designs/designsSlice';
import ProgramsListPage from 'Pages/ProgramsListPage/ProgramsListPage';
import ProgramPage from 'Pages/ProgramPage/ProgramPage';
import NewTemplatePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/NewTemplatePage/NewTemplatePage';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { UserPermissionsProvider } from 'Pages/ProjectPage/context/UserPermissionsContext';
import NewTaskBundlePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/TaskBundlePage';
import EditTaskBundlePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/EditTaskBundlePage/EditTaskBundlePage';

const AppPage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuthentication();
  const currentUserName = useSelector(selectUserName);
  const currentUserEmail = useSelector(selectUserEmail);
  const organizationId = useSelector(selectOrganizationId);
  const userType = useSelector(selectUserType);
  const isFirstUser = useSelector(selectIsFirstUser);
  const isCurrentUserLD = useSelector(selectIsUserLd);
  const sidekickData = useSelector(selectSidekickData);
  const licenseData: License = useSelector(selectOrganizationLicense);
  const ldClient = useLDClient();
  const { hasUserAccess, permissionsLevel } = useHasUserAccess();
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganization(organizationId));
      dispatch(getDesigns());
    }
  }, [organizationId, dispatch]);

  const licenseType = useMemo(() => {
    if (licenseData.license_tier === LICENSE_TIER.REGULAR) {
      return LICENSE_TIER_FOR_PENDO_DISPLAY.REGULAR;
    } else {
      return licenseData.license_tier || '';
    }
  }, [licenseData.license_tier]);

  useEffect(() => {
    if (currentUserEmail && organizationId && licenseType) {
      initPendo(currentUserEmail, organizationId, licenseType, currentUser);
      trackEvent(PENDO_EVENTS.USER_LOGGED_IN, {
        email: currentUserEmail,
        accountType: licenseType,
        firstUser: isFirstUser,
      });
    }
  }, [organizationId, currentUserEmail, licenseType, isFirstUser, currentUser]);

  useEffect(() => {
    if (organizationId && currentUserEmail && currentUserName && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: currentUserEmail,
        name: currentUserName,
        accountId: organizationId,
      });
    }
  }, [organizationId, currentUserEmail, currentUserName, ldClient]);

  usePathPermission();

  useTrialPermission();

  if (!isAuthenticated) {
    return (
      <div className='flex h-screen px-16'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='flex h-screen overflow-hidden'>
      <SideBar />
      <SidePanel />
      {sidekickData.isOpen && <Sidekick />}
      <div className='flex flex-col flex-grow w-11/12 relative'>
        <Header />

        <Notifications />

        <div className='flex-grow overflow-y-auto bg-neutral-white'>
          <Switch>
            <Route exact path={PATHS.ROOT}>
              {userType !== USER_TYPES.BUSINESS ? (
                <LandingPage />
              ) : (
                <Redirect to={PATHS.REQUESTS_LIST_PAGE} />
              )}
            </Route>

            <Route
              path={[`${PATHS.REQUEST_PAGE}/:requestId`, PATHS.REQUEST_PAGE]}
            >
              <RequestPage />
            </Route>

            <Route path={PATHS.REQUESTS_LIST_PAGE}>
              <RequestsListPage />
            </Route>

            <Route path={`${PATHS.NEW_PROJECT_PAGE}/:templateId`}>
              {isCurrentUserLD ? (
                <NewProjectPage />
              ) : (
                <Redirect to={PATHS.PROJECTS_LIST_PAGE} />
              )}
            </Route>

            <Route exact path={`${PATHS.PROJECT_PAGE}/:projectId`}>
              {hasUserAccess ? (
                <UserPermissionsProvider
                  userPermissionsLevel={permissionsLevel}
                >
                  <ProjectPage />
                </UserPermissionsProvider>
              ) : (
                <Redirect to={PATHS.PROJECTS_LIST_PAGE} />
              )}
            </Route>

            <Route
              exact
              path={`${PATHS.PROJECT_PAGE}/:projectId/tasks/:taskId`}
            >
              <TaskPage />
            </Route>

            <Route path={PATHS.INSIGHTS}>
              <InsightsPage />
            </Route>

            <Route path={PATHS.PROJECTS_LIST_PAGE}>
              <ProjectsListPage />
            </Route>

            <Route path={PATHS.TASKS_LIST_PAGE}>
              <TasksListPage />
            </Route>

            <Route path={PATHS.DESIGN}>
              <DesignPage />
            </Route>

            <Route path={[`${PATHS.TEAMS}/:activeTab`, PATHS.TEAMS]}>
              <TeamsPage />
            </Route>

            <Route path={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}/:formId`}>
              <FormPage />
            </Route>

            <Route
              path={`${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}/:fieldId`}
            >
              <CustomFieldPage />
            </Route>

            <Route path={PATHS.NEW_CUSTOM_FIELD_PAGE}>
              <NewCustomFieldPage />
            </Route>

            <Route path={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}`} exact>
              <NewForm />
            </Route>

            <Route
              path={[`${PATHS.SETTINGS}/:activeTab`, PATHS.SETTINGS]}
              exact
            >
              <SettingsPage />
            </Route>

            <Route path={`${PATHS.EDIT_TEAM}/:teamType/:teamId`}>
              <EditTeam />
            </Route>

            <Route path={`${PATHS.USER_PAGE}/:userId/:userType?`}>
              <UserPage />
            </Route>

            <Route path={PATHS.NEW_TEMPLATE_PAGE}>
              <NewTemplatePage />
            </Route>

            <Route path={PATHS.NEW_TASK_BUNDLE_PAGE}>
              <NewTaskBundlePage />
            </Route>

            <Route path={`${PATHS.TASK_BUNDLE_PAGE}/:taskBundleId`}>
              <EditTaskBundlePage />
            </Route>

            <Route path={`${PATHS.TEMPLATE_PAGE}/:templateId`}>
              <TemplatePage />
            </Route>

            <Route path={`${PATHS.VENDOR_PAGE}/:vendorId`}>
              <VendorPage />
            </Route>

            <Route path={PATHS.PROFILE}>
              <EditProfilePage />
            </Route>

            <Route path={PATHS.PROGRAMS_LIST_PAGE}>
              <ProgramsListPage />
            </Route>
            <Route exact path={`${PATHS.PROGRAM_PAGE}/:programId`}>
              <ProgramPage />
            </Route>
            <Route path={PATHS.TIME_OFF}>
              <MyTimeOff />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppPage;
