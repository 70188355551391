import { useEffect, useMemo, Dispatch, SetStateAction, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  FormItem,
  tailwindOverride,
  TextField,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePropertiesComment,
  updatePropertiesComment,
  postPropertiesComment,
} from 'state/RequestComments/requestCommentsSlice';
import { IntakeQuestions } from 'Pages/IntakeQuestions';
import { isNotEmptyArray } from 'Pages/helpers';
import {
  REQUEST_PROPERTIES,
  REQUEST_STATUS,
  REQUEST_SECTIONS,
  LONG_INPUTS_LENGTH,
  USER_ROLES,
  USER_TYPES,
} from 'utils/constants';
import {
  newPropertyCommentType,
  PropertyCommentType,
  Request,
  RequestQuestion,
  RequestRequiredErrors,
  UpdateReqData,
  ProjectCommentMentionsData,
  FormOption,
  User,
} from 'utils/customTypes';
import Comments from 'Organisms/Comments/Comments';
import getPropertyComments from 'Hooks/getPropertyComments';
import {
  getLDUsers,
  selectLDUsersForDropdown,
  allUsers,
  getAllUsers,
  getBusinessReviewers,
  selectBusinessReviewersForDropdown,
  getLDReviewers,
  selectLDReviewersForDropdown,
  selectAllUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { getOrganization } from 'state/Teams/teamsSlice';
import { getForms, selectForms } from 'state/Forms/formSlice';
import {
  selectBusinessTeamsForDropdown,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import {
  selectUserId,
  selectIsUserLd,
  selectOrganizationId,
  selectUser,
} from 'state/User/userSlice';
import { selectIsActiveRequestAForm } from 'state/ActiveRequest/activeRequestSlice';
import UserAvatar from 'Atoms/UserAvatar';
import BusinessUnitPicker from 'Molecules/BusinessUnitPicker/BusinessUnitPicker';
import LinkedProjectsTable from './components/LinkedProjects/LinkedProjectsTable';

type UserOption = {
  label: string;
  avatar: { initial?: string; imageSrc?: string };
  value: string;
};

type Props = {
  requestQuestionsData: RequestQuestion[];
  requestData: Request;
  showComment?: boolean;
  setUpdatedReqData?: Dispatch<React.SetStateAction<UpdateReqData>>;
  propertiesComments?: {
    [key: string]: PropertyCommentType[];
  };
  disableFields?: boolean;
  errors?: RequestRequiredErrors;
  setOwners?: Dispatch<SetStateAction<string[] | null>>;
  setBusinessReviewers?: Dispatch<SetStateAction<string[] | null>>;
  setLDReviewers?: Dispatch<SetStateAction<string[] | null>>;
  questionIdParam?: string;
  propertyNameParam?: string;
  disabledBusinessUnits?: boolean;
  hideViewProject?: boolean;
  requestTitleRef?: React.MutableRefObject<null>;
  shouldUseUserBusinessTeamAsDefault?: boolean;
  isLDReviewer?: boolean;
  updatedReqData?: UpdateReqData;
  sidePanel?: boolean;
};

const BasicDetails = ({
  requestData,
  requestQuestionsData,
  showComment = false,
  propertiesComments = {},
  setUpdatedReqData = () => {},
  disableFields = false,
  errors = {},
  setOwners = () => {},
  updatedReqData,
  setBusinessReviewers = () => {},
  setLDReviewers = () => {},
  questionIdParam = '',
  propertyNameParam = '',
  disabledBusinessUnits = true,
  hideViewProject = false,
  requestTitleRef,
  shouldUseUserBusinessTeamAsDefault = false,
  isLDReviewer = false,
  sidePanel = false,
}: Props) => {
  const dispatch = useDispatch();
  const hasError = useMemo(() => Boolean(errors.title), [errors.title]);
  const requestId = requestData.id!;
  const questions = useMemo<RequestQuestion[]>(() => {
    if (isNotEmptyArray(requestQuestionsData)) {
      return requestQuestionsData.filter(
        (question: { section: string }) =>
          question && question.section === REQUEST_SECTIONS.BASIC_DETAILS
      );
    }

    return [];
  }, [requestQuestionsData]);

  const userId = useSelector(selectUserId);
  const isLDUser = useSelector(selectIsUserLd);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const users = useSelector(allUsers);
  const usersList = useSelector(selectAllUsersForDropdown);
  const allBusinessTeams = useSelector(selectBusinessTeamsForDropdown);
  const isForm = useSelector(selectIsActiveRequestAForm);
  const organizationId = useSelector(selectOrganizationId);
  const forms = useSelector(selectForms);
  const currentUser = useSelector(selectUser);
  const allBusinessReviewers = useSelector(selectBusinessReviewersForDropdown);
  const allLDReviewers = useSelector(selectLDReviewersForDropdown);
  const [selectedRequesterId, setSelectedRequesterId] = useState(
    requestData.requester_id
  );
  const [selectedOwnersIds, setSelectedOwnersIds] = useState(
    requestData.owners?.map((owner) => owner.id) || []
  );
  const [selectedBusinessReviewersIds, setSelectedBusinessReviewersIds] =
    useState(
      requestData.reviewers
        ?.filter((reviewer: User) => reviewer.type === USER_TYPES.BUSINESS)
        .map((reviewer: User) => reviewer.id) || []
    );
  const [selectedLDReviewersIds, setSelectedLDReviewersIds] = useState(
    requestData.reviewers
      ?.filter((reviewer: User) => reviewer.type === USER_TYPES.L_D)
      .map((reviewer: User) => reviewer.id) || []
  );

  const isRequestOwner = useMemo(
    () =>
      currentUser.role === USER_ROLES.ADMIN ||
      requestData.owners?.some((user) => user.id === userId),
    [currentUser.role, requestData.owners, userId]
  );

  const ownersList = useMemo(() => {
    const ownerIds = requestData.owners?.map((owner) => owner.id) || [];

    const rawOwners = users.filter((user) => ownerIds.includes(user.id));

    return rawOwners.map((owner) => ({
      label: `${owner.data.firstName} ${owner.data.lastName}`,
      avatar: {
        imageSrc: owner.avatar_url,
        initial: `${owner.data.firstName.charAt(0)}${owner.data.lastName.charAt(
          0
        )}`,
        name: `${owner.data.firstName} ${owner.data.lastName}`,
      },
      value: owner.id,
    }));
  }, [requestData.owners, users]);

  const selectedBusinessReviewersList = useMemo(() => {
    const reviewersIds =
      requestData.reviewers?.map((reviewer: { id: any }) => reviewer.id) || [];
    const rawOwners = users.filter(
      (user) => reviewersIds.includes(user.id) && user.type === 'business'
    );
    return rawOwners.map((owner) => ({
      label: `${owner.data.firstName} ${owner.data.lastName}`,
      avatar: {
        imageSrc: owner.avatar_url,
        initial: `${owner.data.firstName.charAt(0)}${owner.data.lastName.charAt(
          0
        )}`,
        name: `${owner.data.firstName} ${owner.data.lastName}`,
      },
      value: owner.id,
    }));
  }, [requestData.reviewers, users]);

  const selectedLDReviewersList = useMemo(() => {
    const reviewersIds =
      requestData.reviewers?.map((reviewer: { id: any }) => reviewer.id) || [];
    const rawOwners = users.filter(
      (user) => reviewersIds.includes(user.id) && user.type === 'ld'
    );
    return rawOwners.map((owner) => ({
      label: `${owner.data.firstName} ${owner.data.lastName}`,
      avatar: {
        imageSrc: owner.avatar_url,
        initial: `${owner.data.firstName.charAt(0)}${owner.data.lastName.charAt(
          0
        )}`,
        name: `${owner.data.firstName} ${owner.data.lastName}`,
      },
      value: owner.id,
    }));
  }, [requestData.reviewers, users]);

  useEffect(() => {
    if (isRequestOwner) {
      dispatch(getLDUsers());
    }
    dispatch(getOrganization());
  }, [dispatch, isRequestOwner]);

  useEffect(() => {
    dispatch(getBusinessTeams());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getForms({ organizationId, published: true }));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if ((isRequestOwner || requestData.requester_id === userId) && requestId) {
      dispatch(getBusinessReviewers(requestId));
    }
  }, [dispatch, requestId, isRequestOwner, requestData.requester_id, userId]);

  useEffect(() => {
    if (isRequestOwner && requestId) {
      dispatch(getLDReviewers(requestId));
    }
  }, [dispatch, requestId, isRequestOwner]);

  const onChange = (
    path: string,
    value: string | boolean | string[] | UserOption[] | object
  ) =>
    setUpdatedReqData((prevState: UpdateReqData) => ({
      ...prevState,
      requestAttributes: {
        ...prevState.requestAttributes,
        [path]: value,
      },
    }));

  const onDeleteComment = (commentId: string, propertykey: string) => {
    dispatch(deletePropertiesComment({ commentId, propertykey }));
  };

  const onEditComment = ({
    commentId,
    content,
    mentionsData,
  }: {
    commentId: string;
    content: string;
    mentionsData: ProjectCommentMentionsData;
  }) => {
    dispatch(
      updatePropertiesComment({ commentId, message: content, mentionsData })
    );
  };

  const onCreateComment = (
    content: string,
    mentionsData: ProjectCommentMentionsData,
    propertykey: string
  ) => {
    const newMessage: newPropertyCommentType = {
      message: content,
      isBaseComment: true,
      requestId: requestId,
      requestProperty: propertykey,
      userId: '',
      data: { mentions: mentionsData },
    };
    dispatch(postPropertiesComment(newMessage));
  };

  const changeOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    setSelectedOwnersIds(ownersIds);
    const rawOwners = users.filter((user) => ownersIds.includes(user.id));
    onChange('updatedOwners', ownersIds);
    onChange('owners', rawOwners);
    setOwners(ownersIds);
  };

  const changeBusinessReviewers = (reviewers: UserOption[]) => {
    const businessReviewersIds = reviewers.map(
      (reviewer: { value: string }) => reviewer.value
    );
    setSelectedBusinessReviewersIds(businessReviewersIds);
    setBusinessReviewers(businessReviewersIds);
    onChange('businessReviewers', businessReviewersIds);
    const rawBusinessReviewers = users.filter((user) =>
      businessReviewersIds.includes(user.id)
    );
    const rawLDReviewers = users.filter((user) =>
      selectedLDReviewersIds.includes(user.id)
    );
    onChange('reviewers', rawBusinessReviewers.concat(rawLDReviewers));
  };

  const changeLDReviewers = (reviewers: UserOption[]) => {
    const ldReviewersIds = reviewers.map(
      (reviewer: { value: string }) => reviewer.value
    );
    setSelectedLDReviewersIds(ldReviewersIds);
    setLDReviewers(ldReviewersIds);
    onChange('ldReviewers', ldReviewersIds);
    const rawBusinessReviewers = users.filter((user) =>
      selectedBusinessReviewersIds.includes(user.id)
    );
    const rawLDReviewers = users.filter((user) =>
      ldReviewersIds.includes(user.id)
    );
    onChange('reviewers', rawBusinessReviewers.concat(rawLDReviewers));
  };

  const businessTeams = useMemo(() => {
    if (!isEmpty(forms)) {
      const form = forms.find(
        (item) =>
          item.id ===
          (isForm
            ? get(requestData, 'id')
            : get(requestData, 'data.baseFormId'))
      );
      const teams = get(form, 'businessTeams') || [];
      return teams.map((team) => ({
        label: team.title || '',
        value: team.id,
      }));
    } else {
      return [];
    }
  }, [forms, requestData, isForm]);

  const selectedBusinessTeams = useMemo(() => {
    const currentUserBusinessTeamId = get(currentUser, 'businessTeam');
    if (
      shouldUseUserBusinessTeamAsDefault &&
      disabledBusinessUnits &&
      currentUserBusinessTeamId
    ) {
      const options = allBusinessTeams.filter(
        (option) => currentUserBusinessTeamId === option.value
      );
      return options || [];
    }

    const formBusinessTeams = isForm
      ? []
      : get(requestData, 'businessTeams', []);

    if (!isEmpty(formBusinessTeams)) {
      const options = allBusinessTeams.filter((option) =>
        formBusinessTeams.find(
          (team) => (get(team, 'id') || team) === option.value
        )
      );
      return options || [];
    } else {
      return [];
    }
  }, [
    requestData,
    allBusinessTeams,
    isForm,
    currentUser,
    disabledBusinessUnits,
    shouldUseUserBusinessTeamAsDefault,
  ]);

  const changeBusinessTeams = (businessTeams: FormOption) => {
    onChange('businessTeams', [{ id: businessTeams.value }]);
  };

  const changeRequester = (requesters: UserOption[]) => {
    const requesterId = get(requesters, '[0].value', '');
    setSelectedRequesterId(requesterId);
    const rawRequester = users.filter((user) => user.id === requesterId)[0];
    onChange('requester', rawRequester);
    onChange('requester_id', requesterId);
  };

  const canEditRequester: boolean = useMemo(
    () =>
      Boolean(
        isLDUser &&
          (isRequestOwner || requestData.requester_id === userId) &&
          !disableFields
      ),
    [disableFields, isLDUser, isRequestOwner, requestData.requester_id, userId]
  );
  const ldUsersListsForOwners = useMemo(
    () =>
      ldUsers.filter((user) => !selectedLDReviewersIds?.includes(user.value)),
    [selectedLDReviewersIds, ldUsers]
  );

  const requestersList = useMemo(
    () =>
      usersList.filter(
        (user) =>
          !selectedBusinessReviewersIds?.includes(user.value) &&
          !selectedLDReviewersIds?.includes(user.value)
      ),
    [usersList, selectedBusinessReviewersIds, selectedLDReviewersIds]
  );

  const LDReviewersList = useMemo(
    () =>
      allLDReviewers.filter(
        (user) =>
          !selectedOwnersIds.includes(user.value) &&
          selectedRequesterId !== user.value
      ),
    [allLDReviewers, selectedOwnersIds, selectedRequesterId]
  );

  const businessReviewersList = useMemo(
    () =>
      allBusinessReviewers.filter((user) => selectedRequesterId !== user.value),
    [allBusinessReviewers, selectedRequesterId]
  );

  return (
    <div data-cy='basic-details'>
      {!sidePanel && (
        <>
          <Typography variant='h5' className='mt-8'>
            {intl.get('REQUEST_PAGE.BASIC_DETAILS.TITLE')}
          </Typography>
          <Typography
            variant='caption'
            className='block mb-4 text-neutral-light'
          >
            {intl.get('REQUEST_PAGE.BASIC_DETAILS.CAPTION')}
          </Typography>
        </>
      )}

      <div className='flex flex-col'>
        <div
          className={classnames({
            'grid gap-y-6 gap-x-14 grid-cols-2': !sidePanel,
          })}
        >
          {!sidePanel && (
            <div>
              <div className='relative'>
                {showComment && (
                  <Comments
                    comments={getPropertyComments(
                      propertiesComments,
                      REQUEST_PROPERTIES.REQUEST_TITLE
                    )}
                    className={classnames('absolute', 'right-0')}
                    onEdit={onEditComment}
                    onDelete={(commentId) =>
                      onDeleteComment(
                        commentId,
                        REQUEST_PROPERTIES.REQUEST_TITLE
                      )
                    }
                    onCreate={(content, mentionsData) =>
                      onCreateComment(
                        content,
                        mentionsData,
                        REQUEST_PROPERTIES.REQUEST_TITLE
                      )
                    }
                    testId='request-title'
                    isPopupOpen={
                      propertyNameParam === REQUEST_PROPERTIES.REQUEST_TITLE
                    }
                  />
                )}
                <FormItem data-cy='label__request-title'>
                  <TextField
                    ref={requestTitleRef}
                    label={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_TITLE'
                    )}
                    labelProps={{
                      required: true,
                      state: hasError ? 'error' : 'default',
                    }}
                    variant='text'
                    length='medium'
                    placeholder={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.REQUEST_TITLE'
                    )}
                    displayCharactersCount
                    maxLength={LONG_INPUTS_LENGTH}
                    onChange={(e: { target: { value: string } }) =>
                      onChange('title', e.target.value)
                    }
                    data-cy='field__request-title'
                    disabled={disableFields}
                    state={hasError ? 'error' : 'default'}
                    defaultValue={isForm ? '' : get(requestData, 'title')}
                    helpText={
                      hasError
                        ? intl.get(
                            'REQUEST_PAGE.BASIC_DETAILS.ERRORS.MISSING_INPUT'
                          )
                        : ''
                    }
                  />
                </FormItem>
              </div>
            </div>
          )}

          <div className='relative'>
            {showComment && (
              <Comments
                comments={getPropertyComments(
                  propertiesComments,
                  REQUEST_PROPERTIES.BUSINESS_UNIT
                )}
                className={classnames('absolute', 'right-0')}
                onEdit={onEditComment}
                onDelete={(commentId: string) =>
                  onDeleteComment(commentId, REQUEST_PROPERTIES.BUSINESS_UNIT)
                }
                onCreate={(content, mentionsData) =>
                  onCreateComment(
                    content,
                    mentionsData,
                    REQUEST_PROPERTIES.BUSINESS_UNIT
                  )
                }
                testId='business-unit'
                isPopupOpen={
                  propertyNameParam === REQUEST_PROPERTIES.BUSINESS_UNIT
                }
              />
            )}
            <BusinessUnitPicker
              formItemProps={{
                label: intl.get(
                  'REQUEST_PAGE.BASIC_DETAILS.FIELDS.BUSINESS_UNIT'
                ),
                'data-cy': 'label__business-unit',
                labelProps: {
                  required: true,
                  state: hasError ? 'error' : 'default',
                  className: tailwindOverride({
                    'text-neutral-light': disabledBusinessUnits,
                  }),
                },
              }}
              businessUnits={businessTeams}
              dropdownProps={{
                onChange: changeBusinessTeams,
                values: selectedBusinessTeams,
                triggerProps: { 'data-cy': 'field__business-unit' },
                disabled: disabledBusinessUnits,
                className: classnames({ 'mb-4': sidePanel }),
              }}
            />
          </div>
          {(isRequestOwner || isLDReviewer) &&
            requestData.status !== REQUEST_STATUS.DRAFT && (
              <div>
                <div className='relative'>
                  {showComment && (
                    <Comments
                      comments={getPropertyComments(
                        propertiesComments,
                        REQUEST_PROPERTIES.REQUEST_OWNER
                      )}
                      className={classnames('absolute', 'right-0')}
                      onEdit={onEditComment}
                      onDelete={(commentId: string) =>
                        onDeleteComment(
                          commentId,
                          REQUEST_PROPERTIES.REQUEST_OWNER
                        )
                      }
                      onCreate={(content, mentionsData) =>
                        onCreateComment(
                          content,
                          mentionsData,
                          REQUEST_PROPERTIES.REQUEST_OWNER
                        )
                      }
                      isPopupOpen={
                        propertyNameParam === REQUEST_PROPERTIES.REQUEST_OWNER
                      }
                    />
                  )}
                  <FormItem
                    label={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_OWNER'
                    )}
                    data-cy='label__request-owner'
                    className={classnames({ 'mb-4': sidePanel })}
                  >
                    <UsersPicker
                      usersList={ldUsersListsForOwners}
                      selectedUsersList={ownersList}
                      onChange={changeOwners}
                      required
                      disabled={!isRequestOwner || disableFields}
                      triggerProps={{
                        'data-cy': 'add_owner-button',
                      }}
                    />
                  </FormItem>
                </div>
              </div>
            )}

          {!isForm && requestData.status !== REQUEST_STATUS.DRAFT && (
            <div>
              <div className='relative'>
                {showComment && (
                  <Comments
                    comments={getPropertyComments(
                      propertiesComments,
                      REQUEST_PROPERTIES.REQUESTER
                    )}
                    className='absolute right-0'
                    onEdit={onEditComment}
                    onDelete={(commentId) =>
                      onDeleteComment(commentId, REQUEST_PROPERTIES.REQUESTER)
                    }
                    onCreate={(content, mentionsData) =>
                      onCreateComment(
                        content,
                        mentionsData,
                        REQUEST_PROPERTIES.REQUESTER
                      )
                    }
                    testId='requester'
                    isPopupOpen={
                      propertyNameParam === REQUEST_PROPERTIES.REQUESTER
                    }
                  />
                )}
                <FormItem
                  label={intl.get(
                    'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_CREATOR'
                  )}
                  labelProps={{ required: canEditRequester }}
                  className={classnames({ 'mb-4': sidePanel })}
                >
                  {canEditRequester ? (
                    <UsersPicker
                      usersList={requestersList}
                      selectedUsersList={[
                        {
                          label: `${get(
                            requestData,
                            'requester.data.firstName'
                          )} ${get(requestData, 'requester.data.lastName')}`,
                          avatar: {
                            initial: `${get(
                              requestData,
                              'requester.data.firstName[0]'
                            )}${get(
                              requestData,
                              'requester.data.lastName[0]'
                            )}`,
                            imageSrc: get(requestData, 'requester.avatar_url'),
                          },
                          value: requestData.requester.id,
                        },
                      ]}
                      onChange={changeRequester}
                      triggerText={intl.get(
                        'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUESTER.TRIGGER'
                      )}
                      noSelectedUsersText={intl.get(
                        'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUESTER.PLACEHOLDER'
                      )}
                      maxLimit={1}
                    />
                  ) : (
                    <div className='flex space-x-2 items-center'>
                      <UserAvatar
                        size='small'
                        user={{
                          avatar_url: get(requestData, 'requester.avatar_url'),
                          data: {
                            firstName: get(
                              requestData,
                              'requester.data.firstName'
                            ),
                            lastName: get(
                              requestData,
                              'requester.data.lastName'
                            ),
                          },
                        }}
                      />
                      <Typography
                        variant='body2'
                        className='text-neutral-black'
                      >
                        {`${get(requestData, 'requester.data.firstName')} ${get(
                          requestData,
                          'requester.data.lastName'
                        )}`}
                      </Typography>
                    </div>
                  )}
                </FormItem>
              </div>
            </div>
          )}

          {(isRequestOwner || isLDReviewer) &&
            !isForm &&
            !isEmpty(requestData.requester) &&
            requestData.status !== REQUEST_STATUS.DRAFT && (
              <div>
                <div className='relative'>
                  {showComment && (
                    <Comments
                      comments={getPropertyComments(
                        propertiesComments,
                        REQUEST_PROPERTIES.REQUEST_LD_REVIEWER
                      )}
                      className={classnames('absolute', 'right-0')}
                      onEdit={onEditComment}
                      onDelete={(commentId: string) =>
                        onDeleteComment(
                          commentId,
                          REQUEST_PROPERTIES.REQUEST_LD_REVIEWER
                        )
                      }
                      onCreate={(content, mentionsData) =>
                        onCreateComment(
                          content,
                          mentionsData,
                          REQUEST_PROPERTIES.REQUEST_LD_REVIEWER
                        )
                      }
                      isPopupOpen={
                        propertyNameParam ===
                        REQUEST_PROPERTIES.REQUEST_LD_REVIEWER
                      }
                      testId='request-owner'
                    />
                  )}
                  <FormItem
                    label={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.FIELDS.LD_REVIEWER.REQUEST_LD_REVIEWER'
                    )}
                    data-cy='label__request-ld-reviewer'
                    className={classnames({ 'mb-4': sidePanel })}
                  >
                    <UsersPicker
                      usersList={LDReviewersList}
                      selectedUsersList={selectedLDReviewersList}
                      onChange={changeLDReviewers}
                      triggerText={intl.get(
                        'REQUEST_PAGE.BASIC_DETAILS.FIELDS.LD_REVIEWER.TRIGGER'
                      )}
                      noSelectedUsersText={intl.get(
                        'REQUEST_PAGE.BASIC_DETAILS.FIELDS.LD_REVIEWER.PLACEHOLDER'
                      )}
                      disabled={disableFields || !isRequestOwner}
                      triggerProps={{
                        'data-cy': 'add_ld_reviewer-button',
                      }}
                    />
                  </FormItem>
                </div>
              </div>
            )}

          {!isForm && requestData.status !== REQUEST_STATUS.DRAFT && (
            <div>
              <div className='relative'>
                {showComment && (
                  <Comments
                    comments={getPropertyComments(
                      propertiesComments,
                      REQUEST_PROPERTIES.REQUEST_BUSINESS_REVIEWER
                    )}
                    className={classnames('absolute', 'right-0')}
                    onEdit={onEditComment}
                    onDelete={(commentId: string) =>
                      onDeleteComment(
                        commentId,
                        REQUEST_PROPERTIES.REQUEST_BUSINESS_REVIEWER
                      )
                    }
                    onCreate={(content, mentionsData) =>
                      onCreateComment(
                        content,
                        mentionsData,
                        REQUEST_PROPERTIES.REQUEST_BUSINESS_REVIEWER
                      )
                    }
                    isPopupOpen={
                      propertyNameParam ===
                      REQUEST_PROPERTIES.REQUEST_BUSINESS_REVIEWER
                    }
                  />
                )}
                <FormItem
                  label={intl.get(
                    'REQUEST_PAGE.BASIC_DETAILS.FIELDS.BUSINESS_REVIEWER.REQUEST_BUSINESS_REVIEWER'
                  )}
                  data-cy='label__request-business-reviewer'
                  className={classnames({ 'mb-4': sidePanel })}
                >
                  <UsersPicker
                    usersList={businessReviewersList}
                    selectedUsersList={selectedBusinessReviewersList}
                    onChange={changeBusinessReviewers}
                    triggerText={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.FIELDS.BUSINESS_REVIEWER.TRIGGER'
                    )}
                    noSelectedUsersText={intl.get(
                      'REQUEST_PAGE.BASIC_DETAILS.FIELDS.BUSINESS_REVIEWER.PLACEHOLDER'
                    )}
                    disabled={
                      disableFields ||
                      (!isRequestOwner && requestData.requester_id !== userId)
                    }
                    triggerProps={{
                      'data-cy': 'add_business_reviewer-button',
                    }}
                  />
                </FormItem>
              </div>
            </div>
          )}

          <div>
            <div className='relative'>
              {showComment && (
                <Comments
                  comments={getPropertyComments(
                    propertiesComments,
                    REQUEST_PROPERTIES.FORM_TITLE
                  )}
                  className={classnames('absolute', 'right-0')}
                  onEdit={onEditComment}
                  onDelete={(commentId) =>
                    onDeleteComment(commentId, REQUEST_PROPERTIES.FORM_TITLE)
                  }
                  onCreate={(content, mentionsData) =>
                    onCreateComment(
                      content,
                      mentionsData,
                      REQUEST_PROPERTIES.FORM_TITLE
                    )
                  }
                  isPopupOpen={
                    propertyNameParam === REQUEST_PROPERTIES.FORM_TITLE
                  }
                />
              )}
              <FormItem
                label={intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.FORM_TITLE')}
                labelProps={{ className: 'text-neutral-light' }}
              >
                <TextField
                  variant='text'
                  length='medium'
                  defaultValue={
                    !isForm ? get(requestData, 'form.title') : requestData.title
                  }
                  disabled={isForm || disableFields}
                  readOnly={!isForm && !disableFields}
                  data-cy='field__form-title'
                />
              </FormItem>
            </div>
          </div>
        </div>

        <div
          className={classnames({
            'grid gap-y-6 gap-x-14 grid-cols-2': !sidePanel,
          })}
        >
          {questions && (
            <IntakeQuestions
              questions={questions}
              requestId={requestId}
              showComment={showComment}
              setUpdatedReqData={setUpdatedReqData}
              disableFields={disableFields}
              questionIdParam={questionIdParam}
            />
          )}
        </div>
      </div>
      {requestData.status === REQUEST_STATUS.APPROVED &&
        !sidePanel &&
        requestData.requestProjects?.length! > 0 && (
          <LinkedProjectsTable
            projects={requestData.requestProjects!}
            hideViewProject={!isLDUser || hideViewProject}
            isLDUser={isLDUser}
          />
        )}
    </div>
  );
};

export default BasicDetails;
