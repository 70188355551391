import { useCallback, useEffect, useState } from 'react';
import { TableRowDensity } from 'utils/customTypes';
import TaskBundleTableFilters from './components/TaskBundleTableFilters';
import TaskBundlesTable from './TaskBundleTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTaskBundles,
  taskBundlesForTable,
  updateTaskBundlePagination,
} from 'state/TasksBundle/tasksBundleSlice';

const TaskBundlesList = () => {
  const dispatch = useDispatch();
  const taskBundles = useSelector(taskBundlesForTable);

  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');

  useEffect(() => {
    dispatch(fetchTaskBundles());
  }, [dispatch]);

  const fetchTaskBundlePagination = useCallback(
    (params) => {
      dispatch(updateTaskBundlePagination(params));
    },
    [dispatch]
  );

  return (
    <div className='mt-4 overflow-y-auto'>
      <TaskBundleTableFilters
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
        totalTaskBundles={taskBundles.total}
      />
      <TaskBundlesTable
        taskBundles={taskBundles.data}
        rowDensity={rowDensity}
      />
      <Pagination
        total={taskBundles.total}
        onChange={fetchTaskBundlePagination}
      />
    </div>
  );
};

export default TaskBundlesList;
