import { useMemo, useState, useCallback, useEffect } from 'react';
import { TableOperationHeader, TableFilter } from '@getsynapse/design-system';
import { TableRowDensity, filter } from 'utils/customTypes';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import { useDispatch, useSelector } from 'react-redux';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import intl from 'react-intl-universal';
import {
  setProgramProjectsTableSearch,
  selectProgramProjectsTableSearch,
  setProjectsFilters,
  selectProgramProjectsTableFilters,
  setProjectsFiltersStatus,
} from 'state/Program/programSlice';
import { getFilterInput } from './helpers';
import { PROGRAM_PROJECT_COLUMNS } from 'utils/constants';

const ProgramProjectsTableFilters = ({
  totalProjects,
  selectRowDensity,
}: {
  totalProjects: number;
  selectRowDensity: (rowDensity: TableRowDensity) => void;
}) => {
  const dispatch = useDispatch();
  const searchValue = useSelector(selectProgramProjectsTableSearch);
  const selectedFilters = useSelector(selectProgramProjectsTableFilters);

  const handleSearch = (value: string) => {
    dispatch(setProgramProjectsTableSearch(value));
  };

  const columnOptions: {
    name: string;
    value: string;
    operators: {
      value: string;
      name: string;
    }[];
    type: string;
  }[] = useMemo(() => {
    return PROGRAM_PROJECT_COLUMNS.map(
      (column: {
        value: string;
        operators: string[];
        labelKey: string;
        type: string;
      }) => {
        return {
          name: intl.get(
            `PROGRAM_PAGE.TABLE.COLUMNS.${column.labelKey.toUpperCase()}`
          ),
          ...column,
          operators: column.operators.map((operator) => ({
            name: intl.get(`OPERATORS.${operator}`),
            value: operator,
          })),
        };
      }
    );
  }, []);

  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(setProjectsFiltersStatus(showFilterComponent));
  }, [showFilterComponent, dispatch]);

  const handleSetFilters = useCallback(
    (filters: filter[]) => {
      dispatch(setProjectsFilters(filters));
    },
    [dispatch]
  );

  return (
    <TableOperationHeader
      className='border-b-0 bg-neutral-white'
      inputValue={searchValue}
      setInputValue={handleSearch}
      hideExportButton
      filterComponent={
        <div className='bg-neutral-white'>
          <TableFilter
            columnOptions={columnOptions}
            filterVisible={showFilterComponent}
            filterAction={handleSetFilters}
            filterInputElement={getFilterInput}
            selectedFilters={selectedFilters}
          />
        </div>
      }
      actionButtons={
        <>
          <RowDensityPopup
            onSelectRowDensity={selectRowDensity}
            disabled={!totalProjects}
          />
          <FilterButton
            aria-pressed={showFilterComponent}
            disabled={false}
            onToggleFilter={handleToggleFilterComponent}
          />
        </>
      }
    />
  );
};

export default ProgramProjectsTableFilters;
