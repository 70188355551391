import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { Request, RequestQuestion } from 'utils/customTypes';
import { initAxios } from 'utils/axios';
import { REQUEST_STATUS } from 'utils/constants';

class RequestAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchRequest = async (requestId: string) => {
    const { data } = await this.instance.get(`request/${requestId}`);
    return data;
  };

  deleteRequest = async (requestId: string) => {
    const { data } = await this.instance.delete(`request/${requestId}`);
    return data;
  };

  createRequest: (
    formId: string,
    requestTypeId: string,
    requesterId: string,
    organizationId: string,
    businessTeams?: string[],
    questions?: { [key: string]: RequestQuestion },
    requestData?: { title?: string; published?: boolean },
    requestTitle?: string,
    description?: string,
    status?: string
  ) => Promise<any> = async (
    formId,
    requestTypeId,
    requesterId,
    organizationId,
    businessTeams,
    questions = {},
    requestData = {},
    requestTitle = '',
    description = '',
    status = REQUEST_STATUS.DRAFT
  ) => {
    const { data } = await this.instance.post(`form/${formId}/requests`, {
      requester_id: requesterId,
      request_creator_id: requesterId,
      organizationId: organizationId,
      request_type: requestTypeId,
      data: requestData,
      title: requestTitle,
      description,
      businessTeams,
      questions,
      status,
    });
    return data;
  };

  fetchRequestQuestions = async (requestId: string) => {
    const { data } = await this.instance.get(`request/${requestId}/questions`);
    return data;
  };

  updateRequest = async (requestId: string, updateData: any) => {
    const { data } = await this.instance.put(
      `request/${requestId}/`,
      updateData
    );
    return data;
  };

  updateQuestions = async (requestId: string, updateData: any) => {
    const { data } = await this.instance.put(
      `questions/updateQuestions/${requestId}`,
      updateData
    );
    return data;
  };

  createQuestion = async (requestId: string, questionType: string) => {
    const questionData = { type: questionType, uiController: true };
    const { data } = await this.instance.post(
      `form/${requestId}/questions`,
      questionData
    );
    return data;
  };

  deleteQuestion = async (questionId: string) => {
    const { data } = await this.instance.delete(`questions/${questionId}`);
    return data;
  };

  fetchAllRequests = async (params?: {}) => {
    const { data } = await this.instance.get('request/', { params });
    return data;
  };

  fetchUserRequests = async (params?: {}) => {
    const { data } = await this.instance.get('request/myRequests', { params });
    return data;
  };

  setOwners = async (requestId: string, ownersIds: string[]) => {
    const { data } = await this.instance.put(`request/${requestId}/setOwners`, {
      ownerIds: ownersIds,
    });
    return data;
  };

  linkProjectsToRequest = async (projectIds: string[], requestId: string) => {
    const response = await this.instance.post(
      `request/${requestId}/setLinkProjects`,
      { projectIds }
    );
    return response.data;
  };

  requestDecision = async (requestId: string, decision: string) => {
    const { data } = await this.instance.patch(
      `request/${requestId}/decision`,
      {
        status: { decision },
      }
    );
    return data;
  };

  bulkCreate = async (requests: Partial<Request>[]) => {
    const { data } = await this.instance.post('request/bulkCreate', {
      newRequests: requests,
    });
    return data;
  };

  setReviewers = async (requestId: string, reviewersId: string[]) => {
    const { data } = await this.instance.post(
      `request/${requestId}/setReviewers`,
      {
        reviewerIds: reviewersId,
        requestId: requestId,
      }
    );
    return data;
  };
}
export default new RequestAPI();
