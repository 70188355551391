import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import TasksTable from './TasksTable';
import {
  SortingType,
  TableExportOptions,
  TableRowDensity,
  Task,
} from 'utils/customTypes';
import {
  CentralizedTask,
  CentralizedTasksTableTab,
} from 'utils/types/centralizedTasks';
import TasksTableHeader from './TasksTableHeader';
import { TABLE_EXPORT_OPTIONS } from 'utils/constants';
import Pagination from 'Organisms/Pagination/ServerPagination';
import { RawFilters } from 'utils/filters';
import {
  CENTRALIZED_TASKS_TABLE_TABS,
  CENTRALIZED_TASKS_SORT,
} from 'utils/constants/centralizedTasks';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTasksTablePagination,
  updateMySearchParam,
  updateMyTasksFilters,
  updateMyTasksSort,
  updateTeamSearchParam,
  updateTeamTasksFilters,
  updateTeamTasksSort,
} from 'state/Tasks/taskSlice';
import { PageSizeType } from 'utils/types/pagination';

const Tasks: React.FC<{
  taskTable: CentralizedTasksTableTab;
  tasksList: {
    data: CentralizedTask[];
    total: number;
    allIds: string[];
  };
  onExportTasksToCsv: (taskIds: string[], callback: () => void) => void;
  onUpdatePagination: (pagination: {}) => void;
  onUpdateTask?: () => void;
}> = ({ tasksList, onUpdatePagination, onUpdateTask, taskTable }) => {
  const dispatch = useDispatch();
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');
  const [selectedTaskIds, setSelectedTasks] = useState<string[]>([]);
  const teamTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS)
  );
  const myTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS)
  );

  const { page, size } = useMemo<{ page: number; size: PageSizeType }>(() => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      return {
        page: myTasksTablePagination.offset / myTasksTablePagination.limit + 1,
        size: myTasksTablePagination.limit,
      };
    } else {
      return {
        page:
          teamTasksTablePagination.offset / teamTasksTablePagination.limit + 1,
        size: teamTasksTablePagination.limit,
      };
    }
  }, [
    myTasksTablePagination.limit,
    myTasksTablePagination.offset,
    taskTable,
    teamTasksTablePagination.limit,
    teamTasksTablePagination.offset,
  ]);

  const currentPageTasksIds = useMemo(
    () => tasksList.data.map((task: Task) => task.id),
    [tasksList.data]
  );

  const handleSelectedExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedTasks(tasksList.allIds);
      return;
    }

    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageTasksIds.length > 0
    ) {
      setSelectedTasks(currentPageTasksIds);
      return;
    }

    setSelectedTasks([]);
  };

  const handleSelectTasks = (tasks: string[]) => {
    setSelectedTasks(tasks);
  };

  const onUpdateFilters: (filters: RawFilters) => void = (filters) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMyTasksFilters(filters));
    } else {
      dispatch(updateTeamTasksFilters(filters));
    }
  };

  const handleSearch: (value: string) => void = (value) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMySearchParam(value));
    } else {
      dispatch(updateTeamSearchParam(value));
    }
  };

  const handleSort = (
    sortBy: keyof typeof CENTRALIZED_TASKS_SORT,
    sortOrder: SortingType
  ) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMyTasksSort([sortBy, sortOrder]));
    } else if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS) {
      dispatch(updateTeamTasksSort([sortBy, sortOrder]));
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  return (
    <div className='mt-4'>
      <TasksTableHeader
        taskTable={taskTable}
        onSearch={debouncedHandleSearch}
        isTableEmpty={tasksList.total === 0}
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
        onUpdateFilters={onUpdateFilters}
      />
      <TasksTable
        onSort={handleSort}
        onSelectRows={handleSelectTasks}
        rowDensity={rowDensity}
        tasksList={tasksList.data}
        totalTasks={tasksList.total}
        selectedTaskIds={selectedTaskIds}
        onSelectExportOption={handleSelectedExportOption}
        onUpdateTask={onUpdateTask}
      />
      <Pagination
        total={tasksList.total}
        onChange={onUpdatePagination}
        className='z-10 max-w-full'
        page={page}
        size={size}
      />
    </div>
  );
};

export default Tasks;
