import React from 'react';
import intl from 'react-intl-universal';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import { setTaskId } from 'state/SingleTask/singleTaskSlice';
import { Task } from 'utils/customTypes';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

const TasksDetails: React.FC<{
  task: Task;
}> = ({ task }) => {
  const dispatch = useDispatch();

  return (
    <div className='flex'>
      <div className='w-12' />
      <div className='w-3/4 flex flex-1 flex-col px-6 py-2'>
        <Typography
          variant='caption'
          className={tailwindOverride(
            'text-purple-dark cursor-pointer font-semibold',
            'focus:underline hover:underline focus:outline-none',
            'hover:text-purple-darker focus:text-purple-darker',
            'active:text-purple-darker visited:text-purple-darker'
          )}
          role='button'
          onClick={() => dispatch(setTaskId(task.id))}
          data-testid={`allocation-task-${task.id}`}
        >
          {task.name}
        </Typography>
        <Typography variant='caption' className='text-neutral-dark'>
          {`${intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.TABLE.ASSIGNMENT_ESTIMATED_TIME',
            { estimated: `${task.estimate_hours} ` }
          )}`}
          {task.actual_hours &&
            ` | ${intl.get(
              'PEOPLE.RESOURCE_ALLOCATION.TABLE.ASSIGNMENT_ACTUAL_TIME',
              { hours: `${task.actual_hours} ` }
            )}`}
        </Typography>
      </div>
      <div className='w-1/4 flex py-2 px-4'>
        {getStatusColumn(task.status, false, 'self-start')}
      </div>
    </div>
  );
};

export default TasksDetails;
