import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  RawTeamCapacity,
  TeamMembersAndManagersCapacity,
  ProjectLevelAllocations,
} from './teamsCapacityAPI';
import {
  TeamsCapacity,
  TeamCapacity,
  TeamMemberCapacity,
  ProjectCapacity,
  WeeksCarouselPagination,
} from 'utils/types/teamsCapacity';
import {
  PROJECT_STATUS,
  PROJECT_HEALTH,
  TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS,
} from 'utils/constants';

export const getNewWeeksCarouselPagination = (
  weeksCarouselPagination: WeeksCarouselPagination
): WeeksCarouselPagination => {
  const { startDate, endDate, index } = weeksCarouselPagination;
  const momentStartDate = moment(new Date(startDate.replace(/-/g, '/')));
  const momentEndDate = moment(new Date(endDate.replace(/-/g, '/')));
  if (momentEndDate.diff(momentStartDate, 'weeks') === 21) {
    return weeksCarouselPagination;
  }
  const pivotWeek = momentStartDate.clone().add(index, 'weeks');
  const newStartDate = pivotWeek
    .clone()
    .subtract(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS, 'weeks')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');
  const newEndDate = pivotWeek
    .clone()
    .add(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS * 2, 'weeks')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');
  return {
    startDate: newStartDate,
    endDate: newEndDate,
    index: TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS,
  };
};

export const sortProjectLevelAllocationsKeysByTitle = (
  projects: Record<string, ProjectLevelAllocations>
): string[] => {
  return Object.keys(projects).sort((a, b) => {
    const projectA = projects[a].project;
    const projectB = projects[b].project;
    if (projectA.title < projectB.title) {
      return -1;
    }
    if (projectA.title > projectB.title) {
      return 1;
    }
    return 0;
  });
};

export const sortTeamMembersKeysByName = (
  teamMembers: Record<string, TeamMembersAndManagersCapacity>
): string[] => {
  return Object.keys(teamMembers).sort((a, b) => {
    const teamMemberA = teamMembers[a].user;
    const teamMemberB = teamMembers[b].user;

    const teamMemberAName = `${teamMemberA.data.firstName} ${teamMemberA.data.lastName}`;
    const teamMemberBName = `${teamMemberB.data.firstName} ${teamMemberB.data.lastName}`;
    if (teamMemberAName < teamMemberBName) {
      return -1;
    }
    if (teamMemberAName > teamMemberBName) {
      return 1;
    }
    return 0;
  });
};

const mapProjectsCapacity: (
  projectLevelAllocations: Record<string, ProjectLevelAllocations>,
  projectsIdsWithoutTasks: string[]
) => {
  userRoles: string[];
  projectsCapacity: Record<string, ProjectCapacity>;
} = (projectLevelAllocations, projectsIdsWithoutTasks) => {
  const userRoles = [] as string[];
  const projectsCapacity: Record<string, ProjectCapacity> = {};
  const sortedProjectLevelAllocationsKeys =
    sortProjectLevelAllocationsKeysByTitle(projectLevelAllocations);
  sortedProjectLevelAllocationsKeys.forEach((key) => {
    const { project, projectAllocations } = projectLevelAllocations[key];
    const projectCapacity = {} as ProjectCapacity;
    projectCapacity.data = {
      id: project.id || '',
      title: project.title || '',
      priority: project.priority || null,
      status: project.status || PROJECT_STATUS.NEW,
      health: project.health || PROJECT_HEALTH.ON_TRACK,
      startDate: project.startDate || '',
      targetCompletionDate: project.targetCompletionDate || '',
      userRoles: project.userProjectRoles || [],
      hasTasks: !projectsIdsWithoutTasks.includes(project.id),
    };
    projectCapacity.allocations = projectAllocations;
    for (const role of project.userProjectRoles) {
      if (!userRoles.includes(role)) {
        userRoles.push(role);
      }
    }
    projectsCapacity[project.id] = projectCapacity;
  });
  return { userRoles, projectsCapacity };
};

const mapTeamMembersCapacity: (
  teamMembersAndManagersCapacity: Record<
    string,
    TeamMembersAndManagersCapacity
  >,
  projectsIdsWithoutTasks: string[]
) => Record<string, TeamMemberCapacity> = (
  teamMembersAndManagersCapacity,
  projectsIdsWithoutTasks
) => {
  const teamMembersCapacity: Record<string, TeamMemberCapacity> = {};
  const teamManagersCapacity: Record<string, TeamMemberCapacity> = {};
  const sortedTeamMembersKeys = sortTeamMembersKeysByName(
    teamMembersAndManagersCapacity
  );
  sortedTeamMembersKeys.forEach((key) => {
    const { user, allocations, projectLevelAllocations } =
      teamMembersAndManagersCapacity[key];
    const teamMemberCapacity = {} as TeamMemberCapacity;
    teamMemberCapacity.data = {
      id: user.id || '',
      avatar_url: get(user, 'avatar_url', '') || '',
      firstName: get(user, 'data.firstName', '') || '',
      lastName: get(user, 'data.lastName', '') || '',
      email: get(user, 'data.email', '') || '',
      isTeamManager: user.isTeamManager || false,
      default_capacity: user.default_capacity || 0,
      roles: [],
    };
    teamMemberCapacity.allocations = allocations;
    const { userRoles, projectsCapacity } = mapProjectsCapacity(
      projectLevelAllocations,
      projectsIdsWithoutTasks
    );
    teamMemberCapacity.data.roles = userRoles;
    teamMemberCapacity.projects = projectsCapacity;
    if (user.isTeamManager) {
      teamManagersCapacity[user.id] = teamMemberCapacity;
    } else {
      teamMembersCapacity[user.id] = teamMemberCapacity;
    }
  });
  return { ...teamManagersCapacity, ...teamMembersCapacity };
};

const mapTeamCapacity: (
  rawTeamCapacity: RawTeamCapacity,
  projectsIdsWithoutTasks: string[]
) => TeamCapacity = (rawTeamCapacity, projectsIdsWithoutTasks) => {
  const { learningTeam, teamCapacity, teamMembersAndManagersCapacity } =
    rawTeamCapacity;
  const team = {} as TeamCapacity;
  team.data = {
    id: learningTeam.id || '',
    name: learningTeam.name || '',
    maximumTeamCapacity: learningTeam.maximumTeamCapacity || 0,
  };
  team.allocations = teamCapacity;
  const members = mapTeamMembersCapacity(
    teamMembersAndManagersCapacity,
    projectsIdsWithoutTasks
  );
  team.members = members;
  return team;
};

export const whereToAddWeeklyCapacity = (
  prevPagination: WeeksCarouselPagination,
  newPagination: WeeksCarouselPagination
): 'end' | 'start' => {
  const prevStartWeek = moment(
    new Date(prevPagination.startDate.replace(/-/g, '/'))
  );
  const prevEndWeek = moment(
    new Date(prevPagination.endDate.replace(/-/g, '/'))
  );
  const newStartWeek = moment(
    new Date(newPagination.startDate.replace(/-/g, '/'))
  );
  const newEndWeek = moment(new Date(newPagination.endDate.replace(/-/g, '/')));
  if (newEndWeek.isSameOrBefore(prevStartWeek)) {
    return 'start';
  }
  if (newStartWeek.isSameOrAfter(prevEndWeek)) {
    return 'end';
  }
  return 'start';
};

export const mapApiResponseToTeamsCapacity = (
  apiResponse: RawTeamCapacity[],
  projectsIdsWithoutTasks: string[] = []
): TeamsCapacity => {
  const teamsCapacity: Record<string, TeamCapacity> = {};
  for (const rawTeamCapacity of apiResponse) {
    const team = mapTeamCapacity(rawTeamCapacity, projectsIdsWithoutTasks);
    teamsCapacity[team.data.id] = team;
  }
  return teamsCapacity;
};

export const addWeeklyCapacityToTeams = (
  apiResponse: RawTeamCapacity[],
  currentTeamsCapacity: TeamsCapacity,
  addCapacityAt: 'end' | 'start'
): TeamsCapacity => {
  const updatedTeamsCapacity = cloneDeep(currentTeamsCapacity);
  for (const {
    learningTeam,
    teamCapacity,
    teamMembersAndManagersCapacity,
  } of apiResponse) {
    const teamId = learningTeam.id || '';
    const team = { ...updatedTeamsCapacity[teamId] };
    if (!team) {
      continue;
    }
    const members = Object.entries(teamMembersAndManagersCapacity);
    for (const [memberId, member] of members) {
      const currentMember = { ...team.members[memberId] };
      if (!currentMember) {
        continue;
      }
      const projects = Object.values(member.projectLevelAllocations);
      for (const project of projects) {
        const currentProject = {
          ...currentMember.projects[project.project.id],
        };
        if (!currentProject) {
          continue;
        }
        if (addCapacityAt === 'start') {
          currentProject.allocations = {
            ...project.projectAllocations,
            ...currentProject.allocations,
          };
        } else {
          currentProject.allocations = {
            ...currentProject.allocations,
            ...project.projectAllocations,
          };
        }
        currentMember.projects[project.project.id] = currentProject;
      }
      if (addCapacityAt === 'start') {
        currentMember.allocations = {
          ...member.allocations,
          ...currentMember.allocations,
        };
      } else {
        currentMember.allocations = {
          ...currentMember.allocations,
          ...member.allocations,
        };
      }
      team.members[memberId] = currentMember;
    }
    if (addCapacityAt === 'start') {
      team.allocations = {
        ...teamCapacity,
        ...currentTeamsCapacity[teamId].allocations,
      };
    } else {
      team.allocations = {
        ...currentTeamsCapacity[teamId].allocations,
        ...teamCapacity,
      };
    }
    updatedTeamsCapacity[teamId] = team;
  }
  return updatedTeamsCapacity;
};

export const addTeamsToCurrentCapacity = (
  apiResponse: RawTeamCapacity[],
  currentTeamsCapacity: TeamsCapacity,
  projectsIdsWithoutTasks: string[] = []
): TeamsCapacity => {
  const updatedTeamsCapacity = cloneDeep(currentTeamsCapacity);
  for (const {
    learningTeam,
    teamCapacity,
    teamMembersAndManagersCapacity,
  } of apiResponse) {
    const teamId = learningTeam.id || '';
    if (!updatedTeamsCapacity[teamId]) {
      const newTeam = mapTeamCapacity(
        {
          learningTeam,
          teamCapacity,
          teamMembersAndManagersCapacity,
        },
        projectsIdsWithoutTasks
      );
      updatedTeamsCapacity[teamId] = newTeam;
    } else {
      const oldTeam = { ...updatedTeamsCapacity[teamId] };
      const members = Object.entries(teamMembersAndManagersCapacity);
      for (const [memberId, member] of members) {
        const currentMember = { ...oldTeam.members[memberId] };
        const projects = Object.values(member.projectLevelAllocations);
        for (const project of projects) {
          const currentProject = {
            ...currentMember.projects[project.project.id],
          };
          currentProject.allocations = {
            ...project.projectAllocations,
          };
          currentMember.projects[project.project.id] = currentProject;
        }
        currentMember.allocations = {
          ...member.allocations,
        };
        oldTeam.members[memberId] = currentMember;
      }
      oldTeam.allocations = {
        ...teamCapacity,
      };
      updatedTeamsCapacity[teamId] = oldTeam;
    }
  }
  return updatedTeamsCapacity;
};
