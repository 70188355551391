import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { has, isEmpty, get, orderBy } from 'lodash';
import { selectUserId } from 'state/User/userSlice';
import {
  allUsers,
  allActiveUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { getOriginalProjectData } from 'state/Project/projectSlice';
import {
  TaskDetailType,
  Task_Status,
  NewProject,
  AllUsersType,
  UserAvatars,
  ProjectContent,
  TaskActualHours,
} from 'utils/customTypes';
import { TASK_FIELDS } from 'utils/constants';
import { taskFields } from 'Pages/ProjectPage/tabs/Tasks/helpers/constants';
import singleTaskAPI from './singleTaskAPI';
import { RootState } from 'state/store';

interface taskState {
  value: TaskDetailType;
  taskStatus: Task_Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: taskState = {
  value: taskFields,
  taskStatus: 'new',
};

/* ============================= ACTIONS ============================== */
export const setTaskDetail = createAction<TaskDetailType>(
  'singleTask/SET_TASK_DETAIL'
);

export const addContentFiles = createAction<ProjectContent[]>(
  'singleTask/ADD_CONTENT_FILES'
);

export const setTaskId = createAction<string>('singleTask/SET_TASK_ID');

const taskAPI = singleTaskAPI;
/* ============================== REDUX THUNK =============================== */

export const fetchTask = createAsyncThunk(
  'singleTask/FETCH_TASK',
  async (taskId: string) => {
    const response = await taskAPI.fetchTask(taskId);
    return response.data.task;
  }
);

export const fetchTaskActualHours = createAsyncThunk(
  'singleTask/FETCH_TASK_ACTUAL_HOURS',
  async (taskId: string) => {
    const response = await taskAPI.fetchTaskActualHours(taskId);
    return response.data.actualHours || [];
  }
);

export const updateTaskActualHours = createAsyncThunk(
  'singleTask/UPDATE_TASK_ACTUAL_HOURS',
  async ({
    taskId,
    newActualHours,
    removedActualHours,
  }: {
    taskId: string;
    newActualHours: TaskActualHours[];
    removedActualHours: string[];
  }) => {
    const response = await taskAPI.updateTaskActualHours(
      taskId,
      newActualHours,
      removedActualHours
    );
    return response.data.updatedActualHours;
  }
);

export const resetTask = createAction('project/RESET_TASK');

export const updateTask = createAsyncThunk(
  'singleTask/UPDATE_TASK',
  async (updateData: { taskId: string; data: TaskDetailType | any }) => {
    let fieldsToUpdate = { ...updateData.data };
    let updatedStatus = null;
    let updatedAssignedUsers = undefined;

    if (has(fieldsToUpdate, TASK_FIELDS.STATUS)) {
      const { status, ...remainingTaskData } = fieldsToUpdate;
      updatedStatus = status;
      fieldsToUpdate = { ...remainingTaskData };
    }

    if (has(fieldsToUpdate, TASK_FIELDS.ASSIGNEE_UPDATE)) {
      const { assignedUsers, ...remainingTaskData } = fieldsToUpdate;
      updatedAssignedUsers = assignedUsers;
      fieldsToUpdate = { ...remainingTaskData };
    }

    let updatedtask = fieldsToUpdate;
    if (!isEmpty(fieldsToUpdate)) {
      updatedtask = await taskAPI.updateTask(updateData.taskId, fieldsToUpdate);
    }

    if (updatedStatus !== null) {
      await taskAPI.updateStatus(updateData.taskId, updatedStatus);
      updatedtask[TASK_FIELDS.STATUS] = updatedStatus;
    }

    if (updatedAssignedUsers !== undefined) {
      const response = await taskAPI.updateAssignees(
        updateData.taskId,
        updatedAssignedUsers
      );
      updatedtask[TASK_FIELDS.ASSIGNEE_UPDATE] = response.assignedUsers;
    }

    return { ...updatedtask };
  }
);

export const updateLinkedContent = createAsyncThunk(
  'singleTask/UPDATE_LINKED_CONTENT',
  async (params: { taskId: string; linkedContentFiles: ProjectContent[] }) => {
    const contentFilesIds = params.linkedContentFiles.map(
      (linkedContentFile: ProjectContent) => linkedContentFile.id!
    );
    await taskAPI.updateLinkedContent(params.taskId, contentFilesIds);
    return params.linkedContentFiles;
  }
);

export const updateTaskEnablement = createAsyncThunk(
  'singleTask/UPDATE_TASK_ENABLEMENT',
  async ({ taskId, disabled }: { taskId: string; disabled: boolean }) => {
    await taskAPI.updateEnablement(taskId, disabled);
    return disabled;
  }
);

/* ================================= REDUCER ================================ */
const singleTaskSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTask.fulfilled, (state, action) => {
        state.value = { ...state.value, ...action.payload };
      })
      .addCase(fetchTask.fulfilled, (state, action) => {
        const { assignedUsers = [], ...remainingTaskData } = action.payload;
        const ownersIds = assignedUsers?.map((owner: any) => owner.id || '');

        state.value = {
          ...remainingTaskData,
          assignedUsers: ownersIds,
          taskActualHours: state.value.taskActualHours,
        };
      })
      .addCase(setTaskDetail, (state, action) => {
        state.value = action.payload;
      })
      .addCase(resetTask, (state) => {
        state.value = taskFields;
      })
      .addCase(updateTaskEnablement.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          disabled: action.payload,
        };
      })
      .addCase(addContentFiles, (state, action) => {
        state.value = {
          ...state.value,
          linkedContent: state.value.linkedContent?.concat(action.payload),
        };
      })
      .addCase(setTaskId, (state, action) => {
        state.value = {
          ...state.value,
          id: action.payload,
        };
      })
      .addCase(updateLinkedContent.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          linkedContent: action.payload,
        };
      })
      .addCase(fetchTaskActualHours.fulfilled, (state, action) => {
        state.value.taskActualHours = orderBy(
          action.payload,
          'actual_hours_date',
          'asc'
        );
      })
      .addCase(updateTaskActualHours.fulfilled, (state, action) => {
        state.value.taskActualHours = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const getSingleTaskData = (state: RootState) => state.singleTask.value;
export const getSingleTaskId = (state: RootState) => state.singleTask.value.id;

export const getLinkedTaskContentFilesIds = createSelector(
  [getSingleTaskData],
  (taskData: TaskDetailType) =>
    taskData.linkedContent?.map(
      (linkedContent: ProjectContent) => linkedContent.id
    )
);

const getTaskAssignees = createSelector(
  [getSingleTaskData],
  (task: TaskDetailType) => {
    if (!task) {
      return [];
    }
    return task.assignedUsers
      ? task.assignedUsers.map((assignee) => {
          if (typeof assignee === 'string') {
            return assignee;
          } else {
            return assignee.id;
          }
        })
      : [];
  }
);

export const isCurrentUserAssignedToTask = createSelector(
  [getTaskAssignees, selectUserId],
  (assignees: string[], currentUserId: string = '') => {
    return assignees.includes(currentUserId);
  }
);

export const getFormattedTaskAssignees = createSelector(
  [getTaskAssignees, allUsers],
  (assignees: string[], users: AllUsersType[]) => {
    return users
      .filter((user: AllUsersType) => assignees.includes(user.id))
      .map((user: AllUsersType) => ({
        label: `${user.data.firstName} ${user.data.lastName}`,
        avatar: {
          imageSrc: user.avatar_url,
          initial: `${user.data?.firstName?.charAt(
            0
          )}${user.data?.lastName?.charAt(0)}`,
          name: `${user.data?.firstName} ${user.data?.lastName}`,
        },
        value: user.id,
      })) as UserAvatars[];
  }
);

export const getAvailableUsersForTaskAssignees = createSelector(
  [getOriginalProjectData, getTaskAssignees, allActiveUsers],
  (project: NewProject, assignees: string[], users: AllUsersType[]) => {
    const usersList: string[] = [];
    for (let owner of project.owners) {
      if (!assignees.includes(get(owner, 'project_owners.userId'))) {
        usersList.push(get(owner, 'project_owners.userId'));
      }
    }

    if (project.participants) {
      for (let member of project.participants) {
        if (!assignees.includes(get(member, 'project_participants.userId'))) {
          usersList.push(get(member, 'project_participants.userId'));
        }
      }
    }

    if (project.collaborators) {
      for (let collaborator of project.collaborators) {
        if (
          !assignees.includes(get(collaborator, 'project_collaborators.userId'))
        ) {
          usersList.push(get(collaborator, 'project_collaborators.userId'));
        }
      }
    }
    return users
      .filter((user: AllUsersType) => usersList.includes(user.id))
      .map((user: AllUsersType) => ({
        label: `${user.data.firstName} ${user.data.lastName}`,
        avatar: {
          imageSrc: user.avatar_url,
          initial: `${user.data?.firstName?.charAt(
            0
          )}${user.data?.lastName?.charAt(0)}`,
          name: `${user.data?.firstName} ${user.data?.lastName}`,
        },
        value: user.id,
      })) as UserAvatars[];
  }
);

export default singleTaskSlice.reducer;
