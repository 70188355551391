import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { TableOperationHeader, TableFilter } from '@getsynapse/design-system';
import { selectLearningTeamsForDropdown } from 'state/LearningTeams/learningTeamsSlice';
import { selectBusinessTeamsForDropdown } from 'state/BusinessTeams/businessTeamsSlice';
import {
  setSearchParam,
  setUserFilters,
  exportUsersStatus,
} from 'state/UsersManagement/usersManagementSlice';
import { isUserOrganizationAdmin } from 'state/User/userSlice';
import { FormOption, filter } from 'utils/customTypes';
import { USERS_COLUMNS, SLICE_STATUS } from 'utils/constants';
import userFilterInput from './userFilterInput';
import useImportLDUsers from './hooks/useImportLDUsers';
import useImportBusinessUsers from './hooks/useImportBusinessUsers';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import ExportIcon from 'assets/icons/export.svg';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';

const UsersTableHeadOperations: React.FC<{
  isFilterButtonDisabled: boolean;
  isExportButtonDisabled: boolean;
  handleExport: (callback: () => void) => void;
  toggleFilterComponent: () => void;
  showFilterComponent: boolean;
}> = ({
  isFilterButtonDisabled = true,
  isExportButtonDisabled = true,
  handleExport,
  toggleFilterComponent,
  showFilterComponent,
}) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(isUserOrganizationAdmin);
  const learningTeams = useSelector(selectLearningTeamsForDropdown);
  const businessTeams = useSelector(selectBusinessTeamsForDropdown);
  const exportStatus = useSelector(exportUsersStatus);
  const importLDUsersButtonProps = useImportLDUsers();
  const importBusinessUsersButtonProps = useImportBusinessUsers();
  const [searchParamValue, setSearchParamValue] = useState('');

  const handleSearch = (value: string) => {
    setSearchParamValue(value);
    dispatch(setSearchParam(value));
  };

  const handleSetFilters = useCallback(
    (filters: filter[]) => {
      dispatch(setUserFilters(filters));
    },
    [dispatch]
  );

  const teamsOptions: FormOption[] = [...learningTeams, ...businessTeams];

  const columnOptions: {
    name: string;
    value: string;
    operators: {
      value: string;
      name: string;
    }[];
    type: string;
  }[] = useMemo(() => {
    return USERS_COLUMNS.map(
      (column: {
        value: string;
        operators: string[];
        labelKey: string;
        type: string;
      }) => {
        return {
          name:
            column.labelKey === 'PROVINCE' || column.labelKey === 'COUNTRY'
              ? intl.get(
                  `SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.${column.labelKey}`
                )
              : intl.get(`USERS_PAGE.TABLE.HEAD.${column.labelKey}`),
          ...column,
          operators: column.operators.map((operator) => ({
            name: intl.get(`OPERATORS.${operator}`),
            value: operator,
          })),
        };
      }
    );
  }, []);

  const moreActionsList = [
    ...(isAdmin
      ? [
          { label: intl.get('IMPORT'), isSectionTitle: true },
          {
            label: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_LEARNING_USERS'),
            isImportButton: true,
            disabled:
              isEmpty(importLDUsersButtonProps.currentUser) ||
              isEmpty(importLDUsersButtonProps.settings),
            importButtonProps: importLDUsersButtonProps,
          },
          importBusinessUsersButtonProps.isDataReady && {
            label: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_BUSINESS_USERS'),
            isImportButton: true,
            disabled:
              isEmpty(importBusinessUsersButtonProps.currentUser) ||
              isEmpty(importBusinessUsersButtonProps.settings),
            importButtonProps: importBusinessUsersButtonProps,
          },
        ]
      : []),
    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('USERS_PAGE.TABLE.EXPORT.EXPORT_USERS'),
      selectOption: handleExport,
      disabled: isExportButtonDisabled,
      tooltip: {
        text: isExportButtonDisabled
          ? intl.get('USERS_PAGE.TABLE.EXPORT.EXPORT_TOOLTIP')
          : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'export-users-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
  ] as OptionProps[];

  return (
    <TableOperationHeader
      className='border-0 rounded-b-none bg-neutral-white'
      inputValue={searchParamValue}
      setInputValue={handleSearch}
      filterComponent={
        <div className='bg-neutral-white'>
          <TableFilter
            columnOptions={columnOptions}
            filterVisible={showFilterComponent}
            filterAction={handleSetFilters}
            filterInputElement={(column, filterInput, setInput) =>
              userFilterInput(teamsOptions, column, filterInput, setInput)
            }
          />
        </div>
      }
      actionButtons={
        <>
          <FilterButton
            aria-pressed={showFilterComponent}
            disabled={isFilterButtonDisabled}
            onToggleFilter={toggleFilterComponent}
          />
          <MoreActionsPopup options={moreActionsList} />
        </>
      }
    />
  );
};

export default UsersTableHeadOperations;
