import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { Program } from 'utils/customTypes';
import get from 'lodash/get';

class ProgramAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchProgram = async (programId: string) => {
    const { data } = await this.instance.get(
      `program/retrieveProgram/${programId}`
    );
    return data;
  };

  fetchProjectsForProgram = async (programId: string) => {
    const { data } = await this.instance.get(
      `program/${programId}/retrieveProjectsForProgram`
    );
    return data;
  };

  updateProgram = async (programId: string, updateFields: Partial<Program>) => {
    const response = await this.instance.put(
      `program/updateProgram/${programId}`,
      { updateFields }
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  updateProgramOwners = async (programId: string, ownersIds: string[]) => {
    const response = await this.instance.post(
      `program/${programId}/setOwners`,
      {
        ownersIds,
      }
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  updateProgramProjects = async (programId: string, projectIds: string[]) => {
    const response = await this.instance.post(
      `program/${programId}/setProjects`,
      {
        projectIds,
      }
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };
}

export default new ProgramAPI();
