import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { objKeyAsString, Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import FiltersSettingsApi from './FiltersSettingsApi';
import { RootState } from 'state/store';

interface FiltersSlice {
  filters: {
    [key: string]: {
      id: string;
      type: string;
      settings: objKeyAsString;
    };
  };
  state: Status;
}

export interface NewFilterSettings {
  filter_type: string;
  filter_settings: objKeyAsString;
}

const filtersSettingsApi = FiltersSettingsApi;

/* ============================= INITIAL STATE ============================== */
const initialState: FiltersSlice = {
  filters: {},
  state: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const fetchFilterSettingByType = createAsyncThunk(
  'filtersSettings/FETCH_FILTER_SETTING_BY_ID',
  async (filterType: string, { rejectWithValue }) => {
    try {
      const response = await filtersSettingsApi.retrieveFilterSettingByType(
        filterType
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createFilterSetting = createAsyncThunk(
  'filtersSettings/CREATE_FILTER_SETTING',
  async (newFilterSettings: NewFilterSettings, { rejectWithValue }) => {
    try {
      const response = await filtersSettingsApi.createFilterSetting(
        newFilterSettings
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateFilterSetting = createAsyncThunk(
  'filtersSettings/UPDATE_FILTER_SETTIN',
  async (
    params: { id: string; updateFields: { filter_settings: objKeyAsString } },
    { rejectWithValue }
  ) => {
    try {
      const response = await filtersSettingsApi.updateFilterSetting(
        params.id,
        params.updateFields
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/* ================================= REDUCER ================================ */
const filtersSlice = createSlice({
  name: 'filtersSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterSettingByType.pending, (state) => {
        state.state = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFilterSettingByType.fulfilled, (state, action) => {
        state.state = SLICE_STATUS.IDLE;
        if (action.payload) {
          state.filters = {
            ...state.filters,
            [action.payload.filter_type]: {
              id: action.payload.id,
              type: action.payload.filter_type,
              settings: action.payload.filter_settings,
            },
          };
        }
      })
      .addCase(fetchFilterSettingByType.rejected, (state) => {
        state.state = SLICE_STATUS.FAILED;
      })
      .addCase(createFilterSetting.pending, (state) => {
        state.state = SLICE_STATUS.LOADING;
      })
      .addCase(createFilterSetting.fulfilled, (state, action) => {
        state.state = SLICE_STATUS.IDLE;
        state.filters = {
          ...state.filters,
          [action.payload.filter_type]: {
            id: action.payload.id,
            type: action.payload.filter_type,
            settings: action.payload.filter_settings,
          },
        };
      })
      .addCase(createFilterSetting.rejected, (state) => {
        state.state = SLICE_STATUS.FAILED;
      })
      .addCase(updateFilterSetting.pending, (state) => {
        state.state = SLICE_STATUS.LOADING;
      })
      .addCase(updateFilterSetting.fulfilled, (state, action) => {
        state.state = SLICE_STATUS.IDLE;
        state.filters = {
          ...state.filters,
          [action.payload.filter_type]: {
            id: action.payload.id,
            type: action.payload.filter_type,
            settings: action.payload.filter_settings,
          },
        };
      })
      .addCase(updateFilterSetting.rejected, (state) => {
        state.state = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectFiltersSettingsByType =
  (filterType: string) => (store: RootState) =>
    store.filtersSettings.filters[filterType]
      ? {
          settings: store.filtersSettings.filters[filterType].settings,
          id: store.filtersSettings.filters[filterType].id,
        }
      : null;

export const selectFiltersSliceStatus = (store: RootState) =>
  store.filtersSettings.state;

export default filtersSlice.reducer;
