import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useEffect, useState } from 'react';
import {
  Icon,
  useElevation,
  Typography,
  NotificationPopup,
  StaticNotification,
  Button,
} from '@getsynapse/design-system';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  PATHS,
  NOTIFICATION_STATUS,
  UPDATE_PROJECT_TABS,
  LICENSE_TIER,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'state/User/userSlice';
import { openSidekick } from 'state/Sidekick/sidekickSlice';
import {
  getNotifications,
  selectNotifications,
  markAllAsRead,
  updateNotification,
  markAllAsSeen,
} from 'state/Notifications/notificationsSlice';
import { Notification, FormattedNotification } from 'utils/customTypes';
import { formatNotifications } from 'utils/notificationsHelper';
import {
  selectOrganizationLicense,
  selectRequestsPortalName,
  selectTrialEndDate,
} from 'state/Organization/organizationSlice';
import sparkles from 'assets/icons/sparkles.svg';
import get from 'lodash/get';
import ProfileDropdown from './ProfileDropdown';
import { fetchProgram, getProgramData } from 'state/Program/programSlice';

interface taskPageMatchParams {
  projectId: string;
}

const SidekickButton = () => {
  const dispatch = useDispatch();

  return (
    <Button
      iconName='planet-outline'
      variant='tertiary'
      onClick={() => {
        dispatch(openSidekick());
      }}
      size='small'
    >
      {intl.get('SIDEKICK')}
    </Button>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const fromPage: string = get(location, 'state.from', '');
  const requestPage = useRouteMatch(PATHS.REQUEST_PAGE);
  const customFieldPage = useRouteMatch(
    `${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}`
  );
  const newCustomFieldPage = useRouteMatch(PATHS.NEW_CUSTOM_FIELD_PAGE);
  const newProjectPage = useRouteMatch(`${PATHS.NEW_PROJECT_PAGE}/:templateId`);
  const projectPage = useRouteMatch(`${PATHS.PROJECT_PAGE}/:projectId`);
  const singleTaskPage = useRouteMatch<taskPageMatchParams>(
    `${PATHS.PROJECT_PAGE}/:projectId/tasks/:taskId`
  );
  const editLearningTeamPage = useRouteMatch<taskPageMatchParams>(
    `${PATHS.EDIT_TEAM}/ld/:teamId`
  );
  const editBusinessTeamPage = useRouteMatch<taskPageMatchParams>(
    `${PATHS.EDIT_TEAM}/business/:teamId`
  );
  const userPage = useRouteMatch(PATHS.USER_PAGE);
  const formPage = useRouteMatch(`${PATHS.SETTINGS}${PATHS.FORM_PAGE}`);
  const vendorPage = useRouteMatch(PATHS.VENDOR_PAGE);
  const templatePage = useRouteMatch(`${PATHS.TEMPLATE_PAGE}/:templateId`);
  const programPage = useRouteMatch(`${PATHS.PROGRAM_PAGE}/:programId`);
  const newTaskBundlePage = useRouteMatch(PATHS.NEW_TASK_BUNDLE_PAGE);
  const editTaskBundlePage = useRouteMatch(
    `${PATHS.TASK_BUNDLE_PAGE}/:taskBundleId`
  );

  const programData = useSelector(getProgramData);
  const programId = fromPage.split('/')[2];

  useEffect(() => {
    if (
      projectPage?.isExact &&
      fromPage.includes(`${PATHS.PROGRAM_PAGE}/`) &&
      !isEmpty(programId)
    ) {
      dispatch(fetchProgram(programId));
    }
  }, [dispatch, fromPage, programId, projectPage?.isExact]);

  const user = useSelector(selectUser);
  const skimClass = useElevation(1);
  const [notificationsList, setNotificationsList] = useState<
    FormattedNotification[]
  >([]);
  const portalName = useSelector(selectRequestsPortalName);
  const { license_tier } = useSelector(selectOrganizationLicense);
  const trialEndDate = useSelector(selectTrialEndDate);
  const previousPath = get(history, 'location.state.from') || '';

  const trialEndDays = useMemo<number>(() => {
    const timeToEnd =
      moment(trialEndDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days') || 0;

    return timeToEnd < 0 ? 0 : timeToEnd;
  }, [trialEndDate]);

  const { linkTo, linkText } = useMemo<{
    linkTo?: string;
    linkText?: string;
  }>(() => {
    if (projectPage || newProjectPage) {
      if (
        (singleTaskPage?.isExact || projectPage?.isExact) &&
        fromPage?.includes(PATHS.TASKS_LIST_PAGE)
      ) {
        return {
          linkTo: `${PATHS.TASKS_LIST_PAGE}`,
          linkText: intl.get('TASKS_LIST_PAGE.BACK_LINK'),
        };
      } else if (singleTaskPage && singleTaskPage.isExact) {
        return {
          linkTo: `${PATHS.PROJECT_PAGE}/${singleTaskPage.params.projectId}?tab=${UPDATE_PROJECT_TABS.TASKS}`,
          linkText: intl.get('TASKS.TASK_DETAIL_PAGE.SINGULAR_TITLE'),
        };
      } else if (fromPage && fromPage.includes(PATHS.PROGRAM_PAGE)) {
        return {
          linkTo: fromPage,
          linkText: programData.title,
        };
      } else if (previousPath && previousPath === PATHS.TEAMS) {
        return {
          linkTo: PATHS.TEAMS,
          linkText: intl.get('TEAMS.CAPACITY'),
        };
      } else {
        return {
          linkTo: PATHS.PROJECTS_LIST_PAGE,
          linkText: intl.get('NEW_PROJECT_PAGE.ALL_PROJECTS_LINK'),
        };
      }
    } else if (requestPage) {
      return {
        linkTo: PATHS.REQUESTS_LIST_PAGE,
        linkText: portalName,
      };
    } else if (editLearningTeamPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.LD_TEAM}`,
        linkText: intl.get('ENTITIES.LEARNING_TEAM', { num: 2 }),
      };
    } else if (editBusinessTeamPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.BUSINESS_TEAM}`,
        linkText: intl.get('ENTITIES.BUSINESS_TEAM', { num: 2 }),
      };
    } else if (vendorPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.VENDORS}`,
        linkText: intl.get('ENTITIES.VENDOR', { num: 2 }),
      };
    } else if (userPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.USERS}`,
        linkText: intl.get('ENTITIES.USER', { num: 2 }),
      };
    } else if (formPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`,
        linkText: intl.get('ENTITIES.REQUEST_FORM', { num: 2 }),
      };
    } else if (customFieldPage || newCustomFieldPage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.FIELDS}`,
        linkText: intl.get('ENTITIES.FIELDS', { num: 2 }),
      };
    } else if (templatePage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TEMPLATES}`,
        linkText: intl.get('ENTITIES.TEMPLATE', { num: 2 }),
      };
    } else if (programPage) {
      return {
        linkTo: PATHS.PROGRAMS_LIST_PAGE,
        linkText: intl.get('ENTITIES.PROGRAMS'),
      };
    } else if (newTaskBundlePage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`,
        linkText: intl.get('ENTITIES.TASK_BUNDLE', { num: 2 }),
      };
    } else if (editTaskBundlePage) {
      return {
        linkTo: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`,
        linkText: intl.get('ENTITIES.TASK_BUNDLE', { num: 2 }),
      };
    }

    return {
      linkTo: undefined,
      linkText: undefined,
    };
  }, [
    projectPage,
    newProjectPage,
    requestPage,
    editLearningTeamPage,
    editBusinessTeamPage,
    vendorPage,
    userPage,
    formPage,
    templatePage,
    programPage,
    fromPage,
    programData,
    customFieldPage,
    newCustomFieldPage,
    newTaskBundlePage,
    editTaskBundlePage,
    singleTaskPage,
    previousPath,
    portalName,
  ]);

  const notificationsListSelector = useSelector(selectNotifications);

  const hasNewNotifications = useMemo(
    () =>
      notificationsListSelector.some(
        (notification: Notification) =>
          notification.status === NOTIFICATION_STATUS.NEW
      ),
    [notificationsListSelector]
  );

  const hasUnreadNotications = useMemo(
    () =>
      notificationsListSelector.some(
        (notification: Notification) =>
          notification.status !== NOTIFICATION_STATUS.READ
      ),
    [notificationsListSelector]
  );

  const handleClick = (linkTo: string) => {
    if (linkTo) {
      if (fromPage) {
        history.push(linkTo, {
          from: fromPage,
        });
      } else {
        history.push(linkTo);
      }
    }
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (notificationsListSelector.length > 0) {
      setNotificationsList(
        formatNotifications(notificationsListSelector, user)
      );
    }
  }, [notificationsListSelector, user]);

  const markAllAsReadHandle = () => {
    dispatch(markAllAsRead());
  };

  const markAsRead = (id: string) => {
    dispatch(
      updateNotification({ id, newData: { status: NOTIFICATION_STATUS.READ } })
    );
  };

  const markAllAsSeenHandle = () => {
    if (hasNewNotifications) {
      dispatch(markAllAsSeen());
    }
  };

  const { sidekickEarlyAccess } = useFlags();

  return (
    <header
      className={classnames(
        skimClass,
        'h-10',
        'flex-shrink-0',
        'flex',
        'w-full',
        'justify-between',
        'items-center',
        'px-6'
      )}
    >
      <div className='flex items-center space-x-2'>
        {linkTo && (
          <span
            onClick={() => handleClick(linkTo)}
            className='flex font-body text-body2-sm lg:text-body2 font-normal text-neutral-dark cursor-pointer'
          >
            <Icon
              name='arrow-back-outline'
              className='py-1.5 mr-2 font-body font-normal lg:text-body2 text-body2-sm'
            />
            {linkText}
          </span>
        )}
      </div>

      <div className='flex space-x-4 items-center' data-cy='notifications-list'>
        {license_tier === LICENSE_TIER.TRIAL && (
          <div className='flex space-x-3 items-center'>
            <Typography variant='label' className='text-neutral'>
              {intl.get('DAYS_LEFT', { days: trialEndDays })}
            </Typography>
            <a
              href='https://cognota.com/upgrade-free-trial'
              target='_blank'
              rel='noreferrer'
            >
              <Button
                iconSrc={sparkles}
                color='tertiary'
                className='py-0.5 px-2'
              >
                {intl.get('UPGRADE')}
              </Button>
            </a>
          </div>
        )}

        {sidekickEarlyAccess && <SidekickButton />}

        <NotificationPopup
          hasNewNotifications={hasNewNotifications}
          hasUnreadNotications={hasUnreadNotications}
          markAllHandle={markAllAsReadHandle}
          openNotificationHandle={markAllAsSeenHandle}
          triggerProps={{
            'data-cy': 'notification_bell-button',
            'aria-label': intl.get('NOTIFICATIONS'),
          }}
        >
          {!isEmpty(notificationsList) &&
            notificationsList.map((notification) => (
              <Link to={notification.link} key={notification.id}>
                <StaticNotification
                  avatar={notification.avatar}
                  time={notification.time}
                  unread={notification.status !== 'read'}
                  data-cy={`notification-${notification.id}`}
                  onClick={() => markAsRead(notification.id || '')}
                >
                  {notification.content}
                </StaticNotification>
              </Link>
            ))}
        </NotificationPopup>
        <ProfileDropdown user={user} />
      </div>
    </header>
  );
};

export default Header;
