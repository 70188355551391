import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { tailwindOverride } from '@getsynapse/design-system';
import { selectWeeksCarouselItems } from 'state/TeamsCapacity/teamsCapacitySlice';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import {
  CapacityCarousel,
  CapacityCarouselItem,
} from '../../CapacityCarousel/CapacityCarousel';
import { WeeksCarouselLoader } from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/SkeletonLoader';
import WeeklyCapacity from './WeeklyCapacity/WeeklyCapacity';

const TeamCapacityCarousel: React.FC<{
  teamId: string;
  maximumTeamCapacity: number;
  capacity: Record<
    string,
    { allocation: number; totalWeeklyTimeOffsTotal?: number }
  >;
  displayCapacity?: boolean;
  isLastTeam: boolean;
}> = ({
  teamId,
  capacity,
  maximumTeamCapacity,
  displayCapacity = true,
  isLastTeam,
}) => {
  const weeksCaourselItems = useSelector(selectWeeksCarouselItems);
  return (
    <CapacityCarousel
      id={`team-${teamId}`}
      className={tailwindOverride({
        'shadow-capacity-table-allocation-status': !isLastTeam,
      })}
      loader={
        <WeeksCarouselLoader
          className={tailwindOverride(
            'w-full',
            'absolute top-0 left-0',
            'bg-neutral-white',
            'z-10'
          )}
        />
      }
    >
      {weeksCaourselItems.map((week: Week, index: number) => {
        const weekKey = moment(week.startWeekDate).format('YYYY-MM-DD');
        const allocation = capacity[weekKey]?.allocation || 0;
        const weeklyTimeOff = capacity[weekKey]?.totalWeeklyTimeOffsTotal || 0;
        return (
          <CapacityCarouselItem
            id={`team-${teamId}__weekly-capacity__week-${weekKey}`}
            key={`team-${teamId}__weekly-capacity__week-${weekKey}`}
            index={index}
            isCurrentWeek={week.isCurrentWeek || false}
            className={tailwindOverride({
              'shadow-capacity-table-allocation-status':
                week.isCurrentWeek && !isLastTeam,
            })}
          >
            {displayCapacity && (
              <div className='flex items-center h-full'>
                <WeeklyCapacity
                  weeklyCapacity={maximumTeamCapacity}
                  weeklyTimeOff={weeklyTimeOff}
                  assignedHours={allocation}
                />
              </div>
            )}
          </CapacityCarouselItem>
        );
      })}
    </CapacityCarousel>
  );
};

export default TeamCapacityCarousel;
