import React from 'react';
import intl from 'react-intl-universal';
import {
  Icon,
  Tooltip,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import dayOffIcon from 'assets/icons/day-off.svg';

const TimeOffAllocation: React.FC<{
  timeOff: number;
  'data-testid'?: string;
  'data-cy'?: string;
}> = ({ timeOff, ...otherProps }) => {
  const timeLabel = `${timeOff} ${intl.get(
    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
  )}`;
  return (
    <Tooltip
      className='flex w-full py-1'
      trigger={
        <div
          className={tailwindOverride(
            'w-full h-6 bg-fire-lighter',
            'flex justify-center items-center cursor-pointer',
            'border-b-2 border-fire-darker'
          )}
        >
          <Icon
            src={dayOffIcon}
            className='text-fire-darker mr-2 text-lg'
            aria-label='time-off-icon'
          />
          <Typography
            className='text-fire-darker'
            weight='medium'
            varian='label'
          >
            {timeLabel}
          </Typography>
        </div>
      }
      ariaId='review-allocation-warning'
      showPopper
      usePortal
      openMode='hover2'
      timeout={0}
      position='topCenter'
      contentProps={{
        className: tailwindOverride(
          'bg-primary-darker',
          'rounded-lg p-4',
          'w-max absolute',
          'text-xs',
          'shadow-tooltip'
        ),
      }}
      {...otherProps}
    >
      <div className='flex justify-between'>
        <Typography
          variant='caption'
          className='text-neutral-white font-semibold mr-4'
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.TIME_OFF_TOOLTIP')}
        </Typography>
        <Typography variant='label' className='text-neutral-white'>
          {timeLabel}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default TimeOffAllocation;
