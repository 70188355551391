import {
  Program,
  filter,
  SortingType,
  Status,
  DeleteProgramFlag,
} from 'utils/customTypes';
import ProgramAPI from './programsAPI';
import { RootState } from 'state/store';
import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { orderProgramsBy, filterPrograms } from './helpers';
import { get } from 'lodash';

interface ProgramState {
  programs: Program[];
  filters: filter[];
  pagination: {
    limit: number;
    offset: number;
  };
  sorting: {
    orderBy: string;
    order: SortingType;
  };
  searchParam: string;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ProgramState = {
  programs: [],
  filters: [],
  pagination: {
    limit: 15,
    offset: 0,
  },
  sorting: {
    order: 'asc',
    orderBy: '',
  },
  searchParam: '',
  status: SLICE_STATUS.IDLE,
};

const programsAPI = ProgramAPI;

/* ============================== REDUX THUNK =============================== */
export const createNewProgram = createAsyncThunk(
  'programs/CREATE_PROGRAM',
  async (newProgramData: Program) => {
    const response = await programsAPI.createProgram(newProgramData);
    if (response.success) {
      return response.data;
    }
    return response;
  }
);

export const fetchPrograms = createAsyncThunk(
  'programs/FETCH_PROGRAM',
  async () => {
    const response = await programsAPI.fetchTeamPrograms();
    return response;
  }
);

export const setProgramsSortingOptions = createAction(
  'programs/SET_ORDERS',
  (column: string, order: SortingType) => {
    return { payload: { order: order, orderBy: column } };
  }
);

export const setProgramsSearch = createAction(
  'programs/SET_SEARCH',
  (search: string) => {
    return { payload: search };
  }
);

export const updatePagination = createAction(
  'programs/UPDATE_PAGINATION',
  (pagination) => {
    return { payload: pagination };
  }
);

export const setProgramsFilters = createAction<filter[]>(
  'programs/SET_PROGRAMS_FILTERS'
);

export const deleteProgramAction = createAsyncThunk(
  'programs/DELETE_PROGRAM',
  async (
    {
      programId,
      flag,
      newProgramId,
    }: {
      programId: string;
      flag: DeleteProgramFlag;
      newProgramId: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const response = await programsAPI.deleteProgram(
      programId,
      flag,
      newProgramId
    );
    if (response.success) {
      return state.program.value;
    }
    return response;
  }
);

/* ================================= REDUCER ================================ */
const programsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewProgram.fulfilled, (state, action) => {
        if (get(action, 'payload.data.createdProgram', null)) {
          state.programs = [
            ...state.programs,
            action.payload.data.createdProgram,
          ];
        }
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        state.programs = [...get(action, 'payload.data', [])];
        const apiCallSucceeded = get(action, 'payload.success', false);
        if (apiCallSucceeded) {
          state.status = SLICE_STATUS.IDLE;
        } else {
          state.status = SLICE_STATUS.FAILED;
        }
      })
      .addCase(fetchPrograms.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchPrograms.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(setProgramsSortingOptions, (state, action) => {
        state.sorting = action.payload;
      })
      .addCase(setProgramsSearch, (state, action) => {
        state.searchParam = action.payload;
      })
      .addCase(updatePagination, (state, action) => {
        state.pagination = action.payload;
      })
      .addCase(setProgramsFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(deleteProgramAction.fulfilled, (state, action) => {
        if (get(action, 'payload.id', null)) {
          state.programs = state.programs.filter((program) => {
            return program.id !== action.payload.id;
          });
        }
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectAllPrograms = (state: RootState) => state.programs.programs;
export const programsSearch = (state: RootState) => state.programs.searchParam;
export const programsFilters = (state: RootState) => state.programs.filters;
export const programsSorting = (state: RootState) => state.programs.sorting;
export const selectProgramsSliceStatus = (state: RootState) =>
  state.programs.status;
export const programsPagination = (state: RootState) =>
  state.programs.pagination;

export const selectFilteredSortedPrograms = createSelector(
  [selectAllPrograms, programsSearch, programsSorting, programsFilters],
  (programs, search, sorting, filters) => {
    const filteredPrograms = filterPrograms(programs, search, filters);
    const sortedPrograms = orderProgramsBy(
      filteredPrograms,
      sorting.orderBy,
      sorting.order
    );
    return sortedPrograms;
  }
);

export const selectPaginatedPrograms = createSelector(
  [selectFilteredSortedPrograms, programsPagination],
  (programs, pagination) => {
    return programs.slice(pagination.offset, pagination.limit);
  }
);

export const selectProgramsPaginationData = createSelector(
  [programsPagination, selectFilteredSortedPrograms],
  (pagination, programs) => {
    const count = programs.length;
    return {
      ...pagination,
      count,
    };
  }
);

export default programsSlice.reducer;
