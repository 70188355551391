import intl from 'react-intl-universal';
import {
  PATHS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { addTaskBundle } from 'state/TasksBundle/tasksBundleSlice';
import { Location } from 'history';
import {
  bulkCreateOrUpdateTemplates,
  bulkDeleteTaskTemplatesForBundle,
  fetchCurrentBundle,
  fetchTaskTemplatesForBundle,
  selectActiveBundle,
  selectActiveBundleTemplates,
  selectActiveBundleStatus,
  updateBundle,
} from 'state/ActiveTaskBundle/activeTaskBundleSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import { store } from 'state/store';
import { Tabs, useElevation, Button } from '@getsynapse/design-system';
import BundleOverview from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/components/BundleOverview';
import BuildBundle from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/components/BuildBundle';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskBundle, TaskTemplate } from 'utils/customTypes';
import { isEmpty, isEqual, get } from 'lodash';
import {
  getTemplatesToBeCreatedOrUpdated,
  getTemplatesToBeDeleted,
} from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/helpers';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import DeleteTaskBundle from '../DeleteTaskBundle/DeleteTaskBundle';
import Loader from 'Molecules/Loader/Loader';

const TaskBundlePage = ({ isEditing }: { isEditing?: boolean }) => {
  const history = useHistory();
  const footerElevation = useElevation(1);
  const dispatch = useDispatch() as typeof store.dispatch;
  const [canSave, setCanSave] = useState(false);
  const [bundleBasicData, setBundleBasicData] = useState({} as TaskBundle);
  const [isAddingNewTask, setIsAddingNewTask] = useState(false);
  const [templates, setTemplates] = useState<TaskTemplate[]>([]);
  const [isEditingRowData, setIsEditingRowData] = useState(false);
  const [newAndUpdatedTemplatesIds, setNewAndUpdatedTemplatesIds] = useState<
    Set<string>
  >(new Set([]));
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');

  const handleFieldChange = (field: string, value: string) => {
    setBundleBasicData((prevData) => ({ ...prevData, [field]: value }));
  };

  const params = useParams<{
    taskBundleId: string;
  }>();
  const activeBundle = useSelector(selectActiveBundle);
  const activeBundleStatus = useSelector(selectActiveBundleStatus);
  const activeBundleTemplates = useSelector(selectActiveBundleTemplates);

  useEffect(() => {
    if (isEditing && params.taskBundleId) {
      dispatch(fetchCurrentBundle(params.taskBundleId));
      dispatch(fetchTaskTemplatesForBundle(params.taskBundleId));
    }
  }, [dispatch, params.taskBundleId, isEditing]);

  useEffect(() => {
    if (activeBundle && isEditing) {
      setBundleBasicData(activeBundle);
    }
  }, [activeBundle, isEditing]);

  useEffect(() => {
    if (activeBundleTemplates && isEditing) {
      setTemplates(activeBundleTemplates);
    }
  }, [activeBundleTemplates, isEditing]);

  useEffect(() => {
    const handleCanSaveOrEdit = () => {
      if (isAddingNewTask || isEditingRowData) {
        setCanSave(false);
        return;
      }
      const isBundleOverviewValid =
        bundleBasicData.name && bundleBasicData.description;

      if (isEditing) {
        const overViewChanged = !isEqual(bundleBasicData, activeBundle);
        const templatesChanged = !isEqual(templates, activeBundleTemplates);
        if (
          (overViewChanged || templatesChanged) &&
          isBundleOverviewValid &&
          !canSave
        ) {
          setCanSave(true);
        } else if (
          ((!overViewChanged && !templatesChanged) || !isBundleOverviewValid) &&
          canSave
        ) {
          setCanSave(false);
        }
      } else {
        if (isBundleOverviewValid && !canSave) {
          setCanSave(true);
        } else if (!isBundleOverviewValid && canSave) {
          setCanSave(false);
        }
      }
    };
    handleCanSaveOrEdit();
  }, [
    activeBundle,
    isEditingRowData,
    activeBundleTemplates,
    canSave,
    isAddingNewTask,
    bundleBasicData,
    isEditing,
    templates,
  ]);

  const handleLeavingPageWithUnsavedChanges = (path: string) => {
    setLeavingToLocation(path);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, leavingToLocation, setConfirmedNavigation, history]);
  const bundlesPageRoute = `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`;
  const returnToTablePage = () =>
    handleLeavingPageWithUnsavedChanges(bundlesPageRoute);

  const handleCreateTaskBundle = async () => {
    if (canSave) {
      const { taskBundle } = await dispatch(
        addTaskBundle(bundleBasicData)
      ).unwrap();
      if (templates.length > 0 && taskBundle && taskBundle.id) {
        const templatesToCreate = templates.map((template, index) => {
          return {
            ...template,
            action_type: 'create',
            index,
          };
        });

        dispatch(
          bulkCreateOrUpdateTemplates({
            templates: templatesToCreate,
            bundleId: taskBundle.id,
          })
        );
      }
    }
    returnToTablePage();
    dispatch(
      showNotification({
        autoHide: true,
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.CREATED_SUCCESSFULLY'
        ),
      })
    );
  };

  const handleUpdateTaskBundle = async () => {
    if (canSave) {
      const bundleId = activeBundle?.id;
      const overViewChanged = !isEqual(bundleBasicData, activeBundle);

      if (overViewChanged) {
        await dispatch(updateBundle(bundleBasicData));
      }
      const originalTemplates = [...activeBundleTemplates];

      const templatesToDelete = getTemplatesToBeDeleted({
        originalTemplateData: originalTemplates,
        updatedTemplateData: templates,
      });

      const templatesToCreateOrUpdate = getTemplatesToBeCreatedOrUpdated({
        originalTemplateData: originalTemplates,
        updatedTemplateData: templates,
        newAndUpdatedTemplatesIds,
      });

      let creatorIsNotified = false;

      if (templatesToDelete.length > 0) {
        await dispatch(
          bulkDeleteTaskTemplatesForBundle({
            bundleId,
            templatesToDelete,
          })
        );
        creatorIsNotified = true;
      }

      if (templatesToCreateOrUpdate.length > 0) {
        await dispatch(
          bulkCreateOrUpdateTemplates({
            templates: templatesToCreateOrUpdate,
            bundleId,
            creatorIsNotified,
          })
        );
      }
      setNewAndUpdatedTemplatesIds(new Set([]));

      dispatch(
        showNotification({
          autoHide: true,
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.UPDATED_SUCCESSFULLY'
          ),
        })
      );

      dispatch(fetchTaskTemplatesForBundle(params.taskBundleId));
    }
  };

  const handleClick = () => {
    if (isEditing) {
      handleUpdateTaskBundle();
    } else {
      handleCreateTaskBundle();
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  const changesDetected =
    (isEditing && canSave) ||
    (!isEditing &&
      (!isEmpty(templates) ||
        Boolean(get(bundleBasicData, 'name')) ||
        Boolean(get(bundleBasicData, 'description'))));

  return (
    <>
      {activeBundleStatus === 'loading' ? (
        <Loader />
      ) : (
        <div className='h-full flex flex-col shadow-skim'>
          <Prompt when={changesDetected} message={handleBlockedNavigation} />
          <UnsavedChangesModal
            isOpen={isLeavingWarningModalOpen}
            setIsOpen={setIsLeavingWarningModalOpen}
            onConfirm={() => setConfirmedNavigation(true)}
          />
          <PageTitle
            titleComponent={
              isEditing
                ? activeBundle.name
                : intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.ADD_TASK_BUNDLE')
            }
          />
          <DetailsPage
            topBar={isEditing && <DeleteTaskBundle data={bundleBasicData} />}
            bodyClassName='overflow-y-visible'
            content={
              <Tabs
                data={[
                  {
                    label: intl.get(
                      'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUNDLE_OVERVIEW_TAB'
                    ),
                    content: (
                      <BundleOverview
                        data={bundleBasicData}
                        isEditing={isEditing}
                        handleFieldChange={handleFieldChange}
                      />
                    ),
                  },
                  {
                    label: intl.get(
                      'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUNDLE_BUILD_TAB'
                    ),
                    content: (
                      <BuildBundle
                        templates={templates}
                        setTemplates={setTemplates}
                        isEditing={isEditing}
                        isAddingNewTask={isAddingNewTask}
                        setIsAddingNewTask={setIsAddingNewTask}
                        setNewAndUpdatedTemplates={setNewAndUpdatedTemplatesIds}
                        isEditingRowData={isEditingRowData}
                        setIsEditingRowData={setIsEditingRowData}
                      />
                    ),
                  },
                ]}
              />
            }
          />
          <div
            className={classNames(
              'w-full flex justify-end py-2 px-12',
              footerElevation
            )}
          >
            <Button
              variant='tertiary'
              className='mr-4'
              onClick={() => returnToTablePage()}
            >
              {intl.get('CANCEL')}
            </Button>
            <Button
              disabled={!canSave}
              className={classNames({ 'cursor-not-allowed': !canSave })}
              onClick={handleClick}
              data-cy='save-task-bundle_button'
              data-testid='build-bundle-submit-button'
            >
              {isEditing
                ? intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.UPDATE')
                : intl.get('SAVE')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskBundlePage;
