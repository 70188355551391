import { Fragment, useState } from 'react';
import { Button } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import CommentBlock from './CommentBlock';
import {
  QuestionCommentType,
  ProjectComment,
  ProjectCommentMentionsData,
  UserAvatars,
} from 'utils/customTypes';
import { State } from '@popperjs/core';

const HiddenComments = ({
  comments = [],
  onEdit = () => {},
  onDelete = () => {},
  testId = '',
  mentionableUsers = [],
  mentionsFieldClassName,
  updateCommentsPopup,
}: {
  comments: QuestionCommentType[] | ProjectComment[];
  onEdit?: ({
    commentId,
    content,
    mentionsData,
  }: {
    commentId: string;
    content: string;
    mentionsData: ProjectCommentMentionsData;
  }) => void;
  onDelete?: (commentId: string) => void;
  testId: string;
  mentionableUsers?: UserAvatars[];
  mentionsFieldClassName?: string;
  updateCommentsPopup?: (() => Promise<Partial<State>>) | null;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
    if (updateCommentsPopup) {
      updateCommentsPopup();
    }
  };

  return (
    <Fragment>
      <CommentBlock
        comment={comments[0]}
        onEdit={onEdit}
        onDelete={onDelete}
        mentionableUsers={mentionableUsers}
        mentionsFieldClassName={mentionsFieldClassName}
      />
      <Button
        variant='tertiary'
        onClick={toggleExpanded}
        className='w-full'
        data-testid={`${testId}-hidden-comments-button`}
      >
        {expanded
          ? intl.get('COMMENT.HIDE_COMMENTS')
          : `${comments.length - 1} ${
              comments.length > 2
                ? intl.get('COMMENT.HIDDEN_COMMENTS')
                : intl.get('COMMENT.HIDDEN_COMMENT')
            }`}
      </Button>
      {expanded && (
        <div data-testid={`${testId}-hidden-comments-list`}>
          {comments.map((comment) => (
            <CommentBlock
              key={comment.id}
              comment={comment}
              onEdit={onEdit}
              onDelete={onDelete}
              mentionableUsers={mentionableUsers}
              mentionsFieldClassName={mentionsFieldClassName}
            />
          ))}
        </div>
      )}
      {!expanded && (
        <CommentBlock
          comment={comments[comments.length - 1]}
          onEdit={onEdit}
          onDelete={onDelete}
          mentionableUsers={mentionableUsers}
          mentionsFieldClassName={mentionsFieldClassName}
        />
      )}
    </Fragment>
  );
};

export default HiddenComments;
