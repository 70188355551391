import React, { useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormItem,
  UsersPicker,
  Dropdown,
  tailwindOverride,
  Typography,
  FormLabel,
} from '@getsynapse/design-system';
import { PROJECT_PARTICIPANT_ROLE } from 'utils/constants';
import { Option, UserAvatars } from 'utils/customTypes';
import {
  addParticipant,
  selectParticipantIds,
  setParticipantToUpdate,
  updateParticipant,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { getProjectOwners } from 'state/Project/projectSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import useModal from 'Hooks/useModal';
import UserAvatar from 'Atoms/UserAvatar';

type Participant = {
  user_id: string;
  job_roles?: string[];
};

const defaultState: Participant = {
  user_id: '',
  job_roles: [],
};

const ParticipantField: React.FC<{
  isUpdating: boolean;
  currentParticipant?: Participant;
  handleChange: (key: keyof Participant, value: string | string[]) => void;
}> = ({ isUpdating, currentParticipant, handleChange }) => {
  const participantIds = useSelector(selectParticipantIds);
  const allAvailableUsers = useSelector(selectAllUsersForDropdown);
  const projectOwners = useSelector(getProjectOwners);

  const usersList = useMemo(
    () =>
      allAvailableUsers.filter(
        (user) =>
          user.value !== currentParticipant?.user_id &&
          !participantIds.includes(user.value) &&
          !projectOwners.includes(user.value)
      ),
    [allAvailableUsers, currentParticipant, participantIds, projectOwners]
  );

  const selectedUsers = useMemo(
    () =>
      allAvailableUsers.filter(
        (user) => user.value === currentParticipant?.user_id
      ),
    [currentParticipant, allAvailableUsers]
  );

  return isUpdating ? (
    <div className='h-full flex flex-col'>
      <FormLabel required>
        {intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
        )}
      </FormLabel>
      <div className='flex items-center flex-grow'>
        <UserAvatar
          user={{
            avatar_url: selectedUsers[0]?.avatar?.imageSrc,
            data: {
              firstName: selectedUsers[0]?.label.split(' ')[0],
              lastName: selectedUsers[0]?.label.split(' ')[1],
            },
          }}
          className='mr-2'
        />
        <Typography variant='label' className='text-primary'>
          {selectedUsers[0]?.label}
        </Typography>
      </div>
    </div>
  ) : (
    <FormItem
      label={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
      )}
      labelProps={{ required: true }}
    >
      <UsersPicker
        usersList={usersList}
        multiple={false}
        selectedUsersList={selectedUsers}
        onChange={(user: UserAvatars) => handleChange('user_id', user.value)}
        triggerText={intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_PLACEHOLDER'
        )}
        triggerProps={{
          'aria-label': intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
          ),
          'data-cy': 'participant-user-picker',
        }}
      />
    </FormItem>
  );
};

const AddParticipantModal: React.FC<{
  projectId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  participant?: Participant;
}> = ({ projectId, isModalOpen = false, onCloseModal, participant }) => {
  const dispatch = useDispatch();
  const isUpdating = !!participant;
  const [currentParticipant, setCurrentParticipant] =
    useState<Participant>(defaultState);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const canSave = useMemo(() => {
    if (isUpdating) {
      return (
        !isSaving &&
        currentParticipant?.job_roles?.length !== participant?.job_roles?.length
      );
    } else {
      return !isSaving && !!currentParticipant?.user_id;
    }
  }, [currentParticipant, participant, isSaving, isUpdating]);

  const handleCloseModal = useCallback(() => {
    if (isUpdating) {
      dispatch(setParticipantToUpdate(null));
    }
    setCurrentParticipant(defaultState);
    setIsSaving(false);
    onCloseModal();
    closeModal();
  }, [onCloseModal, closeModal, isUpdating, dispatch]);

  const handleAddNewParticipant = useCallback(async () => {
    await dispatch(
      addParticipant({
        project_id: projectId,
        user_id: currentParticipant.user_id,
        job_roles: currentParticipant.job_roles,
      })
    );
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ADDED_SUCCESS_SNACKBAR_MESSAGE'
        ),
      })
    );
  }, [projectId, currentParticipant, dispatch]);

  const handleUpdateParticipant = useCallback(async () => {
    await dispatch(
      updateParticipant({
        project_id: projectId,
        user_id: currentParticipant.user_id,
        updateFields: {
          job_roles: currentParticipant.job_roles,
        },
      })
    );
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_UPDATED_SUCCESS_SNACKBAR_MESSAGE'
        ),
      })
    );
  }, [projectId, currentParticipant, dispatch]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    if (isUpdating) {
      await handleUpdateParticipant();
    } else {
      await handleAddNewParticipant();
    }
    handleCloseModal();
  }, [
    handleAddNewParticipant,
    handleUpdateParticipant,
    handleCloseModal,
    isUpdating,
  ]);

  const actionButons = useMemo(
    () => [
      {
        children: intl.get('TEAMS.SAVE'),
        variant: 'primary',
        'data-cy': 'confirm-button',
        className: tailwindOverride({ 'cursor-not-allowed': !canSave }),
        disabled: !canSave,
        loading: isSaving,
        onClick: handleSave,
      },
      {
        children: intl.get('PROJECT_DETAIL.DELETE_PROJECT.CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-button',
      },
    ],
    [handleCloseModal, canSave, handleSave, isSaving]
  );

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [openModal, modalProps, isModalOpen]);

  useEffect(() => {
    if (participant && participant?.user_id !== currentParticipant?.user_id) {
      setCurrentParticipant(participant);
    }
  }, [participant, currentParticipant]);

  const handleChange = (key: keyof Participant, value: string | string[]) => {
    setCurrentParticipant((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const participantRolesOptions = useMemo(
    () =>
      Object.entries(PROJECT_PARTICIPANT_ROLE).map(([key, value]) => ({
        label: intl.get(
          `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.${key}`
        ),
        value: value,
      })),
    []
  );

  const availableRoles = useMemo(
    () =>
      participantRolesOptions.filter(
        (roleOption: Option) =>
          !currentParticipant?.job_roles?.includes(roleOption.value)
      ),
    [participantRolesOptions, currentParticipant?.job_roles]
  );

  const selectedRoles = useMemo(
    () =>
      participantRolesOptions.filter((roleOption: Option) =>
        currentParticipant?.job_roles?.includes(roleOption.value)
      ),
    [participantRolesOptions, currentParticipant?.job_roles]
  );

  const modalTitle = isUpdating
    ? intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.UPDATE_ROLE')
    : intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.TITLE');

  return (
    <Modal
      {...modalProps}
      closeModal={handleCloseModal}
      title={modalTitle}
      titleIcon={{
        name: isUpdating ? 'person' : 'person-add',
        className: 'text-purple-dark text-xl',
      }}
      aria-label={modalTitle}
      size='large'
      className='h-full'
      actionButtons={actionButons}
      data-cy={`${isUpdating ? 'update' : 'add'}-participant-modal`}
    >
      <div className='grid grid-cols-2 space-x-10%'>
        <ParticipantField
          isUpdating={isUpdating}
          currentParticipant={currentParticipant}
          handleChange={handleChange}
        />
        <FormItem
          label={intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ROLE_FIELD_LABEL'
          )}
          className='flex-1'
        >
          <Dropdown
            options={availableRoles}
            onChange={(options: Option[]) => {
              const roles = options.map((option) => option.value);
              handleChange('job_roles', roles);
            }}
            placeholder={intl.get(
              'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.SELECT_ROLE'
            )}
            multiple
            values={selectedRoles}
            triggerProps={{
              'aria-label': intl.get(
                'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ROLE_FIELD_LABEL'
              ),
              'data-cy': 'participant-roles-picker',
            }}
          />
        </FormItem>
      </div>
    </Modal>
  );
};

export default AddParticipantModal;
