import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import {
  Typography,
  FormItem,
  TextField,
  Dropdown,
  TextArea,
  Datepicker,
  UsersPicker,
} from '@getsynapse/design-system';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectRequestsByStatus } from 'state/Requests/requestSlice';
import {
  selectBussinessTeams,
  selectProjectCategories,
} from 'state/Organization/organizationSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import {
  getOrganizationProcesses,
  selectEnabledProjectProcesses,
  selectProjectProcesses,
} from 'state/Processes/processesSlice';
import {
  getRequest,
  getRequestQuestions,
} from 'state/ActiveRequest/activeRequestSlice';
import {
  getLDUsers,
  selectLDUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { selectUser } from 'state/User/userSlice';
import ProjectStatusPicker from './ProjectStatusPicker';
import {
  NEW_PROJECT_FORM_FIELDS,
  REQUEST_STATUS,
  PROJECT_PRIORITY,
  PROJECT_PRIVACY,
  PROJECT_STATUS,
  PROJECT_HEALTH,
  LONG_INPUTS_LENGTH,
  USER_TYPES,
} from 'utils/constants';
import {
  NewProject,
  objKeyAsString,
  Request,
  UserOption,
  LearningTeam,
  Option,
  OptionLinkRequest,
} from 'utils/customTypes';
import { ProjectTemplateField } from 'utils/types/templates';
import useModal from 'Hooks/useModal';
import {
  MultipleOptionLI,
  getNewProjectStageValue,
  resetTargetLauchDateWhenGreaterStartDateIsSelected,
} from '../helpers/snippets';
import LinkedProjectRequestsTable from './LinkedProjectRequestsTable';
import ProjectRequestsLinking from 'Organisms/ProjectRequestsLinking/ProjectRequestsLinking';
import ProjectHealtPicker from 'Organisms/ProjectHealtPicker/ProjectHealtPicker';
import ProjectCategoryPicker from 'Molecules/ProjectCategoryPicker/ProjectCategoryPicker';
import ProcessPicker from 'Molecules/ProcessPicker/ProcessPicker';
import ViewRequestModal from './ViewRequestModal/ViewRequestModal';
import handleFieldChange from '../helpers/handleFieldChange';
import TimelineDates from './TimelineDates/TimelineDates';
import BusinessUnitPicker from 'Molecules/BusinessUnitPicker/BusinessUnitPicker';
import OwnersPicker from './OwnersPicker';
import { SpecifyHoldReasonForm } from 'Pages/ProjectPage/components/PutProjectOnHoldModal/PutProjectOnHoldModal';
import CustomFields from 'Organisms/CustomFields/CustomFields';
import { CustomFieldType, FieldType } from '../../../utils/types/fields';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import { fetchPrograms, selectAllPrograms } from 'state/Programs/programsSlice';
import {
  fetchTaskBundles,
  selectAllTaskBundles,
} from 'state/TasksBundle/tasksBundleSlice';

type BasicDetailsProps = {
  data: NewProject;
  setData: Dispatch<SetStateAction<NewProject>>;
  requiredFieldsErrors?: objKeyAsString;
  isOnUpdatingPage?: boolean;
  isReadOnly?: boolean;
  originalData?: NewProject;
  sidePanel?: boolean;
  canPutProjectOnHold?: boolean;
  projectTemplateFields?: ProjectTemplateField[];
  projectId?: string;
};

const BasicDetails: React.FC<BasicDetailsProps> = ({
  data,
  setData,
  requiredFieldsErrors,
  isOnUpdatingPage = false,
  isReadOnly = false,
  originalData,
  sidePanel = false,
  canPutProjectOnHold = false,
  projectTemplateFields,
  projectId,
}) => {
  const dispatch = useDispatch();
  const { selfAssignProject } = useFlags();
  const approvedRequests = useSelector(
    selectRequestsByStatus(REQUEST_STATUS.APPROVED)
  );
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const currentUser = useSelector(selectUser);
  const { Modal, modalProps, openModal } = useModal();
  const linkedRequestIds = data.projectRequests;
  const bussinessTeams = useSelector(selectBussinessTeams);
  const learningTeams = useSelector(selectLearningTeams);
  const projectProcesses = useSelector(selectEnabledProjectProcesses);
  const orgProcesses = useSelector(selectProjectProcesses);
  const projectCategories = useSelector(selectProjectCategories);
  const programs = useSelector(selectAllPrograms);
  const taskBundles = useSelector(selectAllTaskBundles);

  const [displayLauchDateWarning, setDisplayLauchDateWarning] = useState(false);
  const displayActualCompletionDatePicker =
    isOnUpdatingPage &&
    [PROJECT_STATUS.COMPLETED, PROJECT_STATUS.CLOSED].includes(data.status);
  const noneStageProjectStatusArray = [
    PROJECT_STATUS.NEW,
    PROJECT_STATUS.IN_PLANNING,
  ];
  const displayNoneProjectAttributesFields =
    isOnUpdatingPage && !data.project_template_id;
  const stageInitialValue = noneStageProjectStatusArray.includes(data.status)
    ? undefined
    : data.stage_id;
  const [shouldDisplayPutOnHoldFields, setShouldDisplayPutOnHoldFields] =
    useState(false);
  const [holdReason, setHoldReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');
  const shouldSpecifyOtherReason = useMemo(
    () =>
      holdReason ===
      intl.get(
        'PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.REASON_PICKER_OPTIONS.OTHER'
      ),
    [holdReason]
  );

  const canUserAssingItselfAsProjectOwner =
    selfAssignProject &&
    isOnUpdatingPage &&
    currentUser.type === USER_TYPES.L_D &&
    noneStageProjectStatusArray.includes(data.status);

  const projectsProcessesOptions = useMemo(() => {
    if (originalData?.process_id) {
      const originalProcess = orgProcesses.find(
        (process) => process.id === originalData?.process_id
      );
      const isProcessDisabled = originalProcess?.enabled === false;
      if (isProcessDisabled) {
        return [
          ...projectProcesses,
          {
            ...originalProcess,
            processName: `${originalProcess.processName} ${intl.get(
              'NEW_PROJECT_PAGE.DISABLED_PROCESS'
            )}`,
          },
        ];
      }
    }
    return projectProcesses;
  }, [orgProcesses, originalData?.process_id, projectProcesses]);

  const programOptions = useMemo(
    () =>
      programs.map((program) => ({
        label: program.title,
        value: program.id,
      })),
    [programs]
  );

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(getOrganizationProcesses()),
        dispatch(getLDUsers()),
        dispatch(fetchPrograms()),
        dispatch(fetchTaskBundles()),
      ]);
    };
    init();
  }, [dispatch]);

  const stageOptions = useMemo(() => {
    let optionsList: Option[] = [];
    if (data.process_id) {
      const selectedProcess = orgProcesses.find(
        (process) => process.id === data.process_id
      );
      if (selectedProcess) {
        optionsList = selectedProcess.projectStages.map((stage) => ({
          label: stage.stageName,
          value: stage.id,
        }));
      }
    }
    return optionsList;
  }, [orgProcesses, data.process_id]);

  const handleFormFieldChange = useCallback(
    (fieldName: string, fieldValue: any) => {
      if (
        fieldName === NEW_PROJECT_FORM_FIELDS.TARGET_LAUNCH_DATE &&
        displayLauchDateWarning
      ) {
        setDisplayLauchDateWarning(false);
      }
      if (
        fieldName === NEW_PROJECT_FORM_FIELDS.START_DATE &&
        data.targetLaunchDate
      ) {
        const { shouldResetLaunchDate, updatedDates } =
          resetTargetLauchDateWhenGreaterStartDateIsSelected(
            fieldValue,
            data.targetLaunchDate
          );
        setData((prevData) => ({ ...prevData, ...updatedDates }));
        if (shouldResetLaunchDate) {
          setDisplayLauchDateWarning(true);
        }
        return;
      }
      if (isOnUpdatingPage && fieldName === NEW_PROJECT_FORM_FIELDS.STATUS) {
        const { updatedData } = getNewProjectStageValue(
          fieldValue,
          data?.stage_id,
          stageOptions
        );
        setData((prevData) => ({ ...prevData, ...updatedData }));
        return;
      }
      handleFieldChange(fieldName, fieldValue, setData);
    },
    [
      data?.stage_id,
      data.targetLaunchDate,
      displayLauchDateWarning,
      isOnUpdatingPage,
      setData,
      stageOptions,
    ]
  );

  useEffect(() => {
    if (
      sidePanel &&
      !shouldDisplayPutOnHoldFields &&
      originalData &&
      data &&
      originalData?.status !== PROJECT_STATUS.ON_HOLD &&
      data.status === PROJECT_STATUS.ON_HOLD &&
      canPutProjectOnHold
    ) {
      setShouldDisplayPutOnHoldFields(true);
    } else if (
      shouldDisplayPutOnHoldFields &&
      data.status !== PROJECT_STATUS.ON_HOLD
    ) {
      setHoldReason('');
      setOtherReason('');
      setShouldDisplayPutOnHoldFields(false);
    }
  }, [
    sidePanel,
    originalData,
    canPutProjectOnHold,
    data,
    shouldDisplayPutOnHoldFields,
  ]);

  useEffect(() => {
    if (sidePanel && shouldDisplayPutOnHoldFields) {
      handleFormFieldChange(
        'hold_reason',
        `${holdReason}${otherReason && `:${otherReason}`}`
      );
    }
  }, [
    holdReason,
    otherReason,
    shouldDisplayPutOnHoldFields,
    sidePanel,
    handleFormFieldChange,
  ]);

  const unLinkRequestHandle = (requestId: string) => {
    setData((prevData) => ({
      ...prevData,
      projectRequests: prevData.projectRequests.filter(
        (id: string) => id !== requestId
      ),
    }));
  };

  const linkRequestHandler = (requestId: string) => {
    setData((prevData) => ({
      ...prevData,
      projectRequests: prevData.projectRequests
        ? prevData.projectRequests.concat(requestId!)
        : [requestId!],
    }));
  };

  const changeOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    handleFormFieldChange(NEW_PROJECT_FORM_FIELDS.PROJECT_OWNERS, ownersIds);
  };

  const ownersList = useMemo(() => {
    return ldUsers.filter((ldUser) => data.owners?.includes(ldUser.value));
  }, [data.owners, ldUsers]);

  const currentUserOption = useMemo(() => {
    return ldUsers.filter((ldUser) => currentUser.id === ldUser.value);
  }, [currentUser, ldUsers]);

  useEffect(() => {
    if (!isOnUpdatingPage) {
      setData((prevData) => ({ ...prevData, owners: [currentUser.id!] }));
    }
  }, [currentUser.id, setData, isOnUpdatingPage]);

  const availableRequestsList = useMemo(
    () =>
      approvedRequests
        .filter(
          (request: Request) => !data.projectRequests.includes(request.id!)
        )
        .map((filteredRequest: Request) => {
          return {
            label: filteredRequest.title!,
            value: filteredRequest.id!,
            requestNumber: filteredRequest.requestIdentifier || '',
          } as OptionLinkRequest;
        }),
    [approvedRequests, data.projectRequests]
  );

  const linkedRequestsList = useMemo(
    () =>
      approvedRequests.filter((request: Request) =>
        linkedRequestIds.includes(request.id!)
      ),
    [approvedRequests, linkedRequestIds]
  );

  const statusOptions = useMemo(() => {
    const { CLOSED, CANCELED, ...remainingStatus } = PROJECT_STATUS;
    const statusList = { ...remainingStatus };
    const options = Object.keys(statusList).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`),
      value: PROJECT_STATUS[key],
    }));
    if (data.status === PROJECT_STATUS.CLOSED) {
      options.push({
        label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.CLOSED`),
        value: PROJECT_STATUS.CLOSED,
      });
    }
    if (data.status === PROJECT_STATUS.CANCELED) {
      options.push({
        label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.CANCELED`),
        value: PROJECT_STATUS.CANCELED,
      });
    }
    return options;
  }, [data.status]);

  const privacyOptions = useMemo(() => {
    if (isOnUpdatingPage && originalData?.privacy) {
      if (originalData?.privacy === PROJECT_PRIVACY.TEAM) {
        return [
          {
            label: intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.TEAM'),
            value: PROJECT_PRIVACY.TEAM,
          },
          {
            label: intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.PUBLIC'),
            value: PROJECT_PRIVACY.PUBLIC,
          },
        ];
      }

      if (originalData?.privacy === PROJECT_PRIVACY.PUBLIC) {
        return [
          {
            label: intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.PUBLIC'),
            value: PROJECT_PRIVACY.PUBLIC,
          },
          {
            label: intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.TEAM'),
            value: PROJECT_PRIVACY.TEAM,
          },
        ];
      }
    }
    return Object.keys(PROJECT_PRIVACY).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.PRIVACY_OPTIONS.${key}`),
      value: PROJECT_PRIVACY[key],
    }));
  }, [isOnUpdatingPage, originalData?.privacy]);

  const healthOptions = useMemo(
    () =>
      Object.keys(PROJECT_HEALTH).map((key) => ({
        label: intl.get(`PROJECT_DETAIL.HEALTH_OPTIONS.${key}`),
        value: PROJECT_HEALTH[key],
      })),
    []
  );

  const teamOptions = useMemo(
    () =>
      learningTeams.map((team: LearningTeam) => ({
        label: team.name,
        value: team.id,
      })),
    [learningTeams]
  );

  const priorityOptions = useMemo(
    () =>
      Object.keys(PROJECT_PRIORITY).map((key) => ({
        label: intl.get(`PROJECT_DETAIL.PRIORITY_OPTIONS.${key}`),
        value: PROJECT_PRIORITY[key],
      })),
    []
  );

  const taskBundleOptions = useMemo(
    () =>
      taskBundles.map((taskBundle) => ({
        label: taskBundle.name,
        value: taskBundle.id,
      })),
    [taskBundles]
  );

  const customFields = useMemo<CustomFieldType[]>(
    () =>
      isOnUpdatingPage
        ? (data.customProperties?.map(
            (customProperty: ProjectTemplateField) => ({
              required: customProperty.required,
              field_template: customProperty.field_template,
              field: {
                ...customProperty.field,
                value: get(
                  data,
                  `${customProperty.field_template.name}`,
                  customProperty.field?.value
                ),
              },
            })
          ) as CustomFieldType[])
        : (projectTemplateFields?.map(
            (customProperty: ProjectTemplateField) => ({
              required: customProperty.required,
              field_template: customProperty.field_template,
              field: customProperty.field,
            })
          ) as CustomFieldType[]),
    [isOnUpdatingPage, data, projectTemplateFields]
  );

  const viewRequestHandle = async (request: Request) => {
    await dispatch(getRequest(request.id!));
    await dispatch(getRequestQuestions(request.id!));
    openModal({
      title: request.title,
      size: 'large',
      childrenClassName: 'min-h-96',
      children: <ViewRequestModal />,
    });
  };

  const getPrivacyHelperText = () => {
    if (requiredFieldsErrors?.privacy) {
      return intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR');
    }
    switch (data.privacy) {
      case 'team':
        return intl.get('PROJECT_DETAIL.PRIVACY_TEAM_MESSAGE');
      case 'private':
        return intl.get('PROJECT_DETAIL.PRIVACY_PRIVATE_MESSAGE');
      default:
        return '';
    }
  };

  const getInitialValueForDropDown = (
    options: Option[],
    values: string[] | string | undefined
  ) => {
    if (!values) {
      return [];
    }
    return options.filter(
      (option) => values === option.value || values.includes(option.value)
    );
  };

  const shouldShowHealthField =
    isOnUpdatingPage &&
    [
      PROJECT_STATUS.IN_PROGRESS,
      PROJECT_STATUS.COMPLETED,
      PROJECT_STATUS.CLOSED,
    ].includes(data.status);

  return (
    <div className={classnames(`${!sidePanel ? 'mt-8' : 'pt-2'}`)}>
      {!sidePanel && (
        <>
          <Modal
            {...modalProps}
            aria-label={intl.get('NEW_PROJECT_PAGE.VIEW_PROJECT_MODAL.TITLE')}
            data-cy='view-linked-request-modal'
          />
          <Typography variant='h5'>
            {intl.get('PROJECT_DETAIL.BASIC_INFORMATION_TITLE')}
          </Typography>
          {!isOnUpdatingPage && (
            <Typography variant='caption'>
              {intl.get('PROJECT_DETAIL.BASIC_INFORMATION_SUBTITLE')}
            </Typography>
          )}
        </>
      )}
      <div
        className={classnames({
          'mt-8': !isOnUpdatingPage,
          'mt-4': isOnUpdatingPage && !sidePanel,
          'grid gap-y-6 gap-x-10% grid-cols-2': !sidePanel,
        })}
        data-cy='project-basic-details'
      >
        {!sidePanel && (
          <FormItem
            helpText={
              requiredFieldsErrors?.title &&
              intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
            }
            helpTextProps={{
              state: requiredFieldsErrors?.title ? 'error' : 'default',
            }}
          >
            <TextField
              label={intl.get('PROJECT_DETAIL.PROJECT_NAME')}
              labelProps={{
                required: true,
                state: requiredFieldsErrors?.title ? 'error' : 'default',
              }}
              variant='text'
              placeholder={intl.get('PROJECT_DETAIL.PROJECT_NAME_PLACEHOLDER')}
              value={data.title}
              state={requiredFieldsErrors?.title ? 'error' : 'default'}
              displayCharactersCount
              maxLength={LONG_INPUTS_LENGTH}
              disabled={isReadOnly}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.TITLE,
                  event.target.value
                )
              }
              data-cy='project-name-input'
            />
          </FormItem>
        )}
        <FormItem
          label={intl.get('PROJECT_DETAIL.PROJECT_OWNERS')}
          helpText={
            requiredFieldsErrors?.owners &&
            intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
          }
          helpTextProps={{
            state: requiredFieldsErrors?.owners ? 'error' : 'default',
          }}
          labelProps={{
            required: true,
            state: requiredFieldsErrors?.owners ? 'error' : 'default',
          }}
          className={classnames({ 'mb-4': sidePanel })}
        >
          {canUserAssingItselfAsProjectOwner ? (
            <OwnersPicker
              availableUsers={ldUsers}
              selectedUsers={ownersList}
              currentUser={currentUserOption[0]}
              onChangeOwners={changeOwners}
              isReadOnly={isReadOnly}
            />
          ) : (
            <UsersPicker
              disabled={isReadOnly}
              usersList={ldUsers}
              selectedUsersList={
                isOnUpdatingPage ? ownersList : currentUserOption
              }
              onChange={changeOwners}
              maxLimit={2}
              required
            />
          )}
        </FormItem>
        <FormItem
          className={classnames({ 'mb-4': sidePanel })}
          label={intl.get('PROJECT_DETAIL.DESCRIPTION')}
        >
          <TextArea
            disabled={isReadOnly}
            value={data.description || ''}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleFormFieldChange(
                NEW_PROJECT_FORM_FIELDS.DESCRIPTION,
                event.target.value
              )
            }
            textAreaProps={{
              placeholder: intl.get('PROJECT_DETAIL.DESCRIPTION_PLACEHOLDER'),
              className: 'max-h-32',
              'data-cy': 'project-description-input',
            }}
          />
        </FormItem>
        <div>
          <BusinessUnitPicker
            formItemProps={{
              label: intl.get('PROJECT_DETAIL.BUSINESS_UNIT'),
              helpText:
                requiredFieldsErrors?.businessTeams &&
                intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR'),
              helpTextProps: {
                state: requiredFieldsErrors?.businessTeams
                  ? 'error'
                  : 'default',
              },
              labelProps: {
                required: true,
                state: requiredFieldsErrors?.businessTeams
                  ? 'error'
                  : 'default',
              },
            }}
            currentValue={get(
              data,
              `[${NEW_PROJECT_FORM_FIELDS.BUSINESS_TEAMS}]`
            )}
            businessUnits={bussinessTeams}
            dropdownProps={{
              multiple: true,
              disabled: isReadOnly,
              placeholder: intl.get('PROJECT_DETAIL.BUSINESS_UNIT_PLACEHOLDER'),
              onChange: (options: Option[]) => {
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.BUSINESS_TEAMS,
                  options
                );
              },
              listProps: { 'data-cy': 'project-business-teams' },
              state: requiredFieldsErrors?.businessTeams ? 'error' : 'default',
              triggerProps: {
                'data-cy': 'project-business-input',
              },
            }}
          />
          <ProjectCategoryPicker
            categories={projectCategories}
            currentValue={get(data, `[${NEW_PROJECT_FORM_FIELDS.CATEGORY}]`)}
            formItemProps={{
              className: classnames('mt-6', { 'mb-4': sidePanel }),
              label: intl.get('PROJECT_DETAIL.CATEGORY'),
              helpText:
                requiredFieldsErrors?.category_id &&
                intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR'),
              helpTextProps: {
                state: requiredFieldsErrors?.category_id ? 'error' : 'default',
              },
              labelProps: {
                required: true,
                state: requiredFieldsErrors?.category_id ? 'error' : 'default',
              },
            }}
            dropdownProps={{
              disabled: isReadOnly,
              placeholder: intl.get('PROJECT_DETAIL.CATEGORY_PLACEHOLDER'),
              onChange: (option: Option) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.CATEGORY,
                  option.value
                ),
              state: requiredFieldsErrors?.category_id ? 'error' : 'default',
              triggerProps: { 'data-cy': 'project-category' },
            }}
          />
        </div>
        <div className={classnames({ 'mb-4': sidePanel })}>
          <TimelineDates
            startDate={data.startDate}
            targetCompletionDate={data.targetCompletionDate}
            requiredFieldsErrors={requiredFieldsErrors!}
            handleFormFieldChange={handleFormFieldChange}
            originalData={originalData}
            disabled={isReadOnly}
            isOnUpdatingPage={isOnUpdatingPage}
          />
        </div>
        {displayNoneProjectAttributesFields && (
          <FormItem className={classnames({ 'mb-4': sidePanel })}>
            <Datepicker
              disabled={
                isReadOnly || !data.startDate || !data.targetCompletionDate
              }
              className='w-full'
              startDate={
                data.targetLaunchDate && new Date(data.targetLaunchDate)
              }
              startDateLabel={intl.get('PROJECT_DETAIL.TARGET_LAUNCH_DATE')}
              size='large'
              onPickDate={(date: object) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.TARGET_LAUNCH_DATE,
                  date
                )
              }
              data-cy='project-target-input'
              minDate={data.startDate && new Date(data.startDate)}
              startDateWarning={
                displayLauchDateWarning
                  ? intl.get('PROJECT_DETAIL.TARGET_LAUNCH_DATE_WARNING')
                  : undefined
              }
            />
          </FormItem>
        )}
        {(isOnUpdatingPage || displayActualCompletionDatePicker) && (
          <div
            className={classnames({
              'grid grid-cols-2 gap-6': displayActualCompletionDatePicker,
            })}
          >
            {isOnUpdatingPage && (
              <ProjectStatusPicker
                hasError={requiredFieldsErrors?.status}
                fromSidePanel={sidePanel}
                isReadOnly={isReadOnly}
                handleFormFieldChange={handleFormFieldChange}
                values={getInitialValueForDropDown(
                  statusOptions,
                  data[NEW_PROJECT_FORM_FIELDS.STATUS]
                )}
                options={statusOptions}
                projectId={projectId}
              />
            )}
            {shouldDisplayPutOnHoldFields && sidePanel && (
              <SpecifyHoldReasonForm
                holdReason={holdReason}
                setHoldReason={setHoldReason}
                otherReason={otherReason}
                setOtherReason={setOtherReason}
                shouldSpecifyOtherReason={shouldSpecifyOtherReason}
                canUpdateHoldReason
                sidePanel={sidePanel}
              />
            )}
            {displayActualCompletionDatePicker && (
              <FormItem
                label={intl.get('PROJECT_DETAIL.ACTUAL_COMPLETION_DATE')}
                helpText={
                  requiredFieldsErrors?.actualDate &&
                  intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
                }
                helpTextProps={{
                  state: requiredFieldsErrors?.actualDate ? 'error' : 'default',
                }}
                labelProps={{
                  required: true,
                  state: requiredFieldsErrors?.actualDate ? 'error' : 'default',
                }}
                className={classnames({ 'mb-4': sidePanel })}
              >
                <Datepicker
                  disabled={isReadOnly}
                  className='w-full'
                  startDate={data.actualDate && new Date(data.actualDate)}
                  size='large'
                  onPickDate={(date: object) =>
                    handleFormFieldChange(
                      NEW_PROJECT_FORM_FIELDS.ACTUAL_COMPLETION_DATE,
                      date
                    )
                  }
                  data-cy='project-completion-input'
                />
              </FormItem>
            )}
          </div>
        )}
        {data.privacy !== PROJECT_PRIVACY.PRIVATE && (
          <FormItem
            label={intl.get('PROJECT_DETAIL.PROGRAMS')}
            className={classnames({ 'mb-4': sidePanel })}
          >
            <MultiSelectDropdown
              disabled={isReadOnly}
              options={programOptions}
              values={getInitialValueForDropDown(
                programOptions,
                get(data, `[${NEW_PROJECT_FORM_FIELDS.PROGRAMS}]`)
              )}
              onChange={(options: Option[]) => {
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.PROGRAMS,
                  options
                );
              }}
              multiple
              filterable
              noOptionsText={intl.get('PROJECT_DETAIL.NO_PROGRAMS_AVAILABLE')}
              placeholder={intl.get('PROJECT_DETAIL.PROGRAMS_PLACEHOLDER')}
              triggerProps={{
                className: 'py-0 items-center',
                'data-cy': 'project-programs-trigger',
              }}
            />
          </FormItem>
        )}

        <div>
          <FormItem
            label={intl.get('PROJECT_DETAIL.PRIORITY')}
            className={classnames({ 'mb-4': sidePanel })}
          >
            <Dropdown
              disabled={isReadOnly}
              placeholder={intl.get('PROJECT_DETAIL.PRIORITY_PLACEHOLDER')}
              onChange={(option: Option) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.PRIORITY,
                  option.value
                )
              }
              values={getInitialValueForDropDown(
                priorityOptions,
                data[NEW_PROJECT_FORM_FIELDS.PRIORITY]
              )}
              options={priorityOptions}
              triggerProps={{ 'data-cy': 'project-priority-input' }}
            />
          </FormItem>

          {!isOnUpdatingPage && (
            <FormItem
              label={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.TITLE')}
              className={classnames('mt-8', { 'mb-4': sidePanel })}
            >
              <Dropdown
                disabled={isReadOnly}
                placeholder={intl.get(
                  'NEW_PROJECT_PAGE.TASK_BUNDLE.PLACEHOLDER'
                )}
                onChange={(option: Option) =>
                  handleFormFieldChange(
                    NEW_PROJECT_FORM_FIELDS.TASK_BUNDLE,
                    option.value
                  )
                }
                options={taskBundleOptions}
                triggerProps={{ 'data-cy': 'project-task-bundle-input' }}
              />
            </FormItem>
          )}
        </div>

        {shouldShowHealthField && (
          <FormItem label={intl.get('PROJECT_DETAIL.HEALTH')}>
            <ProjectHealtPicker
              onChange={handleFormFieldChange}
              options={healthOptions}
              disabled={isReadOnly}
              values={getInitialValueForDropDown(
                healthOptions,
                data[NEW_PROJECT_FORM_FIELDS.HEALTH]
              )}
            />
          </FormItem>
        )}
      </div>
      <div
        className={classnames(
          'border-neutral-lighter border-t mt-8',
          `${sidePanel ? 'mb-8 pt-2' : 'py-8'}`
        )}
      >
        {!sidePanel && (
          <Typography variant='h5'>
            {intl.get('PROJECT_DETAIL.SHARING')}
          </Typography>
        )}
        <div
          className={classnames('mt-4', {
            'grid gap-y-6 gap-x-10% grid-cols-2': !sidePanel,
          })}
          data-cy='project-privacy-helper-text'
        >
          <FormItem
            label={intl.get('PROJECT_DETAIL.PRIVACY')}
            helpText={getPrivacyHelperText()}
            helpTextProps={{
              state: requiredFieldsErrors?.privacy ? 'error' : 'default',
            }}
            labelProps={{
              required: true,
              state: requiredFieldsErrors?.privacy ? 'error' : 'default',
            }}
            className={classnames({ 'mb-4': sidePanel })}
          >
            <Dropdown
              disabled={isReadOnly}
              placeholder={intl.get('PROJECT_DETAIL.PRIVACY_PLACEHOLDER')}
              values={getInitialValueForDropDown(
                privacyOptions,
                data[NEW_PROJECT_FORM_FIELDS.PRIVACY]
              )}
              onChange={(option: Option) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.PRIVACY,
                  option.value
                )
              }
              options={privacyOptions}
              state={requiredFieldsErrors?.privacy ? 'error' : 'default'}
              triggerProps={{ 'data-cy': 'project-privacy-input' }}
            />
          </FormItem>
          {get(data, 'privacy', '') === 'team' && (
            <FormItem
              label={intl.get('PROJECT_DETAIL.TEAM')}
              helpText={
                requiredFieldsErrors?.privacy &&
                intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
              }
              helpTextProps={{
                state: requiredFieldsErrors?.privacy ? 'error' : 'default',
              }}
              labelProps={{
                required: true,
                state: requiredFieldsErrors?.privacy ? 'error' : 'default',
              }}
            >
              <Dropdown
                disabled={isReadOnly}
                placeholder={intl.get('PROJECT_DETAIL.TEAM_PLACEHOLDER')}
                triggerProps={{ 'data-cy': 'project-team-input' }}
                listProps={{ 'data-cy': 'project-learning-teams' }}
                onChange={(options: Option[]) =>
                  handleFormFieldChange(NEW_PROJECT_FORM_FIELDS.TEAMS, options)
                }
                multiple
                options={teamOptions}
                values={getInitialValueForDropDown(
                  teamOptions,
                  data.learningTeams
                )}
                state={
                  requiredFieldsErrors?.learningTeams ? 'error' : 'default'
                }
                renderOption={(
                  option: Option,
                  isSelected: boolean,
                  selectOption,
                  { className, ...otherProps }
                ) => (
                  <li
                    {...otherProps}
                    className={classnames('group', className, {
                      'hover:bg-primary focus-visible:bg-primary': isSelected,
                    })}
                  >
                    <MultipleOptionLI
                      label={option.label}
                      isSelected={isSelected}
                      selectOption={selectOption}
                    />
                  </li>
                )}
              />
            </FormItem>
          )}
        </div>
      </div>
      <div
        className={classnames('border-neutral-lighter border-t', {
          'py-8 border-b': !sidePanel,
          'pt-2': sidePanel,
          'pb-8 border-b': displayNoneProjectAttributesFields,
        })}
      >
        {!sidePanel && (
          <Typography variant='h5'>
            {intl.get('PROJECT_DETAIL.PROJECT_PROCESS_SECTION')}
          </Typography>
        )}
        <div
          className={classnames(
            { 'grid gap-y-6 gap-x-10% grid-cols-2': !sidePanel },
            'mt-4'
          )}
        >
          <ProcessPicker
            processes={projectsProcessesOptions!}
            formItemProps={{
              label: intl.get('PROJECT_DETAIL.PROCESS'),
              helpText:
                requiredFieldsErrors?.process_id &&
                intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR'),
              helpTextProps: {
                state: requiredFieldsErrors?.process_id ? 'error' : 'default',
              },
              labelProps: {
                required: true,
                state: requiredFieldsErrors?.process_id ? 'error' : 'default',
              },
              className: classnames({ 'mb-4': sidePanel }),
            }}
            currentValue={get(data, `[${NEW_PROJECT_FORM_FIELDS.PROCESS}]`)}
            dropdownProps={{
              disabled:
                isReadOnly ||
                (isOnUpdatingPage &&
                  !noneStageProjectStatusArray.includes(data.status)),
              placeholder: intl.get('PROJECT_DETAIL.PROCESS_PLACEHOLDER'),
              onChange: (option: Option) =>
                handleFormFieldChange(
                  NEW_PROJECT_FORM_FIELDS.PROCESS,
                  option.value
                ),
              state: requiredFieldsErrors?.process_id ? 'error' : 'default',
              triggerProps: { 'data-cy': 'project-process-input' },
            }}
          />
          {isOnUpdatingPage && (
            <FormItem label={intl.get('PROJECT_DETAIL.STAGE')}>
              <Dropdown
                disabled={
                  isReadOnly ||
                  noneStageProjectStatusArray.includes(data.status)
                }
                placeholder={intl.get('PROJECT_DETAIL.STAGE_PLACEHOLDER')}
                values={getInitialValueForDropDown(
                  stageOptions,
                  stageInitialValue
                )}
                onChange={(option: Option) =>
                  handleFormFieldChange(
                    NEW_PROJECT_FORM_FIELDS.STAGE,
                    option.value
                  )
                }
                options={stageOptions}
                state={requiredFieldsErrors?.stage_id ? 'error' : 'default'}
                triggerProps={{
                  'data-cy': 'project-stage-input',
                }}
              />
            </FormItem>
          )}
        </div>
        {!sidePanel && customFields && customFields.length > 0 && (
          <div className='border-neutral-lighter border-t py-8 mt-8'>
            <Typography variant='h5' className='mb-4'>
              {intl.get('PROJECT_DETAIL.ADDITIONAL_INFO')}
            </Typography>
            <CustomFields
              customFields={customFields || []}
              disabled={isReadOnly}
              onChange={(updatedFields: Record<string, FieldType['value']>) =>
                setData((prevData) => ({ ...prevData, ...updatedFields }))
              }
              requiredFieldsErrors={requiredFieldsErrors}
              className={classnames({
                'gap-x-5': sidePanel,
                'gap-x-10%': !sidePanel,
              })}
            />
          </div>
        )}
      </div>
      {!sidePanel && (
        <div className='mt-10'>
          <Typography variant='h5' className='mt-8 mb-4'>
            {intl.get('PROJECT_DETAIL.LINKED_REQUESTS_SECTION.TITLE')}
          </Typography>
          <LinkedProjectRequestsTable
            requestsList={linkedRequestsList}
            unLinkRequest={unLinkRequestHandle}
            viewRequest={viewRequestHandle}
            disabled={isReadOnly}
          />
          <ProjectRequestsLinking
            disabled={isReadOnly}
            placeholder={intl.get(
              'PROJECT_DETAIL.LINKED_REQUESTS_SECTION.PLACEHOLDER'
            )}
            triggerTitle={intl.get(
              'PROJECT_DETAIL.LINKED_REQUESTS_SECTION.LINK_REQUEST'
            )}
            availableItemsList={availableRequestsList}
            onLinkItem={linkRequestHandler}
          />
        </div>
      )}
    </div>
  );
};

export default BasicDetails;
