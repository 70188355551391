import TaskBundlePage from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/TaskBundlePage';

const EditTaskBundlePage = () => {
  return (
    <>
      <TaskBundlePage isEditing />
    </>
  );
};

export default EditTaskBundlePage;
