import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import moment from 'moment';
import intl from 'react-intl-universal';
import {
  PROGRAM_FIELDS,
  COLUMN_OPTION_TYPES,
  TABLE_FILTERS_OPERATORS,
} from 'utils/constants';
import {
  Program,
  SortingType,
  filter,
  rangeDate,
  FormOption,
  Owner,
} from 'utils/customTypes';

export const orderProgramsBy = (
  programs: Program[],
  column: string,
  order: SortingType
) => {
  switch (column) {
    case PROGRAM_FIELDS.OWNERS:
      return orderBy(
        programs,
        [
          (program: Program) => {
            if (!program.programOwners) {
              return '';
            }
            const name = `${get(
              program.programOwners.at(-1),
              'data.firstName',
              ''
            )} ${get(
              program.programOwners.at(-1),
              'data.lastName',
              ''
            )}`.toLowerCase();
            return program.programOwners && program.programOwners.length > 0
              ? name
              : '';
          },
        ],
        [order]
      );
    default:
      return orderBy(programs, [column], [order]);
  }
};

const programMatchAllFilters: (
  program: Program,
  filters: filter[]
) => boolean = (program, filters) => {
  let allFiltersMatched = false;
  for (const filter of filters) {
    if (filter.column) {
      let columnMatchFilter = false;

      type key = keyof Program;
      const columnData = program[filter.column as key];
      if (columnData) {
        columnMatchFilter =
          filter.type === COLUMN_OPTION_TYPES.DATE
            ? checkColumnMatchDateFilter(columnData, filter)
            : checkColumnMatchFilter(columnData, filter);
      }

      if (filter.logic !== undefined) {
        allFiltersMatched =
          filter.logic === 'AND'
            ? allFiltersMatched && columnMatchFilter
            : allFiltersMatched || columnMatchFilter;
      } else {
        allFiltersMatched = columnMatchFilter;
      }
    }
  }
  return allFiltersMatched;
};

export const checkColumnMatchDateFilter = (columnData: any, filter: filter) => {
  const requestDate = moment(new Date(columnData));
  const rangeDate = filter.value as rangeDate;
  const startDate = new Date(rangeDate.startDate);
  const endDate = new Date(rangeDate.endDate);

  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return requestDate.isSame(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER:
      return requestDate.isAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return requestDate.isSameOrAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS:
      return requestDate.isBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return requestDate.isSameOrBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return requestDate.isBetween(startDate, endDate, 'days', '[]');
    default:
      return false;
  }
};

export const checkColumnMatchFilter = (columnData: any, filter: filter) => {
  const value =
    filter.type === COLUMN_OPTION_TYPES.OPTIONS
      ? (filter.value as FormOption).value
      : (filter.value as string);
  if (!value) {
    return false;
  }
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.CONTAINS:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type
      );
    case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type,
        false
      );
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData === value
        : columnData === +value;
    case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData !== value
        : columnData !== +value;
    case TABLE_FILTERS_OPERATORS.GREATER:
      return columnData > +value;
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return columnData >= +value;
    case TABLE_FILTERS_OPERATORS.LESS:
      return columnData < +value;
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return columnData <= +value;
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return false;
    default:
      return false;
  }
};

const checkColumnContainsValue = (
  column: string | undefined,
  columnData: any,
  value: string,
  filterTye: string = COLUMN_OPTION_TYPES.TEXT,
  shouldContain: boolean = true
) => {
  let formattedValue = value.toLocaleLowerCase();
  let formattedColumn = columnData;

  if (column === PROGRAM_FIELDS.STATUS) {
    formattedColumn = intl.get(
      `PROGRAMS_LIST_PAGE.STATUS.${columnData.toUpperCase()}`
    );
  }

  if (column === PROGRAM_FIELDS.DELIVERY) {
    formattedColumn = intl.get(
      `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${columnData.toUpperCase()}`
    );
  }

  if (column === PROGRAM_FIELDS.OWNERS) {
    if (shouldContain) {
      return columnData.some((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return fullname.includes(formattedValue);
      });
    } else {
      return columnData.every((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return !fullname.includes(formattedValue);
      });
    }
  }

  if (column === PROGRAM_FIELDS.PROGRAM_NUMBER) {
    return shouldContain
      ? (formattedColumn + '').indexOf(formattedValue) > -1
      : (formattedColumn + '').indexOf(formattedValue) === -1;
  }

  return formattedColumn
    ? shouldContain
      ? formattedColumn.toLowerCase().includes(formattedValue)
      : !formattedColumn.toLowerCase().includes(formattedValue)
    : false;
};

export const filterPrograms: (
  programs: Program[],
  search: string,
  filters: filter[]
) => Program[] = (programs, search, filters) => {
  let filteredPrograms = programs;
  const filtersActive = filters.length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredPrograms = programs.filter((program: Program) => {
      let programMatchSearch = false;
      let programMatchFilters = false;
      if (filtersActive) {
        programMatchFilters = programMatchAllFilters(program, filters);
      }
      if (searchActive) {
        programMatchSearch =
          program.title
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          program.description
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          programMatchSearch;
      }

      if (searchActive && filtersActive) {
        return programMatchSearch && programMatchFilters;
      } else if (searchActive && !filtersActive) {
        return programMatchSearch;
      } else if (!searchActive && filtersActive) {
        return programMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredPrograms;
};
