import React, { useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  OnChangeHandlerFunc,
} from 'react-mentions';
import { Avatar, Typography } from '@getsynapse/design-system';
import { UserAvatars } from 'utils/customTypes';

type UserDataSuggestion = {
  avatar?: { imageSrc: string; initial: string; name: string };
} & SuggestionDataItem;

const CustomSuggestion: React.FC<{
  suggestion: UserDataSuggestion;
  search: string;
  highlightedDisplay: React.ReactNode;
  index: number;
  focused: boolean;
}> = ({ suggestion, focused }) => {
  return (
    <div
      className={classNames('w-full flex items-center px-4 py-2', {
        'bg-neutral-black': focused,
      })}
    >
      <Avatar
        imageSrc={get(suggestion, 'avatar.imageSrc')}
        initial={get(suggestion, 'avatar.initial')}
        size='small'
      />
      <Typography
        variant='body2'
        className={classNames('ml-2', {
          'text-neutral-black': !focused,
          'text-neutral-white': focused,
        })}
      >
        {suggestion.display}
      </Typography>
    </div>
  );
};

const MentionsTextField: React.FC<{
  placeholder?: string;
  value: string;
  onChange: OnChangeHandlerFunc;
  mentionableUsers: UserAvatars[];
  inputClassName?: string;
  disabled?: boolean;
}> = ({
  placeholder,
  value,
  onChange,
  mentionableUsers,
  inputClassName,
  disabled = false,
}) => {
  const userOptions = useMemo(
    () =>
      mentionableUsers.map((user) => ({
        id: user.value,
        display: user.label,
        avatar: user.avatar,
      })),
    [mentionableUsers]
  );

  const handleDisplayTransform = (id: string, display: string) => `@${display}`;

  return (
    <MentionsInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={inputClassName}
      forceSuggestionsAboveCursor={true}
      data-cy='add-new-comment'
      disabled={disabled}
    >
      <Mention
        appendSpaceOnAdd
        trigger='@'
        data={userOptions}
        displayTransform={handleDisplayTransform}
        className={`${inputClassName}--mention`}
        renderSuggestion={(
          suggestion: UserDataSuggestion,
          search: string,
          highlightedDisplay: React.ReactNode,
          index: number,
          focused: boolean
        ) => (
          <CustomSuggestion
            suggestion={suggestion}
            search={search}
            highlightedDisplay={highlightedDisplay}
            index={index}
            focused={focused}
          />
        )}
      />
    </MentionsInput>
  );
};

export default MentionsTextField;
