import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import {
  ProjectCommentMentionsData,
  newPropertyCommentType,
} from 'utils/customTypes';

class RequestCommentsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  updatePropertyComment = async (
    commentId: string,
    message: string,
    mentionsData: ProjectCommentMentionsData
  ) => {
    const { data } = await this.instance.put(
      `/requestPropertyComment/${commentId}`,
      {
        updateFields: { message: message, data: { mentions: mentionsData } },
      }
    );
    return data;
  };

  updateQuestionComment = async (
    commentId: string,
    content: string,
    mentionsData: ProjectCommentMentionsData
  ) => {
    const { data } = await this.instance.put(`/comments/${commentId}`, {
      content: { content: content, data: { mentions: mentionsData } },
    });
    return data;
  };

  fetchPropertiesComments = async (requestId: string) => {
    const { data } = await this.instance.get(
      `/requestPropertyComment/${requestId}/all`
    );
    return data;
  };

  deletePropertiesComment = async (commentId: string) => {
    const { data } = await this.instance.delete(
      `/requestPropertyComment/${commentId}`
    );
    return data;
  };

  postNewPropertiesComment = async (content: newPropertyCommentType) => {
    const { data } = await this.instance.post(
      `/requestPropertyComment/`,
      content
    );
    return data;
  };

  fetchQuestionsComments = async (questionId: string) => {
    const { data } = await this.instance.get(`/request/${questionId}/comments`);
    return data;
  };

  deleteQuestionComment = async (commentId: string) => {
    const { data } = await this.instance.delete(`/comments/${commentId}`);
    return data;
  };

  postNewQuestionComment = async (
    questionId: string,
    content: string,
    mentionsData: ProjectCommentMentionsData
  ) => {
    const { data } = await this.instance.post(
      `/questions/${questionId}/comments`,
      {
        content,
        data: { mentions: mentionsData },
      }
    );
    return data;
  };
}
export default new RequestCommentsAPI();
