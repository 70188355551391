import { useMemo } from 'react';
import { Typography } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import FieldsTable from './FieldsTable';
import ServerPagination from 'Organisms/Pagination/ServerPagination';
import { PageSizeType } from 'utils/customTypes';

import {
  updatePagination,
  selectTotalCount,
  selectFieldsTablePagination,
} from 'state/CustomFields/customFieldsSlice';

const Fields = () => {
  const dispatch = useDispatch();
  const total = useSelector(selectTotalCount);
  const pagination = useSelector(selectFieldsTablePagination);

  const onPaginationChange = (params: object) => {
    dispatch(updatePagination(params));
  };

  const { page, size } = useMemo<{ page: number; size: PageSizeType }>(() => {
    return {
      page: pagination.offset / pagination.limit + 1,
      size: pagination.limit,
    };
  }, [pagination]);

  return (
    <div>
      <Typography className='text-neutral pb-2'>
        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.PAGE_SUB_TITLE')}
      </Typography>
      <FieldsTable />
      <ServerPagination
        total={total}
        onChange={onPaginationChange}
        page={page}
        size={size}
      />
    </div>
  );
};

export default Fields;
