import React, { useMemo, useState, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import {
  Typography,
  FormItem,
  Dropdown,
  TextField,
} from '@getsynapse/design-system';
import { DATE, PROJECT_CANCEL_REASONS } from 'utils/constants';
import { FormOption } from 'utils/customTypes';
import useModal from 'Hooks/useModal';
import moment from 'moment';

const Content: React.FC<{
  onChangeReason: (reason: string) => void;
  onChangeCustomReason: (reason: string) => void;
  cancelReason?: string;
  cancellationDate?: string;
}> = ({
  onChangeReason,
  onChangeCustomReason,
  cancelReason,
  cancellationDate,
}) => {
  const [reason, setReason] = useState('');

  const reasonOptions = useMemo(
    () =>
      Object.entries(PROJECT_CANCEL_REASONS).map((entry) => {
        return {
          label: intl.get(`PROJECT_DETAIL.CANCEL_PROJECT.REASONS.${entry[0]}`),
          value: entry[1],
        };
      }),
    []
  );

  return (
    <React.Fragment>
      {!cancelReason ? (
        <>
          <Typography variant='body'>
            {intl.getHTML('PROJECT_DETAIL.CANCEL_PROJECT.BODY')}
          </Typography>
          <Typography variant='body' className='mt-6'>
            {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.SELECT_REASON')}
          </Typography>
          <FormItem
            className='mt-6'
            label={intl.get(
              'PROJECT_DETAIL.CANCEL_PROJECT.CANCELLATION_REASON'
            )}
          >
            <Dropdown
              placeholder={intl.get(
                'PROJECT_DETAIL.CANCEL_PROJECT.SELECT_REASON_PLACEHOLDER'
              )}
              onChange={(option: FormOption) => {
                setReason(option.value);
                onChangeReason(option.value);
              }}
              options={reasonOptions}
              triggerProps={{
                'data-cy': 'cancel-reason-picker',
                'aria-label': intl.get(
                  'PROJECT_DETAIL.CANCEL_PROJECT.CANCELLATION_REASON'
                ),
              }}
            />
          </FormItem>
          {reason === PROJECT_CANCEL_REASONS.OTHER && (
            <FormItem
              className='mt-6'
              label={intl.get('PROJECT_DETAIL.CANCEL_PROJECT.SPECIFY')}
            >
              <TextField
                aria-label={intl.get('PROJECT_DETAIL.CANCEL_PROJECT.SPECIFY')}
                className='p-1'
                variant='text'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCustomReason(e.target.value)
                }
                placeholder={intl.get(
                  'PROJECT_DETAIL.CANCEL_PROJECT.SPECIFY_PLACEHOLDER'
                )}
                data-cy='specify-cancel-reason-input'
              />
            </FormItem>
          )}
        </>
      ) : (
        <>
          <Typography variant='h6' className='text-sm'>
            {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.CANCELLATION_REASON')}
          </Typography>
          <Typography data-testid='cancel-reason'>
            {intl.get(
              `PROJECT_DETAIL.CANCEL_PROJECT.REASONS.${Object.keys(
                PROJECT_CANCEL_REASONS
              ).find((key) => PROJECT_CANCEL_REASONS[key] === cancelReason)}`
            ) || cancelReason}
          </Typography>
          {cancellationDate && (
            <>
              <Typography variant='h6' className='text-sm mt-6'>
                {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.CANCELLATION_DATE')}
              </Typography>
              <Typography data-testid='cancellation-date'>
                {moment(cancellationDate).format(DATE.SHORT_FORMAT)}
              </Typography>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const CancelProject: React.FC<{
  confirmCancelProject?: (reason: string, archive: boolean) => void;
  shouldDisplayModal: boolean;
  closeModalCallback: () => void;
  cancelReason?: string;
  cancellationDate?: string;
}> = ({
  confirmCancelProject,
  shouldDisplayModal,
  closeModalCallback,
  cancelReason,
  cancellationDate,
}) => {
  const { Modal, modalProps, openModal, closeModal, updateModal } = useModal();
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [canCancelProject, setCanCancelProject] = useState(false);

  const onCloseModal = useCallback(() => {
    setReason('');
    setCustomReason('');
    closeModal();
    closeModalCallback();
  }, [setReason, setCustomReason, closeModal, closeModalCallback]);

  const handleSubmit = useCallback(
    (archive: boolean) => {
      if (reason === PROJECT_CANCEL_REASONS.OTHER && customReason) {
        confirmCancelProject!(customReason, archive);
      } else {
        confirmCancelProject!(reason, archive);
      }
      onCloseModal();
    },
    [customReason, reason, confirmCancelProject, onCloseModal]
  );

  const getActionButtons = useCallback(() => {
    return !cancelReason
      ? [
          {
            children: intl.get('PROJECT_DETAIL.CANCEL_PROJECT.SUBMIT'),
            variant: 'primary',
            color: 'danger',
            disabled: !canCancelProject,
            'data-cy': 'confirm-button',
            onClick: () => handleSubmit(false),
          },
          {
            children: intl.get('PROJECT_DETAIL.CANCEL_PROJECT.ARCHIVE'),
            variant: 'secondary',
            disabled: !canCancelProject,
            'data-cy': 'confirm-and-archive-button',
            onClick: () => handleSubmit(true),
          },
          {
            children: intl.get('PROJECT_DETAIL.CANCEL_PROJECT.CANCEL'),
            variant: 'tertiary',
            'data-cy': 'cancel-button',
            onClick: () => onCloseModal(),
          },
        ]
      : [];
  }, [canCancelProject, onCloseModal, handleSubmit, cancelReason]);

  useEffect(() => {
    if (!reason || (reason === PROJECT_CANCEL_REASONS.OTHER && !customReason)) {
      if (canCancelProject) {
        setCanCancelProject(false);
      }
    } else {
      if (!canCancelProject) {
        setCanCancelProject(true);
      }
    }
    updateModal({
      actionButtons: getActionButtons(),
    });
  }, [canCancelProject, reason, customReason, getActionButtons, updateModal]);

  const displayModal = useCallback(() => {
    openModal({
      title: cancelReason
        ? intl.get('PROJECT_DETAIL.CANCEL_PROJECT.CANCELLED_PROJECT_TITLE')
        : intl.get('PROJECT_DETAIL.CANCEL_PROJECT.TITLE'),
      titleIcon: { name: 'remove-circle', className: 'text-error-dark' },
      size: 'medium',
      children: (
        <Content
          onChangeReason={setReason}
          onChangeCustomReason={setCustomReason}
          cancelReason={cancelReason}
          cancellationDate={cancellationDate}
        />
      ),
      actionButtons: getActionButtons(),
    });
  }, [
    setReason,
    setCustomReason,
    getActionButtons,
    openModal,
    cancelReason,
    cancellationDate,
  ]);

  useEffect(() => {
    if (shouldDisplayModal && !modalProps.isOpen) {
      displayModal();
    }
  }, [shouldDisplayModal, modalProps.isOpen, displayModal]);

  return (
    <Modal
      {...modalProps}
      aria-label='cancel project confirmation'
      data-cy='cancel-project-modal'
      closeModal={onCloseModal}
    />
  );
};

export default CancelProject;
