import { Owner, Option } from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import {
  PROGRAMS_STATUS,
  PROGRAM_NUMBER_FORMAT,
  COLUMN_OPTION_TYPES,
  PROGRAMS_DELIVERY_TYPE,
  PROGRAM_FIELDS,
} from 'utils/constants';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import {
  TableFilterProps,
  Typography,
  AvatarGroup,
  tailwindOverride,
  Tag,
  TextField,
  Datepicker,
  Dropdown,
} from '@getsynapse/design-system';
import React from 'react';

export const getOwnersCell = (owners: Owner[]) => {
  if (!owners) {
    return '';
  }
  if (owners.length === 1) {
    return (
      <div className='flex items-center'>
        <UserAvatar
          user={{
            avatar_url: owners[0].avatar_url,
            data: {
              firstName: get(owners[0], 'data.firstName'),
              lastName: get(owners[0], 'data.lastName'),
            },
          }}
          className='mr-2.5'
        />
        {`${get(owners, '0.data.firstName')} ${get(owners, '0.data.lastName')}`}
      </div>
    );
  } else {
    return (
      <AvatarGroup
        avatars={owners.map((owner) => {
          const firstName = get(owner, 'data.firstName');
          const lastName = get(owner, 'data.lastName');
          return {
            size: 'medium',
            imageSrc: owner.avatar_url,
            initial: get(firstName, '[0]'),
            name: `${firstName} ${lastName}`,
          };
        })}
      />
    );
  }
};

export const getProgramStatusPill = (status: string) => {
  const statusMapping = {
    [PROGRAMS_STATUS.NOT_STARTED]: {
      bgColor: 'bg-purple-lightest',
      textColor: 'text-purple-darker',
    },
    [PROGRAMS_STATUS.IN_PROGRESS]: {
      bgColor: 'bg-teal-lightest',
      textColor: 'text-teal-dark',
    },
    [PROGRAMS_STATUS.CLOSED]: {
      bgColor: 'bg-warning-lightest',
      textColor: 'text-warning-darker',
    },
  };

  const colorAndLabel = statusMapping[status];

  return (
    <Tag
      className={tailwindOverride(
        `text-xs border border-transparent py-0.5 ${get(
          colorAndLabel,
          'bgColor',
          ''
        )}`
      )}
      textClassName={get(colorAndLabel, 'textColor', '')}
      label={intl
        .get(`PROGRAMS_LIST_PAGE.STATUS.${status.toUpperCase()}`)
        .defaultMessage('')}
    />
  );
};

export const getProgramNumberColumn = (programNumber: number | null) => {
  if (!programNumber) {
    return '';
  }
  const numberLength = programNumber.toString().length;
  if (numberLength < 7) {
    return (
      PROGRAM_NUMBER_FORMAT.FULL.slice(
        0,
        PROGRAM_NUMBER_FORMAT.FULL.length - numberLength
      ) + programNumber
    );
  } else {
    return PROGRAM_NUMBER_FORMAT.BASE + programNumber;
  }
};

export const getFilterInput: TableFilterProps['filterInputElement'] = (
  column,
  filterInput,
  setInput,
  operator
) => {
  const getPickerOptions = () => {
    const statusOptions = Object.values(PROGRAMS_STATUS);
    const deliveryOptions = Object.values(PROGRAMS_DELIVERY_TYPE);
    switch (column.value) {
      case PROGRAM_FIELDS.STATUS:
        return statusOptions.map((status: string) => ({
          label: intl
            .get(`PROGRAMS_LIST_PAGE.STATUS.${status.toUpperCase()}`)
            .defaultMessage(''),
          value: status,
        }));
      case PROGRAM_FIELDS.DELIVERY:
        return deliveryOptions.map((deliveryType: string) => ({
          label: intl.get(
            `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${deliveryType.toUpperCase()}`
          ),
          value: deliveryType,
        }));
      default:
        return [];
    }
  };

  return (
    <div className='flex-grow self-baseline'>
      <Typography
        variant='label'
        weight='medium'
        className='inline-block mb-2 leading-4 tracking-px02'
      >
        {intl.get('FILTER_GENERAL.VALUE')}
      </Typography>
      {column.type === COLUMN_OPTION_TYPES.TEXT && (
        <TextField
          name='table-header__search-input'
          placeholder={intl.get('FILTER_GENERAL.INPUT_VALUE')}
          state='default'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInput(event.target.value);
          }}
          value={filterInput as string}
          data-testid='filter__text-inputs'
          height='small'
          inputClassName='py-1'
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.OPTIONS && (
        <Dropdown
          options={getPickerOptions()}
          listProps={{ 'data-testid': 'filter__options-list' }}
          triggerProps={{
            'data-testid': 'filter__options-input',
            size: 'sm',
          }}
          onChange={(value: Option) => {
            setInput(value);
          }}
          values={filterInput ? [filterInput] : []}
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.DATE && (
        <div data-testid='date__picker' className='flex-grow'>
          <Datepicker
            canEnterDateManually={false}
            data-testid='filter__date-picker'
            canSelectRange={operator ? operator.value === 'BETWEEN' : true}
            endDateLabel={intl.get('FILTER_GENERAL.TO')}
            onPickDate={(event: any) => {
              setInput(event);
            }}
            startDateLabel={intl.get('FILTER_GENERAL.FROM')}
            triggerClassname='h-8 py-1 text-label'
            className='w-full'
          />
        </div>
      )}
    </div>
  );
};
