import { ReactNode } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';

type DetailsPageProps = {
  topBar?: ReactNode;
  content: ReactNode;
  className?: string;
  bodyClassName?: string;
};

const DetailsPage = ({
  topBar,
  content,
  className = '',
  bodyClassName = '',
}: DetailsPageProps) => {
  return (
    <div
      className={tailwindOverride(
        'mx-6 rounded-t-lg border-l border-r border-t border-neutral-lighter-two flex-grow',
        className
      )}
    >
      {topBar && (
        <div className='h-12 border-b p-2 border-neutral-lighter-two'>
          {topBar}
        </div>
      )}
      <div
        className={tailwindOverride(
          'px-6 pt-4 overflow-y-auto pb-8 flex-grow',
          `${topBar ? 'max-h-details-with-bar' : 'max-h-details-without-bar'}`,
          bodyClassName
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default DetailsPage;
