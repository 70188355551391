import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import ProcessesAPI from './processesAPI';
import {
  NewProjectProcess,
  ProjectProcess,
  ProjectProcessStage,
  Status,
} from 'utils/customTypes';
import {
  compareProcessStages,
  calculateEstimatedProcessCompletionTime,
} from './helpers';
import { trackEvent } from 'Services/pendo';
import { PENDO_EVENTS, SLICE_STATUS } from 'utils/constants';
import set from 'lodash/set';

interface Processes {
  projectProcesses: ProjectProcess[];
  processIdToUpdate: string | null;
  status: Status;
}
/* ============================= INITIAL STATE ============================== */
const initialState: Processes = {
  projectProcesses: [],
  processIdToUpdate: null,
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getOrganizationProcesses = createAsyncThunk(
  'processes/GET_PROCESSES',
  async () => {
    const response = await ProcessesAPI.fetchOrganizationProcesses();
    return response;
  }
);

export const addNewProjectProcess = createAsyncThunk(
  'processes/ADD_NEW_PROCESS',
  async (process: NewProjectProcess) => {
    const { projectStages, ...processData } = process;
    const response = await ProcessesAPI.addNewProcess({
      ...processData,
    } as NewProjectProcess);

    const newProcess: ProjectProcess = {
      ...response,
      projectStages: [],
      stagesOrdering: [],
    };

    for (const stage of projectStages) {
      const newStage = { ...stage, process_id: response.id };
      const resp = await ProcessesAPI.addNewProcessStage(newStage);
      newProcess.projectStages.push(resp);
      newProcess.stagesOrdering.push(resp.id);
    }
    trackEvent(PENDO_EVENTS.PROCESS_ADDED, {
      name: processData.processName,
    });
    return newProcess;
  }
);

export const updateProjectProcess = createAsyncThunk(
  'processes/UPDATE_PROCESS',
  async (processToUpdate: ProjectProcess, { getState }) => {
    const state = getState() as RootState;
    const foundProcess = state.processes.projectProcesses.find(
      (process: ProjectProcess) => processToUpdate.id === process.id
    );
    if (!foundProcess) {
      return;
    }
    const {
      projectStages,
      stagesOrdering,
      estimatedCompletionTime,
      id,
      ...processData
    } = processToUpdate;
    const { stagesToBeAdded, stagesToBeDeleted, stagesToBeUpdated } =
      compareProcessStages(foundProcess.projectStages, projectStages);
    for (const stage of stagesToBeDeleted) {
      await ProcessesAPI.removeProcessStage(stage.id);
    }
    for (const stage of stagesToBeUpdated) {
      const { id, ...stageData } = stage;
      if (!stageData.data) {
        set(stageData, 'data', {});
      }

      await ProcessesAPI.updateProcessStage(id, {
        ...stageData,
      });
    }
    let updatedProcessStages = projectStages;
    for (const stage of stagesToBeAdded) {
      const resp = await ProcessesAPI.addNewProcessStage({
        ...stage,
        process_id: foundProcess.id,
      });
      updatedProcessStages = projectStages.map((stg: ProjectProcessStage) =>
        stg.stageName === resp.stageName ? resp : stg
      );
    }
    const response = await ProcessesAPI.updateProcess(id, {
      ...processData,
    } as ProjectProcess);
    const updatedProcess = {
      ...response,
      projectStages: updatedProcessStages,
    };
    return updatedProcess;
  }
);

export const removeProjectProcess = createAsyncThunk(
  'processes/REMOVE_PROCESS',
  async (process: ProjectProcess) => {
    const { id, projectStages } = process;
    for (const stage of projectStages) {
      await ProcessesAPI.removeProcessStage(stage.id);
    }
    await ProcessesAPI.removeProcess(id);
    return id;
  }
);

export const enableProjectProcess = createAsyncThunk(
  'processes/ENABLE_PROCESS',
  async (data: { id: string; enabled: boolean }, { getState }) => {
    const state = getState() as RootState;
    const foundProcess = state.processes.projectProcesses.find(
      (process: ProjectProcess) => data.id === process.id
    );
    if (foundProcess) {
      const clonedProcess: Partial<ProjectProcess> = { ...foundProcess };
      if (clonedProcess.id) {
        delete clonedProcess.id;
      }
      if (clonedProcess.projectStages) {
        delete clonedProcess.projectStages;
      }
      await ProcessesAPI.updateProcess(data.id, {
        ...clonedProcess,
        enabled: data.enabled,
      });
      return data;
    }
  }
);

/* ================================= REDUCER ================================ */
const processesSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationProcesses.fulfilled, (state, action) => {
        state.projectProcesses = action.payload;
      })
      .addCase(addNewProjectProcess.fulfilled, (state, action) => {
        state.projectProcesses = [action.payload, ...state.projectProcesses];
      })
      .addCase(updateProjectProcess.pending, (state) => {
        state.status = SLICE_STATUS.UPDATING;
      })
      .addCase(updateProjectProcess.fulfilled, (state, action) => {
        state.projectProcesses = state.projectProcesses.map(
          (process: ProjectProcess) =>
            process.id === action.payload?.id ? action.payload : process
        );
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(enableProjectProcess.fulfilled, (state, action) => {
        state.projectProcesses = state.projectProcesses.map(
          (process: ProjectProcess) =>
            process.id === action.payload?.id
              ? { ...process, enabled: action.payload.enabled }
              : process
        );
      })
      .addCase(removeProjectProcess.fulfilled, (state, action) => {
        state.projectProcesses = state.projectProcesses.filter(
          (process: ProjectProcess) => process.id !== action.payload
        );
      })
      .addCase(setProcessIdToUpdate, (state, action) => {
        state.processIdToUpdate = action.payload;
      });
  },
});

/* ================================ ACTIONS ================================= */
export const setProcessIdToUpdate = createAction<string | null>(
  'processes/SET_PROCESS_TO_UPDATE'
);

/* =============================== SELECTORS ================================ */
export const selectProcessSliceStatus = (state: RootState) =>
  state.processes.status;

export const selectProjectProcesses = (state: RootState) => {
  return state.processes.projectProcesses;
};

export const selectEnabledProjectProcesses = createSelector(
  [selectProjectProcesses],
  (processes: ProjectProcess[]) => {
    return processes.filter((process) => process.enabled !== false);
  }
);

export const selectProjectProcessIdToUpdate = (state: RootState) =>
  state.processes.processIdToUpdate;

export const selectProjectProcessById = createSelector(
  [selectProjectProcesses, selectProjectProcessIdToUpdate],
  (processes: ProjectProcess[], processId: string | null) => {
    const foundProcess = processes.find((process: ProjectProcess) => {
      return process.id === processId;
    });
    return foundProcess ? foundProcess : null;
  }
);

export const formattedProjectProcesses = createSelector(
  [selectProjectProcesses],
  (processes: ProjectProcess[]) => {
    return processes.map((process: ProjectProcess) => ({
      ...process,
      estimatedCompletionTime: calculateEstimatedProcessCompletionTime(
        process.projectStages
      ),
    }));
  }
);

export default processesSlice.reducer;
