import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { TaskBundleCreationData } from 'utils/customTypes';

class TaskBundleAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTaskBundles = async () => {
    const { data } = await this.instance.get('/taskBundles');
    return data;
  };

  createTaskBundle = async (bundleData: TaskBundleCreationData) => {
    const { data } = await this.instance.post('/taskBundles', bundleData);
    return data;
  };

  attachTaskBundleToProject = async (
    taskBundleId: string,
    projectId: string
  ) => {
    const { data } = await this.instance.post(
      `/project/${projectId}/assignTaskBundle/${taskBundleId}`
    );
    return data;
  };

  deleteTaskBundle = async (bundleId: string) => {
    const { data } = await this.instance.delete(`/taskBundles/${bundleId}`);
    return data;
  };
}
export default new TaskBundleAPI();
