import { useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import Auth from '@aws-amplify/auth';

import { Icon, IconButton, Typography } from '@getsynapse/design-system';
import { closeSidekick } from 'state/Sidekick/sidekickSlice';
import config from 'config/Config';

const Header = () => {
  const dispatch = useDispatch();

  return (
    <div className='flex justify-between py-4 px-6 border-b border-neutral-lighter-two font-semibold'>
      <div className='flex items-center'>
        <Icon name='planet-outline' className='mr-2' />

        <Typography variant='body' weight='medium'>
          {intl.get('SIDEKICK')}
        </Typography>
      </div>

      <IconButton
        onClick={() => {
          dispatch(closeSidekick());
        }}
        name='close'
        className='text-primary-dark rounded text-lg p-1 hover:bg-neutral-lightest hover:shadow-allocation-table'
      />
    </div>
  );
};

const Body = () => {
  const [token, setToken] = useState();
  const sidekickHost = config.get('sidekickURL') + '?token=' + token;

  Auth.currentAuthenticatedUser().then((user) => {
    setToken(user.signInUserSession.idToken.jwtToken);
  });

  if (token) {
    return (
      <iframe
        title='Cognota Sidekick'
        src={sidekickHost}
        className='w-full h-full'
      />
    );
  } else {
    return <></>;
  }
};

const Sidekick = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className='top-0 left-0 absolute w-screen h-screen z-50 flex'>
        <div
          className='w-1/3 h-screen bg-neutral-black opacity-30'
          onClick={() => {
            dispatch(closeSidekick());
          }}
        />

        <div className='flex flex-col w-2/3 h-screen bg-neutral-white'>
          <Header />
          <Body />
        </div>
      </div>
    </>
  );
};

export default Sidekick;
