import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import {
  getTeamTasksAssigneesList,
  getUserTasksAssigneesList,
} from 'state/ProjectTasks/projectTaskSlice';
import { allUsers } from 'state/UsersManagement/usersManagementSlice';
import { TASKS_TABLE_TABS, TASK_STATUS, TASK_TYPES } from 'utils/constants';
import { getUserDisplayName, getUserInitials } from 'utils/functions';
import { RangeFilter, TaskFiltersKey } from 'utils/types/filters';
import { AllUsersType, Option, ProjectTasksTableTab } from 'utils/customTypes';
import moment from 'moment';

function useTasksFilters(taskTable: ProjectTasksTableTab) {
  const teamTasksAssigneesList = useSelector(getTeamTasksAssigneesList);
  const userTasksAssigneesList = useSelector(getUserTasksAssigneesList);
  const orgnizationUsers = useSelector(allUsers);

  const statusOptions = useMemo(() => {
    const options = Object.keys(TASK_STATUS).map((key) => ({
      value: TASK_STATUS[key],
      label: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const typeOptions = useMemo(() => {
    const options = Object.keys(TASK_TYPES).map((key) => ({
      value: key,
      label: intl.get(`TASKS.TASK_TYPES.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const assineeIds = useMemo(() => {
    const assignees =
      taskTable === TASKS_TABLE_TABS.TEAM_TASKS
        ? teamTasksAssigneesList
        : userTasksAssigneesList;
    return assignees.map((assignee) => assignee.id);
  }, [teamTasksAssigneesList, userTasksAssigneesList, taskTable]);

  const assigneeUsers = useMemo(() => {
    const assignees = orgnizationUsers
      .filter((user: AllUsersType) => assineeIds.includes(user.id))
      .map((user) => ({
        label: getUserDisplayName(user),
        avatar: {
          imageSrc: user.avatar_url,
          initial: getUserInitials(user),
          name: getUserDisplayName(user),
        },
        value: user.id,
        iconSrc: user.avatar_url,
      }));
    return orderBy(assignees, 'label');
  }, [orgnizationUsers, assineeIds]);

  const tasksFiltersOptions: Record<TaskFiltersKey, Option[] | RangeFilter> =
    useMemo(
      () => ({
        status: statusOptions,
        type: typeOptions,
        assignedUsers: assigneeUsers,
        start_date: { from: '', to: '' },
        due_date: { from: '', to: '' },
        completion_date: { from: '', to: '' },
        estimate_hours: { from: '', to: '' },
        actual_hours: { from: '', to: '' },
      }),
      [statusOptions, typeOptions, assigneeUsers]
    );

  const getDateFilterLabel = useCallback(
    (value: RangeFilter, dateFormat: string) => {
      let label = '';
      if (value.from) {
        label = `${moment(new Date(value.from)).format(dateFormat)}`;
      }
      if (value.from && value.to) {
        label = `${label} - ${moment(new Date(value.to)).format(dateFormat)}`;
      } else if (!value.from && value.to) {
        label = `${moment(new Date(value.to)).format(dateFormat)}`;
      }
      return label;
    },
    []
  );

  const getHoursRangeFilterLabel = useCallback((value: RangeFilter) => {
    let label = '';
    if (value.from) {
      label = `${value.from}`;
    }
    if (value.from && value.to) {
      label = `${label} - ${value.to}`;
    }
    if (!value.from && value.to) {
      label = `${value.to}`;
    }
    return label.length > 0 ? `${label} hrs` : ``;
  }, []);

  const getFilterOptionLabelByKeyAndValue = useCallback(
    (key: TaskFiltersKey, value: string) => {
      const options = tasksFiltersOptions[key] as Option[];
      const option = options.find((option: Option) => option.value === value);
      return option ? option.label : '';
    },
    [tasksFiltersOptions]
  );

  const getFilterOptionsByKey = useCallback(
    (key: TaskFiltersKey) => tasksFiltersOptions[key] as Option[],
    [tasksFiltersOptions]
  );

  return {
    getFilterOptionsByKey,
    getDateFilterLabel,
    getHoursRangeFilterLabel,
    getFilterOptionLabelByKeyAndValue,
  };
}

export default useTasksFilters;
