import MyWorkspaceTable from '../MyWorkspaceTable';
import { useMemo } from 'react';
import { tablesLimit } from 'state/MyWorkspace/myWorkspaceSlice';
import { useSelector } from 'react-redux';
import Skelton from 'Atoms/Skelton/Skelton';

const TasksTableLoader = () => {
  const tableLimit = useSelector(tablesLimit);

  const rows = useMemo(() => {
    const tasksRows = [];
    for (let i = 0; i < tableLimit; i++) {
      tasksRows.push({
        className: 'table w-full table-fixed cursor-pointer h-14',
        cells: [
          {
            content: <Skelton className='w-3/4 h-4' />,
          },
          {
            content: <Skelton className='w-3/4 h-4' />,
          },
          {
            content: <Skelton className='w-7 h-2 rounded' />,
            className: 'w-16',
          },
          {
            content: <Skelton className='w-3/4 h-4' />,
            className: 'w-36',
          },
        ],
      });
    }
    return tasksRows;
  }, [tableLimit]);

  return (
    <MyWorkspaceTable
      headCells={[
        {
          content: <Skelton />,
        },
        {
          content: <Skelton />,
        },
        {
          content: '',
          className: 'w-16',
        },
        {
          content: <Skelton />,
          className: 'w-36',
        },
      ]}
      rows={rows}
    />
  );
};

export default TasksTableLoader;
