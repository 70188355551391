import axios, { AxiosInstance } from 'axios';
import config from 'config/Config';
import get from 'lodash/get';
import { initAxios } from 'utils/axios';
import {
  AddParticipantApiBody,
  UpdateParticipantApiBody,
  UpdateParticipantsApiBody,
  ParticipantsCapacityApiResponse,
  UpdateParticipantsApiResponse,
} from './types';

class TasksAssignmentsResourcesCapacity {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchCapacity: (
    projectId: string,
    query: { tableStartDate: string; tableEndDate: string }
  ) => Promise<ParticipantsCapacityApiResponse> = async (projectId, query) => {
    const response = await this.instance.get(
      `/capacity/project/retrieveV2ProjectParticipants/${projectId}`,
      { params: { ...query } }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  fetchParticipantCapacity: (
    project_id: string,
    user_id: string,
    query: { tableStartDate: string; tableEndDate: string }
  ) => Promise<ParticipantsCapacityApiResponse> = async (
    project_id,
    user_id,
    query
  ) => {
    const response = await this.instance.get(
      `/capacity/project/retrieveSingleV2ProjectParticipant/${project_id}/${user_id}`,
      { params: { ...query } }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  addParticipant: (
    participant: AddParticipantApiBody
  ) => Promise<UpdateParticipantsApiResponse> = async (participant) => {
    const response = await this.instance.post(
      '/capacity/project/createV2ProjectParticipant',
      { ...participant }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  updateParticipant: (
    participant: UpdateParticipantApiBody
  ) => Promise<UpdateParticipantsApiResponse> = async (participant) => {
    const response = await this.instance.post(
      '/capacity/project/updateV2ProjectParticipant',
      { ...participant }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  removeParticipant: (participant: UpdateParticipantsApiBody) => Promise<any> =
    async (participant) => {
      const response = await this.instance.post(
        '/capacity/project/deleteV2ProjectParticipant',
        { ...participant }
      );
      if (axios.isAxiosError(response)) {
        const errorMessage = get(response, 'response.data.message', '');
        throw errorMessage;
      }
    };
}

export default new TasksAssignmentsResourcesCapacity();
