import React from 'react';
import intl from 'react-intl-universal';
import { Typography, Icon, tailwindOverride } from '@getsynapse/design-system';
import { ParticipantTypes } from 'utils/constants';
import useWeeksSlider from '../../../hooks/useWeeksSlider';
import Timeline, { Column } from '../../Timeline/Timeline';

const Toggle: React.FC<{
  participantType: ParticipantTypes;
  onToggleSection: (sectionId: string) => void;
  isSectionOpen?: boolean;
}> = ({ participantType, isSectionOpen, onToggleSection }) => {
  const { weeksArray } = useWeeksSlider();

  const participantTypeLabel: Record<ParticipantTypes, string> = {
    [ParticipantTypes.OWNERS]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.OWNER'
    ),
    [ParticipantTypes.LEARNING]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.LD'
    ),
    [ParticipantTypes.BUSINESS]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.BUSINESS'
    ),
    [ParticipantTypes.EXTERNAL]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EXTERNAL'
    ),
  };

  return (
    <div
      data-cy={`${participantType}-section-toggle`}
      className='h-10 flex cursor-pointer bg-neutral-white'
      onClick={() => onToggleSection(participantType)}
      role='button'
      aria-label={`${participantType}-section-toggle`}
    >
      <div className={tailwindOverride('w-2/5 flex h-full relative')}>
        <div className='w-12 h-full flex items-center justify-center'>
          <div
            className='flex'
            style={{
              transform: `${isSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
              transition: 'all 0.2s linear',
            }}
          >
            <Icon name='chevron-down' className='text-lg text-neutral-dark' />
          </div>
        </div>
        <div className='px-2 flex flex-1 items-center'>
          <Typography
            variant='caption'
            className='text-neutral-darker font-semibold'
          >
            {participantTypeLabel[participantType]}
          </Typography>
        </div>
      </div>
      <div className='w-3/5 flex h-full'>
        <Timeline
          id={`${participantType}-section-toggle`}
          className='bg-neutral-white'
          displayGridLoader={false}
        >
          {weeksArray.map((week, index) => (
            <Column
              key={week.startWeekDate.valueOf() + index}
              isCurrentWeek={week.isCurrentWeek}
            />
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default Toggle;
