import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { Tab, TabList, Button } from '@getsynapse/design-system';
import { selectProjectProcessIdToUpdate } from 'state/Processes/processesSlice';
import { SETTINGS_SECTIONS, PATHS } from 'utils/constants';
import ProjectSettings from './ProjectSettings/ProjectSettings';
import IntakeSettings from './IntakeSettings/IntakeSettings';
import ProcessDetailPage from './ProjectSettings/ProcessDetailPage';
import Fields from './Fields/Fields';
import { useHistory } from 'react-router-dom';

const Configurations = () => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const indexParam = new URLSearchParams(window.location.search).get('section');
  const isUpdatingProjectProcess =
    useSelector(selectProjectProcessIdToUpdate) !== null;

  useEffect(() => {
    const sectionMapping = {
      [SETTINGS_SECTIONS.INTAKE]: 0,
      [SETTINGS_SECTIONS.PROJECTS]: 1,
      [SETTINGS_SECTIONS.FIELDS]: 2,
    };
    let tab = 0;
    if (indexParam) {
      tab = sectionMapping[indexParam];
    } else {
      tab = isUpdatingProjectProcess ? 1 : 0;
    }
    setActiveIndex(tab);
    return () => {
      setActiveIndex(0);
    };
  }, [indexParam, isUpdatingProjectProcess]);

  return isUpdatingProjectProcess ? (
    <ProcessDetailPage />
  ) : (
    <Tabs
      className='min-h-full flex flex-col'
      index={activeIndex}
      onChange={(index) => setActiveIndex(index)}
    >
      <div className='flex justify-between'>
        <TabList className='max-w-xs mb-4 flex-1'>
          <Tab index={0} data-cy='platform-request'>
            {intl.get('INTAKE')}
          </Tab>

          <Tab index={1} data-cy='platform-settings__projects-tab'>
            {intl.get('ENTITIES.PROJECT', { num: 2 })}
          </Tab>

          <Tab index={2} data-cy='platform-settings__fields-tab'>
            {intl.get('ENTITIES.FIELDS', { num: 2 })}
          </Tab>
        </TabList>
        {activeIndex === 2 && (
          <Button
            onClick={() => history.push(PATHS.NEW_CUSTOM_FIELD_PAGE)}
            data-cy='add-fields-button'
            className='h-8'
          >
            {intl.get('SETTINGS_PAGE.FIELDS_PAGE.BUTTON_TITLE')}
          </Button>
        )}
      </div>
      <TabPanels className='flex-grow flex flex-col pb-4 max-h-details-without-bar'>
        <TabPanel key={0} className='flex-grow bg-neutral-white rounded pt-6'>
          <IntakeSettings />
        </TabPanel>

        <TabPanel key={1} className='flex-grow bg-neutral-white rounded'>
          <ProjectSettings />
        </TabPanel>

        <TabPanel key={2} className='flex-grow bg-neutral-white rounded'>
          <Fields />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Configurations;
