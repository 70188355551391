import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { SidePanel } from '@getsynapse/design-system';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import TaskSidePanelContent from './TaskSidePanelContent';
import TaskSidePanelHeader from './TaskSidePanelHeader';
import { TaskDetailType, ProjectPermissionsLevel } from 'utils/customTypes';
import {
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_USER_ACTIONS,
  TASK_STATUS,
} from 'utils/constants';
import useSidePanel from 'Hooks/useSidePanel';
import { getDifference } from 'Pages/ProjectPage/helpers/updatedProjectData';
import { getActualHoursData } from 'Pages/ProjectPage/tabs/Tasks/helpers/helpers';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

import {
  fetchTask,
  fetchTaskActualHours,
  getSingleTaskData,
  resetTask,
  updateTask,
  updateTaskActualHours,
} from 'state/SingleTask/singleTaskSlice';
import { fetchProject } from 'state/Project/projectSlice';

interface TaskSidePanelProps {
  taskId?: string;
  onClosePanel?: () => void;
  onSave?: () => void;
  currentUserId?: string;
  permissionsLevel?: ProjectPermissionsLevel;
  availableUserActions?: string[];
  isUserAssignedToTask?: boolean;
  canUpdate?: boolean;
}

const TaskSidePanel: React.FC<TaskSidePanelProps> = ({
  taskId,
  onClosePanel = () => {},
  onSave = () => {},
  currentUserId,
  permissionsLevel,
  availableUserActions = [],
  isUserAssignedToTask,
  canUpdate,
}) => {
  const dispatch = useDispatch();
  const taskData = useSelector(getSingleTaskData);
  const [updatedData, setUpdatedData] = useState<TaskDetailType>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const isCurrentUserTaskCreator = taskData
    ? currentUserId === taskData.created_by
    : false;

  let canUpdateTask: boolean;
  if (canUpdate !== undefined) {
    canUpdateTask = canUpdate;
  } else {
    canUpdateTask =
      permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
      (availableUserActions.includes(PROJECT_USER_ACTIONS.UPDATE_TASK) &&
        (isUserAssignedToTask || isCurrentUserTaskCreator));
  }
  const isViewOnlyMode =
    taskData.status === TASK_STATUS.ON_HOLD ||
    taskData.disabled ||
    !canUpdateTask;

  const closePanelCallback = useCallback(() => {
    setIsUpdating(false);
    onClosePanel();
    dispatch(resetTask());
  }, [dispatch, onClosePanel]);

  const {
    openPanel,
    isPanelOpen,
    closePanel,
    onClose,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    showUnsavedBanner,
    showUnsavedChangesAnimation,
  } = useSidePanel(closePanelCallback);

  useEffect(() => {
    if (taskId) {
      openPanel();
      Promise.all([
        dispatch(fetchTask(taskId)),
        dispatch(fetchTaskActualHours(taskId)),
        dispatch(getAllUsers()),
      ]);
    }
  }, [dispatch, openPanel, taskId]);

  useEffect(() => {
    if (taskData.id && !updatedData) {
      setUpdatedData(taskData);
    } else if (!taskData.id) {
      setUpdatedData(undefined);
    }
  }, [taskData, updatedData]);

  useEffect(() => {
    if (taskData.project_id) {
      dispatch(fetchProject(taskData.project_id));
    }
  }, [dispatch, taskData.project_id]);

  const onUpdate = useCallback(async () => {
    setIsUpdating(true);
    const originalData = {
      ...taskData,
      assignedUsers: taskData.assignedUsers.map((user) => {
        if (typeof user === 'string') {
          return user;
        } else {
          return user.id;
        }
      }),
    };
    const newData = {
      ...updatedData,
      assignedUsers: (updatedData as TaskDetailType).assignedUsers.map(
        (user) => {
          if (typeof user === 'string') {
            return user;
          } else {
            return user.id;
          }
        }
      ),
    };
    const updatedTaskData = getDifference(newData, originalData);
    const taskActualHours = updatedTaskData.taskActualHours;
    if (taskActualHours) {
      delete updatedTaskData.taskActualHours;
    }
    await dispatch(
      updateTask({
        taskId: taskData.id,
        data: { ...updatedTaskData },
      })
    );
    onSave();
    if (taskActualHours) {
      const { newActualHours, removedActualHours } = getActualHoursData(
        originalData.taskActualHours!,
        (newData as TaskDetailType).taskActualHours!
      );
      await dispatch(
        updateTaskActualHours({
          taskId: taskData.id,
          newActualHours,
          removedActualHours,
        })
      );
    }
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get('TASKS.TASK_DETAIL_PAGE.UPDATE_SUCCESSFUL'),
      })
    );
    closePanel();
  }, [closePanel, dispatch, onSave, taskData, updatedData]);

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('SIDE_PANEL.UPDATE_BUTTON'),
        disabled: disabled,
        onClick: onUpdate,
        loading: isUpdating,
        'data-cy': 'task-side-panel__update-button',
      },
      {
        children: intl.get('SIDE_PANEL.CANCEL_BUTTON'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [disabled, onUpdate, isUpdating, closePanel]
  );

  return (
    <SidePanel
      id='task-side-panel'
      onClose={onClose}
      isOpen={isPanelOpen}
      isLoading={isEmpty(taskData)}
      displayBackdrop={hasUnsavedChanges}
      headerElement={
        updatedData && (
          <TaskSidePanelHeader
            onClose={onClose}
            projectId={taskData.project_id}
            taskData={taskData}
            updatedData={updatedData}
          />
        )
      }
      footerButtons={footerButtons}
      backdropProps={{
        onClick: onClose,
        className: 'bg-transparent',
      }}
    >
      {updatedData && (
        <TaskSidePanelContent
          taskData={taskData as TaskDetailType}
          unsavedChanges={showUnsavedBanner}
          setUnsavedChanges={setHasUnsavedChanges}
          showUnsavedChangesAnimation={showUnsavedChangesAnimation}
          currentSavedTask={updatedData as TaskDetailType}
          setCurrentSavedTask={
            setUpdatedData as Dispatch<SetStateAction<TaskDetailType>>
          }
          canUpdateTask={true}
          isOnSidepanel={true}
          setShouldDisableUpdate={setDisabled}
          shouldDisableUpdate={disabled}
          isViewOnly={isViewOnlyMode}
        />
      )}
    </SidePanel>
  );
};

export default TaskSidePanel;
