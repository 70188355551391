import intl from 'react-intl-universal';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  filter,
  rangeDate,
  Project,
  Owner,
  ProjectOwner,
  ProjectProcessStage,
  objKeyAsString,
  ProcessStage,
  FormOption,
  NewProject,
  BusinessTeam,
  LearningTeam,
  ProjectCategory,
  ProjectProcess,
  SortingType,
} from 'utils/customTypes';
import {
  ProjectFilters,
  ProjectFiltersKey,
  RangeFilter,
} from 'utils/types/filters';
import { isRangeFilter } from 'utils/typeGuards';
import {
  TABLE_FILTERS_OPERATORS,
  PROJECT_HEALTH,
  PROJECTS_TABLE_FILTER_OPTIONS,
  COLUMN_OPTION_TYPES,
  STAGE_COLORS,
  STAGE_TEXT_COLOR,
  STAGE_ON_DRAG_COLORS,
  STAGE_ON_DRAG_BORDER_COLORS,
  PROJECT_STATUS,
  NEW_PROJECT_NONREQUIRED_ENUM_FIELDS,
  PROJECTS_TABLE_SORTABLE_COLUMNS,
  PROJECTS_TABLE_FILTERS,
} from 'utils/constants';
import {
  getValueById,
  getProjectNumberColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

export const formatFilters = (filters: filter[]) =>
  filters.map((filter) => {
    if (filter.type === COLUMN_OPTION_TYPES.DATE) {
      const dateRange = filter.value as rangeDate;
      const startDate = new Date(dateRange.startDate).toDateString();
      const endDate = dateRange.endDate
        ? new Date(dateRange.endDate).toDateString()
        : startDate;
      return {
        ...filter,
        value: {
          startDate,
          endDate,
        },
      };
    } else {
      return filter;
    }
  });

const checkColumnContainsValue = (
  column: string | undefined,
  columnData: any,
  value: string,
  filterTye: string = COLUMN_OPTION_TYPES.TEXT,
  shouldContain: boolean = true
) => {
  let formattedValue = value.toLocaleLowerCase();
  let formattedColum = columnData;

  if (filterTye === COLUMN_OPTION_TYPES.OPTIONS) {
    let arrayToValidate = columnData;
    if (column === PROJECTS_TABLE_FILTER_OPTIONS.BUSINESS_UNIT.value) {
      arrayToValidate = columnData.map((team: BusinessTeam) => team.id);
    }
    if (column === PROJECTS_TABLE_FILTER_OPTIONS.TEAMS.value) {
      arrayToValidate = columnData.map((team: LearningTeam) => team.id);
    }
    return shouldContain
      ? arrayToValidate.includes(value)
      : !arrayToValidate.includes(value);
  }

  if (column === 'status') {
    formattedColum = intl.get(
      `PROJECT_DETAIL.STATUS_OPTIONS.${columnData.toUpperCase()}`
    );
  }

  if (column === 'priority') {
    formattedColum = intl.get(
      `PROJECT_DETAIL.PRIORITY_OPTIONS.${columnData.toUpperCase()}`
    );
  }

  if (column === 'owners') {
    if (shouldContain) {
      return columnData.some((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return fullname.includes(formattedValue);
      });
    } else {
      return columnData.every((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return !fullname.includes(formattedValue);
      });
    }
  }

  if (column === 'projectNumber') {
    return shouldContain
      ? (formattedColum + '').indexOf(formattedValue) > -1
      : (formattedColum + '').indexOf(formattedValue) === -1;
  }

  return formattedColum
    ? shouldContain
      ? formattedColum.toLowerCase().includes(formattedValue)
      : !formattedColum.toLowerCase().includes(formattedValue)
    : false;
};

export const checkColumnMatchDateFilter = (columnData: any, filter: filter) => {
  const requestDate = moment(new Date(columnData));
  const rangeDate = filter.value as rangeDate;
  const startDate = new Date(rangeDate.startDate);
  const endDate = new Date(rangeDate.endDate);
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return requestDate.isSame(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER:
      return requestDate.isAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return requestDate.isSameOrAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS:
      return requestDate.isBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return requestDate.isSameOrBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return requestDate.isBetween(startDate, endDate, 'days', '[]');
    default:
      return false;
  }
};

export const checkColumnMatchFilter = (columnData: any, filter: filter) => {
  const value =
    filter.type === COLUMN_OPTION_TYPES.OPTIONS
      ? (filter.value as FormOption).value
      : (filter.value as string);
  if (!value) {
    return false;
  }

  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.CONTAINS:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type
      );
    case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type,
        false
      );
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData === value
        : columnData === +value;
    case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData !== value
        : columnData !== +value;
    case TABLE_FILTERS_OPERATORS.GREATER:
      return columnData > +value;
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return columnData >= +value;
    case TABLE_FILTERS_OPERATORS.LESS:
      return columnData < +value;
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return columnData <= +value;
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return false;
    default:
      return false;
  }
};

const checkProjectMatchDateFilter = (
  filterValue: RangeFilter,
  projectValue: string
) => {
  let projectMatchDateFilter = false;
  const projectDate = moment(new Date(projectValue));
  const fromRange = filterValue.from && new Date(filterValue.from);
  const toRange = filterValue.to && new Date(filterValue.to);
  if (fromRange) {
    projectMatchDateFilter = projectDate.isSameOrAfter(fromRange, 'days');
  }
  if (fromRange && toRange) {
    projectMatchDateFilter =
      projectMatchDateFilter && projectDate.isSameOrBefore(toRange, 'days');
  }
  if (!fromRange && toRange) {
    projectMatchDateFilter = projectDate.isSameOrBefore(toRange, 'days');
  }
  return projectMatchDateFilter;
};

const checkProjectMatchAllFilters = (
  project: Project,
  filters: ProjectFilters
) => {
  let projectMatchAllFilters = true;
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as ProjectFiltersKey];
    const projectValue = project[filter as keyof Project];
    if (
      (!isEmpty(filterValue) && !isEmpty(projectValue)) ||
      typeof filterValue === 'boolean'
    ) {
      let projectMatchFilter = false;
      if (isRangeFilter(filterValue)) {
        projectMatchFilter = checkProjectMatchDateFilter(
          filterValue,
          projectValue
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.OWNERS) {
        projectMatchFilter = projectValue.some((owner: ProjectOwner) =>
          (filterValue as string[]).includes(owner.project_owners.userId!)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.BUSINESS_TEAMS) {
        projectMatchFilter = projectValue.some((team: BusinessTeam) =>
          (filterValue as string[]).includes(team.id)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.TEAMS) {
        projectMatchFilter = projectValue.some((team: LearningTeam) =>
          (filterValue as string[]).includes(team.id)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.ARCHIVED) {
        if (projectValue === undefined) {
          projectMatchFilter = !filterValue;
        } else {
          projectMatchFilter = projectValue === filterValue;
        }
      } else {
        projectMatchFilter = (filterValue as string[]).includes(projectValue);
      }
      projectMatchAllFilters = projectMatchAllFilters && projectMatchFilter;
      if (!projectMatchAllFilters) {
        return projectMatchAllFilters;
      }
    } else {
      projectMatchAllFilters = false;
      return projectMatchAllFilters;
    }
  }
  return projectMatchAllFilters;
};

export const filterProjects: (
  projects: Project[],
  search: string,
  filters?: ProjectFilters,
  userId?: string
) => Project[] = (projects, search, filters) => {
  let filteredProjectsList = projects;
  const filterArchivedDefault = {
    ...filters,
    [PROJECTS_TABLE_FILTERS.ARCHIVED]:
      get(filters, PROJECTS_TABLE_FILTERS.ARCHIVED) || false,
  } as ProjectFilters;
  const filtersActive =
    filterArchivedDefault && Object.keys(filterArchivedDefault).length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredProjectsList = projects.filter((project: Project) => {
      let projectMatchSearch = false;
      let projectMatchFilters = false;
      if (searchActive) {
        const projectNumber = getProjectNumberColumn(project.projectNumber);
        const projectNumberRegex = /P?\d{6}/;
        const projectNumberMatchSearch = projectNumberRegex.exec(search);
        if (projectNumberMatchSearch) {
          let formattedSearch = search.toUpperCase();
          if (formattedSearch[0] !== 'P') {
            formattedSearch = `P${search}`;
          }
          projectMatchSearch = projectNumber === formattedSearch;
        }
        if (!projectMatchSearch) {
          projectMatchSearch =
            project.title
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            projectNumber.includes(search);
        }
      }
      if (searchActive && !projectMatchSearch) {
        return projectMatchSearch;
      }
      if (filtersActive) {
        projectMatchFilters = checkProjectMatchAllFilters(
          project,
          filterArchivedDefault
        );
      }
      if (searchActive && filtersActive) {
        return projectMatchSearch && projectMatchFilters;
      } else if (searchActive && !filtersActive) {
        return projectMatchSearch;
      } else if (!searchActive && filtersActive) {
        return projectMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredProjectsList;
};

export const groupProjectsByStage: (
  stages: ProjectProcessStage[],
  projects: Project[]
) => { newAndInQueueProjectsStage: Project[]; stagesList: ProcessStage[] } = (
  stages,
  projects
) => {
  const stagesList: objKeyAsString = {};
  let index = 0;
  const newAndInQueueProjectsArray = [];
  for (const stage of stages) {
    stagesList[stage.id] = {
      id: stage.id,
      name: stage.stageName,
      color: STAGE_COLORS[index],
      textColor: STAGE_TEXT_COLOR[index],
      colorOnDrag: STAGE_ON_DRAG_COLORS[index],
      borderColorOnDrag: STAGE_ON_DRAG_BORDER_COLORS[index],
      cards: [],
    };
    index = (index + 1) % STAGE_COLORS.length;
  }
  for (const project of projects) {
    if (
      [PROJECT_STATUS.NEW, PROJECT_STATUS.IN_PLANNING].includes(project.status)
    ) {
      newAndInQueueProjectsArray.push(project);
      continue;
    }
    if (project.stage_id && stagesList[project?.stage_id]) {
      stagesList[project?.stage_id].cards =
        stagesList[project?.stage_id].cards.concat(project);
    }
  }
  return {
    newAndInQueueProjectsStage: newAndInQueueProjectsArray,
    stagesList: Object.values(stagesList),
  };
};

export const filterOutInactiveProjects = (projects: Project[]) =>
  projects.filter((project) => {
    const inActiveStatus = [
      PROJECT_STATUS.COMPLETED,
      PROJECT_STATUS.CANCELED,
      PROJECT_STATUS.CLOSED,
    ];
    return !inActiveStatus.includes(project.status);
  });

export const processNewProjectData = (project: NewProject) => {
  delete project.health;
  delete project.stage_id;
  delete project.is_archived;
  delete project.isProjectPartOfUserPrograms;
  NEW_PROJECT_NONREQUIRED_ENUM_FIELDS.forEach((field) => {
    if (!project[field]) {
      delete project[field];
    }
  });
  return project;
};

export const formatProjectsList = (
  projects: Project[],
  processes: ProjectProcess[],
  categories: ProjectCategory[]
) =>
  projects.map((project: Project) => {
    let matchedStages: ProjectProcessStage[] = [];
    const matchedProcess = processes.find(
      (process: ProjectProcess) => process.id === project.process_id
    );
    if (matchedProcess) {
      matchedStages = matchedProcess.projectStages;
    }
    return {
      ...project,
      category: getValueById(categories, 'categoryName', project.category_id),
      process: matchedProcess ? matchedProcess.processName : '',
      stage:
        matchedStages.length > 0
          ? getValueById(matchedStages, 'stageName', project.stage_id!)
          : '',
    };
  });

export const orderProjectsBy = (
  projects: Project[],
  column: string,
  order: SortingType
) => {
  const healthMapping: objKeyAsString = {
    [PROJECT_HEALTH.OFF_TRACK]: intl.get(
      'PROJECT_DETAIL.HEALTH_OPTIONS.OFF_TRACK'
    ),
    [PROJECT_HEALTH.ON_TRACK]: intl.get(
      'PROJECT_DETAIL.HEALTH_OPTIONS.ON_TRACK'
    ),
    [PROJECT_HEALTH.AT_RISK]: intl.get('PROJECT_DETAIL.HEALTH_OPTIONS.AT_RISK'),
  };
  switch (column) {
    case PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_OWNER:
      return orderBy(
        projects,
        [
          (project: Project) =>
            project.owners.length > 0
              ? `${project.owners[0].data.firstName} ${project.owners[0].data.lastName}`.toLowerCase()
              : '',
        ],
        [order]
      );
    case PROJECTS_TABLE_SORTABLE_COLUMNS.BUSINESS_UNIT:
      return orderBy(
        projects,
        [
          (project: Project) =>
            project.businessTeams && project.businessTeams.length > 0
              ? project.businessTeams[0].title.toLowerCase()
              : '',
        ],
        [order]
      );
    case PROJECTS_TABLE_SORTABLE_COLUMNS.ACTUAL_COMPLETION_DATE:
    case PROJECTS_TABLE_SORTABLE_COLUMNS.TARGET_COMPLETION_DATE:
    case PROJECTS_TABLE_SORTABLE_COLUMNS.START_DATE:
    case PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NUMBER:
      return orderBy(projects, [column], [order]);
    case PROJECTS_TABLE_SORTABLE_COLUMNS.HEALTH:
      return orderBy(
        projects,
        [
          (project: Project) =>
            project.status === PROJECT_STATUS.IN_PROGRESS ||
            project.status === PROJECT_STATUS.COMPLETED
              ? healthMapping[project.health]
              : '',
        ],
        [order]
      );
    default:
      return orderBy(projects, [column.toLowerCase()], [order]);
  }
};
