import isEmpty from 'lodash/isEmpty';
import {
  requiredFields,
  requiredFieldsErrorsMap,
} from '../../NewProjectPage/helpers/types';
import { objKeyAsString, NewProject } from 'utils/customTypes';
import {
  NEW_PROJECT_FORM_FIELDS,
  PROJECT_STATUS,
  PROJECT_PRIVACY,
} from 'utils/constants';

export const validateRequiredFields = (
  projectData: NewProject,
  isUpdating: boolean = false,
  requiredCustomFieldsErrorsMap: { [key: string]: boolean } = {}
) => {
  let canSave = true;
  const shouldValidateActualCompletionField =
    isUpdating && projectData.status === PROJECT_STATUS.COMPLETED;
  const fieldsToValidate = [...requiredFields];
  const errorsMap: objKeyAsString = {
    ...requiredFieldsErrorsMap,
    ...requiredCustomFieldsErrorsMap,
  };
  if (shouldValidateActualCompletionField) {
    fieldsToValidate.push(NEW_PROJECT_FORM_FIELDS.ACTUAL_COMPLETION_DATE);
    errorsMap[NEW_PROJECT_FORM_FIELDS.ACTUAL_COMPLETION_DATE] = false;
  }
  const dateFields = [
    NEW_PROJECT_FORM_FIELDS.START_DATE,
    NEW_PROJECT_FORM_FIELDS.TARGET_COMPLETION_DATE,
    ...[
      shouldValidateActualCompletionField &&
        NEW_PROJECT_FORM_FIELDS.ACTUAL_COMPLETION_DATE,
    ],
  ];
  fieldsToValidate.forEach((field) => {
    if (!dateFields.includes(field) && isEmpty(projectData[field])) {
      errorsMap[field] = true;
    } else {
      if (
        !(projectData[field] instanceof Date) &&
        isEmpty(projectData[field])
      ) {
        errorsMap[field] = true;
      }
    }
  });
  if (
    projectData.privacy === PROJECT_PRIVACY.TEAM &&
    isEmpty(projectData.learningTeams)
  ) {
    errorsMap[NEW_PROJECT_FORM_FIELDS.TEAMS] = true;
  }
  Object.keys(requiredCustomFieldsErrorsMap).forEach((key: string) => {
    if (!projectData[key]) {
      errorsMap[key] = true;
    } else {
      const isFieldEmpty =
        'val' in projectData[key] && projectData[key].val === null;
      errorsMap[key] = isFieldEmpty;
    }
  });
  canSave = !Object.values(errorsMap).some((val: boolean) => val);
  return { canSave, errorsMap };
};
