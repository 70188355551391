import { AxiosInstance } from 'axios';
import config from 'config/Config';
import moment from 'moment';
import { initAxios } from 'utils/axios';

class OrganizationAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchOrganization = async (organizationId: string) => {
    const { data } = await this.instance.get(`organization/${organizationId}`);
    return data.data;
  };

  updateOrganization = async (organizationId: string, updateFields: any) => {
    const { data } = await this.instance.put(`organization/${organizationId}`, {
      updateFields,
    });

    return data.data;
  };

  createOrganization = async (userEmail: string, company: string) => {
    const { data } = await this.instance.post('organization', {
      account_name: company,
      account_owner: userEmail,
      license_tier: 'trial',
      license_number: 100,
      trial_end_date: moment().add(14, 'days').toDate(),
    });
    return data;
  };

  fetchPortalSettings = async (organizationId: string) => {
    const { data } = await this.instance.get(
      `organization/${organizationId}/portalSettings`
    );
    return data.data;
  };
}
export default new OrganizationAPI();
