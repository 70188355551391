import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import {
  LearningTeam,
  Status,
  FormOption,
  LearningTeamBase,
  LDTeam,
  SortingType,
} from 'utils/customTypes';
import learningTeamsAPI from './learningTeamsAPI';
import { get, isEmpty, orderBy } from 'lodash';

interface LearningTeams {
  searchParam: string;
  value: LearningTeam[];
  status: Status;
  fetched: boolean;
  currentLearningTeam: Partial<LearningTeam>;
  learningTeamsSorting: {
    orderBy: string[];
    order: SortingType;
  };
}

/* ============================= INITIAL STATE ============================== */
const initialState: LearningTeams = {
  searchParam: '',
  value: [],
  status: SLICE_STATUS.IDLE,
  currentLearningTeam: {},
  fetched: false,
  learningTeamsSorting: {
    order: 'asc',
    orderBy: [''],
  },
};

/* ============================== REDUX THUNK =============================== */
export const getLearningTeams = createAsyncThunk(
  'learningTeams/GET_LEARNING_TEAMS',
  async () => {
    const { data } = await learningTeamsAPI.fetchLearningTeams();
    if (!data.learningTeams) {
      throw new Error('An error ocurred');
    }

    return data.learningTeams;
  }
);

export const addLDTeam = createAsyncThunk(
  'learningTeams/CREATE_LD_TEAM',
  async (ldTeam: LearningTeamBase) => {
    const { data } = await learningTeamsAPI.createLDTeam(ldTeam);
    return data;
  }
);

export const getCurrentLearningTeam = createAsyncThunk(
  'learningTeams/GET_CURRENT_LD_TEAM',
  async (id: string) => {
    const { data } = await learningTeamsAPI.getLearningTeam(id);
    if (!data.learningTeam) {
      throw new Error('An error ocurred');
    }

    return data.learningTeam;
  }
);

export const updateLearningTeam = createAsyncThunk(
  'learningTeams/UPDATE_TEAM',
  async ({
    teamId,
    updateFields,
  }: {
    teamId: string;
    updateFields: Partial<LDTeam>;
  }) => {
    const { data } = await learningTeamsAPI.updateTeam(teamId, updateFields);
    if (!data.learningTeam) {
      throw new Error('An error ocurred');
    }

    return data.learningTeam;
  }
);

export const deleteLearningTeam = createAsyncThunk(
  'learningTeams/DELETE_TEAM',
  async (teamId: string) => {
    const { code } = await learningTeamsAPI.deleteTeam(teamId);
    if (code !== 200) {
      throw new Error('An error ocurred');
    }

    return { teamId };
  }
);
/* ================================ ACTIONS ================================= */

export const resetCurrentLearningTeam = createAction(
  'learningTeams/RESET_CURRENT_TEAM'
);

export const setLearningTeamsOrder = createAction<{
  order: SortingType;
  orderBy: string[];
}>('learningTeams/SET_LEARNING_TEAMS_ORDER');

export const setSearchParam = createAction<{ searchParam: string }>(
  'learningTeams/SET_SEARCH_PARAM'
);

/* ================================= REDUCER ================================ */
const slice = createSlice({
  name: 'learningTeams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearningTeams.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getLearningTeams.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getLearningTeams.fulfilled, (state, action) => {
        state.value = action.payload;
        state.fetched = true;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(addLDTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(addLDTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(addLDTeam.fulfilled, (state, action) => {
        const newTeam = get(action, 'payload.learningTeam');
        if (!isEmpty(newTeam)) {
          state.value = [...state.value, newTeam];
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getCurrentLearningTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getCurrentLearningTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getCurrentLearningTeam.fulfilled, (state, action) => {
        state.currentLearningTeam = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateLearningTeam.fulfilled, (state, action) => {
        state.currentLearningTeam = action.payload;
      })
      .addCase(resetCurrentLearningTeam, (state) => {
        state.currentLearningTeam = initialState.currentLearningTeam;
      })
      .addCase(deleteLearningTeam.fulfilled, (state, action) => {
        state.value = state.value.filter(
          (team) => team.id !== action.payload.teamId
        );
      })
      .addCase(setLearningTeamsOrder, (state, action) => {
        state.learningTeamsSorting.order = action.payload.order;
        state.learningTeamsSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.searchParam;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectLearningTeams = (state: RootState) =>
  state.learningTeams.value;

export const selectLearningTeamsForDropdown = createSelector(
  [selectLearningTeams],
  (learningTeams) => {
    return learningTeams.map((team) => ({
      label: team.name,
      value: team.id,
    })) as FormOption[];
  }
);

export const selectLearningTeamStatus = (state: RootState) =>
  state.learningTeams.status;

export const selectLearningTeamIsFetched = (state: RootState) =>
  state.learningTeams.fetched;

export const selectCurrentLearningTeam = (state: RootState) =>
  state.learningTeams.currentLearningTeam;

export const learningTeamsSorting = (state: RootState) =>
  state.learningTeams.learningTeamsSorting;

const learningTeamsSearchParam = (state: RootState) =>
  state.learningTeams.searchParam;

export const selectOrderedLearningTeams = createSelector(
  [selectLearningTeams, learningTeamsSorting, learningTeamsSearchParam],
  (learningTeams, sorting, searchParam: string) => {
    let filteredLearningTeams = learningTeams;
    if (searchParam) {
      filteredLearningTeams = learningTeams.filter(
        (learningTeam: LearningTeam) => {
          const clearedLearningTeamName = learningTeam.name
            .trim()
            .toLowerCase();
          const clearedSearchParam = searchParam.trim().toLowerCase();
          return clearedLearningTeamName.includes(clearedSearchParam);
        }
      );
    }
    return orderBy(filteredLearningTeams, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
  }
);
/* ================================= EXPORT ================================= */
export default slice.reducer;
