import React from 'react';
import { ProjectTemplateField } from 'utils/types/templates';
import CustomField from 'Molecules/CustomField/CustomField';
import { FieldType } from 'utils/types/fields';

const TemplateFieldsList: React.FC<{
  templateFields?: ProjectTemplateField[];
  onUpdateField: (
    newValue: FieldType['value'],
    fieldTemplateName: string,
    fieldId?: string
  ) => void;
}> = ({ templateFields, onUpdateField }) => {
  return (
    <div className='w-full flex-col mt-8'>
      {templateFields?.map((customField: ProjectTemplateField) => (
        <CustomField
          key={customField.field_template_id || customField.field_template.name}
          field={customField.field}
          fieldTemplate={customField.field_template}
          required={customField.required}
          className='mb-2 last:mb-0'
          onUpdate={onUpdateField}
        />
      ))}
    </div>
  );
};

export default TemplateFieldsList;
