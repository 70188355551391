import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  tailwindOverride,
  Checkbox,
} from '@getsynapse/design-system';
import {
  DropdownFieldTemplateType,
  SelectValue,
  DropdownFieldType,
} from 'utils/types/fields';

interface DropdownFieldProps {
  field?: DropdownFieldType;
  fieldTemplate: DropdownFieldTemplateType;
  onChange: (newValue: SelectValue | { val: SelectValue[] }) => void;
  disabled: boolean;
  state?: 'default' | 'error';
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  field,
  fieldTemplate,
  onChange,
  disabled,
  state = 'default',
}) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectValue[]>([]);

  useEffect(() => {
    let options: SelectValue[] = [];
    if (field) {
      options = fieldTemplate.collection
        ? (field.value.val as SelectValue[])
        : [field.value as SelectValue];
    } else if (fieldTemplate.default_value) {
      options = fieldTemplate.collection
        ? (fieldTemplate.default_value.val as SelectValue[])
        : [fieldTemplate.default_value as SelectValue];
    }
    if (options) {
      setSelectedOptions(options);
    }
  }, [field, fieldTemplate.default_value, fieldTemplate.collection]);

  const handleSelectOption = (selectedValues: SelectValue | SelectValue[]) => {
    let value: SelectValue | { val: SelectValue[] } = { val: null };
    if (!fieldTemplate.collection && !Array.isArray(selectedValues)) {
      value = selectedValues as SelectValue;
    }
    if (
      fieldTemplate.collection &&
      Array.isArray(selectedValues) &&
      selectedValues.length > 0
    ) {
      value = { val: selectedValues };
    }
    setSelectedOptions(
      fieldTemplate.collection
        ? (selectedValues as SelectValue[])
        : ([selectedValues] as SelectValue[])
    );
    onChange(value);
  };

  return (
    <Dropdown
      multiple={fieldTemplate.collection}
      options={fieldTemplate.allowed_values || []}
      values={selectedOptions}
      onChange={handleSelectOption}
      disabled={disabled}
      triggerProps={{ 'aria-label': fieldTemplate.name }}
      getOptionLabel={(option: SelectValue) =>
        option.display || option.val?.toString() || ''
      }
      renderOption={(
        option: SelectValue,
        isSelected: boolean,
        onSelectOption: (option: SelectValue) => void,
        { className, ...otherProps }
      ) => {
        return (
          <li
            {...otherProps}
            onClick={() => {
              if (!fieldTemplate.collection) {
                onSelectOption(option);
              }
            }}
            className={tailwindOverride('group', className, {
              'hover:bg-primary focus-visible:bg-primary': isSelected,
              'bg-primary text-neutral-white':
                isSelected && !fieldTemplate.collection,
            })}
          >
            {fieldTemplate.collection ? (
              <Checkbox
                checked={isSelected}
                onChange={() => onSelectOption(option)}
                label={option.display || option.val}
                inputProps={{
                  className: tailwindOverride({
                    'group-hover:border-neutral-white': isSelected,
                    'group-focus-visible:border-neutral-white': isSelected,
                  }),
                }}
                className={tailwindOverride({
                  'group-hover:text-neutral-white': isSelected,
                  'group-focus-visible:text-neutral-white': isSelected,
                })}
              />
            ) : (
              option.display || option.val
            )}
          </li>
        );
      }}
      state={state}
    />
  );
};

export default DropdownField;
