import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import has from 'lodash/has';
import {
  Typography,
  useElevation,
  Toggle,
  Button,
} from '@getsynapse/design-system';
import {
  selectProjectProcessById,
  setProcessIdToUpdate,
  selectProcessSliceStatus,
  updateProjectProcess as dispatchProjectProcessUpdate,
  enableProjectProcess,
} from 'state/Processes/processesSlice';
import { selectAssociatedProcessesAndStages } from 'state/Projects/projectsSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  SLICE_STATUS,
  PATHS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import { ProjectProcess } from 'utils/customTypes';
import ProcessForm from './components/ProcessForm';
import useProjectProcessForm from './hooks/useProjectProcessForm';
import ProcessActions from './components/ProcessActions';
import { OnHoldStatusBanner } from 'Pages/ProjectPage/components/Banners/Banners';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import { selectUserName } from 'state/User/userSlice';

const ProcessDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userName = useSelector(selectUserName);
  const originalProcess = useSelector(selectProjectProcessById);
  const associatedProjectProcesses = useSelector(
    selectAssociatedProcessesAndStages
  );
  const status = useSelector(selectProcessSliceStatus);
  const footerElevation = useElevation(1);
  const [canSaveChanges, setCanSaveChanges] = useState(false);
  const isSavingChanges = status === SLICE_STATUS.UPDATING;
  const isDefaultProcess = get(originalProcess, 'data.createdBySystem', false);
  const isProcessEnabled =
    originalProcess?.enabled !== null ? originalProcess?.enabled : true;
  const isProcessAssociated = originalProcess
    ? has(associatedProjectProcesses, originalProcess?.id)
    : false;
  const canRemoveProcess = !isProcessAssociated;
  const canRemoveProcessTooltip = isProcessAssociated
    ? intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ACTIVE_PROCESS_TOOLTIP')
    : undefined;
  const {
    projectProcess,
    updateProjectProcess,
    addNewProjectProcessStage,
    removeProjectProcessStage,
    updateProjectProcessStage,
    unremovableProjectProcessStages,
    canSubmitForm,
    processStagesWithRepeatedNames,
  } = useProjectProcessForm(originalProcess);

  const handleClosePage = useCallback(() => {
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attributes=${SETTINGS_ATTRIBUTES.PROCESS}`
    );
    dispatch(setProcessIdToUpdate(null));
  }, [dispatch, history]);

  useEffect(() => {
    let canSave = canSubmitForm;
    if (canSave) {
      canSave = !isEqual(originalProcess, projectProcess);
    }
    setCanSaveChanges(canSave);
  }, [canSubmitForm, originalProcess, projectProcess]);

  const handleSaveChanges = async () => {
    let updatedProcees = { ...projectProcess };
    if (isDefaultProcess) {
      updatedProcees = {
        ...updatedProcees,
        data: {
          ...updatedProcees.data,
          createdBy: { name: userName },
          createdBySystem: false,
        },
      };
    }
    await dispatch(
      dispatchProjectProcessUpdate(updatedProcees as ProjectProcess)
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.ADD_PROCESS_MODAL.PROCESS_UPDATED_SUCCESSFULLY_MESSAGE'
        ),
      })
    );
    handleClosePage();
  };

  const handleEnableDisableProcess = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      enableProjectProcess({
        id: originalProcess?.id!,
        enabled: event.target.checked,
      })
    );
  };

  return (
    <div className='flex flex-col h-full overflow-hidden'>
      <DetailsPage
        className='mx-0'
        topBar={
          <div className='z-5'>
            <div className='w-full flex justify-end items-center'>
              <Toggle
                offText={intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ENABLE_PROCESS')}
                onChange={handleEnableDisableProcess}
                onText={intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ENABLE_PROCESS')}
                className='mr-1 my-auto'
                toggleTextPosition='left'
                checked={isProcessEnabled}
              />
              <ProcessActions
                process={originalProcess}
                canRemoveProcess={canRemoveProcess}
                removeProcessTooltip={canRemoveProcessTooltip}
                removeProcessCallback={handleClosePage}
              />
            </div>
          </div>
        }
        content={
          <div className='bg-neutral-white flex-grow'>
            <div className='w-full h-full'>
              <div
                className={classnames({
                  'pt-4': isProcessEnabled,
                })}
              >
                {!isProcessEnabled && (
                  <OnHoldStatusBanner
                    message={intl.get(
                      'SETTINGS_PAGE.PROJECTS_PAGE.DISABLED_PROCESS_WARNING'
                    )}
                  />
                )}
                <Typography
                  variant='h5'
                  className={classnames('text-neutral-black mb-6', {
                    'mt-6': !originalProcess?.enabled,
                  })}
                >
                  {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.PROCESS_PAGE_TITLE', {
                    process: projectProcess?.processName,
                  })}
                </Typography>
                <ProcessForm
                  process={projectProcess}
                  addStage={addNewProjectProcessStage}
                  updateProcess={updateProjectProcess}
                  removeStage={removeProjectProcessStage}
                  updateStage={updateProjectProcessStage}
                  stagesErrors={processStagesWithRepeatedNames || []}
                  unremovableStages={unremovableProjectProcessStages}
                  disabled={!isProcessEnabled}
                />
              </div>
            </div>
          </div>
        }
      />
      <div
        className={classnames(
          'w-full bg-neutral-white flex py-2 z-5',
          footerElevation
        )}
      >
        <div className='flex ml-auto mr-12'>
          <Button
            variant='secondary'
            className='mr-4'
            onClick={handleClosePage}
            data-testid='cancel-button'
          >
            {intl.get('CANCEL')}
          </Button>
          <Button
            onClick={handleSaveChanges}
            disabled={!canSaveChanges || isSavingChanges || !isProcessEnabled}
            data-testid='update-button'
            loading={isSavingChanges}
          >
            {intl.get('UPDATE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProcessDetailPage;
