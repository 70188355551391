import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class LegacyDesignAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}`);
  }

  fetchDesigns = async () => {
    const { data } = await this.instance.get('design/projects/projects');
    return data;
  };
}

export default new LegacyDesignAPI();
