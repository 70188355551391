import {
  Typography,
  Tooltip,
  TextField,
  IconButton,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classnames from 'classnames';

export const getEmptyTableRow = (searchParamValue: string) => [
  {
    id: 'empty-task',
    className: 'cursor-auto pointer-events-none w-full table-fixed',
    cells: [
      {
        content: '',
        className: 'w-5/12',
      },
      {
        content: (
          <Typography variant='body2' className='leading-4 text-primary-light'>
            {searchParamValue
              ? intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.NO_RESULTS_FOUND'
                )
              : intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.NO_TASKS_ADDED'
                )}
          </Typography>
        ),
        className: 'w-7/12',
      },
      {
        content: '',
        className: 'w-16 right-0 sticky z-1 border-transparent',
      },
    ],
  },
];

export const getEditOrAddTaskRow = (
  index: string,
  title: string,
  description: string,
  onTitleUpdate: (title: string) => void,
  onDescriptiongUpdate: (description: string) => void,
  onConfirm: () => void,
  onCancel: () => void,
  disabled: boolean
) => {
  return {
    id: index,
    className: 'cursor-pointer w-full table-fixed',
    cells: [
      {
        content: (
          <TextField
            variant='text'
            value={title}
            placeholder='Enter task title'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onTitleUpdate(event.target.value)
            }
            data-cy='new-task-title_input'
          />
        ),
        className: 'p-0 px-4',
      },
      {
        content: (
          <TextField
            variant='text'
            placeholder='Enter task description'
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onDescriptiongUpdate(event.target.value)
            }
            data-cy='new-task-description_input'
          />
        ),
        className: 'p-0 px-4',
      },
      {
        content: (
          <div
            className={classnames('h-full text-center relative w-full')}
            onClick={(event: React.MouseEvent<HTMLInputElement>) =>
              event.stopPropagation()
            }
          >
            {
              <div className='flex justify-center'>
                <Tooltip
                  openMode='hover1'
                  timeout={0}
                  showPopper
                  position='topCenter'
                  contentProps={{
                    className: 'px-3 py-2 text-sm z-50 absolute',
                  }}
                  usePortal
                  trigger={
                    <IconButton
                      name='close-circle'
                      description='Close'
                      onClick={onCancel}
                      color='danger'
                    />
                  }
                >
                  {intl.get('CANCEL')}
                </Tooltip>
                <Tooltip
                  openMode='hover1'
                  timeout={0}
                  showPopper
                  position='topCenter'
                  contentProps={{
                    className: 'px-3 py-2 text-sm z-50',
                  }}
                  usePortal
                  trigger={
                    <IconButton
                      name='checkmark-circle'
                      className='ml-2'
                      description={intl.get('ADD')}
                      onClick={onConfirm}
                      color='positive'
                      disabled={disabled}
                      data-cy='save-task_button'
                    />
                  }
                >
                  {intl.get('ADD')}
                </Tooltip>
              </div>
            }
          </div>
        ),
        className: 'w-16 right-0 sticky z-1 border-transparent',
      },
    ],
  };
};
