import { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { FieldType } from 'utils/types/fields';
import { ProjectTemplateField, ProjectTemplate } from 'utils/types/templates';

export type ProjectTemplateToUpdate = Pick<
  ProjectTemplate,
  | 'name'
  | 'description'
  | 'owner_id'
  | 'access'
  | 'status'
  | 'resource_type'
  | 'hidden'
>;

type NewProjectTemplateField = Pick<
  ProjectTemplateField,
  | 'field_template_id'
  | 'project_template_id'
  | 'required'
  | 'layout'
  | 'defaultValue'
> & { currentTemplateVersion: number };

export type ProjectTemplateFieldUpdate = Pick<
  ProjectTemplateField,
  'id' | 'layout'
> & { required?: boolean; defaultValue?: FieldType['value'] };
class ProjectTemplateAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchProjectTemplateById: (templateId: string) => Promise<any> = async (
    templateId: string
  ) => {
    const { data } = await this.instance.get(
      `template/retrieveProjectTemplate/${templateId}`
    );
    return data.data;
  };

  bulkCreateProjectTemplateFields: (
    templateFields: NewProjectTemplateField[]
  ) => Promise<any> = async (templateFields: NewProjectTemplateField[]) => {
    const response = await this.instance.post(
      'template/bulkCreateProjectTemplateFields',
      templateFields
    );
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      throw new Error(get(response, 'response.data.errorMessage', 'Error'));
    }
    return response.data.data;
  };

  bulkUpdateProjectTemplateFields: (
    updates: ProjectTemplateFieldUpdate[],
    currentTemplateVersion: number
  ) => Promise<any> = async (updates, currentTemplateVersion) => {
    const response = await this.instance.put(
      'template/bulkUpdateProjectTemplateFields',
      { currentTemplateVersion, updates }
    );
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      throw new Error(get(response, 'response.data.errorMessage', 'Error'));
    }
    return response.data.data;
  };

  bulkDeleteProjectTemplateFields: (
    projectTemplateFieldIds: string[],
    currentTemplateVersion: number
  ) => Promise<any> = async (
    projectTemplateFieldIds,
    currentTemplateVersion
  ) => {
    const response = await this.instance.post(
      'template/bulkDeleteProjectTemplateFields',
      {
        currentTemplateVersion,
        projectTemplateFieldIds,
      }
    );
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      throw new Error(get(response, 'response.data.errorMessage', 'Error'));
    }
  };

  updateProjectTemplate: (
    templateId: string,
    updateFields: Partial<ProjectTemplateToUpdate> & {
      currentTemplateVersion: number;
    }
  ) => Promise<any> = async (
    templateId: string,
    updateFields: Partial<ProjectTemplate>
  ) => {
    const response = await this.instance.put(
      `/template/updateProjectTemplate/${templateId}`,
      { updateFields }
    );
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      throw new Error(get(response, 'response.data.errorMessage', 'Error'));
    }
    return response.data.data;
  };

  increaseProjectTemplateVersion: (body: {
    projectTemplateId: string;
    currentTemplateVersion: number;
  }) => Promise<any> = async (body) => {
    const response = await this.instance.post(
      '/template/increaseProjectTemplateVersion',
      body
    );
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      throw new Error(get(response, 'response.data.errorMessage', 'Error'));
    }
    return response.data.data;
  };
}

export default new ProjectTemplateAPI();
