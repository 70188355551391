import { FormItem } from '@getsynapse/design-system';
import { useMemo } from 'react';
import intl from 'react-intl-universal';
import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
} from 'utils/constants';
import {
  FieldTemplateType,
  FieldType,
  NumberFieldTemplateType,
  NumberFieldType,
  RadioFieldTemplateType,
  RadioFieldType,
  TextFieldTemplateType,
  TextFieldType,
  ToggleFieldTemplateType,
  ToggleFieldType,
  DropdownFieldTemplateType,
  DropdownFieldType,
  DateFieldType,
  DateFieldTemplateType,
  CheckboxFieldTemplateType,
  CheckboxFieldType,
} from 'utils/types/fields';
import NumberField from './NumberField/NumberField';
import RadioField from './RadioField/RadioField';
import TextField from './TextField/TextField';
import ToggleField from './ToggleField/ToggleField';
import DropdownField from './DropdownField/DropdownField';
import DateField from './DateField/DateField';
import CheckboxField from './CheckboxField/CheckboxField';

interface CustomFieldProps {
  field?: FieldType;
  fieldTemplate: FieldTemplateType;
  onUpdate?: (
    newValue: FieldType['value'],
    fieldTemplateName: string,
    fieldId?: string
  ) => void;
  disabled?: boolean;
  required?: boolean;
  displayError?: boolean;
  className?: string;
}

const CustomField = ({
  field,
  fieldTemplate,
  onUpdate = () => {},
  disabled = false,
  required = false,
  displayError = false,
  className = '',
}: CustomFieldProps) => {
  const FieldComponent: JSX.Element = useMemo(() => {
    const onChangeHandler: (newValue: FieldType['value']) => void = (
      newValue
    ) => {
      onUpdate(newValue, fieldTemplate.name, field?.id);
    };

    if (
      fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN
    ) {
      return (
        <DropdownField
          field={field as DropdownFieldType}
          fieldTemplate={fieldTemplate as DropdownFieldTemplateType}
          onChange={onChangeHandler}
          disabled={disabled}
          state={displayError ? 'error' : 'default'}
        />
      );
    } else if (
      fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER
    ) {
      return (
        <DateField
          field={field as DateFieldType}
          fieldTemplate={fieldTemplate as DateFieldTemplateType}
          onChange={onChangeHandler}
          disabled={disabled}
          errorMessage={
            displayError ? intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR') : ''
          }
        />
      );
    } else if (
      fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX
    ) {
      return (
        <CheckboxField
          field={field as CheckboxFieldType}
          fieldTemplate={fieldTemplate as CheckboxFieldTemplateType}
          onChange={onChangeHandler}
          disabled={disabled}
        />
      );
    } else if (
      fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO
    ) {
      return (
        <RadioField
          field={field as RadioFieldType}
          fieldTemplate={fieldTemplate as RadioFieldTemplateType}
          onChange={onChangeHandler}
          disabled={disabled}
        />
      );
    } else {
      if (
        fieldTemplate.type === ALLOWED_FIELD_TYPES.SINGLE_LINE ||
        fieldTemplate.type === ALLOWED_FIELD_TYPES.LABEL ||
        fieldTemplate.type === ALLOWED_FIELD_TYPES.MULTILINE
      ) {
        return (
          <TextField
            field={field as TextFieldType}
            fieldTemplate={fieldTemplate as TextFieldTemplateType}
            onChange={onChangeHandler}
            disabled={disabled}
            state={displayError ? 'error' : 'default'}
          />
        );
      } else if (
        fieldTemplate.type === ALLOWED_FIELD_TYPES.NUMERIC ||
        fieldTemplate.type === ALLOWED_FIELD_TYPES.FLOAT ||
        fieldTemplate.type === ALLOWED_FIELD_TYPES.INT
      ) {
        return (
          <NumberField
            field={field as NumberFieldType}
            fieldTemplate={fieldTemplate as NumberFieldTemplateType}
            onChange={onChangeHandler}
            disabled={disabled}
            state={displayError ? 'error' : 'default'}
          />
        );
      } else {
        return (
          <ToggleField
            field={field as ToggleFieldType}
            fieldTemplate={fieldTemplate as ToggleFieldTemplateType}
            onChange={onChangeHandler}
            disabled={disabled}
          />
        );
      }
    }
  }, [disabled, field, fieldTemplate, onUpdate, displayError]);

  const isFieldSet: boolean =
    fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX ||
    fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO;

  return (
    <FormItem
      component={isFieldSet ? 'fieldset' : 'div'}
      label={fieldTemplate.name}
      labelProps={{ required }}
      helpText={
        displayError &&
        fieldTemplate.value_selector !==
          ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER &&
        intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
      }
      helpTextProps={{
        state: displayError ? 'error' : 'default',
      }}
      className={className}
    >
      {FieldComponent}
    </FormItem>
  );
};

export default CustomField;
