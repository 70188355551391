import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import {
  REQUESTS_TABLE_SORTABLE_COLUMNS,
  REQUEST_TABLE_FILTERS,
  REQUEST_LINKED_PROJECTS_OPTIONS,
} from 'utils/constants';
import {
  BusinessTeam,
  LDUser,
  Project,
  Request,
  SortingType,
} from 'utils/customTypes';
import {
  RangeFilter,
  RequestFilters,
  RequestFiltersKey,
} from 'utils/types/filters';
import { isRangeFilter } from 'utils/typeGuards';
import moment from 'moment';
import { formatRequestIdentifier } from 'Pages/helpers';

export const orderRequestsBy = (
  requests: Request[],
  column: string,
  order: SortingType
) => {
  switch (column) {
    case REQUESTS_TABLE_SORTABLE_COLUMNS.REQUEST_NUMBER:
      return orderBy(
        requests,
        [
          (request: Request) =>
            request.requestIdentifier ? parseInt(request.requestIdentifier) : 0,
        ],
        [order]
      );
    default:
      return orderBy(requests, [column], [order]);
  }
};

const checkRequestMatchDateFilter = (
  filterValue: RangeFilter,
  requestValue: string
) => {
  let requestMatchDateFilter = false;
  const requestDate = moment(new Date(requestValue));
  const fromRange = filterValue.from && new Date(filterValue.from);
  const toRange = filterValue.to && new Date(filterValue.to);
  if (fromRange) {
    requestMatchDateFilter = requestDate.isSameOrAfter(fromRange, 'days');
  }
  if (fromRange && toRange) {
    requestMatchDateFilter =
      requestMatchDateFilter && requestDate.isSameOrBefore(toRange, 'days');
  }
  if (!fromRange && toRange) {
    requestMatchDateFilter = requestDate.isSameOrBefore(toRange, 'days');
  }
  return requestMatchDateFilter;
};

const checkRequestMatchAllFilters = (
  request: Request,
  filters: RequestFilters
) => {
  let requestMatchAllFilters = true;
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as RequestFiltersKey];
    let requestValue;
    if (filter === REQUEST_TABLE_FILTERS.LINKED_PROJECTS) {
      requestValue = request[REQUEST_TABLE_FILTERS.PROJECTS as keyof Request];
      if (Array.isArray(requestValue) && requestValue.length === 0) {
        requestValue = REQUEST_LINKED_PROJECTS_OPTIONS.NONE;
      }
    } else {
      requestValue = request[filter as keyof Request];
    }
    if (!isEmpty(requestValue) && !isEmpty(filterValue)) {
      let requestMatchFilter = false;
      if (isRangeFilter(filterValue)) {
        requestMatchFilter = checkRequestMatchDateFilter(
          filterValue as RangeFilter,
          requestValue
        );
      } else if (filter === REQUEST_TABLE_FILTERS.OWNERS) {
        requestMatchFilter = requestValue.some((owner: LDUser) =>
          (filterValue as string[]).includes(owner.id)
        );
      } else if (filter === REQUEST_TABLE_FILTERS.BUSINESS_TEAMS) {
        requestMatchFilter = requestValue.some((team: BusinessTeam) =>
          (filterValue as string[]).includes(team.id)
        );
      } else if (filter === REQUEST_TABLE_FILTERS.PROJECTS) {
        requestMatchFilter = requestValue.some((project: Project) =>
          (filterValue as string[]).includes(project.id)
        );
      } else if (filter === REQUEST_TABLE_FILTERS.LINKED_PROJECTS) {
        if (
          !requestMatchFilter &&
          filterValue.includes(REQUEST_LINKED_PROJECTS_OPTIONS.NONE)
        ) {
          requestMatchFilter =
            typeof requestValue === 'string' &&
            requestValue === REQUEST_LINKED_PROJECTS_OPTIONS.NONE;
        }
        if (
          !requestMatchFilter &&
          filterValue.includes(REQUEST_LINKED_PROJECTS_OPTIONS.ONE)
        ) {
          requestMatchFilter =
            Array.isArray(requestValue) && requestValue.length === 1;
        }
        if (
          !requestMatchFilter &&
          filterValue.includes(REQUEST_LINKED_PROJECTS_OPTIONS.MANY)
        ) {
          requestMatchFilter =
            Array.isArray(requestValue) && requestValue.length > 1;
        }
      } else {
        requestMatchFilter = (filterValue as string[]).includes(requestValue);
      }
      requestMatchAllFilters = requestMatchAllFilters && requestMatchFilter;
      if (!requestMatchAllFilters) {
        return requestMatchAllFilters;
      }
    } else {
      requestMatchAllFilters = false;
      return requestMatchAllFilters;
    }
  }
  return requestMatchAllFilters;
};

export const filterRequestsBy = (
  requests: Request[],
  search: string,
  filters: RequestFilters
) => {
  let filterRequests = requests;
  const filtersActive = filters && Object.keys(filters).length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filterRequests = requests.filter((request: Request) => {
      let requestMatchSearch = false;
      let requestMatchFilters = false;
      if (searchActive) {
        requestMatchSearch =
          request.title
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          request.description
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          formatRequestIdentifier(request.requestIdentifier!)
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          requestMatchSearch;
      }

      if (searchActive && !requestMatchSearch) {
        return requestMatchSearch;
      }

      if (filtersActive) {
        requestMatchFilters = checkRequestMatchAllFilters(request, filters);
      }

      if (searchActive && filtersActive) {
        return requestMatchSearch && requestMatchFilters;
      } else if (searchActive && !filtersActive) {
        return requestMatchSearch;
      } else if (!searchActive && filtersActive) {
        return requestMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filterRequests;
};
