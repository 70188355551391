import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import has from 'lodash/has';
import set from 'lodash/set';
import get from 'lodash/get';
import { BusinessUser, Owner } from 'utils/customTypes';

class UserAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getUser = async (email: string) => {
    const { data } = await this.instance.get(`user/userEmail/${email}`);
    return data;
  };

  editUser = async (userId: string, updateFields: Owner) => {
    if (has(updateFields, 'data.email')) {
      set(
        updateFields,
        'data.email',
        updateFields.data?.email?.toLowerCase().trim()
      );
    }
    const { data } = await this.instance.put(`user/${userId}`, {
      userId,
      updateFields,
    });
    return data;
  };

  getUserBasicInfo = async (userId: string) => {
    const { data } = await this.instance.get(`user/basicInfo/${userId}`);
    return data;
  };

  setRegistered = async (userId: string) => {
    const { data } = await this.instance.put(`user/setRegistered/${userId}`);
    return data;
  };

  userSelfRegistration = async (
    userId: string,
    updateFields: Partial<BusinessUser>
  ) => {
    const response = await this.instance.put(
      `user/selfRegistration/${userId}`,
      {
        updateFields,
      }
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  updateLoginData = async () => {
    const { data } = await this.instance.put('user/updateData/login');
    return data;
  };
}

export default new UserAPI();
