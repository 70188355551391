import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { COLUMN_OPTION_TYPES } from 'utils/constants';
import { TimeOffData, filter, SortingOrderType } from 'utils/customTypes';
import { TimeOff } from '../../utils/customTypes';
import {
  checkColumnMatchDateFilter,
  checkColumnMatchFilter,
} from 'state/Projects/helpers';

const allFiltersMatched = (timeOff: TimeOffData, filters: filter[]) => {
  let allFiltersMatched = false;
  for (const filter of filters) {
    if (filter.column) {
      let columnMatchFilter = false;
      const columnData = timeOff[filter.column];
      if (columnData) {
        if (filter.type === COLUMN_OPTION_TYPES.DATE) {
          const formattedColumnData = moment(columnData)
            .utc()
            .endOf('day')
            .toDate()
            .toString();
          columnMatchFilter = checkColumnMatchDateFilter(
            formattedColumnData,
            filter
          );
        } else {
          columnMatchFilter = checkColumnMatchFilter(columnData, filter);
        }
      }
      if (filter.logic !== undefined) {
        allFiltersMatched =
          filter.logic === 'AND'
            ? allFiltersMatched && columnMatchFilter
            : allFiltersMatched || columnMatchFilter;
      } else {
        allFiltersMatched = columnMatchFilter;
      }
    }
  }
  return allFiltersMatched;
};

export const sortTimeOffsBy = (
  timeOffs: TimeOffData[],
  sorting: SortingOrderType
) => {
  let column = sorting.orderBy || 'start_date';
  const { order, orderBy: sortBy } = sorting;
  if (sortBy === 'dates') {
    column = 'start_date';
  }
  return orderBy(timeOffs, [column], [order]);
};

export const filterTimeOffs = (
  timeOffs: TimeOffData[],
  filters: filter[],
  searchParam: string
) => {
  let filteredTimeOffs = timeOffs;
  const filtersActive = filters.length > 0;
  const searchActive = searchParam !== '';
  if (filtersActive || searchActive) {
    filteredTimeOffs = timeOffs.filter((timeOff: TimeOffData) => {
      let searchMatch = false;
      let filtersMatch = false;
      if (filtersActive) {
        filtersMatch = allFiltersMatched(timeOff, filters);
      }
      if (searchActive && timeOff.time_off_type) {
        const timeOffTypeLabel = intl.get(
          `TEAMS.TIME_OFF.TIME_OFF_TYPES.${timeOff.time_off_type}`
        );
        searchMatch =
          timeOffTypeLabel
            .toLocaleLowerCase()
            .includes(searchParam.toLocaleLowerCase()) || false;
      }
      if (searchActive && filtersActive) {
        return searchMatch && filtersMatch;
      } else if (searchActive && !filtersActive) {
        return searchMatch;
      } else if (!searchActive && filtersActive) {
        return filtersMatch;
      } else {
        return false;
      }
    });
  }
  return filteredTimeOffs;
};

export const convertTimeOffToFormData: (timeOff: TimeOffData) => TimeOff = (
  timeOff: TimeOffData
) => ({
  id: timeOff.id,
  startDate: moment(timeOff.start_date)
    .utc()
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment(timeOff.end_date)
    .utc()
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  timeOffType: timeOff.time_off_type,
  userId: timeOff.user_id,
});
