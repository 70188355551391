import React from 'react';
import { get } from 'lodash';
import intl from 'react-intl-universal';
import {
  Button,
  Typography,
  tailwindOverride,
  Tag,
} from '@getsynapse/design-system';
import { TeamMemberCapacity } from 'utils/types/teamsCapacity';
import UserAvatar from 'Atoms/UserAvatar';

const TeamMemberData: React.FC<{
  teamId: string;
  memberData: TeamMemberCapacity['data'];
  hasProjectsAssigned: boolean;
  isProjectsListDisplayed: boolean;
  toggleProjectsList: () => void;
  className?: string;
}> = ({
  teamId,
  memberData,
  hasProjectsAssigned,
  isProjectsListDisplayed,
  toggleProjectsList,
  className,
}) => {
  const maxRoles = memberData.isTeamManager ? 2 : 3;
  return (
    <div className={tailwindOverride('flex flex-1 relative', className)}>
      <div className='w-10' />
      <div className={tailwindOverride('w-3/5 h-full', 'flex', 'px-2 py-3.5')}>
        <div className={tailwindOverride('w-6 h-full')}>
          <UserAvatar
            user={{
              avatar_url: get(memberData, 'avatar_url'),
              data: {
                firstName: get(memberData, 'firstName'),
                lastName: get(memberData, 'lastName'),
                email: get(memberData, 'email'),
              },
            }}
          />
        </div>
        <div className='flex flex-1 flex-col ml-2'>
          <Typography
            variant='label'
            className='text-primary font-semibold mb-1'
          >
            {`${get(memberData, 'firstName')} ${get(memberData, 'lastName')}`}
          </Typography>
          <Typography variant='label' className='text-neutral mb-2 break-all'>
            {get(memberData, 'email')}
          </Typography>
          {!hasProjectsAssigned && (
            <Typography variant='caption' className='text-primary-dark'>
              {intl.get('TEAMS_CAPACITY.NO_PROJECTS_ASSIGNED')}
            </Typography>
          )}
          {hasProjectsAssigned && (
            <Button
              variant='tertiary'
              className='text-sm self-start px-1 text-primary'
              onClick={toggleProjectsList}
              size='small'
              aria-label={`team-${teamId}__member-${memberData.id}__toggle-projects-button`}
              data-cy={`team-${teamId}__member-${memberData.id}__toggle-projects-button`}
            >
              {isProjectsListDisplayed
                ? intl.get('TEAMS_CAPACITY.HIDE_PROJECTS')
                : intl.get('TEAMS_CAPACITY.VIEW_PROJECTS')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={tailwindOverride('w-2/5 h-full flex flex-col', 'px-2 py-4')}
      >
        {memberData.isTeamManager && (
          <Tag
            label={intl.get('TEAMS_CAPACITY.TEAM_MANAGER')}
            textClassName='text-secondary-darker text-xs'
            className={tailwindOverride(
              'bg-secondary-lighter mb-2',
              'py-0.5 px-2'
            )}
          />
        )}
        {memberData.roles.slice(0, maxRoles).map((role, index) => (
          <Typography
            key={`team-${teamId}__member-${memberData.id}__role-${index}`}
            variant='caption'
            className='text-primary break-all'
          >
            {`${role}`}
          </Typography>
        ))}
        {memberData.roles.length > maxRoles && (
          <Typography variant='caption' className='text-primary'>{`+${
            memberData.roles.length - maxRoles
          }`}</Typography>
        )}
      </div>
    </div>
  );
};

export default TeamMemberData;
