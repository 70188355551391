import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import {
  SLICE_STATUS,
  USER_STATUS,
  USER_TYPES,
  TABLE_FILTERS_OPERATORS,
  MEMBER_VALUES,
} from 'utils/constants';
import {
  LDUser,
  AllUsersType,
  SortingType,
  Status,
  UserAvatars,
  LDTeam,
  filter,
  BusinessUser,
} from 'utils/customTypes';
import { selectOrganizationLicense } from 'state/Organization/organizationSlice';
import { selectCurrentLearningTeam } from 'state/LearningTeams/learningTeamsSlice';
import { SelectComapnyName } from 'state/Organization/organizationSlice';
import { selectUserId, selectUser, setCurrentUser } from 'state/User/userSlice';
import { compare } from 'state/Requests/requestSlice';
import usersManagementAPI from './usersManagementAPI';
import exportAPI from 'Services/exportAPI';

interface Users {
  ldUsers: LDUser[];
  allUsers: AllUsersType[];
  usersSorting: {
    orderBy: string[];
    order: SortingType;
  };
  allUsersPagination: {
    limit: number;
    offset: number;
  };
  selectedUser: AllUsersType;
  searchParam: string;
  filters?: filter[];
  selectedUserStatus: Status;
  businessReviewers: BusinessUser[];
  LDReviewers: LDUser[];
  exportStatus: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: Users = {
  ldUsers: [],
  allUsers: [],
  usersSorting: {
    order: 'asc',
    orderBy: ['data.firstName', 'data.lastName'],
  },
  allUsersPagination: {
    limit: 15,
    offset: 0,
  },
  selectedUser: {
    data: {
      firstName: '',
      lastName: '',
      email: '',
    },
    registeredLearningTeams: [],
    id: '',
    country_iso_3166_1_alpha_2_code: '',
    type: 'ld',
    default_capacity: 0,
    status: '',
    role: 'user',
    disabled_at: '',
    avatar_url: '',
  },
  searchParam: '',
  selectedUserStatus: SLICE_STATUS.IDLE,
  businessReviewers: [],
  LDReviewers: [],
  exportStatus: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getLDUsers = createAsyncThunk(
  'usersManagement/GET_LD_USERS',
  async () => {
    const response = await usersManagementAPI.fetchUsers({ type: 'ld' });
    const data = response.data.map((user: LDUser) => {
      return {
        businessTeam_id: get(user, 'businessTeam_id'),
        avatar_url: get(user, 'avatar_url'),
        data: {
          email: get(user, 'data.email'),
          lastName: get(user, 'data.lastName'),
          firstName: get(user, 'data.firstName'),
          avatar_url: get(user, 'avatar_url'),
        },
        id: get(user, 'id'),
        managedTeams: get(user, 'teamsManaged') || [],
        status: get(user, 'status'),
      };
    });
    return data;
  }
);

export const getAllUsers = createAsyncThunk(
  'usersManagement/GET_ALL_USERS',
  async () => {
    const { data } = await usersManagementAPI.fetchUsers();
    return data;
  }
);

export const getSelectedUser = createAsyncThunk(
  'usersManagement/GET_USER',
  async (userId: string) => {
    const { data } = await usersManagementAPI.fetchUser(userId);
    return data.user;
  }
);

export const updateUser = createAsyncThunk(
  'usersManagement/UPDATE_USER',
  async (
    {
      userId,
      updateFields,
    }: {
      userId: string;
      updateFields: Partial<AllUsersType>;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const { data } = await usersManagementAPI.updateUser(userId, updateFields);
    const currentUserId = selectUserId(state);

    if (get(data, 'user.id') === currentUserId) {
      const currentUser = selectUser(state);
      dispatch(
        setCurrentUser({
          ...currentUser,
          full_name: `${get(data, 'user.data.firstName')} ${get(
            data,
            'user.data.lastName'
          )}`,
          avatar_url: get(data, 'user.avatar_url'),
          firstName: get(data, 'user.data.firstName'),
          lastName: get(data, 'user.data.lastName'),
        })
      );
    }
    return data.user;
  }
);

export const assignUserToLearningTeam = createAsyncThunk(
  'usersManagement/ASSIGN_USER_TO_LEARNING_TEAM',
  async ({ userId, teamsIds }: { userId: string; teamsIds: string[] }) => {
    const { data } = await usersManagementAPI.assignUserToLearningTeam(
      userId,
      teamsIds
    );
    return data.user;
  }
);

export const addUser = createAsyncThunk(
  'usersManagement/ADD_USER',
  async (userData: Partial<AllUsersType>) => {
    const { data } = await usersManagementAPI.createUser(userData);
    return data.user;
  }
);

export const addBusinessUser = createAsyncThunk(
  'usersManagement/ADD_USER',
  async (userData: Partial<AllUsersType>) => {
    const response = await usersManagementAPI.createBusinessUser(userData);
    if (response.success) {
      return response.data.user;
    }
    return response;
  }
);

export const addUserAndOrganization = createAsyncThunk(
  'usersManagement/ADD_USER_AND_ORGANIZATION',
  async (userData: Partial<AllUsersType> & { organization: object }) => {
    const response = await usersManagementAPI.createOrganizationWithFirstUser(
      userData
    );
    if (response.success) {
      return response.data.user;
    }
    return response;
  }
);

export const inviteUser = createAsyncThunk(
  'usersManagement/INVITE_USER',
  async (
    {
      userId = '',
      email,
      firstName,
      selfRegistration = false,
    }: {
      userId?: string;
      email: string;
      firstName: string;
      selfRegistration?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const companyName = SelectComapnyName(state);
    const { data } = await usersManagementAPI.inviteUser(
      userId,
      email,
      firstName,
      companyName,
      selfRegistration
    );
    return data.user;
  }
);

export const bulkCreateUsers = createAsyncThunk(
  'usersManagement/BULK_CREATE_USERS',
  async (newUsers: Partial<LDUser>[]) => {
    const { data } = await usersManagementAPI.bulkCreateUsers(newUsers);
    return data;
  }
);

export const exportUsers = createAsyncThunk(
  'usersManagement/EXPORT_CSV',
  async (userIds: string[], { rejectWithValue }) => {
    try {
      const response = await exportAPI.exportUsers(userIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBusinessReviewers = createAsyncThunk(
  'usersManagement/GET_BUSINESS_REVIEWERS',
  async (requestId: string) => {
    const { data } = await usersManagementAPI.fetchBusinessReviewers(requestId);
    return data;
  }
);

export const getLDReviewers = createAsyncThunk(
  'usersManagement/GET_LD_REVIEWERS',
  async (requestId: string) => {
    const { data } = await usersManagementAPI.fetchLDReviewers(requestId);
    return data;
  }
);

/* ================================ ACTIONS ================================= */
export const updateUsersPagination = createAction<{
  limit: number;
  offset: number;
}>('usersManagement/UPDATE_USERS_PAGINATION');

export const setUsersOrder = createAction<{
  order: SortingType;
  orderBy: string[];
}>('usersManagement/SET_USERS_ORDER');

export const resetSelectedUser = createAction(
  'usersManagement/RESET_SELECTED_USER'
);

export const setSearchParam = createAction<string>(
  'usersManagement/SET_SEARCH_PARAM'
);

export const setUserFilters = createAction<filter[]>(
  'usersManagement/SET_USER_FILTERS'
);

/* ================================= REDUCER ================================ */
const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLDUsers.fulfilled, (state, action) => {
        state.ldUsers = action.payload;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.allUsers = [];
      })
      .addCase(updateUsersPagination, (state, action) => {
        state.allUsersPagination.limit = action.payload.limit;
        state.allUsersPagination.offset = action.payload.offset;
      })
      .addCase(setUsersOrder, (state, action) => {
        state.usersSorting.order = action.payload.order;
        state.usersSorting.orderBy = action.payload.orderBy;
      })
      .addCase(getSelectedUser.fulfilled, (state, action) => {
        state.selectedUserStatus = SLICE_STATUS.IDLE;
        state.selectedUser = action.payload;
      })
      .addCase(getSelectedUser.pending, (state) => {
        state.selectedUserStatus = SLICE_STATUS.LOADING;
      })
      .addCase(getSelectedUser.rejected, (state) => {
        state.selectedUserStatus = SLICE_STATUS.FAILED;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.allUsers = state.allUsers
          .filter((user) => user.id !== action.payload.id)
          .concat([action.payload]);
      })
      .addCase(resetSelectedUser, (state) => {
        state.selectedUser = initialState.selectedUser;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.allUsers = [action.payload].concat(state.allUsers);
      })
      .addCase(addUserAndOrganization.fulfilled, (state, action) => {
        state.allUsers = [action.payload].concat(state.allUsers);
      })
      .addCase(bulkCreateUsers.fulfilled, (state, action) => {
        state.allUsers = [...state.allUsers, ...action.payload];
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.toLocaleLowerCase().trim();
      })
      .addCase(setUserFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(exportUsers.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportUsers.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportUsers.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      })
      .addCase(getBusinessReviewers.fulfilled, (state, action) => {
        state.businessReviewers = action.payload;
      })
      .addCase(getLDReviewers.fulfilled, (state, action) => {
        state.LDReviewers = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */
const selectSelf = (state: RootState) => state;
export const selectLDUsers = (state: RootState) => state.users.ldUsers;

export const allUsers = (state: RootState) => state.users.allUsers;

export const allActiveUsers = createSelector(allUsers, (users) => {
  const activeUsers = users.filter(
    (user) =>
      user.status === USER_STATUS.INVITED ||
      user.status === USER_STATUS.REGISTERED
  );
  return orderBy(
    activeUsers,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as AllUsersType[];
});

export const selectBusinessReviewers = (state: RootState) =>
  state.users.businessReviewers;

export const selectLDReviewers = (state: RootState) => state.users.LDReviewers;

const usersSorting = (state: RootState) => state.users.usersSorting;

const allUsersPagination = (state: RootState) => state.users.allUsersPagination;

const selectSearchParam = (state: RootState) => state.users.searchParam;

export const selectFilters = (state: RootState) => state.users.filters;
export const exportUsersStatus = (state: RootState) => state.users.exportStatus;

const filterPositive = (
  filter: filter,
  isGood: boolean | undefined,
  columnData: any,
  searchedVal: string,
  user: AllUsersType
) => {
  switch (filter.column) {
    case MEMBER_VALUES.JOB_TITLE:
      return compare(
        columnData
          .toLocaleLowerCase()
          .includes(searchedVal.toLocaleLowerCase()),
        isGood,
        filter.logic
      );
    case MEMBER_VALUES.EMPLOYMENT_TYPE:
      const employmentType = intl.get(`TEAMS.EMPLOYMENT_TYPE.${columnData}`);
      return compare(employmentType === searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.COUNTRY:
      const country = intl.get(`COUNTRIES.${columnData}`);
      return compare(country === searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.TYPE:
      const userType = intl.get(
        `USERS_PAGE.TABLE.USER_TYPE.${columnData.toUpperCase()}`
      );
      return compare(userType === searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.TEAM:
      if (user.type === USER_TYPES.BUSINESS) {
        const registeredTeams = get(user, 'businessTeams.title');
        return compare(registeredTeams === searchedVal, isGood, filter.logic);
      } else if (user.type === USER_TYPES.L_D) {
        const registeredTeams = get(user, 'registeredLearningTeams');
        const teamsArray = registeredTeams.map((team: LDTeam) => team.name);
        return compare(
          teamsArray.some((teamName: string) => teamName === searchedVal),
          isGood,
          filter.logic
        );
      }
      return compare(false, isGood, filter.logic);
    case MEMBER_VALUES.STATUS:
      const userStatus = intl.get(
        `USERS_PAGE.TABLE.USER_STATUS.${columnData.toUpperCase()}`
      );
      if (searchedVal === intl.get('USERS_PAGE.TABLE.USER_PENDING.PENDING')) {
        return compare(
          columnData === USER_STATUS.INVITED && !user.notified_user,
          isGood,
          filter.logic
        );
      }
      return compare(userStatus === searchedVal, isGood, filter.logic);
    default:
      return compare(
        columnData
          .toLocaleLowerCase()
          .includes(searchedVal.toLocaleLowerCase()),
        isGood,
        filter.logic
      );
  }
};

const filterNegative = (
  filter: filter,
  isGood: boolean | undefined,
  columnData: any,
  searchedVal: string,
  user: AllUsersType
) => {
  switch (filter.column) {
    case MEMBER_VALUES.JOB_TITLE:
      return compare(
        !columnData
          .toLocaleLowerCase()
          .includes(searchedVal.toLocaleLowerCase()),
        isGood,
        filter.logic
      );
    case MEMBER_VALUES.EMPLOYMENT_TYPE:
      const employmentType = intl.get(`TEAMS.EMPLOYMENT_TYPE.${columnData}`);
      return compare(employmentType !== searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.COUNTRY:
      const country = intl.get(`COUNTRIES.${columnData}`);
      return compare(country !== searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.TYPE:
      const userType = intl.get(
        `USERS_PAGE.TABLE.USER_TYPE.${columnData.toUpperCase()}`
      );
      return compare(userType !== searchedVal, isGood, filter.logic);
    case MEMBER_VALUES.TEAM:
      if (user.type === USER_TYPES.BUSINESS) {
        const registeredTeams = get(user, 'businessTeams.title');
        return compare(registeredTeams !== searchedVal, isGood, filter.logic);
      } else if (user.type === USER_TYPES.L_D) {
        const registeredTeams = get(user, 'registeredLearningTeams');
        const teamsArray = registeredTeams.map((team: LDTeam) => team.name);
        return compare(
          !teamsArray.some((teamName: string) => teamName === searchedVal),
          isGood,
          filter.logic
        );
      }
      return compare(false, isGood, filter.logic);
    case MEMBER_VALUES.STATUS:
      const userStatus = intl.get(
        `USERS_PAGE.TABLE.USER_STATUS.${columnData.toUpperCase()}`
      );
      if (searchedVal === intl.get('USERS_PAGE.TABLE.USER_PENDING.PENDING')) {
        return compare(
          !(columnData === USER_STATUS.INVITED && !user.notified_user),
          isGood,
          filter.logic
        );
      }
      return compare(userStatus !== searchedVal, isGood, filter.logic);
    default:
      return compare(
        !columnData
          .toLocaleLowerCase()
          .includes(searchedVal.toLocaleLowerCase()),
        isGood,
        filter.logic
      );
  }
};

export const selectAllUsers = createSelector(
  [
    allUsers,
    usersSorting,
    allUsersPagination,
    selectSearchParam,
    selectFilters,
  ],
  (users, sorting, pagination, searchParam, filters) => {
    let formattedUsers: AllUsersType[] = users.map((user: AllUsersType) => {
      if (user.type === USER_TYPES.BUSINESS) {
        return { ...user, team: get(user, 'businessTeams.title', '-') };
      } else if (user.type === USER_TYPES.L_D) {
        const registeredTeams = get(user, 'registeredLearningTeams') || [];
        const teamsArray = registeredTeams.map((team: LDTeam) => team.name);
        return {
          ...user,
          team: teamsArray.length ? teamsArray.join(', ') : '-',
        };
      } else {
        return { ...user, team: '-' };
      }
    });

    formattedUsers = orderBy(
      formattedUsers,
      (user) => {
        return sorting.orderBy.map((orderRoute) => {
          const label = `${get(user, orderRoute) || ''}`.trim();
          return label ? label.toLocaleLowerCase() : '';
        });
      },
      [sorting.order, sorting.order]
    );

    const hasFilters = !isEmpty(filters);
    if (searchParam || hasFilters) {
      formattedUsers = formattedUsers.filter((user: AllUsersType) => {
        let hasTitle = false;
        let isGood: boolean | undefined;
        if (searchParam) {
          const name = `${get(user, 'data.firstName')} ${get(
            user,
            'data.lastName'
          )}`;
          hasTitle =
            name.trim().toLocaleLowerCase().includes(searchParam) ||
            user.data.email.includes(searchParam) ||
            hasTitle;
        }

        if (hasFilters) {
          filters?.forEach((filter) => {
            if (filter.column) {
              const columnData = get(user, filter.column, '');
              const searchedVal = filter.value as string;

              if (searchedVal) {
                switch (filter.operator) {
                  case TABLE_FILTERS_OPERATORS.CONTAINS:
                  case TABLE_FILTERS_OPERATORS.EQUAL:
                    isGood = filterPositive(
                      filter,
                      isGood,
                      columnData,
                      searchedVal,
                      user
                    );
                    break;
                  case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
                  case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
                    isGood = filterNegative(
                      filter,
                      isGood,
                      columnData,
                      searchedVal,
                      user
                    );
                    break;
                  default:
                    isGood = compare(
                      columnData.includes(searchedVal),
                      isGood,
                      filter.logic
                    );
                    break;
                }
              }
            }
          });
        }
        if (searchParam && hasFilters) {
          return hasTitle && isGood;
        } else if (searchParam && !hasFilters) {
          return hasTitle;
        } else if (!searchParam && hasFilters) {
          return isGood;
        } else {
          return false;
        }
      });
    }

    const total = formattedUsers.length;
    const allUsers = formattedUsers;
    formattedUsers = formattedUsers.slice(pagination.offset, pagination.limit);
    return {
      data: formattedUsers,
      total,
      all: allUsers,
    };
  }
);

export const selectSelectedUser = (state: RootState) =>
  state.users.selectedUser;

export const selectUserStatus = (state: RootState) =>
  state.users.selectedUserStatus;

export const selectActiveLDUsers = createSelector(selectSelf, (state) => {
  const users = state.users.ldUsers.filter(
    (user) =>
      user.status === USER_STATUS.INVITED ||
      user.status === USER_STATUS.REGISTERED
  );
  return orderBy(
    users,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as LDUser[];
});

export const selectLDUsersForDropdown = createSelector(
  [selectActiveLDUsers, selectCurrentLearningTeam],
  (users, team) => {
    if (!isEmpty(team)) {
      return users.reduce<UserAvatars[]>((ldUsers, user) => {
        if (!team.ldTeamMembers?.find((member) => member.id === user.id)) {
          const fullName = `${get(user, 'data.firstName') || ''} ${
            get(user, 'data.lastName') || ''
          }`;
          if (fullName.trim()) {
            return ldUsers.concat({
              label: `${user.data.firstName} ${user.data.lastName}`,
              avatar: {
                imageSrc: user.avatar_url,
                initial: `${user.data?.firstName?.charAt(
                  0
                )}${user.data?.lastName?.charAt(0)}`,
                name: `${user.data.firstName} ${user.data.lastName}`,
              },
              value: user.id,
            });
          }
        }
        return ldUsers;
      }, []);
    } else {
      return users
        .filter((user) => {
          const fullName = `${get(user, 'data.firstName') || ''} ${
            get(user, 'data.lastName') || ''
          }`;
          return fullName.trim() ? true : false;
        })
        .map((user) => {
          return {
            label: `${user.data.firstName} ${user.data.lastName}`,
            avatar: {
              imageSrc: user.avatar_url,
              initial: `${user.data.firstName.charAt(
                0
              )}${user.data?.lastName?.charAt(0)}`,
              name: `${user.data.firstName} ${user.data.lastName}`,
            },
            value: user.id,
          };
        }) as UserAvatars[];
    }
  }
);

export const selectAllUsersForDropdown = createSelector(
  [allActiveUsers],
  (users) => {
    return users
      .filter((user) => {
        const fullName = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`;
        return fullName.trim() ? true : false;
      })
      .map((user) => {
        return {
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data.firstName.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          value: user.id,
        };
      }) as UserAvatars[];
  }
);

export const selectBusinessReviewersForDropdown = createSelector(
  [selectBusinessReviewers],
  (users) => {
    const orderedUsers = orderBy(
      users,
      (user) => {
        const label = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`.trim();
        return label ? label.toLocaleLowerCase() : '';
      },
      'asc'
    );

    return orderedUsers
      .filter((user) => {
        const fullName = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`;
        return fullName.trim() ? true : false;
      })
      .map((user) => {
        return {
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data?.firstName?.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          value: user.id,
        };
      }) as UserAvatars[];
  }
);

export const selectLDReviewersForDropdown = createSelector(
  [selectLDReviewers],
  (users) => {
    const orderedUsers = orderBy(
      users,
      (user) => {
        const label = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`.trim();
        return label ? label.toLocaleLowerCase() : '';
      },
      'asc'
    );
    return orderedUsers
      .filter((user) => {
        const fullName = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`;
        return fullName.trim() ? true : false;
      })
      .map((user) => {
        return {
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data?.firstName?.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          value: user.id,
        };
      }) as UserAvatars[];
  }
);

export const selectDisabledDate = (state: RootState) =>
  state.users?.selectedUser?.disabled_at;

export const selectAvailableLicenses = createSelector(
  [selectActiveLDUsers, selectOrganizationLicense],
  (users, licenses) => {
    const licencesLeft = licenses.license_number - users.length;
    return licencesLeft > 0 ? licencesLeft : 0;
  }
);

export const selectUserById = createSelector(
  [allUsers, selectUserId],
  (users: AllUsersType[], userId: string | undefined) => {
    if (userId) {
      return users.find((user: AllUsersType) => user.id === userId);
    }
    return undefined;
  }
);

export default usersManagementSlice.reducer;
