import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  ProjectFiltersKey,
  AppliedFilter,
  ProjectFilters,
  RangeFilter,
} from 'utils/types/filters';
import { isRangeFilter } from 'utils/typeGuards';
import { DATE, PROJECTS_TABLE_FILTERS } from 'utils/constants';
import useProjectFiltersOptions from './hooks/useProjectFiltersOptions';

export type Props = {
  filters: ProjectFilters;
  onUpdateFilters: (filters: ProjectFilters) => void;
  onDisplayAllFilters: () => void;
  className?: string;
};

const AppliedFiltersTags: React.FC<Props> = ({
  filters,
  onUpdateFilters,
  onDisplayAllFilters,
  className = '',
}) => {
  const { getDateFilterLabelByKey, getFilterOptionLabelByKeyAndValue } =
    useProjectFiltersOptions();
  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const key of Object.keys(filters)) {
      const filterKey = key as ProjectFiltersKey;
      if (isRangeFilter(filters[filterKey])) {
        const filter = filters[filterKey] as RangeFilter;
        const label = getDateFilterLabelByKey(
          filterKey,
          filter,
          DATE.TASK_TABLE_FORMAT
        );
        tags.push({ label, value: '', key });
      } else if (filterKey === PROJECTS_TABLE_FILTERS.ARCHIVED) {
        tags.push({
          label: intl.get('PROJECT_DETAIL.ARCHIVED'),
          value: 'true',
          key,
        });
      } else {
        const filter = filters[filterKey] as string[];
        filter.forEach((value: string) => {
          const label = getFilterOptionLabelByKeyAndValue(filterKey, value);
          tags.push({ label, value, key });
        });
      }
    }
    return tags;
  }, [filters, getDateFilterLabelByKey, getFilterOptionLabelByKeyAndValue]);
  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value } = filter;
    const filterKey = key as ProjectFiltersKey;
    const updatedFilters = { ...filters };
    if (
      filterKey === PROJECTS_TABLE_FILTERS.START_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE
    ) {
      delete updatedFilters[filterKey];
    } else if (filterKey === PROJECTS_TABLE_FILTERS.ARCHIVED) {
      delete updatedFilters[filterKey];
    } else {
      const filterValues = updatedFilters[filterKey] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[filterKey];
      } else {
        updatedFilters[filterKey] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };
  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={() => onUpdateFilters({} as ProjectFilters)}
      onDisplayAllFilters={onDisplayAllFilters}
      className={tailwindOverride('border-primary-lighter-two z-20', className)}
      data-testid='applied-projects-filters'
    />
  );
};

export default AppliedFiltersTags;
