import { useEffect } from 'react';
import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { useHistory } from 'react-router-dom';
import { Button } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import AddProgramModal from './components/AddProgramModal';
import ProgramsList from './components/ProgramsList';
import { isUserOrganizationAdmin, selectIsUserLd } from 'state/User/userSlice';
import { PATHS } from 'utils/constants';
import { fetchPrograms } from 'state/Programs/programsSlice';
import { useState } from 'react';

const ProgramsListPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const isUserLd = useSelector(selectIsUserLd);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isUserAdmin && !isUserLd) {
      history.push(PATHS.ROOT);
    }
    dispatch(fetchPrograms());
  }, [dispatch, isUserAdmin, isUserLd, history]);

  return (
    <>
      <AddProgramModal setIsOpen={setIsModalOpen} isOpen={isModalOpen} />
      <PageTitle
        titleComponent={intl.get('PROGRAMS_LIST_PAGE.TITLE')}
        headerChildren={
          <Button
            onClick={() => setIsModalOpen(true)}
            data-testid='add-program_button'
          >
            {intl.get('PROGRAMS_LIST_PAGE.ADD_PROGRAM_BUTTON')}
          </Button>
        }
      />
      <ProgramsList onAddProgram={() => setIsModalOpen(true)} />
    </>
  );
};

export default ProgramsListPage;
