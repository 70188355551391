import { configureStore, combineReducers } from '@reduxjs/toolkit';
import activeRequest from './ActiveRequest/activeRequestSlice';
import forms from './Forms/formSlice';
import inlineNotification from './InlineNotification/inlineNotificationSlice';
import requestsState from './Requests/requestSlice';
import projectsState from './Projects/projectsSlice';
import project from './Project/projectSlice';
import projectComments from './ProjectComments/projectCommentsSlice';
import currentUser from './User/userSlice';
import userSettings from './UserSetting/userSettingsSlice';
import requestComments from './RequestComments/requestCommentsSlice';
import users from './UsersManagement/usersManagementSlice';
import teams from './Teams/teamsSlice';
import organization from './Organization/organizationSlice';
import processes from './Processes/processesSlice';
import notifications from './Notifications/notificationsSlice';
import timeOff from './TimeOff/TimeOffSlice';
import singleTask from './SingleTask/singleTaskSlice';
import projectTasks from './ProjectTasks/projectTaskSlice';
import tasks from './Tasks/taskSlice';
import vendors from './Vendors/vendorsSlice';
import businessTeams from './BusinessTeams/businessTeamsSlice';
import learningTeams from './LearningTeams/learningTeamsSlice';
import budgets from './Budget/budgetSlice';
import capacity from './Capacity/capacitySlice';
import resourceAllocation from './ResourceAllocation/resourceAllocation';
import designs from './Designs/designsSlice';
import myWorkspace from './MyWorkspace/myWorkspaceSlice';
import sidePanel from './SidePanel/sidePanelSlice';
import sidekick from './Sidekick/sidekickSlice';
import projectTemplates from './ProjectTemplates/projectTemplatesSlice';
import projectTemplate from './ProjectTemplate/ProjectTemplateSlice';
import programs from './Programs/programsSlice';
import program from './Program/programSlice';
import snackbarNotification from './SnackbarNotification/SnackbarNotificationSlice';
import filtersSettings from './Settings/Filters/FiltersSlice';
import tasksAssignmentsResourcesCapacity from './TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import projectsInsights from './Insights/ProjectsSlice';
import intakeInsights from './Insights/InsightsSlice';
import customFields from './CustomFields/customFieldsSlice';
import taskBundles from 'state/TasksBundle/tasksBundleSlice';
import activeTaskBundle from './ActiveTaskBundle/activeTaskBundleSlice';
import teamsCapacity from './TeamsCapacity/teamsCapacitySlice';

export const rootReducer = combineReducers({
  activeRequest,
  businessTeams,
  capacity,
  learningTeams,
  currentUser,
  userSettings,
  designs,
  forms,
  inlineNotification,
  notifications,
  organization,
  project,
  projectsState,
  projectComments,
  requestComments,
  requestsState,
  tasks,
  singleTask,
  projectTasks,
  teams,
  timeOff,
  users,
  processes,
  vendors,
  budgets,
  resourceAllocation,
  myWorkspace,
  sidePanel,
  sidekick,
  projectTemplates,
  projectTemplate,
  programs,
  program,
  snackbarNotification,
  filtersSettings,
  tasksAssignmentsResourcesCapacity,
  projectsInsights,
  intakeInsights,
  customFields,
  taskBundles,
  activeTaskBundle,
  teamsCapacity,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
