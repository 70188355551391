import { ComponentProps } from 'react';
import { Dropdown, tailwindOverride } from '@getsynapse/design-system';
import classnames from 'classnames';
import { FormOption } from 'utils/customTypes';
import MultipleOptionListItem from 'Molecules/MultipleOptionsListItem/MultipleOptionsListItem';
import get from 'lodash/get';

type DropdownProps = ComponentProps<typeof Dropdown>;

const MultiSelectDropdown = ({ ...otherProps }: DropdownProps) => {
  const placeholderText = get(otherProps, 'placeholder');
  const {
    triggerProps,
    listProps,
    renderOption: customOptionRender,
  } = otherProps;
  const triggerClassName: string = get(triggerProps, 'className');
  return (
    <Dropdown
      {...otherProps}
      triggerProps={{
        'data-cy': 'dropdown',
        placeholder: placeholderText,
        ...triggerProps,
        className: tailwindOverride('w-full', triggerClassName),
      }}
      listProps={{
        'data-cy': 'multiple-dropdown__options-list',
        noOptionsText: otherProps?.noOptionsText,
        ...listProps,
      }}
      renderOption={
        customOptionRender
          ? customOptionRender
          : (
              option: FormOption,
              isSelected: boolean,
              selectOption,
              { className, ...otherProps }
            ) => (
              <li
                {...otherProps}
                key={option.value}
                className={classnames('group', className, {
                  'hover:bg-primary focus-visible:bg-primary': isSelected,
                })}
              >
                <MultipleOptionListItem
                  label={option.label}
                  isSelected={isSelected}
                  selectOption={selectOption}
                  value={option.value}
                />
              </li>
            )
      }
      multiple
    />
  );
};

export default MultiSelectDropdown;
