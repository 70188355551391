import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  FormItem,
  Modal,
  Typography,
} from '@getsynapse/design-system';
import importIcon from 'assets/icons/import.svg';
import {
  attachTaskBundleToProject,
  fetchTaskBundles,
  selectAllTaskBundles,
} from 'state/TasksBundle/tasksBundleSlice';
import { Option } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { SETTINGS_FILTERS } from 'utils/constants';
import {
  fetchTeamTasks,
  fetchUserTasks,
} from 'state/ProjectTasks/projectTaskSlice';

interface ImportTasksModalProps {
  showModal: boolean;
  onClose: () => void;
}

const ImportTasksModal: React.FC<ImportTasksModalProps> = ({
  showModal,
  onClose,
}) => {
  const dispatch = useDispatch();

  const taskBundles = useSelector(selectAllTaskBundles);

  const [selectedTaskBundle, setSelectedTaskBundle] = useState<Option>();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseModal = () => {
    setSelectedTaskBundle(undefined);
    onClose();
  };

  useEffect(() => {
    dispatch(fetchTaskBundles());
  }, [dispatch]);

  const taskBundleOptions = useMemo(
    () =>
      taskBundles.map((taskBundle) => ({
        label: taskBundle.name,
        value: taskBundle.id,
      })),
    [taskBundles]
  );

  const selectTaskBundle: (templateId: string) => void = useCallback(
    (templateId) => {
      const selectedTaskBundle = taskBundleOptions.find(
        (taskBundle) => taskBundle.value === templateId
      );

      if (selectedTaskBundle) {
        setSelectedTaskBundle(selectedTaskBundle);
      }
    },
    [taskBundleOptions]
  );

  const { projectId } = useParams<{ projectId: string }>();

  const onImportTasksFromBundle = async () => {
    setIsLoading(true);

    await dispatch(
      attachTaskBundleToProject({
        projectId,
        taskBundleId: selectedTaskBundle?.value,
      })
    );

    setIsLoading(false);
    onClose();

    await Promise.all([
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'TASKS.IMPORT_TASKS_MODAL.SUCCESS_MESSAGE'
          ),
        })
      ),
      dispatch(fetchUserTasks(projectId)),
      dispatch(fetchTeamTasks(projectId)),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.PROJECT_TEAM_TASKS_TABLE)
      ),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECT_TASKS_TABLE)
      ),
    ]);
  };

  return (
    <Modal
      title={intl.get('TASKS.IMPORT_TASKS_MODAL.TITLE')}
      titleIcon={{ src: importIcon }}
      isOpen={showModal}
      closeModal={onCloseModal}
      aria-label={intl.get('TASKS.IMPORT_TASKS_MODAL.TITLE')}
      data-cy='import-tasks-modal'
      size='medium'
    >
      <Typography>
        {intl.get('TASKS.IMPORT_TASKS_MODAL.DESCRIPTION')}
      </Typography>

      <FormItem
        label='Task Bundle'
        labelProps={{ required: true }}
        className='w-full mt-3'
      >
        <Dropdown
          options={taskBundleOptions}
          placeholder={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.PLACEHOLDER')}
          onChange={(option: Option) => {
            selectTaskBundle(option.value);
          }}
          triggerProps={{ 'data-cy': 'project-task-bundle-input' }}
        />
      </FormItem>

      <div className='flex space-x-3 mt-8'>
        <Button
          loading={isLoading}
          onClick={onImportTasksFromBundle}
          disabled={!selectedTaskBundle}
          className=' h-10'
          data-cy='import-tasks-modal-import_button'
        >
          Import
        </Button>

        <Button
          onClick={onCloseModal}
          disabled={isLoading}
          className=' h-10'
          variant='tertiary'
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ImportTasksModal;
