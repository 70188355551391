import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { TableOperationHeader } from '@getsynapse/design-system';
import { TableRowDensity } from 'utils/customTypes';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import TasksFiltersSidePanel from './TasksFiltersSidePanel/TasksFiltersSidePanel';
import AppliedFiltersTags from './TasksFiltersSidePanel/AppliedFiltersTags';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { TaskFilters } from 'utils/types/filters';
import { RawFilters } from 'utils/filters';
import { selectTasksSearch } from 'state/Tasks/taskSlice';

const TasksTableHeader: React.FC<{
  isTableEmpty: boolean;
  selectRowDensity: (rowDensity: TableRowDensity) => void;
  taskTable: CentralizedTasksTableTab;
  onUpdateFilters: (filters: RawFilters) => void;
  onSearch: (value: string) => void;
}> = ({
  isTableEmpty,
  selectRowDensity,
  taskTable,
  onUpdateFilters,
  onSearch,
}) => {
  const dispatch = useDispatch();

  const filtersSettings = useSelector(selectFiltersSettingsByType(taskTable));

  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState(false);

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prev) => !prev);

  const appliedFilters = useMemo(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  const handleUpdateFilters = (filters: TaskFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: taskTable,
          filter_settings: filters,
        })
      );
    }
    onUpdateFilters(filters);
  };

  const searchValue = useSelector(selectTasksSearch(taskTable));
  const firstRender = useRef<boolean>(true);
  const [value, setValue] = useState<string>();
  const handleSearch = useCallback(
    async (value: string) => {
      setValue(value);
      onSearch(value);
    },
    [onSearch]
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (searchValue !== value) {
        setValue(searchValue);
      }
    }
  }, [value, searchValue, handleSearch]);

  return (
    <>
      <TasksFiltersSidePanel
        open={shouldDisplayFilterComponent}
        onClose={toggleFilterComponent}
        filters={appliedFilters}
        taskTable={taskTable}
        onUpdateFilters={handleUpdateFilters}
      />

      <TableOperationHeader
        className='border-b-0'
        setInputValue={handleSearch}
        inputValue={value ?? ''}
        actionButtons={
          <>
            <FilterButton
              onToggleFilter={toggleFilterComponent}
              aria-pressed={shouldDisplayFilterComponent}
              data-cy='taskTable__filter-button'
            />
            <RowDensityPopup
              onSelectRowDensity={selectRowDensity}
              disabled={isTableEmpty}
            />
          </>
        }
      />
      <AppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
      />
    </>
  );
};

export default TasksTableHeader;
