import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { InsightsProjectActiveFilter } from 'utils/customTypes';

class ProjectsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(`${config.get('insightsBackendURL')}projects`);
  }

  fetchProjectsByHealth = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/by-health', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchProjectsByPriority = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/by-priority', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchProjectsByProcess = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/by-process', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchProjectsByStatus = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/projects-by-status', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchProjectTasksByStatus = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/tasks-by-status', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchProjectAggregates = async (
    activeFilters: InsightsProjectActiveFilter
  ) => {
    const { data } = await this.instance.get('/aggregates', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchFilters = async () => {
    const { data } = await this.instance.get('/filters');
    return data;
  };
}

export default new ProjectsAPI();
