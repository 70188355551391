import { useState } from 'react';
import { ProjectVendor, TableRowDensity } from 'utils/customTypes';
import { Table, Typography } from '@getsynapse/design-system';
import { useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';
import VendorsTableHead from './VendorsTableHead';
import TableHeader from '../Teams/TableHeader/TableHeader';
import intl from 'react-intl-universal';
import moment from 'moment';
import { DATE, PATHS } from 'utils/constants';
import get from 'lodash/get';

type VendorsTableProps = {
  data: ProjectVendor[];
  handleSort: (orderByParam: string, order: 'desc' | 'asc') => void;
  handleSearch: (value: string) => void;
  isVisible: boolean;
};

const VendorsTable = ({
  data,
  handleSort,
  handleSearch,
  isVisible,
}: VendorsTableProps) => {
  const history = useHistory();
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');
  return (
    <>
      <TableHeader
        onSearch={handleSearch}
        shouldClearInput={!isVisible}
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
        canChangeRowDensity={data.length > 0}
        tableIdentifier='vendors-table'
      />
      <Table
        data-testid='vendors-table'
        className='max-h-table w-full relative'
        canSelectRows={false}
        rowDensity={rowDensity}
        bodyProps={{
          className: classnames('max-h-table-body overflow-y-auto', {
            block: data.length > 0,
          }),
        }}
        emptyComponent={
          <Typography
            variant='body2'
            className='flex justify-center items-center h-12 text-neutral'
          >
            {intl.get('VENDORS_PAGE.TABLE.EMPTY_STATE')}
          </Typography>
        }
        data={{
          headData: {
            handleSort,
            className: 'w-full block h-auto min-h-10',
            rowProps: {
              className: 'table w-full table-fixed h-auto min-h-10',
            },
            headCells: VendorsTableHead(),
          },
          rows: data.map((vendor) => {
            return {
              id: vendor.id,
              'data-cy': `vendor_row-${vendor.id}`,
              className: 'cursor-pointer table w-full table-fixed',
              onClick: (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                history.push(`${PATHS.VENDOR_PAGE}/${vendor.id}`);
              },
              cells: [
                {
                  content: (
                    <Link to={`${PATHS.VENDOR_PAGE}/${vendor.id}`}>
                      <Typography variant='body2' className='truncate'>
                        {vendor.vendorName}
                      </Typography>
                    </Link>
                  ),
                  className: 'leading-4 w-3/12',
                },
                {
                  content: (
                    <Typography
                      title={vendor.description}
                      variant='body2'
                      className='truncate max-w-lg leading-4'
                    >
                      {vendor.description}
                    </Typography>
                  ),
                  className: 'w-3/12',
                },
                {
                  content: intl.get(
                    `COUNTRIES.${vendor.country_iso_3166_1_alpha_2_code}`
                  ),
                  className: 'leading-4',
                },
                {
                  content: get(
                    vendor,
                    'data.province',
                    intl.get('VENDORS_PAGE.TABLE.NOT_ASSIGNED')
                  ),
                  className: 'leading-4',
                },
                {
                  content:
                    vendor.createdAt &&
                    moment(new Date(vendor.createdAt)).format(
                      DATE.SHORT_FORMAT
                    ),
                  className: 'leading-4',
                },
                {
                  content: (
                    <Typography variant='body2'>
                      {get(vendor, 'enabled')
                        ? intl.get('VENDORS_PAGE.TABLE.HEAD.ENABLED')
                        : intl.get('TASKS.TABLE.HEAD.DISABLED')}
                    </Typography>
                  ),
                },
              ],
            };
          }),
        }}
      />
    </>
  );
};

export default VendorsTable;
