import intl from 'react-intl-universal';
import {
  Typography,
  Datepicker,
  IconButton,
  GlobalBanner,
} from '@getsynapse/design-system';
import moment from 'moment';
import { get, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useMemo, useState, KeyboardEvent } from 'react';
import { TASK_STATUS, DATE } from 'utils/constants';
import { TaskActualHours, Task_Status, AvatarUser } from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import { selectUser } from 'state/User/userSlice';
import styles from './TaskActualHoursLog.module.css';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Divider from 'Atoms/Divider';
import { calculateActualHoursTotal } from '../../helpers/helpers';

type ChangeTaskDataFunctionType = (
  item: string,
  value: string | string[] | TaskActualHours[]
) => void;

type SetActualHoursStateType = React.Dispatch<
  React.SetStateAction<TaskActualHours[]>
>;

type TaskActualHoursLogProps = {
  currentTaskStatus: Task_Status;
  originalTaskStatus: Task_Status;
  isTaskDisabled: boolean;
  taskStartDate: string;
  canAddActualHours: boolean;
  canDeleteActualHours: boolean;
  taskActualHoursTemp: TaskActualHours[];
  setTaskActualHoursTemp: SetActualHoursStateType | ChangeTaskDataFunctionType;
  isOnSidepanel?: boolean;
};

const TaskActualHoursLog = ({
  currentTaskStatus,
  originalTaskStatus,
  isTaskDisabled,
  taskStartDate,
  canAddActualHours,
  canDeleteActualHours,
  taskActualHoursTemp,
  setTaskActualHoursTemp,
  isOnSidepanel = false,
}: TaskActualHoursLogProps) => {
  const currentUserSelector = useSelector(selectUser);

  const currentDate = useMemo(() => new Date(), []);

  const [newNumberOfHours, setNewNumberOfHours] = useState('');
  const [newActualHoursDate, setNewActualHoursDate] = useState(currentDate);

  const currentUser = {
    avatar_url: currentUserSelector.avatar_url,
    data: {
      firstName: currentUserSelector.firstName!,
      lastName: currentUserSelector.lastName!,
    },
  };

  const canUserDeleteActualHours = (actualHours: TaskActualHours) =>
    canDeleteActualHours ||
    (originalTaskStatus !== TASK_STATUS.COMPLETED &&
      currentTaskStatus !== TASK_STATUS.COMPLETED &&
      currentTaskStatus !== TASK_STATUS.ON_HOLD &&
      !isTaskDisabled &&
      actualHours.user_id === currentUserSelector.id);

  const removeActualHours = (id: string) => {
    const actualHoursAfterRemoving = taskActualHoursTemp!.filter(
      (actualHours) => actualHours.id !== id
    );
    if (isOnSidepanel) {
      (setTaskActualHoursTemp as ChangeTaskDataFunctionType)(
        'taskActualHours',
        actualHoursAfterRemoving
      );
      const actualHoursTotal = calculateActualHoursTotal(
        actualHoursAfterRemoving
      );
      (setTaskActualHoursTemp as ChangeTaskDataFunctionType)(
        'actual_hours',
        actualHoursTotal.toString()
      );
    } else {
      (setTaskActualHoursTemp as SetActualHoursStateType)(
        actualHoursAfterRemoving
      );
    }
  };

  const checkInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  const getDatepickerWidthClass = () => {
    if (taskActualHoursTemp?.length < 5 && !isOnSidepanel) {
      return 'w-39.2';
    } else if (!isOnSidepanel) {
      return 'w-38.4';
    } else {
      return 'w-37.6';
    }
  };

  const addNewHours = () => {
    const uuid = uuidv4();
    const newActualHours = {
      id: uuid,
      user_id: currentUserSelector?.id || '',
      number_of_hours: Number(newNumberOfHours),
      actual_hours_date: newActualHoursDate.toISOString(),
      actual_hours_creator: currentUser,
    };
    if (isOnSidepanel) {
      const updatedActualHours = orderBy(
        [...taskActualHoursTemp, newActualHours],
        'actual_hours_date',
        'asc'
      );
      (setTaskActualHoursTemp as ChangeTaskDataFunctionType)(
        'taskActualHours',
        updatedActualHours
      );
      const actualHoursTotal = calculateActualHoursTotal(updatedActualHours);
      (setTaskActualHoursTemp as ChangeTaskDataFunctionType)(
        'actual_hours',
        actualHoursTotal.toString()
      );
    } else {
      (setTaskActualHoursTemp as SetActualHoursStateType)((prevState) =>
        orderBy([...prevState!, newActualHours], 'actual_hours_date', 'asc')
      );
    }
    setNewNumberOfHours('');
    setNewActualHoursDate(currentDate);
  };

  return (
    <>
      {canAddActualHours && (
        <Typography className='mb-4'>
          {intl.get('TASK_ACTUAL_HOURS.CAPTION')}
        </Typography>
      )}
      {!canAddActualHours && taskActualHoursTemp?.length === 0 && (
        <Typography className='text-neutral'>
          {intl.get('TASK_ACTUAL_HOURS.NO_ACTUAL_HOURS_DATA')}
        </Typography>
      )}
      {!canAddActualHours &&
        canDeleteActualHours &&
        taskActualHoursTemp?.length === 1 && (
          <GlobalBanner variant='warning' className='mb-4'>
            {() => (
              <Typography>{intl.get('TASK_ACTUAL_HOURS.WARNING')}</Typography>
            )}
          </GlobalBanner>
        )}
      {taskActualHoursTemp?.map((entry, index) => (
        <div
          className='flex h-12 border-primary-lighter-two border rounded mb-1 items-center shadow-table-column-2'
          key={entry.id}
        >
          <Typography
            variant={`${entry.actual_hours_date ? 'body' : 'body2'}`}
            className={classNames(
              'bg-primary-lightest h-full py-4 pl-4 leading-4 rounded-l flex items-center',
              `${isOnSidepanel ? 'w-50' : 'w-40'}`
            )}
          >
            {entry.actual_hours_date
              ? moment(entry.actual_hours_date).format(DATE.SHORT_FORMAT)
              : intl.get('TASK_ACTUAL_HOURS.NO_DATE')}
          </Typography>
          <Typography
            className={classNames(
              'leading-4 py-4 pl-4 border-r border-neutral-lightest',
              `${isOnSidepanel ? 'w-50' : 'w-75.2'}`
            )}
          >
            {`${parseFloat(entry.number_of_hours.toString())}h`}
          </Typography>
          <div
            className={classNames(
              'flex py-4 pl-4',
              `${isOnSidepanel ? 'w-56	' : 'w-75.2'}`
            )}
          >
            {entry.user_id ? (
              <>
                <UserAvatar
                  user={get(entry, 'actual_hours_creator') as AvatarUser}
                />
                <span className='ml-2.5'>
                  {`${get(
                    entry,
                    'actual_hours_creator.data.firstName',
                    '-'
                  )} ${get(entry, 'actual_hours_creator.data.lastName')}`}
                </span>
              </>
            ) : (
              <Typography variant='body2'>
                {intl.get('TASK_ACTUAL_HOURS.NO_USER_DATA')}
              </Typography>
            )}
          </div>
          {canUserDeleteActualHours(entry) && (
            <div className='w-12 border-l h-full border-neutral-lightest px-2 py-3'>
              <IconButton
                name='trash'
                description={intl.get('REMOVE')}
                className='text-primary-dark'
                onClick={() => removeActualHours(entry.id!)}
                data-testid={`remove-actuals-icon-button-${index}`}
              />
            </div>
          )}
        </div>
      ))}
      {canAddActualHours && (
        <>
          {taskActualHoursTemp?.length > 0 && <Divider className='mt-2' />}
          <div className='flex h-12 border-primary-lighter-two border rounded mt-2 items-center shadow-table-column-2'>
            <div
              className={`bg-primary-lightest flex h-full items-center rounded-l ${getDatepickerWidthClass()}`}
            >
              <Datepicker
                triggerClassname='bg-primary-lightest border-0 h-full rounded-none'
                canEnterDateManually={false}
                startPlaceHolder={intl.get('TASK_ACTUAL_HOURS.TODAY')}
                onPickDate={(date: any) => {
                  setNewActualHoursDate(date.startDate);
                }}
                startDate={
                  newActualHoursDate === currentDate ? '' : newActualHoursDate
                }
                useCustomModifier={false}
                minDate={taskStartDate && new Date(taskStartDate)}
                maxDate={new Date()}
                data-cy='actuals-date-picker'
              />
            </div>
            <div
              className={classNames(
                'bg-neutral-white h-full flex items-center',
                `${isOnSidepanel ? 'w-38' : 'w-75.2'}`
              )}
            >
              <input
                className={`${styles.hoursInput} border-0 focus:ring-0`}
                type='number'
                placeholder='Add Hours'
                min={0}
                onChange={(event) => setNewNumberOfHours(event.target.value)}
                onKeyDown={checkInput}
                value={newNumberOfHours}
                data-testid='actuals-input'
              />
            </div>
            <div
              className={classNames(
                'flex items-center pl-4 border-l border-neutral-lightest h-full',
                `${isOnSidepanel ? 'w-56' : 'w-75.2'}`
              )}
            >
              <UserAvatar user={currentUser} />
              <span className='ml-2.5'>
                {`${get(currentUserSelector, 'firstName', '-')} ${get(
                  currentUserSelector,
                  'lastName'
                )}`}
              </span>
            </div>
            <div className='w-12 border-l h-full border-neutral-lightest px-2 py-3'>
              <IconButton
                name='checkmark-circle'
                description={intl.get('ADD')}
                onClick={addNewHours}
                disabled={!newNumberOfHours}
                color='positive'
                data-testid='add-actuals-icon-button'
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TaskActualHoursLog;
