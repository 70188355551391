import { Table, Typography, Icon } from '@getsynapse/design-system';
import classnames from 'classnames';
import importantIcon from 'assets/icons/important.svg';
import intl from 'react-intl-universal';

type MyWorspaceTableProps = {
  headCells: any;
  handleSort?: (orderByParam: string[], order: 'desc' | 'asc') => void;
  rows: any;
  cardTable?: boolean;
  dataCy?: string;
};

const MyWorspaceTable = ({
  headCells,
  handleSort = () => {},
  rows,
  cardTable = true,
  dataCy,
}: MyWorspaceTableProps) => (
  <Table
    data-cy={dataCy}
    className='w-full rounded-t'
    canSelectRows={false}
    bodyProps={{
      className: classnames(
        `${cardTable ? 'h-84 2xl:h-112' : 'h-112'} overflow-y-auto block`
      ),
    }}
    emptyComponent={
      <div className='h-80 2xl:h-108 w-144 2xl:w-192 flex justify-center items-center'>
        <Icon src={importantIcon} className='h-6 w-6 mr-2' />
        <Typography>{intl.get('MY_WORKSPACE.ERROR_STATE')}</Typography>
      </div>
    }
    data={{
      headData: {
        className: 'block rounded-t',
        rowProps: {
          className: 'table w-full table-fixed h-full',
        },
        handleSort,
        headCells,
      },
      rows,
    }}
  />
);

export default MyWorspaceTable;
