import React from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { ProjectLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { formatter } from 'utils/functions';
import { ParticipantTypes } from 'utils/constants';
import { Column } from '../../../Timeline/Timeline';
import TimeOffAllocation from '../TimeOffAllocation/TimeOffAllocation';

const Allocation: React.FC<{
  allocation: ProjectLevelAllocation;
  participantType: ParticipantTypes;
  isCurrentWeek: boolean;
  'data-testid'?: string;
  'data-cy'?: string;
}> = ({
  allocation,
  isCurrentWeek = false,
  participantType,
  ...otherProps
}) => {
  const allocatedHours = allocation.allocation;
  const displayAvailability =
    participantType !== ParticipantTypes.BUSINESS &&
    participantType !== ParticipantTypes.EXTERNAL;
  const availability = allocation.availability;
  const overallocated = displayAvailability && availability <= 0;
  const noAllocated = allocation.allocation === 0;
  const hasTimeOff = allocation.timeOff > 0;
  return (
    <Column isCurrentWeek={isCurrentWeek} {...otherProps}>
      <div
        className={tailwindOverride(
          'w-full h-6 flex justify-center items-center',
          'bg-success-lighter',
          {
            'bg-primary-lighter': noAllocated,
            'bg-error-lightest': overallocated,
            'mb-3': hasTimeOff,
          }
        )}
      >
        <Typography
          variant='label'
          className={tailwindOverride(
            'font-semibold mr-1.5',
            'text-success-darkest',
            {
              'text-primary-darker': noAllocated,
              'text-error-darker': overallocated,
            }
          )}
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
            allocation: `${formatter.format(allocatedHours)}`,
          })}
        </Typography>
        {displayAvailability && (
          <Typography
            variant='caption'
            className={tailwindOverride('text-success-darkest', {
              'text-primary-darker': noAllocated,
              'text-error-darker': overallocated,
            })}
          >
            {intl.get(
              'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_AVAILABILITY',
              {
                availability: `${formatter.format(availability)}h`,
              }
            )}
          </Typography>
        )}
      </div>
      {hasTimeOff && <TimeOffAllocation timeOff={allocation.timeOff} />}
    </Column>
  );
};

export default Allocation;
