import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  FormItem,
  Dropdown,
  Tooltip,
  tailwindOverride,
  Icon,
} from '@getsynapse/design-system';
import classnames from 'classnames';
import { Option } from 'utils/customTypes';
import {
  fetchTeamTasks,
  selectAreAllTasksClosed,
} from 'state/ProjectTasks/projectTaskSlice';

import { NEW_PROJECT_FORM_FIELDS, TASK_STATUS } from 'utils/constants';

const ProjectStatusPicker = ({
  hasError,
  fromSidePanel,
  isReadOnly,
  handleFormFieldChange,
  values,
  options,
  projectId,
}: {
  hasError: boolean;
  fromSidePanel: boolean;
  isReadOnly: boolean;
  handleFormFieldChange: (status: string, value: string) => void;
  values: Option[];
  options: Option[];
  projectId?: string;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectId) {
      dispatch(fetchTeamTasks(projectId));
    }
  }, [projectId, dispatch]);
  const areAllTasksClosed = useSelector(selectAreAllTasksClosed);

  return (
    <FormItem
      label={intl.get('PROJECT_DETAIL.STATUS')}
      helpText={hasError && intl.get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')}
      helpTextProps={{
        state: hasError ? 'error' : 'default',
      }}
      labelProps={{
        required: true,
        state: hasError ? 'error' : 'default',
      }}
      className={classnames({ 'mb-4': fromSidePanel })}
    >
      <Dropdown
        disabled={isReadOnly}
        placeholder={intl.get('PROJECT_DETAIL.STATUS_PLACEHOLDER')}
        state={hasError ? 'error' : 'default'}
        onChange={(option: Option) =>
          handleFormFieldChange(NEW_PROJECT_FORM_FIELDS.STATUS, option.value)
        }
        values={values}
        options={options}
        triggerProps={{ 'data-cy': 'project-status-input' }}
        renderOption={(
          option: Option,
          selected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => {
          const isCompletedOption = option.value === TASK_STATUS.COMPLETED;
          const disabledClass =
            !areAllTasksClosed && isCompletedOption
              ? 'text-neutral-lighter cursor-not-allowed'
              : '';
          const listItem = (
            <li
              {...otherProps}
              onClick={() => {
                if (!disabledClass) {
                  selectOption();
                }
              }}
              tabIndex={selected ? 0 : -1}
              className={tailwindOverride(
                'font-body text-base truncate group px-4 py-2 focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
                {
                  'cursor-pointer text-neutral-black hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
                    !disabledClass,
                  'text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white':
                    selected && !disabledClass,
                },
                disabledClass
              )}
            >
              {option.label}
            </li>
          );

          return disabledClass ? (
            <Tooltip
              className='w-full'
              showPopper
              usePortal
              openMode='hover1'
              timeout={0}
              trigger={listItem}
              position='bottomRight'
              contentProps={{
                className: 'z-200000 text-neutral-darker bg-neutral-white flex',
              }}
            >
              <Icon
                name='information-circle-outline'
                className='text-2xl text-neutral-darker'
              />
              <span className='ml-2'>
                {intl.get('PROJECT_DETAIL.COMPLETION_DISABLED')}
              </span>
            </Tooltip>
          ) : (
            listItem
          );
        }}
      />
    </FormItem>
  );
};

export default ProjectStatusPicker;
