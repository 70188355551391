import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import capitalize from 'lodash/capitalize';
import { Option, Project } from 'utils/customTypes';
import {
  REQUEST_STATUS,
  REQUEST_LD_PRIORITY,
  REQUEST_LINKED_PROJECTS_OPTIONS,
} from 'utils/constants';
import { RangeFilter, RequestFiltersKey } from 'utils/types/filters';
import {
  selectLDUsersForDropdown,
  selectAllUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { selectBusinessTeamsForDropdown } from 'state/BusinessTeams/businessTeamsSlice';
import { selectAllProjects } from 'state/Projects/projectsSlice';
import { selectFormsOptionsAlphabetically } from 'state/Forms/formSlice';
import moment from 'moment';

const useRequestFilters = () => {
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const usersList = useSelector(selectAllUsersForDropdown);
  const businessTeams = useSelector(selectBusinessTeamsForDropdown);
  const projects = useSelector(selectAllProjects);
  const forms = useSelector(selectFormsOptionsAlphabetically);
  const statusOptions = useMemo<Option[]>(() => {
    const options = Object.keys(REQUEST_STATUS).map((key) => ({
      value: REQUEST_STATUS[key as keyof typeof REQUEST_STATUS],
      label: intl.get(`REQUESTS_LIST_PAGE.TABLE.STATUS_LABEL.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);
  const priorityOptions = useMemo<Option[]>(() => {
    const options = Object.keys(REQUEST_LD_PRIORITY).map((key) => ({
      value: REQUEST_LD_PRIORITY[key as keyof typeof REQUEST_LD_PRIORITY],
      label: capitalize(key),
    }));
    return orderBy(options, 'label');
  }, []);
  const projectsOptions = useMemo<Option[]>(() => {
    const options = projects.map((project: Project) => ({
      label: project.title,
      value: project.id,
    }));
    return orderBy(options, 'label');
  }, [projects]);
  const linkedProjectsOptions = useMemo<Option[]>(() => {
    type keys = keyof typeof REQUEST_LINKED_PROJECTS_OPTIONS;
    return Object.keys(REQUEST_LINKED_PROJECTS_OPTIONS).map((key) => ({
      label: intl.get(
        `REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.PICKER_OPTIONS.${key}`
      ),
      value: REQUEST_LINKED_PROJECTS_OPTIONS[key as keys],
    }));
  }, []);

  const requestFiltersOptions: Record<
    RequestFiltersKey,
    Option[] | RangeFilter
  > = useMemo(
    () => ({
      status: statusOptions,
      requester_id: usersList,
      owners: ldUsers,
      requestProjects: projectsOptions,
      businessTeams: businessTeams,
      ldPriority: priorityOptions,
      form_id: forms,
      linkedProjects: linkedProjectsOptions,
      createdAt: { from: '', to: '' },
      submittedAt: { from: '', to: '' },
      decision_date: { from: '', to: '' },
      updatedAt: { from: '', to: '' },
    }),
    [
      statusOptions,
      priorityOptions,
      projectsOptions,
      forms,
      usersList,
      ldUsers,
      businessTeams,
      linkedProjectsOptions,
    ]
  );

  const getFilterOptionsByKey = useCallback(
    (key: RequestFiltersKey) => requestFiltersOptions[key] as Option[],
    [requestFiltersOptions]
  );

  const getDateFilterLabel = useCallback(
    (value: RangeFilter, dateFormat: string) => {
      let label = '';
      if (value.from) {
        label = `${moment(new Date(value.from)).format(dateFormat)}`;
      }
      if (value.from && value.to) {
        label = `${label} - ${moment(new Date(value.to)).format(dateFormat)}`;
      } else if (!value.from && value.to) {
        label = `${moment(new Date(value.to)).format(dateFormat)}`;
      }
      return label;
    },
    []
  );

  const getFilterOptionLabelByKeyAndValue = useCallback(
    (key: RequestFiltersKey, value: string) => {
      const options = requestFiltersOptions[key] as Option[];
      const option = options.find((option: Option) => option.value === value);
      return option ? option.label : '';
    },
    [requestFiltersOptions]
  );

  return {
    getFilterOptionsByKey,
    getFilterOptionLabelByKeyAndValue,
    getDateFilterLabel,
  };
};

export default useRequestFilters;
