import { Avatar } from '@getsynapse/design-system';
import get from 'lodash/get';
import { AvatarUser } from 'utils/customTypes';

interface Props {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  user?: AvatarUser;
}

const UserAvatar = ({ className, size, user }: Props) => {
  const getInitials = () => {
    const displayInitial = `${get(user, 'data.firstName[0]', '')}${get(
      user,
      'data.lastName[0]',
      ''
    )}`;
    if (!displayInitial) {
      return get(user, 'data.email[0]', '');
    }
    return displayInitial;
  };

  return (
    <Avatar
      className={className}
      imageSrc={get(user, 'avatar_url')}
      initial={getInitials()}
      size={size}
    />
  );
};

export default UserAvatar;
