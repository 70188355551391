import { useState, useRef, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import Auth from '@aws-amplify/auth';
import * as Cookies from 'js-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { shutdown } from 'Services/intercom';
import {
  useLink,
  Typography,
  TextField,
  Checkbox,
  Button,
} from '@getsynapse/design-system';
import { state } from '@getsynapse/design-system/dist/Molecules/Input/Input';
import classnames from 'classnames';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  getUser,
  resetUser,
  selectUser,
  selectUserSliceStatus,
  updateLoginData,
} from 'state/User/userSlice';
import { EMAIL_REGEX, PATHS, USER_STATUS } from 'utils/constants';
import usePortalSettings from 'Hooks/usePortalSettings';
import accountsAPI from 'Services/accountsAPI';
import config from 'config/Config';
import { checkUser } from '../../aws-exports';

interface InputType {
  state: state;
  helpText: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const userSliceStatus = useSelector(selectUserSliceStatus);
  const user = useSelector(selectUser);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const rememberChkRef = useRef<HTMLInputElement>(null);
  const [redirectUrl, setRedirectUrl] = useState<string>(PATHS.ROOT);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<InputType>({
    state: 'default',
    helpText: '',
  });
  const [password, setPassword] = useState<InputType>({
    state: 'default',
    helpText: '',
  });
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { intakePortalName, isFetching } = usePortalSettings(organizationId);

  const validateEnterpriseConnection = useCallback(
    async (accountId, redirectTo, connectionLink = '') => {
      let connectionUrl;
      if (connectionLink) {
        connectionUrl = connectionLink;
      } else {
        connectionUrl = await accountsAPI.getEnterpriseConnection(accountId);
      }

      if (connectionUrl) {
        Cookies.set('connectionUrl', connectionUrl);
        Cookies.set('ssoAccountId', accountId);
        if (redirectTo) {
          Cookies.set('redirectUrl', redirectTo);
        } else {
          Cookies.set('redirectUrl', PATHS.ROOT);
        }
        history.push(PATHS.SSO_AUTHENTICATION_PAGE);
      } else {
        dispatch(resetUser());
      }
    },
    [dispatch, history]
  );

  const checkIfActiveSession = useCallback(async () => {
    const response = await checkUser();
    if (!isEmpty(response)) {
      await dispatch(getUser(response.attributes.email));
    }
  }, [dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const shouldLogOut = query.get('logout');
    if (shouldLogOut) {
      Auth.signOut({ global: true });
      shutdown();
    } else {
      checkIfActiveSession();
    }
  }, [checkIfActiveSession, dispatch, location.search]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const redirectUrl = query.get('redirect') || '';
    const hash = location.hash || '';
    const redirectTo = `${redirectUrl}${hash}`;
    const accountId =
      query.get('accountId') || query.get('redirect')?.split('=')[1];

    if (accountId) {
      validateEnterpriseConnection(accountId, redirectTo);
    }
    if (redirectTo) {
      accountId
        ? setRedirectUrl(`${redirectTo}&accountId=${accountId}`)
        : setRedirectUrl(redirectTo);
    }
  }, [location.hash, location.search, validateEnterpriseConnection]);

  const validateEmail = (email: string) => {
    const isEmailValid = EMAIL_REGEX.test(email);

    return isEmailValid;
  };

  const resetInputStates = () => {
    setEmail({
      state: 'default',
      helpText: '',
    });
    setPassword({
      state: 'default',
      helpText: '',
    });
  };

  const changeButtonState = useCallback(() => {
    setButtonDisabled(
      !validateEmail(emailRef.current?.value!) ||
        !emailRef.current?.value ||
        !passwordRef.current?.value
    );
  }, []);

  useEffect(() => {
    changeButtonState();
  }, [changeButtonState]);

  const checkEmail = () => {
    setEmail({
      state: 'default',
      helpText: '',
    });
    changeButtonState();
  };

  const checkPassword = () => {
    setPassword({
      state: 'default',
      helpText: '',
    });
    changeButtonState();
  };

  const validateEmailInput = () => {
    checkEmail();
    let validInput = true;
    if (!emailRef.current!.value) {
      setEmail({
        state: 'error',
        helpText: intl.get('LOGIN_PAGE.INPUT.EMPTY_HELP_TEXT'),
      });
      validInput = false;
    } else {
      validInput = validateEmail(emailRef.current!.value);
      if (!validInput) {
        setEmail({
          state: 'error',
          helpText: intl.get('LOGIN_PAGE.INPUT.INVALID_HELP_TEXT'),
        });
      }
    }
  };

  const validatePasswordInput = () => {
    checkPassword();
    if (!passwordRef.current!.value) {
      setPassword({
        state: 'error',
        helpText: intl.get('LOGIN_PAGE.INPUT.EMPTY_HELP_TEXT'),
      });
    }
  };

  const createRemeberMeCookie = (userDataCookie: string) => {
    const cookieName = `${userDataCookie}.remember`;
    Cookies.set(cookieName, rememberChkRef.current!.checked.toString(), {
      expires: 365,
      path: '/',
    });
  };

  const getDomainFromEmail = (email: string) => {
    const length = (email.match(new RegExp('@', 'g')) || []).length;
    if (length === 1) {
      return email.split('@')[1];
    }
    return '';
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    validateEmailInput();
    validatePasswordInput();
    try {
      await Auth.signOut({ global: true });
      const user = await Auth.signIn(
        emailRef.current!.value,
        passwordRef.current!.value
      );
      createRemeberMeCookie(user.userDataKey);
      await dispatch(getUser(user.attributes.email));
    } catch (error: any) {
      const domain = getDomainFromEmail(emailRef.current!.value);
      const connectionData = await accountsAPI.getEnterpriseConnectionByDomain(
        domain
      );
      const connectionUrl = get(connectionData, 'connectionId');
      if (connectionUrl) {
        const query = new URLSearchParams(location.search);
        const redirectTo = query.get('redirect');
        const accountId = get(connectionData, 'accountId');
        validateEnterpriseConnection(accountId, redirectTo, connectionUrl);
      } else {
        dispatch(
          showNotificationBanner({
            notificationVariant: 'error',
            notificationText: intl.get(
              'LOGIN_PAGE.INPUT.INCORRECT_EMAIL_OR_PASSWORD'
            ),
            timeout: 7000,
          })
        );
        setLoading(false);
        resetInputStates();
      }
    }
  };

  useEffect(() => {
    const handleError = async () => {
      await Auth.signOut({ global: true });
      setLoading(false);
    };

    if (userSliceStatus === 'idle' && !isEmpty(user)) {
      if (password.state === 'error') {
        validateEnterpriseConnection(user.organization_id, redirectUrl);
      } else if (
        user.status === USER_STATUS.INVITED_DISABLED ||
        user.status === USER_STATUS.REGISTERED_DISABLED
      ) {
        history.push(PATHS.DEACTIVATED_ACCOUNT);
      } else {
        if (redirectUrl.includes(PATHS.ELEMENTS)) {
          window.location.href = `${config.get('designURL')}${redirectUrl}`;
        }
        history.push(redirectUrl);
        dispatch(updateLoginData());
      }
    } else if (userSliceStatus === 'failed') {
      handleError();
    }
  }, [
    userSliceStatus,
    user,
    redirectUrl,
    history,
    password.state,
    validateEnterpriseConnection,
    dispatch,
  ]);

  const portalText = !isFetching
    ? `${intl.get('LOGIN_PAGE.WELCOME_TO')} ${intakePortalName}`
    : '';

  return (
    <div className='min-w-74'>
      {organizationId ? (
        <Typography
          variant='h2'
          weight='medium'
          className={classnames(
            'text-neutral-black',
            'text-center w-80 h-16 mb-16'
          )}
          data-cy='business-portal-name'
        >
          {portalText}
        </Typography>
      ) : (
        <>
          <Typography
            variant='h2'
            weight='medium'
            className={classnames('text-neutral-black', 'text-center')}
          >
            {intl.get('LOGIN_PAGE.HEADING')}
          </Typography>
          <Typography
            variant='body2'
            className={classnames('text-neutral-dark', 'mb-16', 'text-center')}
          >
            {intl.get('LOGIN_PAGE.SLOGAN')}
          </Typography>
        </>
      )}

      <form onSubmit={handleLogin}>
        <TextField
          label={intl.get('LOGIN_PAGE.INPUT.EMAIL_INPUT.LABEL')}
          placeholder={intl.get('LOGIN_PAGE.INPUT.EMAIL_INPUT.PLACEHOLDER')}
          className='mb-6 w-74'
          ref={emailRef}
          state={email.state}
          helpText={email.helpText}
          onChange={checkEmail}
          onBlur={validateEmailInput}
          data-cy='login-input__email'
          id='login-input__email'
        />
        <TextField
          label={intl.get('LOGIN_PAGE.INPUT.PASSWORD_INPUT.LABEL')}
          variant='password'
          placeholder={intl.get('LOGIN_PAGE.INPUT.PASSWORD_INPUT.PLACEHOLDER')}
          ref={passwordRef}
          state={password.state}
          helpText={password.helpText}
          onChange={checkPassword}
          onBlur={validatePasswordInput}
          data-cy='login-input__password'
          id='login-input__password'
          className='w-74'
        />
        <div className={classnames('flex', 'mt-6', 'mb-10', 'justify-between')}>
          <Checkbox
            label={intl.get('LOGIN_PAGE.REMEMBER_ME')}
            inputProps={{ ref: rememberChkRef }}
            data-cy='login-input__remember-me'
          />
          <Link
            to={PATHS.FORGOT_PASSWORD}
            className={classnames('no-underline', useLink())}
            data-cy='forgot-password__link'
          >
            {intl.get('LOGIN_PAGE.FORGET_PASSWORD_LINK')}
          </Link>
        </div>
        <Button
          variant='primary'
          className={classnames('w-full', 'justify-center')}
          disabled={buttonDisabled}
          loading={loading}
          type='submit'
          data-cy='login-submit__button'
        >
          {intl.get('LOGIN_PAGE.BUTTON')}
        </Button>
      </form>
    </div>
  );
};

export default Login;
