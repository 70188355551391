import {
  createSlice,
  createAsyncThunk,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { Status } from 'utils/customTypes';
import {
  SLICE_STATUS,
  TEAMS_CAPACITY_TABLE_NUMBER_OF_TEAMS,
  TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS,
} from 'utils/constants';
import {
  TeamsCapacity,
  WeeksCarouselPagination,
  TeamsListPagination,
  ProjectCapacity,
} from 'utils/types/teamsCapacity';
import teamsCapacityApi from './teamsCapacityAPI';
import projectsAPI from 'state/Projects/projectsAPI';
import { getOriginalProjectData } from 'state/Project/projectSlice';
import {
  mapApiResponseToTeamsCapacity,
  addWeeklyCapacityToTeams,
  addTeamsToCurrentCapacity,
  whereToAddWeeklyCapacity,
  getNewWeeksCarouselPagination,
} from './helpers';
import { Week, getWeek } from 'Organisms/CapacityAllocationTable/helpers';
import { RootState } from 'state/store';

interface Capacity {
  value: TeamsCapacity;
  teamsListPagination: TeamsListPagination;
  weeksCarouselPagination: WeeksCarouselPagination;
  projectsIdsWithoutTasks: string[];
  status: Status;
}

const API = teamsCapacityApi;

/* ============================= INITIAL STATE ============================== */
const initialState: Capacity = {
  value: {},
  teamsListPagination: {
    offset: 0,
    limit: TEAMS_CAPACITY_TABLE_NUMBER_OF_TEAMS,
    total: 0,
  },
  weeksCarouselPagination: {
    startDate: '',
    endDate: '',
    index: TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS,
  },
  projectsIdsWithoutTasks: [],
  status: SLICE_STATUS.IDLE,
};

/* ============================== ACTIONS =============================== */
export const setWeeksCarouselPagination = createAction<
  Partial<WeeksCarouselPagination>
>('teamsCapacity/SET_WEEKS_CAROUSEL_PAGINATION');

const setTeamsListPagination = createAction<Partial<TeamsListPagination>>(
  'teamsCapacity/SET_TEAMS_LIST_PAGINATION'
);

const setProjectsIdsWithoutTasks = createAction<string[]>(
  'teamsCapacity/SET_PROJECTS_IDS_WITHOUT_TASKS'
);

/* ============================== REDUX THUNK =============================== */
export const fetchTeamsCapacity = createAsyncThunk(
  'teamsCapacity/FETCH_TEAMS_CAPACITY',
  async (
    params: {
      tableStartDate: string;
      tableEndDate: string;
      offset: number;
      limit: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await API.fetchTeamsCapacity(params);
      const projectsIdsWithoutTasks =
        await projectsAPI.getProjectsIdsWithoutTasks();
      const teamsCapacity = mapApiResponseToTeamsCapacity(
        response.teamCapacityTables,
        projectsIdsWithoutTasks
      );
      dispatch(
        setWeeksCarouselPagination({
          startDate: params.tableStartDate,
          endDate: params.tableEndDate,
        })
      );
      dispatch(
        setTeamsListPagination({
          total: response.viewableTeamsCount,
        })
      );
      dispatch(setProjectsIdsWithoutTasks(projectsIdsWithoutTasks));
      return teamsCapacity;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMoreWeeklyCapacity = createAsyncThunk(
  'teamsCapacity/FETCH_MORE_WEEKLY_CAPACITY',
  async (
    params: WeeksCarouselPagination,
    { rejectWithValue, dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const teamsListPagination = state.teamsCapacity.teamsListPagination;
    const currentTeamsCapacity = state.teamsCapacity.value;
    const currentPagination = state.teamsCapacity.weeksCarouselPagination;
    const addCapacityAt = whereToAddWeeklyCapacity(currentPagination, params);
    try {
      const response = await API.fetchTeamsCapacity({
        tableStartDate: params.startDate,
        tableEndDate: params.endDate,
        offset: teamsListPagination.offset,
        limit: teamsListPagination.limit,
      });
      const newTeamsCapacity = addWeeklyCapacityToTeams(
        response.teamCapacityTables,
        currentTeamsCapacity,
        addCapacityAt
      );
      const newPagination: WeeksCarouselPagination = {
        startDate:
          addCapacityAt === 'start'
            ? params.startDate
            : currentPagination.startDate,
        endDate:
          addCapacityAt === 'end' ? params.endDate : currentPagination.endDate,
        index: params.index,
      };
      dispatch(setWeeksCarouselPagination(newPagination));
      return newTeamsCapacity;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMoreTeams = createAsyncThunk(
  'teamsCapacity/FETCH_MORE_TEAMS',
  async (params, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const teamsListPagination = state.teamsCapacity.teamsListPagination;
    const weeksCarouselPagination = state.teamsCapacity.weeksCarouselPagination;
    const currentTeamsCapacity = state.teamsCapacity.value;
    const projectsIdsWithoutTasks = state.teamsCapacity.projectsIdsWithoutTasks;
    try {
      const newWeeksCarouselPagination = getNewWeeksCarouselPagination(
        weeksCarouselPagination
      );
      const newTeamsListPagination = {
        ...teamsListPagination,
        limit: teamsListPagination.limit + TEAMS_CAPACITY_TABLE_NUMBER_OF_TEAMS,
      };
      const response = await API.fetchTeamsCapacity({
        tableEndDate: newWeeksCarouselPagination.endDate,
        tableStartDate: newWeeksCarouselPagination.startDate,
        offset: newTeamsListPagination.offset,
        limit: newTeamsListPagination.limit,
      });
      const teamsCapacity = addTeamsToCurrentCapacity(
        response.teamCapacityTables,
        currentTeamsCapacity,
        projectsIdsWithoutTasks
      );
      dispatch(setWeeksCarouselPagination(newWeeksCarouselPagination));
      dispatch(setTeamsListPagination(newTeamsListPagination));
      return teamsCapacity;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setUpdatedProject = createAsyncThunk(
  'teamsCapacity/SET_UPDATED_PROJECT',
  (params, { getState }) => {
    const state = getState() as RootState;
    const currentProject = getOriginalProjectData(state);
    return currentProject;
  }
);

/* =============================== SELECTORS ================================ */
export const selectTeamsCapacity = (state: RootState) =>
  state.teamsCapacity.value;
export const fetchTeamsCapacityStatus = (state: RootState) =>
  state.teamsCapacity.status;
export const selectWeeksCarouselPagination = (state: RootState) =>
  state.teamsCapacity.weeksCarouselPagination;
export const selectWeeksCarouselItems = createSelector(
  [
    (state: RootState) => state.teamsCapacity.weeksCarouselPagination.startDate,
    (state: RootState) => state.teamsCapacity.weeksCarouselPagination.endDate,
  ],
  (startDate, endDate) => {
    if (!startDate || !endDate) {
      return [];
    }
    const momentStartDate = moment(new Date(startDate.replace(/-/g, '/')));
    const momentEndDate = moment(new Date(endDate.replace(/-/g, '/')));
    const weeksArray: Week[] = [];
    let pivotWeek = momentStartDate;
    while (pivotWeek.isBefore(momentEndDate)) {
      const week = getWeek(pivotWeek);
      weeksArray.push(week);
      pivotWeek = pivotWeek.add(7, 'days');
    }
    return weeksArray;
  }
);
export const selectTeamsPagination = (state: any) =>
  state.teamsCapacity.teamsListPagination;

/* ================================= REDUCER ================================ */
const teamsCapacitySlice = createSlice({
  name: 'teamsCapacity',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamsCapacity.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamsCapacity.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          state.value = action.payload;
        }
      })
      .addCase(fetchTeamsCapacity.rejected, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(setWeeksCarouselPagination, (state, action) => {
        state.weeksCarouselPagination = {
          ...state.weeksCarouselPagination,
          ...action.payload,
        };
      })
      .addCase(setTeamsListPagination, (state, action) => {
        state.teamsListPagination = {
          ...state.teamsListPagination,
          ...action.payload,
        };
      })
      .addCase(setProjectsIdsWithoutTasks, (state, action) => {
        state.projectsIdsWithoutTasks = action.payload;
      })
      .addCase(fetchMoreWeeklyCapacity.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchMoreWeeklyCapacity.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          state.value = action.payload;
        }
      })
      .addCase(fetchMoreWeeklyCapacity.rejected, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchMoreTeams.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchMoreTeams.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          state.value = action.payload;
        }
      })
      .addCase(fetchMoreTeams.rejected, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(setUpdatedProject.fulfilled, (state, action) => {
        const currentProject = {
          id: action.payload.id,
          title: action.payload.title,
          priority: action.payload.priority,
          status: action.payload.status,
          health: action.payload.health,
          startDate: action.payload.startDate,
          targetCompletionDate: action.payload.targetCompletionDate,
        };
        for (const team in state.value) {
          for (const member in state.value[team].members) {
            if (
              currentProject.id in state.value[team].members[member].projects
            ) {
              state.value[team].members[member].projects[
                currentProject.id
              ].data = {
                ...state.value[team].members[member].projects[currentProject.id]
                  .data,
                ...currentProject,
              } as ProjectCapacity['data'];
            }
          }
        }
      });
  },
});

export default teamsCapacitySlice.reducer;
