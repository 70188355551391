import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import has from 'lodash/has';
import set from 'lodash/set';
import get from 'lodash/get';
import { AllUsersType, LDUser } from 'utils/customTypes';

class UsersManagementAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchUsers = async (params?: { type?: string }) => {
    const { data } = await this.instance.get('user/orgUsers', { params });
    return data;
  };

  fetchUser = async (userId: string) => {
    const { data } = await this.instance.get(`user/userId/${userId}`);
    return data;
  };

  updateUser = async (userId: string, updateFields: Partial<AllUsersType>) => {
    if (has(updateFields, 'data.email')) {
      set(
        updateFields,
        'data.email',
        updateFields.data?.email.toLowerCase().trim()
      );
    }
    const { data } = await this.instance.put(`user/${userId}`, {
      updateFields,
    });
    return data;
  };

  assignUserToLearningTeam = async (userId: string, teamId: string[]) => {
    const { data } = await this.instance.post('learningTeam/updateUser', {
      userId,
      teamId,
    });
    return data;
  };

  createUser = async (userData: Partial<AllUsersType>) => {
    if (has(userData, 'data.email')) {
      set(userData, 'data.email', userData.data?.email.toLowerCase().trim());
    }
    const { data } = await this.instance.post('user', userData);
    return data;
  };

  createBusinessUser = async (userData: Partial<AllUsersType>) => {
    if (has(userData, 'data.email')) {
      set(userData, 'data.email', userData.data?.email.toLowerCase().trim());
    }
    const response = await this.instance.post(
      'user/createBusinessUser',
      userData
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  createOrganizationWithFirstUser = async (userData: Partial<AllUsersType>) => {
    if (has(userData, 'data.email')) {
      set(userData, 'data.email', userData.data?.email.toLowerCase().trim());
    }
    const response = await this.instance.post('user/createFirstUser', userData);
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  inviteUser = async (
    userId: string,
    email: string,
    firstName: string,
    companyName: string,
    selfRegistration: boolean
  ) => {
    const { data } = await this.instance.post('user/inviteUser', {
      userId,
      email,
      firstName,
      companyName,
      selfRegistration,
    });
    return data;
  };

  bulkCreateUsers = async (newUsers: Partial<LDUser>[]) => {
    const { data } = await this.instance.post('user/bulkCreate', { newUsers });
    return data;
  };

  fetchBusinessReviewers = async (requestId: string) => {
    const { data } = await this.instance.get(
      `request/${requestId}/retrievePossibleReviewersFromBusinessTeam`
    );
    return data;
  };

  fetchLDReviewers = async (requestId: string) => {
    const { data } = await this.instance.get(
      `request/${requestId}/retrievePossibleReviewersFromLearningTeams`
    );
    return data;
  };
}
export default new UsersManagementAPI();
