import React from 'react';
import intl from 'react-intl-universal';
import { GlobalBanner, Typography } from '@getsynapse/design-system';

const LicenseBanner: React.FC<{}> = () => {
  return (
    <GlobalBanner variant='warning' className='mt-4' hasCloseIcon>
      {() => (
        <div className='flex justify-between -ml-2.5'>
          <Typography variant='body' className='text-warning-darker'>
            {intl.getHTML('LICENSE.LICENSE_INACTIVE')}
          </Typography>
        </div>
      )}
    </GlobalBanner>
  );
};

export default LicenseBanner;
