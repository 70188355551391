import React, { useState, useMemo, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  TASKS_TABLE_TABS,
  PROJECT_USER_ACTIONS,
  SLICE_STATUS,
} from 'utils/constants';
import { ProjectTasksTableTab, TableRowDensity } from 'utils/customTypes';
import { TaskFilters } from 'utils/types/filters';
import {
  selectFiltersSettingsByType,
  createFilterSetting,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import {
  setFilters,
  setSearchParam,
  setShowReorder,
  exportTasksStatus,
  selectTableSearchParam,
} from 'state/ProjectTasks/projectTaskSlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import ExportIcon from 'assets/icons/export.svg';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import ReorderToggle from 'Organisms/TableHeaderActionButtons/ReorderToggle';
import TasksFiltersSidePanel from './TasksFiltersSidePanel/TasksFiltersSidePanel';
import AppliedFiltersTags from './TasksFiltersSidePanel/AppliedFiltersTags';
import ImportTasksModal from './ImportTasksModal/ImportTasksModal';
import ImportIcon from 'assets/icons/import.svg';
import useImportTasks from './ImportTasks/useImporTasks';

const TasksTableFilters: React.FC<{
  taskTable: ProjectTasksTableTab;
  onExport: (callback: () => void) => void;
  exportEnabled?: Boolean;
  filterButtonEnabled?: Boolean;
  selectRowDensity: (rowDensity: TableRowDensity) => void;
  totalTasks?: number;
}> = ({
  taskTable,
  onExport,
  exportEnabled = false,
  filterButtonEnabled = false,
  selectRowDensity,
  totalTasks = 0,
}) => {
  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState(false);
  const { canUser } = useUserPermissionsContext();
  const dispatch = useDispatch();
  const exportStatus = useSelector(exportTasksStatus);
  const filtersSettings = useSelector(selectFiltersSettingsByType(taskTable));
  const searchParam = useSelector(selectTableSearchParam(taskTable));
  const appliedFilters = useMemo(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );
  const { disabled: isImportButtonDisable, ...importTasksButtonProps } =
    useImportTasks();

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setFilters(appliedFilters, taskTable));
    }
  }, [appliedFilters, taskTable, dispatch]);

  const tableActionsList: Partial<OptionProps>[] = [
    { label: intl.get('IMPORT'), isSectionTitle: true },
    {
      label: intl.get('TASKS.FROM_BUNDLE'),
      selectOption: () => setDisplayImportTaskModal(true),
      iconSrc: ImportIcon,
      disabled:
        isEmpty(importTasksButtonProps.currentUser) ||
        isEmpty(importTasksButtonProps.settings) ||
        isImportButtonDisable,
      dataCy: 'import-tasks-from-bundle_button',
    },
    {
      label: intl.get('TASKS.FROM_FILE'),
      isImportButton: true,
      disabled:
        isEmpty(importTasksButtonProps.currentUser) ||
        isEmpty(importTasksButtonProps.settings) ||
        isImportButtonDisable,
      importButtonProps: importTasksButtonProps,
    },
    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('TASKS.TABLE.EXPORT'),
      selectOption: onExport,
      disabled: !exportEnabled,
      tooltip: {
        text: !exportEnabled ? intl.get('TASKS.TABLE.EXPORT_TOOLTIP') : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'table-header__export-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
  ];

  const [displayImportTasktModal, setDisplayImportTaskModal] =
    useState<boolean>(false);

  const [searchValue, setSearchValue] = useState('');

  const [showReorderButton, setShowReorderButton] = useState(false);

  useEffect(() => {
    if (searchParam !== searchValue) {
      setSearchValue(searchParam);
    }
  }, [searchParam, searchValue]);

  const canReorder: boolean = useMemo(() => {
    const isTeamTasks: boolean = taskTable === TASKS_TABLE_TABS.TEAM_TASKS;
    const canUserReorder: boolean = canUser(PROJECT_USER_ACTIONS.REORDER_TASKS);

    return isTeamTasks && canUserReorder;
  }, [canUser, taskTable]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const searchParam = value.toLowerCase();
    dispatch(setSearchParam(searchParam, taskTable));
  };

  const handleReorderButtonClick = () => {
    setShowReorderButton(!showReorderButton);
    dispatch(setShowReorder(!showReorderButton, taskTable));
  };

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prev) => !prev);

  const handleUpdateFilters = (filters: TaskFilters) => {
    dispatch(setFilters(filters, taskTable));
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: taskTable,
          filter_settings: filters,
        })
      );
    }
  };

  return (
    <>
      <TasksFiltersSidePanel
        open={shouldDisplayFilterComponent}
        onClose={toggleFilterComponent}
        filters={appliedFilters}
        taskTable={taskTable}
        onUpdateFilters={handleUpdateFilters}
      />
      <ImportTasksModal
        showModal={displayImportTasktModal}
        onClose={() => setDisplayImportTaskModal(false)}
      />

      <TableOperationHeader
        className='border-0 rounded-b-none bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearch}
        searchProps={{
          disabled: showReorderButton,
        }}
        actionButtons={
          <>
            <RowDensityPopup
              onSelectRowDensity={selectRowDensity}
              disabled={totalTasks === 0}
              tableTestId={taskTable}
            />
            {taskTable === TASKS_TABLE_TABS.TEAM_TASKS && (
              <ReorderToggle
                disabled={
                  !filterButtonEnabled ||
                  shouldDisplayFilterComponent ||
                  searchValue ||
                  !isEmpty(appliedFilters) ||
                  !canReorder
                }
                isChecked={showReorderButton}
                onClick={handleReorderButtonClick}
              />
            )}
            <FilterButton
              onToggleFilter={toggleFilterComponent}
              disabled={showReorderButton || !filterButtonEnabled}
              aria-pressed={shouldDisplayFilterComponent}
              data-cy={`${taskTable}__filter-button`}
            />
            {PROJECT_USER_ACTIONS.IMPORT_TASKS && (
              <MoreActionsPopup options={tableActionsList} />
            )}
          </>
        }
      />
      <AppliedFiltersTags
        taskTable={taskTable}
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
      />
    </>
  );
};

export default TasksTableFilters;
