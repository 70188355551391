import { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { Task } from 'utils/customTypes';
import { useParams } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import {
  createNewTask,
  duplicateTask,
} from 'state/ProjectTasks/projectTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { get, set } from 'lodash';
import TaskForm from './TaskForm';
import { taskModalFields } from '../../helpers/constants';

const TaskModal: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  taskData: Task;
  duplicateTaskCheck: boolean;
}> = ({ isOpen, setIsOpen, taskData, duplicateTaskCheck }) => {
  const { Modal, modalProps } = useModal();
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const [canCreateTask, setCanCreateTask] = useState(false);
  const [taskFields, setTaskFields] = useState(taskModalFields);

  const addTask = async () => {
    let newTaskData = { ...taskFields };
    if (duplicateTaskCheck) {
      if (!newTaskData.start_date) {
        set(newTaskData, 'start_date', null);
      }
      if (!newTaskData.due_date) {
        set(newTaskData, 'due_date', null);
      }
      const duplicatedTaskData = await dispatch(
        duplicateTask({ taskId: taskData.id, newTaskData })
      );
      const duplicatedTaskId = get(duplicatedTaskData, 'payload.id', '');
      if (duplicatedTaskId != null) {
        await dispatch(
          showNotificationBanner({
            notificationVariant: 'success',
            notificationText: intl.get(
              'TASKS.NOTIFICATIONS.TASK_DUPLICATION_SUCCESS'
            ),
          })
        );
      }
    } else {
      if (!newTaskData.start_date) {
        set(newTaskData, 'start_date', null);
      }
      if (!newTaskData.due_date) {
        set(newTaskData, 'due_date', null);
      }
      await dispatch(createNewTask(newTaskData));
      await dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'TASKS.NOTIFICATIONS.TASK_CREATION_SUCCESS'
          ),
        })
      );
    }
    setIsOpen(false);
  };

  return (
    <Fragment>
      <Modal
        {...modalProps}
        aria-label={intl.get('TASKS.ADD_TASK_MODAL.TITLE')}
        closeModal={() => setIsOpen(false)}
        data-cy='add-tasks-modal'
        title={intl.get('TASKS.ADD_TASK_MODAL.TITLE')}
        size='large'
        className='h-full'
        isOpen={isOpen}
        actionButtons={[
          {
            children: intl.get('TEAMS.SAVE'),
            variant: 'primary',
            'data-cy': 'confirm-button',
            disabled: !canCreateTask,
            onClick: addTask,
          },
          {
            children: intl.get('PROJECT_DETAIL.DELETE_PROJECT.CANCEL'),
            variant: 'tertiary',
            onClick: () => setIsOpen(false),
            'data-cy': 'cancel-button',
          },
        ]}
      >
        <TaskForm
          taskData={taskData}
          canCreateTask={canCreateTask}
          duplicateTaskCheck={duplicateTaskCheck}
          projectId={projectId}
          setCanCreateTask={setCanCreateTask}
          setTaskFields={setTaskFields}
        />
      </Modal>
    </Fragment>
  );
};

export default TaskModal;
