import React, { useState, useEffect } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { ProjectFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';

export type Props = {
  open: boolean;
  onUpdateFilters: (filters: ProjectFilters) => void;
  onClose: () => void;
  filters?: ProjectFilters;
  isBoardView?: boolean;
};

const ProjectsFiltersSidePanel: React.FC<Props> = ({
  open,
  filters = {},
  onUpdateFilters,
  onClose,
  isBoardView = false,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<ProjectFilters>(
    {} as ProjectFilters
  );
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters as ProjectFilters);
    }
  }, [filters]);

  const handleUpdateFilters = (updatedFilters: ProjectFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({} as ProjectFilters);
    setAppliedFilters({} as ProjectFilters);
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='projects-filters-side-panel'
    >
      <FiltersForm
        filters={appliedFilters as ProjectFilters}
        onUpdateFilters={handleUpdateFilters}
        isBoardView={isBoardView}
      />
    </SidePanelFilter>
  );
};

export default ProjectsFiltersSidePanel;
