import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TabPanels, TabPanel, Tabs } from '@reach/tabs';
import { Tab, TabList } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { INSIGHTS_TABS } from 'utils/constants';
import Intake from './Intake/Intake';
import Projects from './Projects/Projects';

const InsightsPage = () => {
  const { activeTab } = useParams<{ activeTab: string }>();
  const tabMapping = useMemo(() => {
    return {
      [INSIGHTS_TABS.INTAKE]: 0,
      [INSIGHTS_TABS.PROJECTS]: 1,
    };
  }, []);

  const tabToString: any = {
    0: INSIGHTS_TABS.INTAKE,
    1: INSIGHTS_TABS.PROJECTS,
  };

  const [activeIndex, setActiveIndex] = useState(
    activeTab ? tabMapping[activeTab] : 0
  );

  const handleTabChange = (index: number) => {
    setActiveIndex(index);

    const tabKey = tabToString[index];
    history.push(`${location.pathname}?tab=${tabKey}`);
  };

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const defaultTabString = queryParams.get('tab') as 'Intake' | 'Project';

  useEffect(() => {
    if (defaultTabString) {
      setActiveIndex(tabMapping[defaultTabString]);
    }
  }, [defaultTabString, tabMapping]);

  return (
    <Tabs
      index={activeIndex || 0}
      className='flex flex-col'
      onChange={handleTabChange}
    >
      <PageTitle
        titleComponent={intl.get('INSIGHTS_PAGE.TITLE')}
        headerChildren={
          <div className='flex'>
            <TabList type='subHeader'>
              <Tab index={0} type='subHeader'>
                {intl.get('INSIGHTS_PAGE_TABS.INTAKE')}
              </Tab>

              <Tab index={1} type='subHeader'>
                {intl.get('INSIGHTS_PAGE_TABS.PROJECTS')}
              </Tab>
            </TabList>
          </div>
        }
      />

      <TabPanels className='px-6 relative'>
        <TabPanel key={0} className='overflow-y-hidden'>
          <Intake />
        </TabPanel>

        <TabPanel key={1}>
          <Projects />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default InsightsPage;
