import {
  TaskTemplate,
  TaskTemplateForCreateOrUpdate,
  Status,
  TaskBundle,
} from 'utils/customTypes';
import ActiveTaskBundleAPI from './activeTaskBundleApi';
import { RootState } from 'state/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';

interface ActiveTaskBundleState {
  status: Status;
  bundle: {
    status: Status;
    data: TaskBundle;
  };
  activeBundleTemplates: TaskTemplate[];
}

/* ============================= INITIAL STATE ============================== */
const initialState: ActiveTaskBundleState = {
  status: SLICE_STATUS.IDLE,
  bundle: {
    status: SLICE_STATUS.IDLE,
    data: {} as TaskBundle,
  },
  activeBundleTemplates: [],
};

const activeBundleAPI = ActiveTaskBundleAPI;

/* ============================== REDUX THUNK =============================== */
export const fetchCurrentBundle = createAsyncThunk(
  'activeBundle/FETCH_CURRENT_BUNDLE',
  async (bundleId: string) => {
    const response = await activeBundleAPI.fetchTaskBundle(bundleId);
    return response.data;
  }
);

export const updateBundle = createAsyncThunk(
  'activeBundle/UPDATE_BUNDLE',
  async (updatedBundle: TaskBundle) => {
    const response = await activeBundleAPI.updateTaskBundle(updatedBundle);
    return response.data;
  }
);

export const bulkCreateOrUpdateTemplates = createAsyncThunk(
  'activeBundle/BULK_CREATE_TEMPLATES',
  async (params: {
    templates: TaskTemplateForCreateOrUpdate[];
    bundleId: string;
    creatorIsNotified?: boolean;
  }) => {
    const response = await activeBundleAPI.bulkCreateOrUpdateTemplatesForBundle(
      params.templates,
      params.bundleId,
      params.creatorIsNotified
    );
    return response.data;
  }
);

export const fetchTaskTemplatesForBundle = createAsyncThunk(
  'activeBundle/FETCH_TASK_TEMPLATES_FOR_BUNDLE',
  async (bundleId: string) => {
    const response = await activeBundleAPI.fetchTaskTemplatesForBundle(
      bundleId
    );
    return response.data;
  }
);

export const bulkDeleteTaskTemplatesForBundle = createAsyncThunk(
  'activeBundle/BULK_DELETE_TASK_TEMPLATES_FOR_BUNDLE',
  async (params: { templatesToDelete: string[]; bundleId: string }) => {
    const response = await activeBundleAPI.bulkDeleteTaskTemplatesForBundle(
      params.templatesToDelete,
      params.bundleId
    );
    return response.data;
  }
);

/* ============================= ACTIONS ============================== */

/* ================================= REDUCER ================================ */
const activeBundleSlice = createSlice({
  name: 'activeBundle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bulkCreateOrUpdateTemplates.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(bulkCreateOrUpdateTemplates.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(bulkCreateOrUpdateTemplates.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchCurrentBundle.fulfilled, (state, action) => {
        state.bundle.status = SLICE_STATUS.IDLE;
        state.bundle.data = action.payload.taskBundle;
      })
      .addCase(fetchCurrentBundle.pending, (state) => {
        state.bundle.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchCurrentBundle.rejected, (state) => {
        state.bundle.status = SLICE_STATUS.FAILED;
      })
      .addCase(updateBundle.fulfilled, (state, action) => {
        state.bundle.data = action.payload.taskBundle;
        state.bundle.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTaskTemplatesForBundle.fulfilled, (state, action) => {
        state.activeBundleTemplates = action.payload.taskTemplates;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTaskTemplatesForBundle.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTaskTemplatesForBundle.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(bulkDeleteTaskTemplatesForBundle.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(bulkDeleteTaskTemplatesForBundle.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(bulkDeleteTaskTemplatesForBundle.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectActiveBundle = (state: RootState) =>
  state.activeTaskBundle.bundle.data;

export const selectActiveBundleStatus = (state: RootState) =>
  state.activeTaskBundle.bundle.status;

export const selectActiveBundleTemplates = (state: RootState) =>
  state.activeTaskBundle.activeBundleTemplates;

export default activeBundleSlice.reducer;
