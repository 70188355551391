import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class UserSettingsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getUserSettings = async () => {
    const { data } = await this.instance.get(`user/userSettings`);
    return data;
  };

  updatePublicDraftSettings = async (requestId?: string) => {
    const { data } = await this.instance.put(
      `user/userSettings/toggleDraftPublicSetting`,
      {
        updateFields: { requestId },
      }
    );
    return data;
  };
}

export default new UserSettingsAPI();
