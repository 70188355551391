import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { FlatfileSettings, FlatfileResults } from '@flatfile/react';
import {
  REQUEST_IMPORT_ATTRIBUTES,
  REQUEST_TYPE,
  REQUEST_LD_PRIORITY,
  REQUEST_STATUS,
} from 'utils/constants';
import { Request, Option } from 'utils/customTypes';

export const flatfileSettings: (
  forms: Option[],
  owners: Option[],
  users: Option[],
  businessTeams: Option[]
) => FlatfileSettings = (forms, owners, users, businessTeams) => ({
  type: 'Requests',
  title: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.IMPORT_FROM_FILE'),
  fields: [
    {
      label: intl.get('SETTINGS_PAGE.FORMS.REQUEST_TYPE'),
      key: REQUEST_IMPORT_ATTRIBUTES.REQUEST_TYPE,
      validators: [{ validate: 'required' }],
      type: 'select',
      options: orderBy(
        REQUEST_TYPE.map((type) => ({
          value: type,
          label: intl.get(`REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.${type}`),
        })),
        (type) => {
          const label = get(type, 'label') || '';
          return label.toLocaleLowerCase();
        },
        'asc'
      ),
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.CREATOR'),
      key: REQUEST_IMPORT_ATTRIBUTES.CREATOR,
      type: 'select',
      options: users,
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.BASE_FORM'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.BASE_FORM,
      type: 'select',
      options: forms,
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.TITLE'),
      key: REQUEST_IMPORT_ATTRIBUTES.TITLE,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.DESCRIPTION'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.DESCRIPTION,
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.OWNERS'),
      key: REQUEST_IMPORT_ATTRIBUTES.OWNERS,
      type: 'select',
      options: owners,
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.BUSINESS_TEAM'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.BUSINESS_TEAM,
      type: 'select',
      options: businessTeams,
    },
    {
      label: intl.get('REQUEST_PAGE.L_D_SECTION.FIELDS.EFFORT'),
      key: REQUEST_IMPORT_ATTRIBUTES.EFFORT,
    },
    {
      label: intl.get('REQUEST_PAGE.L_D_SECTION.FIELDS.COST'),
      key: REQUEST_IMPORT_ATTRIBUTES.COST,
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.LD_PRIORITY'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.LD_PRIORITY,
      type: 'select',
      options: Object.keys(REQUEST_LD_PRIORITY).map((priority) => ({
        value: priority.toLocaleLowerCase(),
        label: intl.get(
          `REQUEST_PAGE.L_D_SECTION.PRIORITY_OPTIONS.${
            REQUEST_LD_PRIORITY[priority as keyof typeof REQUEST_LD_PRIORITY]
          }`
        ),
      })),
    },
    {
      label: intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_STATUS'),
      key: REQUEST_IMPORT_ATTRIBUTES.STATUS,
      type: 'select',
      options: Object.keys(REQUEST_STATUS).map((status) => ({
        value: status.toLocaleLowerCase(),
        label: intl.get(`REQUESTS_LIST_PAGE.TABLE.STATUS_LABEL.${status}`),
      })),
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'),
      key: REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT,
    },
  ],
});

export const defaultRequest: Partial<Request> = {
  request_type: 'OTHERS',
  requestCreator: '',
  data: { baseFormId: '' },
  title: '',
  description: '',
  owners: [],
  businessTeams: [],
  effort: '',
  cost: '',
  ldPriority: 'unassigned',
  organization_id: '',
  status: REQUEST_STATUS.DRAFT,
};

export const cleanImportedRequests = async (
  result: FlatfileResults,
  organizationId: string,
  baseFormId: string,
  businessUnit: { id: string; title: string }
) => {
  const requestData = result.validData;

  if (isEmpty(requestData)) {
    return [];
  }
  const cleanRequestData = requestData.map((request: Partial<Request>) => {
    let cleanRequest: Partial<Request> = { ...defaultRequest };

    for (const key of Object.keys(cleanRequest)) {
      if (key === 'data') {
        cleanRequest = {
          ...cleanRequest,
          data: {
            ...request.data,
            baseFormId: get(request, 'baseFormId') || '',
          },
        };
      } else {
        cleanRequest = { ...cleanRequest, [key]: get(request, key) || '' };
      }
    }
    if (!get(cleanRequest, 'organization_id')) {
      cleanRequest = {
        ...cleanRequest,
        organization_id: organizationId,
      };
    }
    if (!get(cleanRequest, 'data.baseFormId')) {
      cleanRequest = {
        ...cleanRequest,
        data: { ...cleanRequest.data, baseFormId },
      };
    }
    if (!get(cleanRequest, 'businessTeams')) {
      cleanRequest = {
        ...cleanRequest,
        businessTeams: [businessUnit],
      };
    }
    if (has(request, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT)) {
      const submittedAt = get(request, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT);
      if (!isEmpty(submittedAt) && moment(submittedAt).isValid()) {
        cleanRequest = {
          ...cleanRequest,
          submittedAt: new Date(submittedAt).toISOString(),
        };
      }
    }
    if (!get(cleanRequest, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT)) {
      cleanRequest = {
        ...cleanRequest,
        submittedAt: new Date().toISOString(),
      };
    }
    cleanRequest = {
      ...cleanRequest,
      type: 'request',
    };
    return cleanRequest;
  });

  return cleanRequestData;
};
