import intl from 'react-intl-universal';
import { MentionType } from 'utils/customTypes';
import projectMentionIcon from 'assets/icons/project-mention.svg';
import requestMentionIcon from 'assets/icons/request-mention.svg';
import designMentionIcon from 'assets/icons/design-mention.svg';
import { Icon, Typography } from '@getsynapse/design-system';
import { MENTIONS_TYPES } from 'utils/constants';
import { MentionsInput, Mention } from 'react-mentions';
import styles from './MentionsList.module.css';
import importantIcon from 'assets/icons/important.svg';

type MentionsListProps = {
  data: MentionType[];
  cardList: boolean;
};

const MentionsList = ({ data, cardList }: MentionsListProps) => (
  <>
    {data.length ? (
      <div data-cy='recent-mentions-list'>
        {data.map((mention, index) => {
          const iconSrc =
            mention.type === MENTIONS_TYPES.DESIGN
              ? designMentionIcon
              : mention.type === MENTIONS_TYPES.PROJECT
              ? projectMentionIcon
              : requestMentionIcon;

          return (
            <a
              key={mention.id}
              data-cy={mention.id}
              href={mention.link}
              className={`mb-1 block pb-5 pl-8 pr-4 ${
                index !== 0 ? 'pt-3' : 'pt-1'
              }`}
            >
              <div className='flex mb-1'>
                <Icon src={iconSrc} className='w-6 h-6 mr-2' />
                <Typography variant='body2'>
                  {`${mention.authorName} ${intl.get(
                    'MY_WORKSPACE.RECENT_MENTIONS.IN'
                  )} `}
                  <span className='font-semibold'>{`${mention.title}:`}</span>
                </Typography>
              </div>
              <MentionsInput
                value={`“${mention.content}”`}
                className={`${
                  cardList ? styles.cardMention : styles.modalMention
                } ml-8 mb-4 flex max-w-full text-sm`}
                disabled
              >
                <Mention
                  trigger='@'
                  data={[]}
                  className='text-purple-darker'
                  displayTransform={(_, display) => `@${display}`}
                />
              </MentionsInput>
              <Typography className='ml-8' variant='body2'>
                {mention.createdAt}
              </Typography>
            </a>
          );
        })}
      </div>
    ) : (
      <div className='h-80 2xl:h-108 w-144 2xl:w-192 flex justify-center items-center'>
        <Icon src={importantIcon} className='h-6 w-6 mr-2' />
        <Typography>{intl.get('MY_WORKSPACE.ERROR_STATE')}</Typography>
      </div>
    )}
  </>
);

export default MentionsList;
