import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { tailwindOverride } from '@getsynapse/design-system';
import { selectWeeksCarouselItems } from 'state/TeamsCapacity/teamsCapacitySlice';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import {
  CapacityCarousel,
  CapacityCarouselItem,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityCarousel/CapacityCarousel';
import WeeklyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/TeamRow/components/WeeklyCapacity/WeeklyCapacity';
import WeeklyTimeOff from './WeeklyTimeOff';
import NoDefaultCapacityBanner from './NoDefaultCapacityBanner';
import { WeeksCarouselLoader } from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/SkeletonLoader';

const TeamMemberCapacity: React.FC<{
  teamId: string;
  memberId: string;
  defaultCapacity: number;
  capacity: Record<string, { allocation: number; totalWeeklyTimeOff?: number }>;
  className?: string;
}> = ({ teamId, memberId, capacity, defaultCapacity, className }) => {
  const weeksCaourselItems = useSelector(selectWeeksCarouselItems);
  const hasUserCapacityAssigned = defaultCapacity > 0;
  return (
    <CapacityCarousel
      id={`team-${teamId}__member-${memberId}`}
      className={className}
      emptyComponent={
        !hasUserCapacityAssigned ? (
          <NoDefaultCapacityBanner
            userId={memberId}
            aria-label={`team-${teamId}__member-${memberId}__no-default-capacity`}
          />
        ) : null
      }
      loader={
        <WeeksCarouselLoader
          className={tailwindOverride(
            'w-full',
            'absolute top-0 left-0',
            className,
            'z-10'
          )}
        />
      }
    >
      {weeksCaourselItems.map((week: Week, index: number) => {
        const weekKey = moment(week.startWeekDate).format('YYYY-MM-DD');
        const allocation = capacity[weekKey]?.allocation || 0;
        const weeklyTimeOff = capacity[weekKey]?.totalWeeklyTimeOff || 0;
        return (
          <CapacityCarouselItem
            id={`team-${teamId}__member-${memberId}__weekly-capacity__week-${weekKey}`}
            key={`team-${teamId}__member-${memberId}__weekly-capacity__week-${weekKey}`}
            index={index}
            isCurrentWeek={week.isCurrentWeek || false}
            className='py-3.5 justify-self-stretch'
          >
            {hasUserCapacityAssigned && (
              <WeeklyCapacity
                weeklyCapacity={defaultCapacity}
                weeklyTimeOff={weeklyTimeOff}
                assignedHours={allocation}
              />
            )}
            {weeklyTimeOff > 0 && <WeeklyTimeOff timeOff={weeklyTimeOff} />}
          </CapacityCarouselItem>
        );
      })}
    </CapacityCarousel>
  );
};

export default TeamMemberCapacity;
