import { useState, useMemo, useCallback, useEffect } from 'react';
import { Dropdown, Typography, useElevation } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import PageSlider from 'Molecules/PageSlider';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';
import {
  PageSizeOption,
  PageSizeType,
  PageSliderOption,
} from 'utils/types/pagination';

type PaginationProps = {
  total: number;
  onChange: (params: object) => void;
  className?: string;
  page?: number;
  size?: PageSizeType;
};

const pageSizeOptions: PageSizeOption[] = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
];

const ResultsRange: React.FC<{
  limit: number;
  total: number;
  offset: number;
}> = ({ limit, total, offset }) => {
  const rangeStart = offset + 1;
  const rangeEnd = limit < total ? limit : total;
  return (
    <div className='h-full flex items-center'>
      <Typography
        variant='body'
        data-cy={`total_results-${total}`}
        className='text-neutral-black'
      >
        {intl.get('PAGINATION.RESULTS', {
          range:
            rangeEnd > 0 && rangeEnd > rangeStart
              ? `${rangeStart}-${rangeEnd}`
              : rangeEnd,
          total: total,
        })}
      </Typography>
    </div>
  );
};

const ServerPagination = ({
  total,
  onChange,
  className,
  page,
  size,
}: PaginationProps) => {
  const skim = useElevation(1);
  const [pageSize, setPageSize] = useState<PageSizeOption>({
    value: DEFAULT_PAGE_SIZE,
    label: DEFAULT_PAGE_SIZE.toString(),
  });
  const [currentPage, setCurrentPage] = useState<PageSliderOption>({
    value: 1,
    label: '1',
  });

  useEffect(() => {
    if (page) {
      setCurrentPage({ value: page, label: page.toString() });
    }
  }, [page]);

  useEffect(() => {
    if (size) {
      setCurrentPage({ value: 1, label: '1' });
      setPageSize({ value: size, label: size.toString() });
    }
  }, [size]);

  const pageCount = useMemo<number>(
    () => Math.ceil(total / pageSize.value),
    [pageSize, total]
  );

  const handleChangePageSize = useCallback(
    (option: PageSizeOption) => {
      setCurrentPage({ value: 1, label: '1' });
      setPageSize(option);
      onChange({
        limit: option.value,
        offset: DEFAULT_OFFSET,
      });
    },
    [onChange]
  );

  const handleChangePage = useCallback(
    (value: number) => {
      setCurrentPage({ value, label: value.toString() });
      onChange({
        limit: pageSize.value,
        offset: pageSize.value * (value - 1),
      });
    },
    [onChange, pageSize.value]
  );

  return (
    <div
      data-cy='pagination-container'
      className={classnames(
        'absolute bottom-0 left-0 py-3 z-10',
        'px-6 bg-neutral-white w-full',
        'flex items-center justify-between',
        skim,
        className
      )}
    >
      <ResultsRange
        total={total}
        limit={pageSize.value * currentPage.value}
        offset={pageSize.value * (currentPage.value - 1)}
      />
      <div className='flex h-full items-center'>
        <Typography variant='body' className='mr-2 text-neutral-black'>
          {intl.get('PAGINATION.SHOW')}
        </Typography>
        <div className='min-w-14'>
          <Dropdown
            options={pageSizeOptions}
            values={[pageSize]}
            onChange={handleChangePageSize}
            triggerProps={{
              size: 'sm',
              'data-cy': 'pagination__show-picker',
              className: 'border-transparent w-14 text-base',
            }}
            listProps={{ 'data-cy': 'pagination__page-size-options' }}
            disabled={total === 0}
          />
        </div>
        <PageSlider
          total={total}
          pages={pageCount}
          offset={currentPage.value}
          limit={pageSize.value}
          setOffset={handleChangePage}
        />
      </div>
    </div>
  );
};

export default ServerPagination;
