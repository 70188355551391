import { Tag } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { REQUEST_STATUS } from 'utils/constants';

export const getRequestSatus = (
  status: string | null | undefined,
  isForm?: boolean
) => {
  const mapTagColor = {
    [REQUEST_STATUS.DRAFT]: {
      className: 'bg-purple-lightest group-hover:border-purple-dark',
      textClassName: 'text-purple-darker',
    },
    [REQUEST_STATUS.SUBMITTED]: {
      className: 'bg-teal-lightest group-hover:border-teal',
      textClassName: 'text-teal-dark',
    },
    [REQUEST_STATUS.APPROVED]: {
      className: 'bg-success-lightest group-hover:border-success-dark',
      textClassName: 'text-success-darker',
    },
    [REQUEST_STATUS.REJECTED]: {
      className: 'bg-fire-lighter group-hover:border-fire-dark',
      textClassName: 'text-fire-darker',
    },
    [REQUEST_STATUS.CANCELED]: {
      className: 'bg-error-lightest group-hover:border-error',
      textClassName: 'text-error-darker',
    },
    [REQUEST_STATUS.PENDING_CANCELLATION]: {
      className: 'bg-tertiary-lightest group-hover:border-tertiary',
      textClassName: 'text-tertiary-darker',
    },
    [REQUEST_STATUS.WAITLISTED]: {
      className: 'bg-warning-lightest group-hover:border-warning-light',
      textClassName: 'text-warning-darker',
    },
  };
  const tagProps = status && mapTagColor[status];

  return (
    status &&
    tagProps && (
      <Tag
        label={
          isForm
            ? intl.get('REQUEST_PAGE.TOP_BAR.FORM')
            : intl.get(
                `REQUESTS_LIST_PAGE.TABLE.STATUS_LABEL.${status.toUpperCase()}`
              )
        }
        className={`border border-transparent py-0.5 ${tagProps.className}`}
        textClassName={tagProps.textClassName}
      />
    )
  );
};
