import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FlatfileResults } from '@flatfile/react';
import { selectFormsOptions } from 'state/Forms/formSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  getBusinessTeams,
  selectBusinessTeamsForDropdown,
} from 'state/BusinessTeams/businessTeamsSlice';
import { selectUser } from 'state/User/userSlice';
import { SelectOrganisationId } from 'state/Organization/organizationSlice';
import {
  getLDUsers,
  selectLDUsersForDropdown,
  getAllUsers,
  selectAllUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { importRequests } from 'state/Requests/requestSlice';
import { flatfileSettings, cleanImportedRequests } from '../flatfileHelper';

const useImportRequests = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const organizationId = useSelector(SelectOrganisationId);
  const forms = useSelector(selectFormsOptions);
  const businessTeams = useSelector(selectBusinessTeamsForDropdown);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const users = useSelector(selectAllUsersForDropdown);

  useEffect(() => {
    dispatch(getBusinessTeams());
    dispatch(getLDUsers());
    dispatch(getAllUsers());
  }, [dispatch]);

  const settings = useMemo(
    () => flatfileSettings(forms, ldUsers, users, businessTeams),
    [businessTeams, forms, ldUsers, users]
  );

  const isReady = useMemo(
    () =>
      !isEmpty(forms) &&
      !isEmpty(businessTeams) &&
      !isEmpty(ldUsers) &&
      !isEmpty(users),
    [businessTeams, forms, ldUsers, users]
  );

  const cleanUploadedDataAndCreate = async (result: FlatfileResults) => {
    const cleanRequests = await cleanImportedRequests(
      result,
      organizationId,
      get(forms, '[0].value') || '',
      get(businessTeams, '[0]') || ''
    );
    dispatch(
      importRequests({
        importData: cleanRequests,
        userId: currentUser.id || '',
      })
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.SUCCESS'),
      })
    );
  };

  return {
    currentUser,
    settings,
    isReady,
    onData: cleanUploadedDataAndCreate,
  };
};

export default useImportRequests;
