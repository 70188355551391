import moment from 'moment';
import { DATE } from './constants';

export type RawFilters = Record<
  string,
  string[] | { from?: string; to?: string }
>;

type BaseFilters = Record<string, string[] | string>;

export const parseFiltersForBackend: (
  rawFilters?: RawFilters
) => BaseFilters = (rawFilters) => {
  const filters: BaseFilters = {};

  if (rawFilters) {
    for (const property in rawFilters) {
      const rawFilterValue = rawFilters[property];
      if (Array.isArray(rawFilterValue)) {
        filters[property] = (rawFilterValue as string[]).join(',');
      } else if (rawFilterValue.from || rawFilterValue.to) {
        if (rawFilterValue.from) {
          const propertyName = `${property}_from`;
          if (isNaN(rawFilterValue.from as any)) {
            const parsedDate = moment(new Date(rawFilterValue.from));
            filters[propertyName] = parsedDate.format(DATE.DATE_STAMP);
          } else {
            filters[propertyName] = rawFilterValue.from;
          }
        }
        if (rawFilterValue.to) {
          const propertyName = `${property}_to`;
          if (isNaN(rawFilterValue.to as any)) {
            const parsedDate = moment(new Date(rawFilterValue.to));
            filters[propertyName] = parsedDate.format(DATE.DATE_STAMP);
          } else {
            filters[propertyName] = rawFilterValue.to;
          }
        }
      }
    }
  }

  return filters;
};
