import { useEffect, useCallback, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs } from '@getsynapse/design-system';
import { PATHS, TEAMS_PAGE_TABS } from 'utils/constants';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import Pagination from 'Organisms/Pagination/ClientPagination';
import {
  getLDMembers,
  selectLearningTeamsForTable,
  updateLDTeamsPagination,
  setLDTeamsFilters,
  setLearningTeamMemberIdToUpdate,
} from 'state/Teams/teamsSlice';
import TeamsTable from './components/TeamsTable';
import TeamsCapacity from './views/TeamsCapacity/TeamsCapacity';
import CapacityPage from './components/CapacityPage/CapacityPage';

const TeamsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const { activeTab } = useParams<{ activeTab: string }>();
  const learningTeamMemberIdToUpdate = queryParams.get('user');
  const learningTeams = useSelector(selectLearningTeamsForTable);
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getLDMembers());
    return () => {
      dispatch(setLDTeamsFilters([]));
    };
  }, [dispatch]);

  useEffect(() => {
    let tabToSwitch = 0;
    if (activeTab === TEAMS_PAGE_TABS.TEAMS) {
      tabToSwitch = 1;
    }
    if (learningTeamMemberIdToUpdate) {
      dispatch(setLearningTeamMemberIdToUpdate(learningTeamMemberIdToUpdate));
    }
    setCurrentTab(tabToSwitch);
  }, [learningTeamMemberIdToUpdate, activeTab, dispatch]);

  const setPagination = useCallback(
    (params) => {
      dispatch(updateLDTeamsPagination(params));
    },
    [dispatch]
  );

  const handleChangeTab = (index: number) => {
    setCurrentTab(index);
    let activeTab = TEAMS_PAGE_TABS.CAPACITY;
    if (index === 1) {
      activeTab = TEAMS_PAGE_TABS.TEAMS;
    }
    history.push(`${PATHS.TEAMS}/${activeTab}`);
  };

  return (
    <div className='flex flex-col h-full'>
      <PageTitle titleComponent={intl.get('SIDEBAR.TEAMS')} />
      <div className='mb-16 px-6 flex-grow overflow-y-auto'>
        <Tabs
          index={currentTab}
          onChange={handleChangeTab}
          tabListProps={{ className: 'max-w-sm mb-4' }}
          type='tab'
          data={[
            {
              label: intl.get('TEAMS.CAPACITY'),
              content: tasksAssignmentsBasedResourcesCapacity ? (
                <TeamsCapacity />
              ) : (
                <CapacityPage />
              ),
            },
            {
              label: intl.get('TEAMS.TEAM'),
              content: (
                <Fragment>
                  <TeamsTable
                    learningTeamsRows={learningTeams.data}
                    allTeamsMembers={learningTeams.all}
                  />
                  <Pagination
                    total={learningTeams.total}
                    onChange={setPagination}
                  />
                </Fragment>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TeamsPage;
