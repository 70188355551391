import { useHistory, Link } from 'react-router-dom';
import MyWorkspaceTable from '../MyWorkspaceTable';
import intl from 'react-intl-universal';
import { Tooltip } from '@getsynapse/design-system';
import { PATHS, DATE } from 'utils/constants';
import get from 'lodash/get';
import { Project } from 'utils/customTypes';
import moment from 'moment';
import {
  getStatusColumn,
  getStatusPill,
  getPriorityLabel,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

type ProjectsTableProps = {
  data: Project[];
  handleSort: (orderByParam: string[], order: 'desc' | 'asc') => void;
  cardTable: boolean;
};
const ProjectsTable = ({ data, handleSort, cardTable }: ProjectsTableProps) => {
  const history = useHistory();

  return (
    <MyWorkspaceTable
      cardTable={cardTable}
      dataCy='due-projects-table'
      headCells={[
        {
          content: intl.get('MY_WORKSPACE.DUE_PROJECTS.TABLE.HEAD_CELLS.TITLE'),
          columnName: 'title',
        },
        ...(!cardTable
          ? [
              {
                content: intl.get(
                  'MY_WORKSPACE.DUE_PROJECTS.TABLE.HEAD_CELLS.MY_TASKS'
                ),
                className: 'w-24',
                columnName: 'projectTasks.length',
              },
            ]
          : []),
        {
          content: intl.get(
            'MY_WORKSPACE.DUE_PROJECTS.TABLE.HEAD_CELLS.STATUS'
          ),
          columnName: 'status',
          className: `${cardTable ? 'w-20' : 'w-28'}`,
        },
        {
          content: intl.get(
            'MY_WORKSPACE.DUE_PROJECTS.TABLE.HEAD_CELLS.PRIORITY'
          ),
          columnName: 'priority',
          className: 'w-28',
        },
        {
          content: intl.get(
            'MY_WORKSPACE.DUE_PROJECTS.TABLE.HEAD_CELLS.COMPLETION_DATE'
          ),
          columnName: 'targetCompletionDate',
          className: 'w-36',
        },
      ]}
      rows={data.map((project) => {
        const targetCompletionDateWithoutTime = moment(
          new Date(project.targetCompletionDate!)
        ).format('L');
        const currentDate = moment(new Date());
        const currentTime = currentDate.format('HH:mm:ssZ');
        const momentTargetCompletionDate = moment(
          `${targetCompletionDateWithoutTime} ${currentTime}`
        );
        let targetCompletionDateFromNow = momentTargetCompletionDate.fromNow();
        if (
          targetCompletionDateFromNow.includes('hour') ||
          targetCompletionDateFromNow.includes('minute') ||
          targetCompletionDateFromNow.includes('second')
        ) {
          targetCompletionDateFromNow = intl.get(
            'MY_WORKSPACE.DUE_TASKS.TABLE.TODAY'
          );
        }
        const targetCompletionDate = cardTable
          ? targetCompletionDateFromNow
          : momentTargetCompletionDate.format(DATE.SHORT_FORMAT);
        const dDiff = momentTargetCompletionDate.diff(moment(), 'days');
        const daysBetween =
          currentDate.date() - momentTargetCompletionDate.date();
        let targetCompletionDateStyle = '';
        if (dDiff < 0) {
          targetCompletionDateStyle = 'text-error-darker';
          if (!cardTable) {
            targetCompletionDateStyle =
              targetCompletionDateStyle + ' bg-error-lightest';
          }
        } else if (dDiff === 0 && daysBetween === 0) {
          targetCompletionDateStyle = 'text-warning-darker';
        }
        const targetCompletionDateComponent = (
          <span className={targetCompletionDateStyle}>
            {targetCompletionDate.charAt(0).toUpperCase() +
              targetCompletionDate.slice(1)}
          </span>
        );

        return {
          id: project.id,
          className: 'table w-full table-fixed cursor-pointer h-14',
          onClick: () => history.push(`${PATHS.PROJECT_PAGE}/${project.id}`),
          cells: [
            {
              content: (
                <Link
                  to={`${PATHS.PROJECT_PAGE}/${project.id}`}
                  title={project.title}
                >
                  {project.title}
                </Link>
              ),
              className: 'truncate',
            },
            ...(!cardTable
              ? [
                  {
                    content: get(project, 'projectTasks.length'),
                    className: 'w-24 text-right',
                  },
                ]
              : []),
            {
              content: cardTable
                ? getStatusPill(project.status)
                : getStatusColumn(project.status),
              className: `${cardTable ? 'w-20' : 'w-28'}`,
            },
            {
              content: getPriorityLabel(project.priority),
              className: 'w-28',
            },
            {
              content: cardTable ? (
                targetCompletionDateComponent
              ) : (
                <Tooltip
                  trigger={targetCompletionDateComponent}
                  showPopper
                  openMode='hover1'
                  timeout={0}
                  contentProps={{ className: 'z-200000 py-1 px-3' }}
                >
                  {targetCompletionDateFromNow.charAt(0).toUpperCase() +
                    targetCompletionDateFromNow.slice(1)}
                </Tooltip>
              ),
              className: 'w-36',
            },
          ],
        };
      })}
      handleSort={handleSort}
    />
  );
};

export default ProjectsTable;
