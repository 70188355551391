import React, { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { TextField, Dropdown, Button } from '@getsynapse/design-system';
import { ProjectProcess, ProjectsBoardTabs, Option } from 'utils/customTypes';
import { ProjectFilters } from 'utils/types/filters';
import { BOARD_SORTING_OPTIONS } from 'utils/constants';
import { selectProjectProcesses } from 'state/Processes/processesSlice';
import {
  setBoardProcess,
  setBoardSorting,
  setBoardSearchParam,
  selectAssociatedProcessesAndStages,
  setBoardFilters,
} from 'state/Projects/projectsSlice';
import {
  selectFiltersSettingsByType,
  createFilterSetting,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import ProjectsFiltersSidePanel from './ProjectsFiltersSidePanel/ProjectsFiltersSidePanel';
import AppliedFiltersTags from './ProjectsFiltersSidePanel/AppliedFiltersTags';

type ProcessOption = { enabled: boolean } & Option;

const BoardHeader: React.FC<{
  boardTab: ProjectsBoardTabs;
  selectedProcessId: string;
  searchParam: string;
}> = ({ selectedProcessId, boardTab, searchParam }) => {
  const dispatch = useDispatch();
  const organizationProjectProcesses = useSelector(selectProjectProcesses);
  const processesAssociatedToProjects = useSelector(
    selectAssociatedProcessesAndStages
  );
  const filtersSettings = useSelector(selectFiltersSettingsByType(boardTab));
  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as ProjectFilters)
        : ({} as ProjectFilters),
    [filtersSettings]
  );

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setBoardFilters(appliedFilters, boardTab));
    }
  }, [appliedFilters, boardTab, dispatch]);

  const [shouldDisplayFilters, setShouldDisplayFilters] =
    useState<boolean>(false);

  const availableProcesses = useMemo(() => {
    const processes = [];
    for (const process of organizationProjectProcesses) {
      const isProcessDisabled = process.enabled !== null && !process.enabled;
      const isProcessAssociated = process.id in processesAssociatedToProjects;
      if (!isProcessDisabled || isProcessAssociated) {
        processes.push(process);
      }
    }
    return processes;
  }, [organizationProjectProcesses, processesAssociatedToProjects]);

  const processesOptions = useMemo(() => {
    return availableProcesses.map((process: ProjectProcess) => ({
      value: process.id,
      label: `${intl.get('PROJECTS_LIST_PAGE.BOARD.VIEW_BY')} ${
        process.processName
      }`,
      enabled: process.enabled !== null ? process.enabled : true,
    }));
  }, [availableProcesses]);

  const selectedProcess = useMemo(() => {
    return processesOptions.filter(
      (option: Option) => option.value === selectedProcessId
    );
  }, [processesOptions, selectedProcessId]);

  const onChangeProcess = (option: Option) => {
    dispatch(setBoardProcess(option.value, boardTab));
  };

  const sortingOptions = useMemo(() => {
    return Object.keys(BOARD_SORTING_OPTIONS).map((key) => ({
      value: BOARD_SORTING_OPTIONS[key],
      label: intl.get(`PROJECTS_LIST_PAGE.BOARD.SORTING.${key}`),
    }));
  }, []);

  const handleChangeSorting = (option: Option) => {
    dispatch(setBoardSorting(option.value, boardTab));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBoardSearchParam(event.target.value, boardTab));
  };

  const handleSetFilters = (filters: ProjectFilters) => {
    dispatch(setBoardFilters(filters, boardTab));
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({ filter_type: boardTab, filter_settings: filters })
      );
    }
  };

  const toggleFilters = () => {
    setShouldDisplayFilters((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ProjectsFiltersSidePanel
        open={shouldDisplayFilters}
        filters={appliedFilters}
        onClose={toggleFilters}
        onUpdateFilters={handleSetFilters}
        isBoardView
      />
      <div className='flex absolute top-0 right-0 mr-26'>
        <div className='ml-2'>
          <Dropdown
            options={processesOptions}
            values={selectedProcess}
            onChange={onChangeProcess}
            triggerProps={{
              placeholder: intl.get('PROJECTS_LIST_PAGE.BOARD.VIEW_BY'),
              className: 'w-50',
              size: 'sm',
              'data-cy': 'board-process-picker',
            }}
            renderOption={(
              option: ProcessOption,
              isSelected: boolean,
              selectOption,
              { className, ...otherProps }
            ) => (
              <li
                {...otherProps}
                className={classnames(className, {
                  'bg-neutral-black text-neutral-white': isSelected,
                })}
                onClick={() => selectOption()}
              >
                <div className='w-full flex justify-between'>
                  <span className='truncate flex-1'>{option.label}</span>
                  {!option.enabled && (
                    <span>{`(${intl
                      .get('TASKS.TABLE.HEAD.DISABLED')
                      .toLowerCase()})`}</span>
                  )}
                </div>
              </li>
            )}
          />
        </div>
        <TextField
          placeholder={intl.get('PROJECTS_LIST_PAGE.BOARD.SEARCH')}
          variant='search'
          height='small'
          className='w-61 ml-2'
          inputClassName='h-8.5'
          value={searchParam}
          onChange={handleSearchChange}
          data-cy='board-search-input'
        />
        <div className='ml-2'>
          <Dropdown
            options={sortingOptions}
            onChange={handleChangeSorting}
            triggerProps={{
              placeholder: intl.get('PROJECTS_LIST_PAGE.BOARD.SORT_BY'),
              className: 'w-47',
              size: 'sm',
              'data-cy': 'board-sorting-picker',
            }}
          />
        </div>
        <Button
          iconName='filter-circle'
          iconPosition='left'
          size='small'
          variant='tertiary'
          className={classnames('ml-2 py-0', {
            'bg-neutral-white': shouldDisplayFilters,
          })}
          onClick={toggleFilters}
          data-cy='board-filter-button'
        >
          {intl.get('PROJECTS_LIST_PAGE.BOARD.FILTER')}
        </Button>
      </div>
      <AppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleSetFilters}
        onDisplayAllFilters={toggleFilters}
        className='border-0 px-0'
      />
    </React.Fragment>
  );
};

export default BoardHeader;
