import React from 'react';
import moment from 'moment';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import {
  useElevation,
  tailwindOverride,
  Typography,
  Tooltip,
  IconButton,
} from '@getsynapse/design-system';
import config from 'config/Config';
import { ProjectContent, ProjectContentType } from 'utils/customTypes';
import { PROJECT_CONTENT_TYPE, DATE } from 'utils/constants';
import {
  getContentTypeLabel,
  getContentFileName,
  getThumbnailBackgroundImage,
} from 'Pages/ProjectPage/tabs/Content/components/ContentTable/utils';
import UnlinkContent from 'assets/images/unlink-content.svg';

const Thumbnail: React.FC<{
  cloudFrontUrl?: string;
  backupUrl?: string;
  contentType: ProjectContentType;
  mimeType: string;
  className?: string;
  canUpdateContent?: boolean;
  onUnlinkFile: () => void;
}> = ({
  cloudFrontUrl = '',
  backupUrl = '',
  contentType,
  mimeType,
  canUpdateContent = true,
  onUnlinkFile,
}) => {
  const backgroundImageUrl = getThumbnailBackgroundImage(
    contentType,
    mimeType,
    cloudFrontUrl,
    backupUrl
  );

  return (
    <div className='px-2 pt-2'>
      <div className='w-38 h-18 relative'>
        <div
          data-cy='thumbnail-hover-shadow'
          className={tailwindOverride(
            'absolute top-0 left-0 w-full h-18 justify-end z-20',
            'hidden bg-neutral-black group-hover:bg-opacity-70',
            { 'group-hover:flex': canUpdateContent }
          )}
        >
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onUnlinkFile();
            }}
            className='mt-2 mr-1 fill-current text-neutral-white text-xl hover:text-neutral-lighter-two w-6 h-6'
            src={UnlinkContent}
            data-cy='unlink-content-file-button'
          />
        </div>
        <div
          className='absolute top-0 left-0 w-full h-full'
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
          }}
        />
      </div>
    </div>
  );
};

const Card: React.FC<{
  file: ProjectContent;
  canUpdateContent?: boolean;
  onUnlinkFile: (id: string) => void;
}> = ({ file, canUpdateContent = true, onUnlinkFile }) => {
  const shadowElevation = useElevation(2);
  const isWeblink = file.content_type === PROJECT_CONTENT_TYPE.WEB_LINK;
  const isDesign =
    file.content_type === PROJECT_CONTENT_TYPE.DESIGN ||
    file.content_type === PROJECT_CONTENT_TYPE.DESIGN_FILE;
  const displayContentTypeLabel = isWeblink || isDesign;
  return (
    <div
      data-cy={`linked-file-thumbnail--${file.id}`}
      className={tailwindOverride(
        'w-42 h-36 bg-neutral-white',
        'rounded-sm group cursor-pointer',
        shadowElevation
      )}
    >
      <Thumbnail
        cloudFrontUrl={get(file, 'data.cloudFrontURL', '')}
        backupUrl={get(file, 'data.url')}
        mimeType={get(file, 'data.mimetype', '')}
        contentType={file.content_type}
        canUpdateContent={canUpdateContent}
        onUnlinkFile={() => onUnlinkFile(file.id!)}
      />
      <div className='w-full py-2 px-2.5'>
        <div className='truncate'>
          <Typography
            variant='label'
            className='text-primary cursor-pointer'
            weight='medium'
            data-cy='linked-file-name'
          >
            {getContentFileName(file)}
          </Typography>
        </div>
        {displayContentTypeLabel && (
          <Typography
            variant='label'
            className='text-neutral cursor-pointer'
            data-cy='linked-file-type'
          >
            {isWeblink && intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.WEBLINK')}
            {isDesign && intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.DESIGN')}
          </Typography>
        )}
      </div>
    </div>
  );
};

const DownladableCard: React.FC<{ file: ProjectContent }> = ({
  file,
  children,
}) => {
  let props = {};
  switch (file.content_type) {
    case PROJECT_CONTENT_TYPE.FILE:
    case PROJECT_CONTENT_TYPE.DESIGN_FILE:
      props = {
        href: get(file, 'data.cloudFrontURL') || get(file, 'data.url'),
        download: get(file, 'data.filename'),
      };
      break;
    case PROJECT_CONTENT_TYPE.WEB_LINK:
      props = {
        href: get(file, 'data.url', ''),
      };
      break;
    case PROJECT_CONTENT_TYPE.DESIGN:
      props = {
        href: `${config.get('designURL')}#/elements/${get(
          file,
          'data.id'
        )}/briefElements`,
      };
      break;
    default:
      break;
  }

  return (
    <a target='_blank' rel='noreferrer' {...props}>
      {children}
    </a>
  );
};

const LinkedContentFiles: React.FC<{
  files: ProjectContent[];
  canUpdateContent?: boolean;
  onUnlinkFile: (id: string) => void;
}> = ({ files, canUpdateContent = true, onUnlinkFile }) => {
  return (
    <div className='w-full flex flex-wrap mt-6'>
      {files.map((file: ProjectContent) => {
        return (
          <Tooltip
            key={file.id}
            className='mr-2 mb-2 last:mr-0'
            trigger={
              <div>
                <DownladableCard file={file}>
                  <Card
                    file={file}
                    canUpdateContent={canUpdateContent}
                    onUnlinkFile={onUnlinkFile}
                  />
                </DownladableCard>
              </div>
            }
            openMode='hover2'
            timeout={0}
            ariaId='budget-notes-info'
            position='topRight'
            showPopper
            contentProps={{
              className: classnames(
                'bg-neutral-darker shadow-tooltip',
                'rounded-lg p-4',
                'w-max absolute',
                'text-body'
              ),
            }}
          >
            <div className='flex flex-col'>
              <Typography variant='body' className='text-neutral-white mb-1'>
                {getContentFileName(file)}
              </Typography>
              <Typography variant='label' className='text-neutral-white mb-1'>
                {getContentTypeLabel(file.content_type)}
              </Typography>
              <Typography variant='label' className='text-neutral-white'>
                {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TOOLTIP_CONTENT', {
                  user: `${get(file, 'uploadedBy.data.firstName')} ${get(
                    file,
                    'uploadedBy.data.lastName'
                  )}`,
                  date:
                    file.createdAt &&
                    moment(new Date(file.createdAt)).format(DATE.SHORT_FORMAT),
                })}
              </Typography>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default LinkedContentFiles;
