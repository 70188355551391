import { Toggle } from '@getsynapse/design-system';
import { ChangeEvent, useMemo } from 'react';
import {
  ToggleFieldTemplateType,
  ToggleFieldType,
  ToggleValue,
} from 'utils/types/fields';

interface ToggleFieldProps {
  field?: ToggleFieldType;
  fieldTemplate: ToggleFieldTemplateType;
  onChange: (newValue: ToggleValue) => void;
  disabled: boolean;
}

const ToggleField = ({
  field,
  fieldTemplate,
  onChange,
  disabled,
}: ToggleFieldProps) => {
  const defaultChecked = useMemo<boolean>(() => {
    if (field) {
      return Boolean(field.value.val);
    } else {
      return Boolean(fieldTemplate.default_value.val);
    }
  }, [field, fieldTemplate.default_value.val]);

  const onOption = fieldTemplate.allowed_values.find((option) => option.val);
  const offOption = fieldTemplate.allowed_values.find((option) => !option.val);

  return (
    <Toggle
      id={`toggle-field-${fieldTemplate.id}`}
      onText={onOption?.display}
      offText={offOption?.display}
      checked={defaultChecked}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked ? onOption! : offOption!);
      }}
      disabled={disabled}
    />
  );
};
export default ToggleField;
