import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import TableView from './views/TableView';

const TasksListPage = () => {
  return (
    <div className='flex flex-col h-full'>
      <PageTitle titleComponent={intl.get('TASKS_LIST_PAGE.TITLE')} />
      <TableView />
    </div>
  );
};

export default TasksListPage;
