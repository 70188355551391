import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import {
  AvatarGroup,
  IconButton,
  Table,
  Tooltip,
} from '@getsynapse/design-system';
import {
  AvatarUser,
  SortingType,
  TableExportOptions,
  TableRowDensity,
  Task,
} from 'utils/customTypes';
import { DATE, PATHS, PROJECT_STATUS, TASK_STATUS } from 'utils/constants';
import emptyTasksTable from 'assets/icons/empty-tasks.svg';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import {
  getDueAndActualDateColumn,
  getStatusColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { canUpdateTask } from 'utils/permissionChecker';
import { useSelector } from 'react-redux';
import { selectUserById } from 'state/UsersManagement/usersManagementSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import TaskSidePanel from 'Organisms/TaskSidePanel/TaskSidePanel';
import UserAvatar from 'Atoms/UserAvatar';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { CentralizedTask } from 'utils/types/centralizedTasks';
import { CENTRALIZED_TASKS_SORT } from 'utils/constants/centralizedTasks';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getEstimatedAndActualHoursColumn = (hours?: string) => {
  const hasHours = hours && parseFloat(hours) > 0;
  return hasHours ? `${parseFloat(hours)}h` : '0h';
};

const getAssignedUsers = (assignedUser: AvatarUser[] = []) => {
  if (isEmpty(assignedUser)) {
    return (
      <div className='flex items-center'>
        <span className='text-neutral-dark'>
          {intl.get('TASKS.TABLE.NO_ASSIGNEE')}
        </span>
      </div>
    );
  }
  if (assignedUser.length === 1) {
    return (
      <div className='flex items-center'>
        <UserAvatar user={assignedUser[0]} className='mr-2' />
        {`${get(assignedUser, '0.data.firstName')} ${get(
          assignedUser,
          '0.data.lastName'
        )}`}
      </div>
    );
  } else if (assignedUser.length > 1) {
    return (
      <div className='flex items-center'>
        <AvatarGroup
          avatars={assignedUser.map((user) => {
            const firstName = get(user, 'data.firstName');
            const lastName = get(user, 'data.lastName');
            return {
              imageSrc: user.avatar_url,
              initial: get(firstName, '[0]'),
              name: `${firstName} ${lastName}`,
            };
          })}
        />
      </div>
    );
  }
};

const TasksTable: React.FC<{
  tasksList: CentralizedTask[];
  rowDensity?: TableRowDensity;
  totalTasks?: number;
  selectedTaskIds: string[];
  onSort: (
    sortBy: keyof typeof CENTRALIZED_TASKS_SORT,
    sortOrder: SortingType
  ) => void;
  onSelectRows?: (selectedRows: string[]) => void;
  onSelectExportOption: (exportOption: TableExportOptions) => void;
  onUpdateTask?: () => void;
}> = ({
  tasksList,
  rowDensity = 'default',
  totalTasks = 0,
  selectedTaskIds,
  onSort,
  onSelectRows,
  onSelectExportOption,
  onUpdateTask,
}) => {
  const history = useHistory();
  const userData = useSelector(selectUserById);
  const ldTeams = useSelector(selectLearningTeams);
  const isListEmpty = tasksList.length === 0;
  const [activeTask, setActiveTask] = useState<Task | undefined>();
  const [canUpdateActiveTask, setCanUpdateActiveTask] =
    useState<boolean>(false);
  const togglePanel = (task: Task | undefined, canUpdate: boolean = false) => {
    setActiveTask(task);
    setCanUpdateActiveTask(canUpdate);
  };
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();

  return (
    <div
      className={classnames(
        'w-full rounded-b border-neutral-lighter-two max-h-no-filtered-table-body',
        {
          'overflow-auto border': !isListEmpty,
          'overflow-hidden border-r': isListEmpty,
        }
      )}
    >
      <TaskSidePanel
        taskId={activeTask?.id}
        onClosePanel={() => togglePanel(undefined)}
        currentUserId={userData?.id}
        canUpdate={canUpdateActiveTask}
        onSave={onUpdateTask}
      />
      <Table
        className={classnames('w-full relative', {
          'border-0': !isListEmpty,
        })}
        canSelectRows={false}
        selectedRowsIds={selectedTaskIds}
        onSelectRows={onSelectRows}
        rowDensity={rowDensity}
        isSelectRowCellSticky={true}
        data-cy='tasks-list-table'
        data={{
          headData: {
            handleSort: onSort,
            stickyHeader: true,
            onSelectExportOption,
            headCells: [
              {
                'data-cy': 't-header__task_name',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.TASK_NAME'),
                columnName: CENTRALIZED_TASKS_SORT.NAME,
                className: classnames('h-10', {
                  'w-84 left-0 z-2': !isListEmpty,
                }),
              },
              {
                'data-cy': 't-header__task_staus',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.STATUS'),
                columnName: CENTRALIZED_TASKS_SORT.STATUS,
                className: classnames('h-10', {
                  'w-32 left-92 z-2': !isListEmpty,
                }),
              },
              {
                'data-cy': 't-header__task_project',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.PROJECT'),
                columnName: CENTRALIZED_TASKS_SORT.PROJECT,
              },
              {
                'data-cy': 't-header__task_assignee',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ASSIGNEE'),
              },
              {
                'data-cy': 't-header__task_start_date',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.START_DATE'),
                columnName: CENTRALIZED_TASKS_SORT.START_DATE,
              },
              {
                'data-cy': 't-header__task_due_date',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.DUE_DATE'),
                columnName: CENTRALIZED_TASKS_SORT.DUE_DATE,
              },
              {
                'data-cy': 't-header__task_estimate_hours',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ESTIMATED_HOURS'),
                columnName: CENTRALIZED_TASKS_SORT.ESTIMATED_HOURS,
              },
              {
                'data-cy': 't-header__task_actual_hours',
                content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ACTUAL_HOURS'),
                columnName: CENTRALIZED_TASKS_SORT.ACTUAL_HOURS,
              },
              {
                'data-cy': 't-header__task_actions',
                className: classnames('h-10 w-6', {
                  'right-0 z-2': !isListEmpty,
                }),
                content: <div />,
              },
            ],
          },
          rows: tasksList.map((task, index) => {
            const canUpdate =
              task.projectLearnOp &&
              userData &&
              canUpdateTask(task, task.projectLearnOp, userData, ldTeams, tasksAssignmentsBasedResourcesCapacity);

            const isViewOnlyMode =
              task.status === TASK_STATUS.ON_HOLD ||
              task.disabled ||
              !canUpdate ||
              [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
                task.projectLearnOp?.status
              );

            const isOdd = index % 2 !== 0;

            const stickyCellsStyles = (className: string) =>
              classnames(
                'border-transparent',
                {
                  'sticky z-1': !isListEmpty,
                  'bg-neutral-white': !isOdd,
                  'bg-neutral-lightest-two': isOdd,
                },
                className
              );

            return {
              id: task.id,
              'data-cy': `task-${task.id}`,
              className: classnames('group cursor-pointer relative'),
              onClick: () => {
                if (activeTask) {
                  togglePanel(task, canUpdate);
                } else {
                  history.push(`/project/${task.project_id}/tasks/${task.id}`, {
                    from: PATHS.TASKS_LIST_PAGE,
                  });
                }
              },
              cells: [
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-84 h-4': !isListEmpty,
                      })}
                    >
                      {task.name}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-84 left-0': !isListEmpty })
                  ),
                },
                {
                  content: (
                    <div
                      className={classnames({
                        'w-32 h-4 h-6': !isListEmpty,
                      })}
                    >
                      {getStatusColumn(task.status)}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-32 left-92': !isListEmpty })
                  ),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-72 h-4': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      <Link
                        to={{
                          pathname: `${PATHS.PROJECT_PAGE}/${task.project_id}`,
                          state: {
                            from: PATHS.TASKS_LIST_PAGE,
                          },
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        {task.projectLearnOp?.title}
                      </Link>
                    </div>
                  ),
                  className: classnames({ 'w-72 left-54': !isListEmpty }),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-44 h-full': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      {getAssignedUsers(task.assignedUsers)}
                    </div>
                  ),
                  className: classnames({ 'w-72 left-54': !isListEmpty }),
                },
                {
                  content: task.start_date && (
                    <div
                      className={classnames('truncate', {
                        'w-32 h-4': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      {moment(new Date(task.start_date)).format(
                        DATE.SHORT_FORMAT
                      )}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isListEmpty }),
                },
                {
                  content: task.due_date && (
                    <div
                      className={classnames('truncate', {
                        'w-32 h-4': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      {getDueAndActualDateColumn({
                        status: task.status,
                        due_date: task.due_date,
                      })}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isListEmpty }),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-32 h-4': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      {getEstimatedAndActualHoursColumn(task.estimate_hours)}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isListEmpty }),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-32 h-4': !isListEmpty,
                        'w-full': isListEmpty,
                      })}
                    >
                      {getEstimatedAndActualHoursColumn(task.actual_hours)}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isListEmpty }),
                },
                {
                  className: stickyCellsStyles(
                    classnames({ 'w-6 right-0': !isListEmpty })
                  ),
                  content: (
                    <div
                      className='h-full w-6 text-center relative'
                      onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                        event.stopPropagation()
                      }
                    >
                      {
                        <div className='hidden group-hover:flex'>
                          <Tooltip
                            openMode='hover1'
                            timeout={0}
                            showPopper
                            position='topCenter'
                            contentProps={{
                              className: 'px-3 py-2 text-sm z-50 absolute',
                            }}
                            usePortal
                            trigger={
                              <IconButton
                                name={
                                  !isViewOnlyMode ? 'pencil-outline' : 'eye'
                                }
                                data-cy={`task-quick-edit-button-${task.id}`}
                                iconClassname='pointer-events-none'
                                className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                onClick={() => togglePanel(task, canUpdate)}
                              />
                            }
                          >
                            {!isViewOnlyMode
                              ? intl.get('SIDE_PANEL.QUICK_EDIT')
                              : intl.get('SIDE_PANEL.QUICK_VIEW')}
                          </Tooltip>
                        </div>
                      }
                    </div>
                  ),
                },
              ],
            };
          }),
          total: totalTasks,
        }}
        emptyComponent={
          <RenderNoRecords
            dataCy='tasks-list-empty-table'
            imageSrc={emptyTasksTable}
            imageClassName='-ml-4'
            className='h-empty-table-body'
            caption={intl.get('TASKS_LIST_PAGE.TABLE.EMPTY')}
            labelClassName='mt-0'
          />
        }
      />
    </div>
  );
};

export default TasksTable;
