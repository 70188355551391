import { useEffect, useMemo, useState, useRef } from 'react';
import intl from 'react-intl-universal';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import classnames from 'classnames';
import { isEmpty, get, isEqual } from 'lodash';
import moment from 'moment';
import { useElevation, Typography } from '@getsynapse/design-system';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import {
  PATHS,
  REQUEST_STATUS,
  REQUEST_SECTIONS,
  REQUEST_PROPERTIES,
  USER_TYPES,
  USER_ROLES,
  DATE,
} from 'utils/constants';
import {
  RequestPageTabs,
  UpdateReqData,
  FormOption,
  User,
} from 'utils/customTypes';
import {
  getForms,
  selectHasForms,
  filteredFormsOptions,
} from 'state/Forms/formSlice';
import {
  editRequest,
  getRequest,
  getRequestQuestions,
  resetActiveRequest,
  selectActiveRequest,
  selectActiveRequestQuestions,
  selectActiveRequestSliceStatus,
  updateRequestQuestions,
  updateOwners,
  updateReviewers,
  selectIsActiveRequestAForm,
  newRequest,
} from 'state/ActiveRequest/activeRequestSlice';
import {
  selectOrganizationId,
  selectUserId,
  selectUserType,
  selectUser,
} from 'state/User/userSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationVariant,
  setNotificationTimeout,
} from 'state/InlineNotification/inlineNotificationSlice';
import { exists } from 'Pages/helpers';
import { formatRequestIdentifier } from 'Pages/helpers';
import DetailsPage from './DetailsPage';
import FooterButtons from './components/FooterButtons/FooterButtons';
import RequestPageSidebar from 'Organisms/RequestPageSidebar/RequestPageSidebar';
import CancelCreateRequestModal from './components/CancelCreateRequestModal';
import LeavingUnsavedRequestModal from './components/LeavingUnsavedRequestModal';
import Loader from 'Molecules/Loader/Loader';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import EmptyRequestPage from 'assets/icons/empty-requests.svg';
import { RequestProvider } from './context/RequestContext';
import { validateRequiredFields } from './helpers/validateFields';
import { calculateRequestPermissions } from './helpers/permissions';
import {
  resetSidePanelUpdatedData,
  selectSidePanelUpdatedData,
} from 'state/SidePanel/sidePanelSlice';

const RequestPage = () => {
  const fromSidePanel = new URLSearchParams(window.location.search).get(
    'fromSidePanel'
  )
    ? true
    : false;
  const footerElevation = useElevation(2);
  const { requestId } = useParams<{ requestId: string }>();
  const formOptionsSelector: FormOption[] = useSelector(filteredFormsOptions);
  const dispatch = useDispatch();
  const hasForms = useSelector(selectHasForms);
  const organizationId = useSelector(selectOrganizationId);
  const requestSliceStatus = useSelector(selectActiveRequestSliceStatus);
  const requestData = useSelector(selectActiveRequest);
  const sidePanelRequestData = useSelector(selectSidePanelUpdatedData);
  const currentData = useMemo(
    () =>
      fromSidePanel && !isEmpty(sidePanelRequestData?.requestAttributes)
        ? { ...requestData, ...sidePanelRequestData?.requestAttributes }
        : requestData,
    [fromSidePanel, requestData, sidePanelRequestData]
  );
  const requestQuestionsData = useSelector(selectActiveRequestQuestions);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');

  const [shouldResetSideBarForm, setShouldResetSideBarForm] =
    useState<boolean>(false);

  const history = useHistory();
  const [updatedReqData, setUpdatedReqData] = useState<UpdateReqData>({
    requestAttributes: fromSidePanel
      ? sidePanelRequestData?.requestAttributes
      : {},
    requestQuestions: fromSidePanel
      ? sidePanelRequestData?.requestQuestions
      : {},
  });
  const sidePanelQuestions = useMemo(() => {
    const questions = [];
    for (let i = 0; i < requestQuestionsData.length; i++) {
      const updatedQuestion =
        sidePanelRequestData?.requestQuestions[requestQuestionsData![i].id];
      if (
        !isEmpty(updatedQuestion) &&
        updatedQuestion?.data.value !== requestQuestionsData![i].data.value
      ) {
        const newValue = updatedQuestion?.data.value;
        const newQuestion = {
          ...requestQuestionsData![i],
          data: {
            ...requestQuestionsData![i].data,
            value: newValue,
          },
        };
        questions.push(newQuestion);
      } else {
        questions.push(requestQuestionsData[i]);
      }
    }
    return questions;
  }, [requestQuestionsData, sidePanelRequestData?.requestQuestions]);

  const currentQuestionsData = useMemo(
    () =>
      fromSidePanel && !isEmpty(sidePanelRequestData?.requestQuestions)
        ? sidePanelQuestions
        : requestQuestionsData,
    [
      fromSidePanel,
      requestQuestionsData,
      sidePanelRequestData?.requestQuestions,
      sidePanelQuestions,
    ]
  );
  const [owners, setOwners] = useState<string[] | null>(null);
  const [businessReviewers, setBusinessReviewers] = useState<string[] | null>(
    null
  );
  const [ldReviewers, setLDReviewers] = useState<string[] | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<RequestPageTabs>(0);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [questionIdParam, setQuestionIdParam] = useState<string>('');
  const [propertyNameParam, setPropertNameParam] = useState<string>('');
  const [uidParam, setUidParam] = useState<string>('');
  const [shouldShowSideBar, setShouldShowSideBar] = useState(false);
  const requestDataStatus = (requestData && requestData.status) || '';

  const isForm = useSelector(selectIsActiveRequestAForm);
  const userId = useSelector(selectUserId);
  const userType = useSelector(selectUserType);
  const currentUser = useSelector(selectUser);
  const isAdmin = currentUser.role === USER_ROLES.ADMIN;

  const originalBusinessReviewerIds = useMemo(() => {
    return requestData.reviewers
      ?.filter((reviewer: User) => reviewer.type === USER_TYPES.BUSINESS)
      .map((reviewer: User) => reviewer.id);
  }, [requestData.reviewers]);

  const originalLDReviewerIds = useMemo(() => {
    return requestData.reviewers
      ?.filter((reviewer: User) => reviewer.type === USER_TYPES.L_D)
      .map((reviewer: User) => reviewer.id);
  }, [requestData.reviewers]);

  const detectChangeInUsers = useMemo(() => {
    const ownerChange =
      !isEmpty(owners) && !isEqual(requestData.owners, owners);
    const ldReviewersChange =
      !isEmpty(ldReviewers) && !isEqual(originalLDReviewerIds, ldReviewers);
    const businessReviewerChange =
      !isEmpty(businessReviewers) &&
      !isEqual(originalBusinessReviewerIds, businessReviewers);
    return ownerChange || ldReviewersChange || businessReviewerChange;
  }, [
    businessReviewers,
    ldReviewers,
    originalBusinessReviewerIds,
    originalLDReviewerIds,
    owners,
    requestData.owners,
  ]);

  const requestTitleRef = useRef(null);

  const requestIdentifier = useMemo(
    () => formatRequestIdentifier(get(requestData, 'requestIdentifier')!),
    [requestData]
  );

  useEffect(() => {
    if (fromSidePanel && sidePanelRequestData?.requestAttributes) {
      if (
        isEmpty(owners) &&
        sidePanelRequestData?.requestAttributes?.updatedOwners
      ) {
        setOwners(sidePanelRequestData?.requestAttributes?.updatedOwners);
      }
      if (
        isEmpty(ldReviewers) &&
        sidePanelRequestData?.requestAttributes?.ldReviewers
      ) {
        setLDReviewers(sidePanelRequestData?.requestAttributes?.ldReviewers);
      }
      if (
        isEmpty(businessReviewers) &&
        sidePanelRequestData?.requestAttributes?.businessReviewers
      ) {
        setBusinessReviewers(
          sidePanelRequestData?.requestAttributes?.businessReviewers
        );
      }
    }
  }, [
    businessReviewers,
    fromSidePanel,
    ldReviewers,
    owners,
    sidePanelRequestData?.requestAttributes,
  ]);

  useEffect(() => {
    if (
      !requestId &&
      !isEmpty(formOptionsSelector) &&
      formOptionsSelector[0].value
    ) {
      history.replace(`${PATHS.REQUEST_PAGE}/${formOptionsSelector[0].value}`);
    }
  }, [formOptionsSelector, requestId, history]);

  const isOwner = useMemo(() => {
    if (!isEmpty(requestData.owners)) {
      return requestData.owners!.some((owner: User) => owner.id === userId);
    } else {
      return false;
    }
  }, [requestData, userId]);

  const questionsWithoutLd = useMemo(
    () =>
      currentQuestionsData.filter(
        (que) => que.section !== REQUEST_SECTIONS.LD_DETAILS
      ),
    [currentQuestionsData]
  );

  useEffect(() => {
    const filteredRequestQuestions =
      userType === USER_TYPES.L_D && requestData.status !== REQUEST_STATUS.DRAFT
        ? currentQuestionsData
        : questionsWithoutLd;
    setUpdatedReqData((prev) => ({
      ...prev,
      requestQuestions: filteredRequestQuestions.reduce(
        (reqQuestions, questionData) => ({
          ...reqQuestions,
          [questionData.id]: questionData,
        }),
        {}
      ),
    }));
  }, [currentQuestionsData, questionsWithoutLd, requestData.status, userType]);

  const { requestHasUpdate, shouldUseUserBusinessTeamAsDefault } = useMemo(
    () =>
      calculateRequestPermissions(
        requestData,
        isForm,
        currentUser,
        updatedReqData,
        requestQuestionsData
      ),
    [currentUser, isForm, requestData, requestQuestionsData, updatedReqData]
  );

  const isLDReviewer = useMemo<boolean>(
    () => originalLDReviewerIds?.includes(userId),
    [originalLDReviewerIds, userId]
  );

  useEffect(() => {
    if (requestDataStatus === REQUEST_STATUS.DRAFT && !shouldShowSideBar) {
      setShouldShowSideBar(true);
    }
  }, [requestDataStatus, shouldShowSideBar, setShouldShowSideBar]);

  const changesDetected = requestHasUpdate || detectChangeInUsers;

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
    return () => {};
  }, [changesDetected]);

  useEffect(() => {
    const areFieldsValid = validateRequiredFields(
      get(requestTitleRef, 'current.value'),
      requestData,
      updatedReqData,
      shouldUseUserBusinessTeamAsDefault,
      isForm
    );
    setHasErrors(!areFieldsValid);
  }, [
    isForm,
    requestData,
    shouldUseUserBusinessTeamAsDefault,
    updatedReqData,
    requestTitleRef,
  ]);

  const onSubmit = async () => {
    const reqAttributesToUpdate = updatedReqData.requestAttributes;
    const reqQuestionsToUpdate = updatedReqData.requestQuestions;
    setConfirmedNavigation(true);
    if (!isForm) {
      if (!isEmpty(reqQuestionsToUpdate)) {
        await dispatch(
          updateRequestQuestions({
            requestId,
            updateData: reqQuestionsToUpdate,
          })
        );
      }
      await dispatch(
        editRequest({
          request: { ...requestData, ...reqAttributesToUpdate },
          updateData: {
            status: REQUEST_STATUS.SUBMITTED,
          },
        })
      );
      history.push(PATHS.REQUESTS_LIST_PAGE);
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      dispatch(
        setNotificationText(
          intl.get('REQUEST_PAGE.NOTIFICATIONS.REQUEST_SUBMIT', {
            requestNo: requestIdentifier,
          })
        )
      );
      dispatch(displayNotification());
    } else {
      await onUpdateOrCreateDraft(true);
    }
  };

  const onCancelCreatingRequest = () => {
    if (isForm && (requestHasUpdate || detectChangeInUsers)) {
      setIsOpenCancelModal(true);
    } else {
      history.push(PATHS.REQUESTS_LIST_PAGE);
    }
  };
  const onUpdateOrCreateDraft = async (shouldSubmit?: boolean) => {
    const reqAttributesToUpdate = updatedReqData.requestAttributes;
    const reqQuestionsToUpdate = updatedReqData.requestQuestions;
    const businessTeamId = get(
      reqAttributesToUpdate,
      'businessTeams.[0].id',
      ''
    );

    const businessTeamIdToUpdate = businessTeamId
      ? [businessTeamId]
      : shouldUseUserBusinessTeamAsDefault
      ? [get(currentUser, 'businessTeam')]
      : [];

    if (isForm) {
      const createdRequest = await dispatch(
        newRequest({
          formId: requestData.id || '',
          requestTypeId: requestData.request_type || '',
          businessTeams: businessTeamIdToUpdate,
          questions: reqQuestionsToUpdate,
          description: reqAttributesToUpdate.description,
          title: reqAttributesToUpdate.title,
          shouldUpdateActiveRequest: false,
          status: shouldSubmit
            ? REQUEST_STATUS.SUBMITTED
            : REQUEST_STATUS.DRAFT,
        })
      );
      const identifier = formatRequestIdentifier(
        get(createdRequest, 'payload.data.requestIdentifier', '')
      );
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      if (shouldSubmit) {
        dispatch(
          setNotificationText(
            intl.get('REQUEST_PAGE.NOTIFICATIONS.REQUEST_SUBMIT', {
              requestNo: identifier,
            })
          )
        );
      } else {
        dispatch(
          setNotificationText(
            intl.get('REQUEST_PAGE.NOTIFICATIONS.REQUEST_CREATE_SUCCESS', {
              requestNo: identifier,
            })
          )
        );
      }
      dispatch(displayNotification());
      leaveToRequestListPage();
    } else {
      if (owners) {
        await dispatch(updateOwners({ requestId, ownersIds: owners! }));
      }
      if (businessReviewers && ldReviewers) {
        await dispatch(
          updateReviewers({
            requestId,
            reviewersId: businessReviewers.concat(ldReviewers),
          })
        );
      } else if (businessReviewers) {
        await dispatch(
          updateReviewers({
            requestId,
            reviewersId: businessReviewers.concat(originalLDReviewerIds),
          })
        );
      } else if (ldReviewers) {
        await dispatch(
          updateReviewers({
            requestId,
            reviewersId: ldReviewers.concat(originalBusinessReviewerIds),
          })
        );
      }

      if (!isEmpty(reqAttributesToUpdate)) {
        await dispatch(
          editRequest({
            request: { ...requestData, ...reqAttributesToUpdate },
            updateData: {},
          })
        );
      }
      if (!isEmpty(reqQuestionsToUpdate)) {
        await dispatch(
          updateRequestQuestions({
            requestId,
            updateData: reqQuestionsToUpdate,
          })
        );
      }
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      dispatch(
        setNotificationText(
          intl.get('REQUEST_PAGE.NOTIFICATIONS.MODIFICATION_SUCCESS', {
            requestNo: requestIdentifier,
          })
        )
      );
      dispatch(displayNotification());
      leaveToRequestListPage();
    }
  };

  useEffect(() => {
    if (organizationId) {
      dispatch(getForms({ organizationId, published: true }));
    }
  }, [organizationId, dispatch, hasForms]);

  useEffect(() => {
    if (requestId) {
      dispatch(getRequest(requestId));
      dispatch(getRequestQuestions(requestId));
      dispatch(getLearningTeams());
    }
    return () => {
      setOwners(null);
      setLDReviewers(null);
      setBusinessReviewers(null);
      setUpdatedReqData({
        requestAttributes: {},
        requestQuestions: {},
      });
      dispatch(resetActiveRequest());
      if (fromSidePanel) {
        dispatch(resetSidePanelUpdatedData);
      }
    };
  }, [requestId, dispatch, fromSidePanel]);

  useEffect(() => {
    if (requestSliceStatus === 'failed') {
      history.push(PATHS.REQUESTS_LIST_PAGE);
    }
  }, [requestSliceStatus, history]);

  const questionId = new URLSearchParams(window.location.search).get(
    'questionId'
  );
  const propertyName = new URLSearchParams(window.location.search).get(
    'propertyName'
  );
  const uuid = new URLSearchParams(window.location.search).get('uuid');

  useEffect(() => {
    if (
      (questionId &&
        questionIdParam !== questionId &&
        requestQuestionsData.length) ||
      (questionId && requestQuestionsData.length && uuid !== uidParam)
    ) {
      if (uuid !== uidParam) setUidParam(uuid!);
      if (questionIdParam !== questionId) setQuestionIdParam(questionId!);
      const question = requestQuestionsData.find(
        (question) => question.id === questionId
      );
      switch (question?.section) {
        case REQUEST_SECTIONS.REQUEST_DETAILS:
          setActiveTabIndex(1);
          break;
        case REQUEST_SECTIONS.ADDITIONAL_DETAILS:
          setActiveTabIndex(2);
          break;
        case REQUEST_SECTIONS.LD_DETAILS:
          setActiveTabIndex(3);
          break;
        default:
          setActiveTabIndex(0);
      }
    } else if (
      (propertyName && propertyName !== propertyNameParam) ||
      (propertyName && uuid !== uidParam)
    ) {
      if (uuid !== uidParam) setUidParam(uuid!);
      if (propertyName !== propertyNameParam)
        setPropertNameParam(propertyName!);
      switch (propertyName) {
        case REQUEST_PROPERTIES.REQUEST_DESC:
          setActiveTabIndex(1);
          break;
        case REQUEST_PROPERTIES.LD_PRIORITY:
        case REQUEST_PROPERTIES.EFFORT:
        case REQUEST_PROPERTIES.COST:
        case REQUEST_PROPERTIES.ADDITONAL_INFORMATION:
          setActiveTabIndex(3);
          break;
        default:
          setActiveTabIndex(0);
      }
    }
  }, [
    requestQuestionsData,
    propertyNameParam,
    questionIdParam,
    uidParam,
    propertyName,
    questionId,
    uuid,
  ]);

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(location.pathname);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  const handleLeavingPageWithUnsavedChanges = (path: string) => {
    setLeavingToLocation(path);
    setConfirmedNavigation(true);
  };

  const leaveToRequestListPage = () =>
    handleLeavingPageWithUnsavedChanges(PATHS.REQUESTS_LIST_PAGE);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, leavingToLocation, setConfirmedNavigation, history]);

  return (
    <RequestProvider
      requestData={requestData}
      updatedReqData={updatedReqData}
      requestQuestionsData={requestQuestionsData}
    >
      <Prompt when={changesDetected} message={handleBlockedNavigation} />
      <LeavingUnsavedRequestModal
        isOpen={isLeavingWarningModalOpen}
        isCreating={isForm}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
        onClose={() => setShouldResetSideBarForm(true)}
      />
      {isForm && (
        <CancelCreateRequestModal
          isOpen={isOpenCancelModal}
          setIsOpen={setIsOpenCancelModal}
          onConfirm={leaveToRequestListPage}
        />
      )}
      <div className='h-full flex flex-col'>
        <PageTitle
          titleComponent={
            isForm ? intl.get('REQUEST_PAGE.TITLE') : requestData.title
          }
          headerChildren={
            requestData.status === REQUEST_STATUS.SUBMITTED && (
              <Typography
                variant='label'
                className='text-neutral-black'
                data-cy='request-title'
              >
                {intl.get('REQUEST_PAGE.SUBMITTED_ON', {
                  num: requestData.submittedAt ? 1 : 0,
                  date: moment(requestData.submittedAt).format(
                    DATE.SHORT_FORMAT
                  ),
                  user: `${get(requestData, 'requester.data.firstName')} ${get(
                    requestData,
                    'requester.data.lastName'
                  )}`,
                })}
              </Typography>
            )
          }
          dataCy='request-title'
        />
        <main id='request-tabs-container' className='flex-grow flex'>
          {(requestDataStatus === REQUEST_STATUS.DRAFT ||
            shouldShowSideBar) && (
            <RequestPageSidebar
              requestId={requestId}
              shouldResetForm={shouldResetSideBarForm}
              setShouldResetForm={setShouldResetSideBarForm}
              handleLeavingPageWithUnsavedChanges={
                handleLeavingPageWithUnsavedChanges
              }
              hasUpdate={changesDetected}
            />
          )}
          <div
            data-cy='request-details-overflow'
            className={classnames('flex-grow bg-neutral-white h-full flex', {
              'items-center justify-center':
                requestSliceStatus !== 'loading' && !exists(requestData),
            })}
          >
            {requestSliceStatus !== 'loading' && !exists(requestData) && (
              <div className='flex flex-col w-71 justify-center items-center'>
                <img
                  src={EmptyRequestPage}
                  alt='Empty request page'
                  className='w-66 h-auto'
                />
                <Typography
                  variant='body'
                  className='mt-6 text-center text-neutral-black'
                >
                  {intl.get('REQUEST_PAGE.EMPTY_STATE')}
                </Typography>
              </div>
            )}
            {requestSliceStatus !== 'loading' && exists(requestData) && (
              <DetailsPage
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                requestData={currentData}
                requestQuestionsData={currentQuestionsData}
                setUpdatedReqData={setUpdatedReqData}
                updatedReqData={updatedReqData}
                setOwners={setOwners}
                setBusinessReviewers={setBusinessReviewers}
                setLDReviewers={setLDReviewers}
                questionIdParam={questionIdParam}
                propertyNameParam={propertyNameParam}
                requestTitleRef={requestTitleRef}
                handleLeavingPageWithUnsavedChanges={
                  handleLeavingPageWithUnsavedChanges
                }
                canEditProperties={isOwner || isAdmin}
                isLDReviewer={isLDReviewer}
              />
            )}
            {requestSliceStatus === 'loading' && !exists(requestData) && (
              <Loader />
            )}
          </div>
        </main>
        <div
          className={classnames(
            'h-12 z-5 px-8',
            'flex-shrink-0',
            'bg-neutral-white',
            'flex items-center',
            'w-full justify-between',
            footerElevation
          )}
        >
          <FooterButtons
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            onSubmit={onSubmit}
            onUpdate={onUpdateOrCreateDraft}
            requestDataStatus={requestDataStatus}
            hasErrors={hasErrors || !changesDetected}
            validRequiredFields={!hasErrors}
            onCancel={onCancelCreatingRequest}
            changesDetected={changesDetected}
          />
        </div>
      </div>
    </RequestProvider>
  );
};

export default RequestPage;
