import intl from 'react-intl-universal';
import {
  Typography,
  FormItem,
  TextField,
  TextArea,
} from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import { selectUser } from 'state/User/userSlice';
import UserAvatar from 'Atoms/UserAvatar';
import { ChangeEvent } from 'react';
import { TaskBundle } from 'utils/customTypes';
import get from 'lodash/get';

type BundleOverviewProps = {
  handleFieldChange: (field: string, value: string) => void;
  isEditing?: boolean;
  data: TaskBundle;
};
const BundleOverview = ({
  handleFieldChange,
  isEditing,
  data,
}: BundleOverviewProps) => {
  const currentUser = useSelector(selectUser);
  const formatBundleCreator: any = () => {
    if (isEditing) {
      return {
        avatar_url: get(data, 'taskBundleCreator.avatar_url', ''),
        firstName: data?.taskBundleCreator?.data?.firstName,
        lastName: data?.taskBundleCreator?.data?.lastName,
      };
    } else {
      return currentUser;
    }
  };

  const bundleCreator = formatBundleCreator();

  return (
    <div className='mt-6'>
      <Typography variant='body2' className='text-neutral-dark'>
        {intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.OVERVIEW_SECTION.SECTION_DESCRIPTION'
        )}
      </Typography>
      <div className='grid grid-cols-2 mt-6 gap-y-6 gap-x-10%'>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.OVERVIEW_SECTION.TITLE_LABEL'
          )}
          labelProps={{ required: true }}
        >
          <TextField
            value={data?.name}
            data-testid='build-bundle-name-input'
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange('name', event.target.value)
            }
            data-cy='task-bundle-title_input'
          />
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.OVERVIEW_SECTION.CREATOR_LABEL'
          )}
        >
          <div className='flex items-center'>
            <UserAvatar
              className='mr-2 pointer-events-none'
              user={{
                avatar_url: bundleCreator?.avatar_url,
                data: {
                  firstName: bundleCreator?.firstName,
                  lastName: bundleCreator?.lastName,
                },
              }}
            />
            <Typography variant='body2' className='text-neutral-black'>
              {bundleCreator?.firstName} {bundleCreator?.lastName}
            </Typography>
          </div>
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.OVERVIEW_SECTION.DESCRIPTION_LABEL'
          )}
          labelProps={{ required: true }}
        >
          <TextArea
            textAreaProps={{
              'data-cy': 'task-bundle-description_input',
              'data-testid': 'build-bundle-description-input',
            }}
            value= {data?.description || ''}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              handleFieldChange('description', event.target.value)
            }
          /> 
        </FormItem>
      </div>
    </div>
  );
};

export default BundleOverview;
