import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import { FormItem, Dropdown, Typography } from '@getsynapse/design-system';
import {
  PROJECT_STATUS,
  PATHS,
  PROJECT_USER_ACTIONS,
  NEW_PROJECT_FORM_FIELDS,
  PROJECT_RESOURCING_TYPE,
  PROJECT_BUDGET_SOURCE,
  LONG_INPUTS_LENGTH,
} from 'utils/constants';
import { NewProject, Option } from 'utils/customTypes';
import { validateRequiredFields } from 'Pages/ProjectPage/helpers/formValidation';
import {
  OnHoldStatusBanner,
  ClosedStatusBanner,
  CancelledStatusBanner,
} from 'Pages/ProjectPage/components/Banners/Banners';
import UnsavedChangesBanner from 'Atoms/UnsavedChangesBanner/UnsavedChangesBanner';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';
import BasicDetails from 'Pages/NewProjectPage/components/BasicDetails';
import { Redirect } from 'react-router-dom';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { getInitialValueForDropDown } from 'utils/functions';
import SidePanelTitle from 'Atoms/SidePanelTitle/SidePanelTitle';

const ProjectSidePanelContent = ({
  projectData,
  shouldDisableUpdate,
  setShouldDisableUpdate,
  unsavedChanges,
  currentSavedProject,
  setCurrentSavedProject,
  showUnsavedChangesAnimation,
  setUnsavedChanges,
}: {
  projectData: NewProject;
  shouldDisableUpdate: boolean;
  setShouldDisableUpdate: Dispatch<SetStateAction<boolean>>;
  unsavedChanges: boolean;
  currentSavedProject: NewProject;
  setCurrentSavedProject: Dispatch<SetStateAction<NewProject>>;
  showUnsavedChangesAnimation: boolean;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const { hasUserAccess } = useHasUserAccess();
  const { canUser } = useUserPermissionsContext();
  const isReadOnly = useGetProjectReadOnly(
    projectData.status,
    projectData.is_archived
  );

  const resourcingTypeOptions = useMemo(
    () =>
      Object.keys(PROJECT_RESOURCING_TYPE).map((key) => ({
        label: intl.get(`PROJECT_DETAIL.RESOURCING_TYPE_OPTIONS.${key}`),
        value: PROJECT_RESOURCING_TYPE[key],
      })),
    []
  );

  const budgetSourceOptions = useMemo(
    () =>
      Object.keys(PROJECT_BUDGET_SOURCE).map((key) => ({
        label: intl.get(`PROJECT_DETAIL.BUDGET_SOURCE_OPTIONS.${key}`),
        value: PROJECT_BUDGET_SOURCE[key],
      })),
    []
  );

  const canUserUpdateResourcePlan =
    canUser(PROJECT_USER_ACTIONS.UPDATE_RESOURCE_PLAN) && !isReadOnly;

  const canUserEditBudgetPlanFields =
    canUser(PROJECT_USER_ACTIONS.UPDATE_BUDGET_PLAN) && !isReadOnly;

  useEffect(() => {
    setCurrentSavedProject(projectData);
  }, [projectData, setCurrentSavedProject]);

  useEffect(() => {
    const changesDetected = !isEqual(currentSavedProject, projectData);
    setUnsavedChanges(changesDetected);
    let { canSave } = validateRequiredFields(currentSavedProject, true);
    if (
      currentSavedProject.status === PROJECT_STATUS.ON_HOLD &&
      projectData.status !== PROJECT_STATUS.ON_HOLD
    ) {
      canSave = canSave && currentSavedProject.hold_reason !== '';
      if (
        currentSavedProject.hold_reason?.includes(
          intl.get(
            'PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.REASON_PICKER_OPTIONS.OTHER'
          )
        )
      ) {
        canSave = canSave && currentSavedProject.hold_reason?.includes(':');
      }
    }
    if (changesDetected && shouldDisableUpdate && canSave) {
      setShouldDisableUpdate(false);
    }
    if ((!changesDetected || !canSave) && !shouldDisableUpdate) {
      setShouldDisableUpdate(true);
    }
  }, [
    dispatch,
    currentSavedProject,
    projectData,
    shouldDisableUpdate,
    setShouldDisableUpdate,
    setUnsavedChanges,
    unsavedChanges,
  ]);

  const handleFieldChange = (fieldName: string, fieldValue: any) => {
    setCurrentSavedProject((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  return hasUserAccess ? (
    <div className='bg-neutral-white flex-grow'>
      <div className='w-full h-full'>
        <SidePanelTitle
          placeholder={intl.get('PROJECT_DETAIL.PROJECT_NAME_PLACEHOLDER')}
          value={currentSavedProject.title}
          maxLength={LONG_INPUTS_LENGTH}
          disabled={isReadOnly}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(NEW_PROJECT_FORM_FIELDS.TITLE, event.target.value)
          }
          containerProps={{ className: 'pt-0' }}
        />
        <div className='px-1'>
          {unsavedChanges && (
            <UnsavedChangesBanner
              showEmphasisAnimation={showUnsavedChangesAnimation}
              message={intl.get('SIDE_PANEL.UNSAVED_CHANGES')}
            />
          )}
          {projectData?.status === PROJECT_STATUS.ON_HOLD && (
            <OnHoldStatusBanner
              message={intl.get(
                'PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.PROJECT_ON_HOLD_BANNER'
              )}
              sidePanel
            />
          )}
          {projectData.status === PROJECT_STATUS.CLOSED && (
            <ClosedStatusBanner closedDate={projectData.closedDate} sidePanel />
          )}
          {projectData.status === PROJECT_STATUS.CANCELED && (
            <CancelledStatusBanner
              reason={projectData.cancel_reason}
              sidePanel
            />
          )}
          <BasicDetails
            data={currentSavedProject}
            setData={setCurrentSavedProject}
            isOnUpdatingPage={true}
            isReadOnly={isReadOnly}
            originalData={projectData}
            sidePanel={true}
            canPutProjectOnHold={canUser(
              PROJECT_USER_ACTIONS.PUT_PROJECT_ON_HOLD
            )}
          />
          <div className='border-neutral-lighter border-t'>
            <Typography variant='h5' className='mt-6'>
              {intl.get('SIDE_PANEL.PROJECT.RESOURCE_AND_BUDGET')}
            </Typography>
            <FormItem
              label={intl.get('PROJECT_DETAIL.RESOURCING_TYPE')}
              className='mt-6'
            >
              <Dropdown
                placeholder={intl.get(
                  'PROJECT_DETAIL.RESOURCING_TYPE_PLACEHOLDER'
                )}
                onChange={(option: Option) => {
                  handleFieldChange(
                    NEW_PROJECT_FORM_FIELDS.RESOURCING_TYPE,
                    option.value
                  );
                }}
                options={resourcingTypeOptions}
                values={getInitialValueForDropDown(
                  resourcingTypeOptions,
                  projectData?.resourcing_type
                )}
                disabled={!canUserUpdateResourcePlan}
                triggerProps={{ 'data-cy': 'project-resource-input' }}
              />
            </FormItem>
            {!projectData.project_template_id && (
              <>
                <FormItem
                  label={intl.get('PROJECT_DETAIL.BUDGET_SOURCE')}
                  className='mt-6'
                >
                  <Dropdown
                    disabled={!canUserEditBudgetPlanFields}
                    placeholder={intl.get(
                      'PROJECT_DETAIL.BUDGET_SOURCE_PLACEHOLDER'
                    )}
                    onChange={(option: Option) =>
                      handleFieldChange(
                        NEW_PROJECT_FORM_FIELDS.BUDGET_SOURCE,
                        option.value
                      )
                    }
                    options={budgetSourceOptions}
                    values={getInitialValueForDropDown(
                      budgetSourceOptions,
                      projectData.budget_source
                    )}
                    triggerProps={{ 'data-cy': 'project-budget-source' }}
                  />
                </FormItem>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={PATHS.PROJECTS_LIST_PAGE} />
  );
};

export default ProjectSidePanelContent;
