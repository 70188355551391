import { HTMLAttributes, ReactNode } from 'react';
import { Typography } from '@getsynapse/design-system';
import classnames from 'classnames';

type PageTitleProps = {
  headerChildren?: ReactNode;
  titleComponent: ReactNode;
  dataCy?: string;
  titleVariant?: string;
} & HTMLAttributes<HTMLDivElement>;

const PageTitle = ({
  headerChildren,
  className,
  titleComponent,
  titleVariant,
  dataCy,
  ...otherProps
}: PageTitleProps) => {
  return (
    <div
      className={classnames(
        'flex justify-between min-h-14 max-h-14',
        'items-center px-6 pt-2 pb-4 bg-neutral-white z-5',
        className
      )}
      {...otherProps}
    >
      <Typography
        variant={titleVariant || 'h4'}
        weight='medium'
        className='text-neutral-black'
        data-cy={dataCy}
      >
        {titleComponent}
      </Typography>
      {headerChildren}
    </div>
  );
};

export default PageTitle;
