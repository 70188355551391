import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchTeamsCapacity } from 'state/TeamsCapacity/teamsCapacitySlice';
import { TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';
import CapacityTable from './components/CapacityTable/CapacityTable';

const TeamsCapacity = () => {
  const dispatch = useDispatch();

  const init = useCallback(async () => {
    const currentDate = moment();
    const tableStartDate = currentDate
      .clone()
      .subtract(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const tableEndDate = currentDate
      .clone()
      .add(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS * 2, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    await dispatch(
      fetchTeamsCapacity({ tableStartDate, tableEndDate, offset: 0, limit: 5 })
    );
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return <CapacityTable />;
};

export default TeamsCapacity;
