import { Option } from 'utils/customTypes';
import {
  PROJECT_STATUS,
  PROJECT_PRIORITY,
  COLUMN_OPTION_TYPES,
  PROGRAM_PROJECT_FIELDS,
} from 'utils/constants';
import intl from 'react-intl-universal';
import {
  TableFilterProps,
  Typography,
  TextField,
  Datepicker,
  Dropdown,
} from '@getsynapse/design-system';
import React from 'react';

export const getFilterInput: TableFilterProps['filterInputElement'] = (
  column,
  filterInput,
  setInput,
  operator
) => {
  const getPickerOptions = () => {
    const statusOptions = Object.values(PROJECT_STATUS);
    const priorityOptions = Object.values(PROJECT_PRIORITY);
    switch (column.value) {
      case PROGRAM_PROJECT_FIELDS.STATUS:
        return statusOptions.map((status: string) => ({
          label: intl
            .get(`PROJECT_DETAIL.STATUS_OPTIONS.${status.toUpperCase()}`)
            .defaultMessage(''),
          value: status,
        }));
      case PROGRAM_PROJECT_FIELDS.PRIORITY:
        return priorityOptions.map((priority: string) => ({
          label: intl.get(
            `PROJECT_DETAIL.PRIORITY_OPTIONS.${priority.toUpperCase()}`
          ),
          value: priority,
        }));
      default:
        return [];
    }
  };

  return (
    <div className='flex-grow self-baseline'>
      <Typography
        variant='label'
        weight='medium'
        className='inline-block mb-2 leading-4 tracking-px02'
      >
        {intl.get('FILTER_GENERAL.VALUE')}
      </Typography>
      {column.type === COLUMN_OPTION_TYPES.TEXT && (
        <TextField
          name='table-header__search-input'
          placeholder={intl.get('FILTER_GENERAL.INPUT_VALUE')}
          state='default'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInput(event.target.value);
          }}
          value={filterInput as string}
          data-testid='filter__text-inputs'
          height='small'
          inputClassName='py-1'
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.OPTIONS && (
        <Dropdown
          options={getPickerOptions()}
          listProps={{ 'data-testid': 'filter__options-list' }}
          triggerProps={{
            'data-testid': 'filter__options-input',
            size: 'sm',
          }}
          onChange={(value: Option) => {
            setInput(value);
          }}
          values={filterInput ? [filterInput] : []}
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.DATE && (
        <div data-testid='date__picker' className='flex-grow'>
          <Datepicker
            canEnterDateManually={false}
            data-testid='filter__date-picker'
            canSelectRange={operator ? operator.value === 'BETWEEN' : true}
            endDateLabel={intl.get('FILTER_GENERAL.TO')}
            onPickDate={(event: any) => {
              setInput(event);
            }}
            startDateLabel={intl.get('FILTER_GENERAL.FROM')}
            triggerClassname='h-8 py-1 text-label'
            className='w-full'
          />
        </div>
      )}
    </div>
  );
};
