import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { PROJECTS_TABLE_TABS, TABLE_EXPORT_OPTIONS } from 'utils/constants';
import { selectUserProjects } from 'state/Projects/projectsSlice';
import {
  TableRowDensity,
  ProjectsTableTab,
  Project,
  Pagination as PaginationType,
  TableExportOptions,
} from 'utils/customTypes';
import TableHeaderFilters from '../TableHeaderFilters';
import MyProjectsTable from './MyProjectsTable';
import Pagination from 'Organisms/Pagination/ClientPagination';

const MyProjects: React.FC<{
  onAddProject: () => void;
  onDuplicateProject: (projectId: string) => void;
  onArchiveProject: (projectId: string) => void;
  onUpdatePagination: (
    pagination: PaginationType,
    table: ProjectsTableTab
  ) => void;
  onExportProjectsToCsv: (projectIds: string[], callback: () => void) => void;
}> = ({
  onAddProject,
  onDuplicateProject,
  onArchiveProject,
  onExportProjectsToCsv,
  onUpdatePagination,
}) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');
  const userProjectsList = useSelector(selectUserProjects);

  const allProjectsIds = useMemo(
    () => userProjectsList.all.map((project: Project) => project.id),
    [userProjectsList.all]
  );

  const currentPageProjectsIds = useMemo(
    () => userProjectsList.data.map((project: Project) => project.id),
    [userProjectsList.data]
  );

  const handleExportProjectsToCsv = (callback: () => void) => {
    onExportProjectsToCsv(selectedProjects, callback);
  };

  const handleSelectProjects = (projects: string[]) => {
    setSelectedProjects(projects);
  };

  const handleUpdatePagination = useCallback(
    (pagination) => {
      const table: ProjectsTableTab = PROJECTS_TABLE_TABS.MY_PROJECTS;
      onUpdatePagination(pagination, table);
    },
    [onUpdatePagination]
  );

  const handleSelectedExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedProjects(allProjectsIds);
      return;
    }

    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageProjectsIds.length > 0
    ) {
      setSelectedProjects(currentPageProjectsIds);
      return;
    }

    setSelectedProjects([]);
  };

  return (
    <div className='mt-4'>
      <TableHeaderFilters
        table={PROJECTS_TABLE_TABS.MY_PROJECTS}
        onExport={(callback: () => void) => handleExportProjectsToCsv(callback)}
        exportEnabled={!isEmpty(selectedProjects)}
        filterButtonEnabled={!isEmpty(userProjectsList.data)}
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
        totalProjects={userProjectsList.total}
      />
      <MyProjectsTable
        onSelectProjects={handleSelectProjects}
        totalProjects={userProjectsList.total}
        projectsList={userProjectsList.data}
        addNewProject={onAddProject}
        onDuplicateProject={onDuplicateProject}
        onArchiveProject={onArchiveProject}
        rowDensity={rowDensity}
        onSelectExportOption={handleSelectedExportOption}
        selectedProjectsIds={selectedProjects}
      />
      <Pagination
        total={userProjectsList.total}
        onChange={handleUpdatePagination}
        className='z-10 max-w-full'
      />
    </div>
  );
};

export default MyProjects;
