import { useState } from 'react';
import { Tabs } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import BudgetPlan from './BudgetPlan';
import BudgetDetails from './BudgetDetails';

const Budget = ({
  fromSidePanel,
  templateId,
  isReadOnly,
}: {
  fromSidePanel: boolean;
  templateId?: string;
  isReadOnly: boolean;
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  if (!templateId) {
    return (
      <div className='px-6'>
        <Tabs
          index={currentTabIndex}
          onChange={(index: number) => setCurrentTabIndex(index)}
          tabListProps={{
            className: 'w-68',
          }}
          type='tab'
          data={[
            {
              label: intl.get('BUDGET.BUDGET_PLAN'),
              content: <BudgetPlan fromSidePanel={fromSidePanel} />,
            },
            {
              label: intl.get('BUDGET.BUDGET_DETAILS'),
              content: <BudgetDetails isReadOnly={isReadOnly} />,
            },
          ]}
        />
      </div>
    );
  }

  return <BudgetDetails isReadOnly={isReadOnly} />;
};

export default Budget;
