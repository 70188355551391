import React, { useEffect, useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';
import { Typography } from '@getsynapse/design-system';
import kanbanCardPlus from 'assets/icons/kanban-card-plus.svg';
import { ProjectTemplateField } from 'utils/types/templates';
import TemplateFieldsList from './TemplateFieldsList';
import { FieldType } from 'utils/types/fields';

const DefaultValuesModal: React.FC<{
  shouldDisplayModal: boolean;
  templateFields: ProjectTemplateField[];
  onUpdate: (updatedFields: ProjectTemplateField[]) => void;
  onCancel: () => void;
}> = ({
  shouldDisplayModal = false,
  onUpdate,
  onCancel,
  templateFields = [],
}) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [updatedTemplateFields, setUpdatedTemplateFields] = useState<
    ProjectTemplateField[]
  >([]);

  useEffect(() => {
    if (shouldDisplayModal && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [modalProps, openModal, shouldDisplayModal]);

  useEffect(() => {
    if (templateFields.length > 0) {
      setUpdatedTemplateFields(templateFields);
    }
  }, [templateFields]);

  const canUpdate = useMemo(() => {
    return updatedTemplateFields.length > 0
      ? updatedTemplateFields.every(
          (field: ProjectTemplateField) => field.defaultValue !== undefined
        )
      : false;
  }, [updatedTemplateFields]);

  const handleCancel = () => {
    onCancel();
    closeModal();
  };

  const handleUpdate = () => {
    onUpdate(updatedTemplateFields);
    closeModal();
  };

  const handleSetDefaultValueToField = (
    newValue: FieldType['value'],
    fieldTemplateName: string,
    fieldId?: string
  ) => {
    const updatedTemplateFieldsCopy = [...updatedTemplateFields];
    let fieldToUpdateIndex = -1;
    if (fieldId) {
      fieldToUpdateIndex = updatedTemplateFieldsCopy.findIndex(
        (field: ProjectTemplateField) => field.id === fieldId
      );
    } else {
      fieldToUpdateIndex = updatedTemplateFieldsCopy.findIndex(
        (field: ProjectTemplateField) =>
          field.field_template.name === fieldTemplateName
      );
    }
    if (fieldToUpdateIndex !== -1) {
      updatedTemplateFieldsCopy[fieldToUpdateIndex] = {
        ...updatedTemplateFieldsCopy[fieldToUpdateIndex],
        defaultValue: newValue,
      };
      setUpdatedTemplateFields(updatedTemplateFieldsCopy);
    }
  };

  return (
    <Modal
      {...modalProps}
      size='medium'
      title={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DEFAULT_VALUES_MODAL.TITLE')}
      titleIcon={{
        src: kanbanCardPlus,
      }}
      aria-label={intl.get(
        'SETTINGS_PAGE.TEMPLATE_PAGE.DEFAULT_VALUES_MODAL.TITLE'
      )}
      data-cy='set-default-values-modal'
      childrenClassName='max-h-100 overflow-y-auto'
      actionButtons={[
        {
          children: intl.get('UPDATE'),
          variant: 'primary',
          onClick: handleUpdate,
          disabled: !canUpdate,
          'data-cy': 'set-default-values-modal__update-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: handleCancel,
        },
      ]}
      closeModal={handleCancel}
    >
      <Typography variant='body2' className='text-primary'>
        {intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.DEFAULT_VALUES_MODAL.DESCRIPTION'
        )}
      </Typography>
      <TemplateFieldsList
        templateFields={updatedTemplateFields}
        onUpdateField={handleSetDefaultValueToField}
      />
    </Modal>
  );
};

export default DefaultValuesModal;
