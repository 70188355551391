import { useState, useEffect, useMemo } from 'react';
import {
  Tooltip,
  IconButton,
  Typography,
  TextField,
  FormItem,
  TextArea,
  Dropdown,
  UsersPicker,
} from '@getsynapse/design-system';
import {
  getLDUsers,
  selectLDUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { Program, UserOption, Owner, Option } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import collapseIcon from 'assets/icons/collapse.svg';
import expandIcon from 'assets/icons/expand.svg';
import classnames from 'classnames';
import { PROGRAMS_DELIVERY_TYPE } from 'utils/constants';
import debounce from 'lodash/debounce';
import ProgramStatusPicker from './ProgramStatusPicker';
import { getProgramStatusPill } from 'Pages/ProgramsListPage/components/helpers';

const ProgramLeftPanel = ({
  program,
  userHasWriteAccess,
  handleFieldChange,
}: {
  program: Program;
  userHasWriteAccess: boolean;
  handleFieldChange: (path: string, value: any) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatch = useDispatch();
  const ldUsers = useSelector(selectLDUsersForDropdown);

  const CollapseExpandButtons = () => (
    <Tooltip
      timeout={0}
      position={isExpanded ? 'bottomLeft' : 'bottomRight'}
      contentProps={{
        className: classnames(
          'z-10 py-1 px-3 bg-neutral-darker min-w-12 absolute',
          'text-center shadow-tooltip'
        ),
      }}
      openMode='hover2'
      trigger={
        isExpanded ? (
          <IconButton
            className='text-xl'
            src={collapseIcon}
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <IconButton
            className='text-xl'
            src={expandIcon}
            onClick={() => setIsExpanded(true)}
          />
        )
      }
    >
      {isExpanded
        ? intl.get('REQUEST_PAGE.LEFT_PANEL.COLLAPSE')
        : intl.get('REQUEST_PAGE.LEFT_PANEL.EXPAND')}
    </Tooltip>
  );

  useEffect(() => {
    dispatch(getLDUsers());
  }, [dispatch]);

  const deliveryTypeOptions = useMemo(() => {
    return Object.values(PROGRAMS_DELIVERY_TYPE).map((type) => ({
      label: intl.get(
        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${type.toUpperCase()}`
      ),
      value: type,
    }));
  }, []);

  const ownersList = useMemo(() => {
    return ldUsers.filter((ldUser) =>
      program.programOwners?.some((owner: Owner) => {
        return owner.id === ldUser.value;
      })
    );
  }, [program.programOwners, ldUsers]);

  const getSelectedValue = (options: Option[], value: string | null) => {
    const selectedOption = options.find(
      (option: Option) => option.value === value
    );
    return selectedOption || [];
  };

  const changeOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    handleFieldChange('programOwners', ownersIds);
  };

  return (
    <div
      className={classnames(
        'bg-neutral-white pt-4 border border-neutral-lighter-two relative',
        'transition-all flex items-center flex-col',
        'duration-150 ease-in',
        'motion-safe',
        'border-b-0 rounded-t-lg rounded-b-none',
        `${isExpanded ? 'w-80 min-w-80' : 'w-12 min-w-12'}`
      )}
    >
      <div className='flex px-4 w-full items-center'>
        <div className={!isExpanded ? 'hidden' : 'w-full'}>
          <Typography variant='h5'>
            {intl.get('PROGRAM_PAGE.OVERVIEW')}
          </Typography>
        </div>
        <CollapseExpandButtons />
      </div>
      <div
        className={classnames(
          'bg-neutral-white rounded-lg py-4 mt-4 px-4',
          'h-fit border',
          'border-neutral-lighter-two relative',
          !isExpanded ? 'hidden' : 'w-70'
        )}
      >
        {userHasWriteAccess ? (
          <TextField
            variant='text'
            value={program.title}
            data-cy='program-title-field'
            onChange={debounce(
              (event) => handleFieldChange('title', event.target.value),
              500
            )}
            inputClassName='text-xl font-semibold'
          />
        ) : (
          <Tooltip
            timeout={100}
            position='botomCenter'
            contentProps={{
              className: classnames(
                'z-50 py-1 px-3 bg-neutral-darker w-60 absolute max-h-100 overflow-auto',
                'text-center shadow-tooltip'
              ),
            }}
            openMode='hover2'
            trigger={
              <Typography
                variant='h5'
                className='text-xl font-semibold line-clamp-2 w-60'
              >
                {program.title}
              </Typography>
            }
          >
            {program.title}
          </Tooltip>
        )}
        <FormItem
          className='mt-6'
          label={intl.get('ADD_PROGRAM_MODAL.DESCRIPTION')}
        >
          {userHasWriteAccess ? (
            <TextArea
              textAreaProps={{
                className: 'max-h-32',
                placeholder: intl.get(
                  'ADD_PROGRAM_MODAL.DESCRIPTION_PLACEHOLDER'
                ),
                'data-cy': 'program-description-field',
                defaultValue: program.description,
              }}
              onChange={debounce(
                (event) => handleFieldChange('description', event.target.value),
                500
              )}
            />
          ) : program.description ? (
            <Tooltip
              timeout={100}
              position='botomCenter'
              contentProps={{
                className: classnames(
                  'z-50 py-1 px-3 bg-neutral-darker w-60 absolute max-h-100 overflow-auto',
                  'text-center shadow-tooltip'
                ),
              }}
              openMode='hover2'
              trigger={
                <Typography className='line-clamp-4 w-60'>
                  {program.description}
                </Typography>
              }
            >
              {program.description}
            </Tooltip>
          ) : (
            <Typography className='line-clamp-4 w-60 text-neutral'>
              {intl.get('PROGRAM_PAGE.NO_DESCRIPTION')}
            </Typography>
          )}
        </FormItem>
        <FormItem
          className='mt-6'
          label={intl.get('ADD_PROGRAM_MODAL.OWNERS')}
          labelProps={{ required: true }}
        >
          <UsersPicker
            usersList={ldUsers}
            selectedUsersList={ownersList}
            onChange={changeOwners}
            required
            maxLimit={2}
            disabled={!userHasWriteAccess}
            triggerProps={{ 'data-testid': 'owners-picker-trigger' }}
          />
        </FormItem>
        <FormItem
          className='mt-6'
          label={intl.get('PROGRAM_PAGE.STATUS')}
          labelProps={{ required: true }}
        >
          {userHasWriteAccess ? (
            <ProgramStatusPicker
              program={program}
              handleFieldChange={handleFieldChange}
              getSelectedValue={getSelectedValue}
            />
          ) : (
            getProgramStatusPill(program.status)
          )}
        </FormItem>
        <FormItem
          className='mt-6'
          label={intl.get('ADD_PROGRAM_MODAL.DELIVERY_TYPE')}
        >
          {userHasWriteAccess ? (
            <Dropdown
              options={deliveryTypeOptions}
              onChange={(option: Option) => {
                handleFieldChange('delivery_type', option.value);
              }}
              triggerProps={{
                placeholder: intl.get(
                  'ADD_PROGRAM_MODAL.DELIVERY_TYPE_PLACEHOLDER'
                ),
                'data-cy': 'program-delivery-field',
              }}
              listProps={{
                'data-cy': 'delivery-type_list',
              }}
              values={[
                getSelectedValue(deliveryTypeOptions, program.delivery_type),
              ]}
            />
          ) : (
            intl.get(
              `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${program.delivery_type?.toUpperCase()}`
            )
          )}
        </FormItem>
      </div>
    </div>
  );
};

export default ProgramLeftPanel;
