import React from 'react';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const StatusDescription: React.FC<{
  description: string;
  color: string;
}> = ({ description, color }) => {
  return (
    <div className='flex px-2 py-1 items-center'>
      <div className={`w-2 h-2 rounded-full bg-${color} mr-2`} />
      <Typography variant='label' className='text-neutral-darker'>
        {description}
      </Typography>
    </div>
  );
};

const AllocationStatusDescription = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full px-4 py-3 bg-neutral-white',
        'flex justify-end space-x-2',
        'shadow-capacity-table-allocation-status'
      )}
    >
      <StatusDescription
        description={intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION')}
        color='success-darker'
      />
      <StatusDescription
        description={intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.TABLE.OVERALLOCATION'
        )}
        color='error-dark'
      />
      <StatusDescription
        description={intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.TABLE.TIME_OFF_HOLIDAYS'
        )}
        color='fire-dark'
      />
    </div>
  );
};

export default AllocationStatusDescription;
