import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PROJECT_PRIVACY, USER_ROLES } from './constants';
import {
  AllUsersType,
  LDTeam,
  LearningTeam,
  Project,
  Task,
} from './customTypes';

export const canUpdateTask: (
  task: Task,
  project: Project,
  user: AllUsersType,
  ldTeams: LearningTeam[],
  tasksAssignmentsBasedResourcesCapacity?: boolean
) => boolean = (task, project, user, ldTeams, tasksAssignmentsBasedResourcesCapacity) => {
  const isAdmin: boolean = user.role === USER_ROLES.ADMIN;
  if (isAdmin) return true;

  const isOwner: boolean = Boolean(
    project.owners.find((owner) => owner.project_owners.userId === user.id)
  );
  if (isOwner) return true;

  const isMember: boolean = Boolean(
    project.participants.find(
      (participant) => tasksAssignmentsBasedResourcesCapacity ? get(participant, 'v2_project_participant.user_id') === user.id
      : get(participant, 'project_participants.userId') === user.id
    )
  );
  const isCollaborator: boolean = Boolean(
    project.collaborators.find(
      (collaborator) => collaborator.project_collaborators.userId === user.id
    )
  );
  const isAssignee: boolean = Boolean(
    task.assignedUsers?.find((assignedUser) => assignedUser.id === user.id)
  );
  const isCreator: boolean = Boolean(task.created_by === user.id);
  if ((isMember || isCollaborator) && (isAssignee || isCreator)) return true;

  const isTeamPrivate: boolean = project.privacy === PROJECT_PRIVACY.TEAM;
  if (isTeamPrivate) {
    const allowedProjectTeams = get(project, 'ldteams', []).map(
      (team: LDTeam) => team.id
    );
    const managedTeams = get(user, 'teamsManaged', []) as LearningTeam[];

    const getSubTeamIds = (ldTeamId?: string) => {
      if (!ldTeamId) {
        return [];
      }
      return ldTeams
        .filter((team: LearningTeam) => {
          return team.parent_id === ldTeamId;
        })
        .map((team: LearningTeam) => team.id);
    };

    const checkSubTeamsAccess = (baseTeamIds: string[]) => {
      const exploredLearningTeamIds = new Set();
      while (!isEmpty(baseTeamIds)) {
        const learningTeamId = baseTeamIds.shift();
        exploredLearningTeamIds.add(learningTeamId);
        if (learningTeamId && allowedProjectTeams.includes(learningTeamId)) {
          return true;
        }
        const subTeamIds = getSubTeamIds(learningTeamId);
        for (const childTeam of subTeamIds) {
          if (!exploredLearningTeamIds.has(childTeam)) {
            baseTeamIds.push(childTeam);
          }
        }
      }
      return false;
    };

    const userManagedTeamIds = managedTeams.map(
      (team: LearningTeam) => team.id
    );
    const userManagesAllowedTeam = checkSubTeamsAccess(userManagedTeamIds);

    if (userManagesAllowedTeam) return true;
  }

  return false;
};
