import { useState, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@getsynapse/design-system';
import {
  exportProjects,
  fetchProjects,
  fetchUserProjects,
  resetBoardView,
  updatePagination,
  resetTableSearch,
} from 'state/Projects/projectsSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import { selectIsUserLd } from 'state/User/userSlice';
import { fetchProject, setProjectId } from 'state/Project/projectSlice';
import { Pagination, ProjectsTableTab } from 'utils/customTypes';
import { SETTINGS_FILTERS } from 'utils/constants';
import DuplicateProjectModal from '../components/DuplicateProject/DuplicateProjectModal';
import TeamProjects from '../components/TeamProjects/TeamProjects';
import MyProjects from '../components/MyProjects/MyProjects';
import ArchiveProjectModal from 'Organisms/ArchiveProjectModal/ArchiveProjectModal';
import ProjectSidePanel from 'Organisms/ProjectSidePanel/ProjectSidePanel';

const TableView: React.FC<{ handleAddProject: () => void }> = ({
  handleAddProject,
}) => {
  const dispatch = useDispatch();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isDuplicateProjectModalOpen, setIsDuplicateProjectModalOpen] =
    useState<boolean>(false);
  const [displayArchiveModal, setDisplayArchiveModal] = useState(false);
  const isLDUser = useSelector(selectIsUserLd);

  useEffect(() => {
    dispatch(resetBoardView());
    dispatch(getOrganizationProcesses());
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.TEAM_PROJECTS_TABLE));
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECTS_TABLE));
    dispatch(resetTableSearch());
  }, [dispatch]);

  const handleExportProjectsToCsv = async (
    projectIds: string[],
    isTeamModule: boolean,
    callback: () => void
  ) => {
    await dispatch(exportProjects({ projectIds, isTeamModule }));
    callback();
  };

  const handleDuplicateProject = (projectId: string) => {
    dispatch(fetchProject(projectId));
    setIsDuplicateProjectModalOpen(true);
  };

  const onArchiveProject = (projectId: string) => {
    dispatch(fetchProject(projectId));
    setDisplayArchiveModal(true);
  };

  const afterArchiveProject = () => {
    dispatch(fetchProjects());
    dispatch(fetchUserProjects());
  };

  const onCloseProjectPanel = async () => {
    await dispatch(setProjectId(''));
  };

  const handleUpdatePagination = useCallback(
    (pagination: Pagination, table: ProjectsTableTab) => {
      dispatch(updatePagination(pagination, table));
    },
    [dispatch]
  );

  return (
    <div className='relative w-full h-full'>
      <DuplicateProjectModal
        isOpen={isDuplicateProjectModalOpen}
        onCloseModal={() => setIsDuplicateProjectModalOpen(false)}
      />
      <ArchiveProjectModal
        afterArchiveProject={afterArchiveProject}
        shouldDisplayModal={displayArchiveModal}
        onCloseModal={() => setDisplayArchiveModal(false)}
      />
      <ProjectSidePanel onClosePanel={onCloseProjectPanel} />
      <div className='pb-4 px-6'>
        {isLDUser ? (
          <Tabs
            index={currentTabIndex}
            onChange={(index: number) => setCurrentTabIndex(index)}
            tabListProps={{ className: 'w-58 mb-4' }}
            tabPanelsProps={{ className: 'max-h-table overflow-y-auto' }}
            type='tab'
            data={[
              {
                label: intl.get('PROJECTS_LIST_PAGE.TEAM_PROJECTS'),
                content: currentTabIndex === 0 && (
                  <TeamProjects
                    onAddProject={handleAddProject}
                    onDuplicateProject={handleDuplicateProject}
                    onArchiveProject={onArchiveProject}
                    onExportProjectsToCsv={(projectIds, callback) =>
                      handleExportProjectsToCsv(projectIds, true, callback)
                    }
                    onUpdatePagination={handleUpdatePagination}
                  />
                ),
              },
              {
                label: intl.get('PROJECTS_LIST_PAGE.MY_PROJECTS'),
                content: currentTabIndex === 1 && (
                  <MyProjects
                    onAddProject={handleAddProject}
                    onDuplicateProject={handleDuplicateProject}
                    onArchiveProject={onArchiveProject}
                    onExportProjectsToCsv={(projectIds, callback) =>
                      handleExportProjectsToCsv(projectIds, false, callback)
                    }
                    onUpdatePagination={handleUpdatePagination}
                  />
                ),
              },
            ]}
          />
        ) : (
          <div>
            <span className='text-neutral-dark font-semibold text-base'>
              {intl.get('PROJECTS_LIST_PAGE.MY_PROJECTS')}
            </span>
            <MyProjects
              onAddProject={handleAddProject}
              onDuplicateProject={handleDuplicateProject}
              onArchiveProject={onArchiveProject}
              onExportProjectsToCsv={(projectIds, callback) =>
                handleExportProjectsToCsv(projectIds, false, callback)
              }
              onUpdatePagination={handleUpdatePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableView;
