import intl from 'react-intl-universal';
import {
  FormItem,
  TextField,
  Toggle,
  Typography,
} from '@getsynapse/design-system';
import { get, debounce } from 'lodash';
import { CUSTOM_FIELDS } from 'utils/constants';
import {
  FieldTemplateType,
  FieldValueType,
  BaseValue,
} from 'utils/types/fields';

export const ToggleField = ({
  data,
  handleFieldChange,
}: {
  data: FieldTemplateType;
  handleFieldChange: (
    fieldName: string,
    fieldValue: FieldValueType[] | BaseValue
  ) => void;
}) => {
  const toggleValue = get(data, 'default_value.val', false);
  const onOption = data?.allowed_values?.find(
    (option: FieldValueType) => option.val
  );
  const offOption = data?.allowed_values?.find(
    (option: FieldValueType) => !option.val
  );

  const updateToggleCheck = (checked: boolean) => {
    handleFieldChange(CUSTOM_FIELDS.DEFAULT_VALUE, {
      val: checked,
      display: toggleValue ? onOption?.display : offOption?.display,
    });
  };

  const changeToggleText = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedAllowedValues = toggleValue
        ? data?.allowed_values?.map((item: FieldValueType) =>
            item.val ? { ...item, display: event.target.value } : item
          )
        : data?.allowed_values?.map((item: FieldValueType) =>
            !item.val ? { ...item, display: event.target.value } : item
          );
      handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, updatedAllowedValues!);
    },
    500
  );

  return (
    <>
      <FormItem
        label={
          data?.name ||
          intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TITLE_FIELD_NAME')
        }
        className='mt-6'
      >
        <div className='flex items-center'>
          <Toggle
            id={`toggle-${data.id}`}
            checked={toggleValue}
            onChange={(event) => updateToggleCheck(event.target.checked)}
            inputProps={{ 'data-cy': 'field-toggle' }}
          />
          <TextField
            noBorder
            onChange={changeToggleText}
            defaultValue={toggleValue ? onOption?.display : offOption?.display}
            placeholder={intl.get(
              'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.TOGGLE.VALUE'
            )}
            key={toggleValue}
          />
        </div>
      </FormItem>
      <Typography className='text-neutral' variant='body2' weight='medium'>
        {intl.get(
          'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.TOGGLE.HELP_TEXT'
        )}
      </Typography>
    </>
  );
};

export default ToggleField;
