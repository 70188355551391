import { ComponentProps, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import {
  Dropdown,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Option, BusinessTeam, SortingType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import { MultipleOptionLI } from '../../Pages/NewProjectPage/helpers/snippets';

type Props = {
  formItemProps: ComponentProps<typeof FormItem>;
  businessUnits: Option[] | BusinessTeam[];
  sortOrder?: SortingType;
  dropdownProps: Partial<ComponentProps<typeof Dropdown>>;
  currentValue?: string;
};

const BusinessUnitPicker = ({
  formItemProps,
  dropdownProps,
  currentValue = '',
  sortOrder = 'asc',
  businessUnits = [],
}: Props) => {
  const formatOptions = useMemo(() => {
    return orderBy(
      businessUnits,
      (businessUnit) => {
        const businessLabel =
          get(businessUnit, 'label') || get(businessUnit, 'title') || '';
        return businessLabel.toLocaleLowerCase();
      },
      sortOrder
    ).map((businessUnit) => ({
      label: get(businessUnit, 'label') || get(businessUnit, 'title'),
      value: get(businessUnit, 'value') || get(businessUnit, 'id'),
    }));
  }, [businessUnits, sortOrder]);

  const {
    onChange = () => {},
    multiple,
    ...otherDropdownProps
  } = dropdownProps;
  return (
    <FormItem {...formItemProps}>
      <Dropdown
        multiple={multiple}
        onChange={onChange}
        options={formatOptions}
        values={getInitialValueForDropDown(formatOptions, currentValue)}
        renderOption={
          multiple
            ? (
                option: Option,
                isSelected: boolean,
                selectOption,
                { className, ...otherProps }
              ) => (
                <li
                  {...otherProps}
                  className={tailwindOverride('group', className, {
                    'hover:bg-primary focus-visible:bg-primary': isSelected,
                  })}
                >
                  <MultipleOptionLI
                    label={option.label}
                    isSelected={isSelected}
                    selectOption={selectOption}
                  />
                </li>
              )
            : undefined
        }
        {...otherDropdownProps}
      />
    </FormItem>
  );
};

export default BusinessUnitPicker;
