import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import { Button, GlobalBanner, Typography } from '@getsynapse/design-system';
import { DATE, PROJECT_CANCEL_REASONS } from 'utils/constants';

export const OnHoldStatusBanner: React.FC<{
  message: string;
  handleOnClick?: () => void;
  iconProps?: { 'data-testid'?: string; name?: string; src?: string };
  sidePanel?: boolean;
}> = ({ message, handleOnClick, iconProps, sidePanel = false }) => (
  <GlobalBanner
    variant='warning'
    hasCloseIcon
    className={classnames(`h-16 items-center ${sidePanel ? 'mb-4' : 'mt-4'}`)}
    data-cy='on-hold-inline-notification'
    iconProps={iconProps}
  >
    {() => (
      <div className='flex justify-between items-center'>
        <span>{message}</span>
        {handleOnClick && (
          <Button
            color='warning'
            onClick={handleOnClick}
            data-cy='view-hold-reason-button'
          >
            {intl.get('PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.VIEW_DETAILS')}
          </Button>
        )}
      </div>
    )}
  </GlobalBanner>
);

export const ClosedStatusBanner: React.FC<{
  handleOnClick?: () => void;
  closedDate?: string | null;
  sidePanel?: boolean;
}> = ({ handleOnClick, closedDate, sidePanel = false }) => (
  <GlobalBanner
    variant='information'
    hasCloseIcon
    className='mt-4 items-center'
    data-cy='closed-inline-notification'
  >
    {() => (
      <div className='flex justify-between items-center'>
        <Typography variant='body' className='text-primary'>
          {intl.get('PROJECT_DETAIL.CLOSE_PROJECT.CLOSED_PROJECT_BANNER')}
        </Typography>
        <div className='flex items-center'>
          <Typography
            variant='body'
            className={classnames(
              `text-primary ${sidePanel ? 'mb-4' : 'mr-4'}`
            )}
            data-cy='closed-notification-date'
          >
            {closedDate &&
              moment(closedDate).format(DATE.FULL_MONTH_YEAR_FORMAT)}
          </Typography>
          {handleOnClick && (
            <Button onClick={handleOnClick} data-cy='view-closed-details'>
              {intl.get('PROJECT_DETAIL.CLOSE_PROJECT.VIEW_DETAILS_BUTTON')}
            </Button>
          )}
        </div>
      </div>
    )}
  </GlobalBanner>
);

export const CancelledStatusBanner: React.FC<{
  handleOnClick?: () => void;
  reason?: string | null;
  sidePanel?: boolean;
}> = ({ handleOnClick, reason, sidePanel = false }) => (
  <GlobalBanner
    variant='information'
    className={`${sidePanel ? 'mb-4' : 'mt-4'}`}
    data-cy='cancelled-project-banner'
  >
    {() => (
      <div className='flex justify-between'>
        <div>
          <Typography variant='h6'>
            {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.BANNER_TITLE')}
          </Typography>
          <Typography data-cy='banner-cancellation-reason'>
            {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.REASON', {
              reason:
                intl.get(
                  `PROJECT_DETAIL.CANCEL_PROJECT.REASONS.${Object.keys(
                    PROJECT_CANCEL_REASONS
                  ).find((key) => PROJECT_CANCEL_REASONS[key] === reason)}`
                ) || reason,
            })}
          </Typography>
        </div>
        <div className='flex items-center'>
          {handleOnClick && (
            <Button
              onClick={handleOnClick}
              data-cy='cancelled-banner-button'
            >
              {intl.get('PROJECT_DETAIL.CLOSE_PROJECT.VIEW_DETAILS_BUTTON')}
            </Button>
          )}
        </div>
      </div>
    )}
  </GlobalBanner>
);
