import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import {
  Button,
  Typography,
  tailwindOverride,
  IconButton,
} from '@getsynapse/design-system';
import { Participant } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { getUserDisplayName } from 'utils/functions';
import { PROJECT_PARTICIPANT_TYPE } from 'utils/constants';
import { formatter } from 'utils/functions';
import { setParticipantToUpdate } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import UserAvatar from 'Atoms/UserAvatar';
import { selectUserId } from 'state/User/userSlice';

const ProjectRoles: React.FC<{
  isOwner: boolean;
  roles: string[];
  userId: string;
}> = ({ isOwner, roles, userId }) => {
  return (
    <div className='flex flex-1 flex-col justify-start space-y-3 mr-4 py-1'>
      {isOwner && (
        <Typography variant='caption' className='text-primary'>
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.PROJECT_OWNER_ROLE')}
        </Typography>
      )}
      {!isOwner &&
        roles.length > 0 &&
        roles.map((role, index) => (
          <Typography
            key={`user-${userId}__role-${index}`}
            variant='caption'
            className='text-primary'
          >
            {role}
          </Typography>
        ))}
      {!isOwner && roles.length === 0 && (
        <Typography variant='caption' className='text-primary-dark'>
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.NO_ROLE')}
        </Typography>
      )}
    </div>
  );
};

const UserDetails: React.FC<{
  isOwner: boolean;
  user: Participant;
  hasTasksAssigned?: boolean;
  toggleTaksSection?: () => void;
  isTasksSectionOpen?: boolean;
}> = ({
  isOwner = false,
  user,
  hasTasksAssigned = false,
  toggleTaksSection,
  isTasksSectionOpen = false,
}) => {
  const dispatch = useDispatch();
  const userId = get(user, 'id');
  const roles = get(user, 'job_roles', []);
  const { hasUserPermissionsLevelOf } = useUserPermissionsContext();
  const currentUserId = useSelector(selectUserId);
  const isCurrentUserOwner = hasUserPermissionsLevelOf(
    PROJECT_PARTICIPANT_TYPE.OWNER
  );
  const isCurrentUserMember = hasUserPermissionsLevelOf(
    PROJECT_PARTICIPANT_TYPE.MEMBER
  );
  const canCurrentUserUpdateRoles =
    isCurrentUserOwner || (isCurrentUserMember && currentUserId === userId);
  const handleUpdatRoles = () => {
    dispatch(
      setParticipantToUpdate({
        user_id: userId,
        job_roles: roles,
      })
    );
  };
  return (
    <div className='w-2/5 flex h-full relative'>
      <div className='w-12 py-2' />
      <div className='w-2/4 flex p-2'>
        <div className='w-6'>
          <UserAvatar
            user={{
              avatar_url: get(user, 'avatar_url'),
              data: {
                firstName: get(user, 'data.firstName'),
                lastName: get(user, 'data.lastName'),
                email: get(user, 'data.email'),
              },
            }}
          />
        </div>
        <div className='flex flex-1 flex-col ml-2'>
          <Typography
            variant='label'
            className='text-primary font-semibold mb-1'
          >
            {getUserDisplayName(user)}
          </Typography>
          <Typography variant='label' className='text-neutral mb-2 break-all'>
            {get(user, 'data.email')}
          </Typography>
          {!hasTasksAssigned && (
            <Typography variant='caption' className='text-primary-dark'>
              {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.NO_TASKS')}
            </Typography>
          )}
          {hasTasksAssigned && (
            <Button
              variant='tertiary'
              disabled={!hasTasksAssigned}
              className='text-sm self-start px-1 text-primary'
              onClick={toggleTaksSection}
              size='small'
              data-cy={`participant-${userId}__toggle-tasks-button`}
            >
              {isTasksSectionOpen
                ? intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HIDE_TASKS')
                : intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.VIEW_TASKS')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={tailwindOverride(
          'w-1/4 p-2',
          'flex justify-start justify-between'
        )}
      >
        <ProjectRoles userId={userId} roles={roles} isOwner={isOwner} />
        {!isOwner && canCurrentUserUpdateRoles && (
          <div className='w-6'>
            <IconButton
              onClick={handleUpdatRoles}
              name='pencil'
              className='text-lg text-neutral-dark p-1'
              aria-label={`participant-${userId}__update-roles-button`}
              data-cy={`participant-${userId}__update-roles-button`}
            />
          </div>
        )}
      </div>
      <div className='w-1/4 p-2'>
        <Typography variant='caption' className='text-primary'>
          {`${formatter.format(user.total_allocation)} ${intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
          )}`}
        </Typography>
      </div>
    </div>
  );
};

export default UserDetails;
