import { useEffect, useCallback, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, Typography, Icon } from '@getsynapse/design-system';
import {
  selectAllUsers,
  getAllUsers,
  updateUsersPagination,
  setUsersOrder,
  getLDUsers,
  selectAvailableLicenses,
  setSearchParam,
  setUserFilters,
} from 'state/UsersManagement/usersManagementSlice';
import { selectOrganizationLicense } from 'state/Organization/organizationSlice';
import { LICENSE_TIER } from 'utils/constants';
import { License } from 'utils/customTypes';
import UsersTable from './UsersTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import AddUserModal from './AddUserModal';

const UsersListPage = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const fetchUsersWithPagination = useCallback(
    (params) => {
      dispatch(updateUsersPagination(params));
    },
    [dispatch]
  );

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const licenseData: License = useSelector(selectOrganizationLicense);
  const availableLicenses = useSelector(selectAvailableLicenses);

  useEffect(() => {
    dispatch(setSearchParam(''));
    dispatch(setUserFilters([]));
    dispatch(
      setUsersOrder({
        order: 'asc',
        orderBy: ['data.firstName', 'data.lastName'],
      })
    );
    dispatch(getLDUsers());
  }, [dispatch]);

  return (
    <Fragment>
      <AddUserModal isOpen={showAddUserModal} setIsOpen={setShowAddUserModal} />
      <div className='flex justify-between items-center mb-4'>
        <Typography className='text-neutral-dark' weight='medium'>
          {intl.get('USERS_PAGE.TITLE')}
        </Typography>
        <div className='flex'>
          {licenseData.license_tier !== LICENSE_TIER.TRIAL && (
            <div className='flex flex-row mr-8 items-center'>
              <Icon
                name='information-circle-outline'
                className='text-xl mr-2'
              />
              <Typography
                variant='body2'
                className='text-neutral-dark leading-4'
              >
                {intl.get('LICENSE.LICENSE_REMAINING', {
                  purchasedLicense: licenseData.license_number,
                  licensesLeft: availableLicenses,
                })}
              </Typography>
            </div>
          )}
          <Button
            onClick={() => setShowAddUserModal(true)}
            data-cy='add-user_button'
          >
            {intl.get('ADD_USER.ADD_USER_BUTTON')}
          </Button>
        </div>
      </div>
      <UsersTable data={users.data} allUsers={users.all} />
      <Pagination total={users.total} onChange={fetchUsersWithPagination} />
    </Fragment>
  );
};

export default UsersListPage;
