import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import {
  Program,
  Status,
  SortingType,
  ProgramLinkedProject,
  filter,
} from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { RootState } from 'state/store';
import { orderProjectsBy, filterProgramProjects } from './helpers';
import { isEmpty, get } from 'lodash';
import programAPI from './programAPI';

interface programState {
  value: Program;
  status: Status;
  projectsForProgram: ProgramLinkedProject[];
  projectsSearchValue: string;
  sorting: {
    orderBy: string;
    order: SortingType;
  };
  filters: filter[];
  isFiltersOn: boolean;
}

/* ============================= INITIAL STATE ============================== */

const defaultValues = {
  title: '',
  id: '',
  description: '',
  status: '',
  programOwners: [],
  createdAt: '',
  delivery_type: null,
  program_number: null,
  programProjects: [],
  program_creator_id: '',
  updatedProjects: undefined,
} as Program;

const initialState: programState = {
  value: defaultValues,
  status: SLICE_STATUS.IDLE,
  projectsForProgram: [],
  projectsSearchValue: '',
  sorting: {
    order: 'asc',
    orderBy: '',
  },
  filters: [],
  isFiltersOn: false,
};

/* ============================= ACTIONS ============================== */
export const resetProgram = createAction('program/RESET_PROGRAM');

export const setLinkedProjects = createAction<{
  projects: ProgramLinkedProject[];
}>('program/SET_LINKED_PROJECTS');

export const unlinkProjectFromProgram = createAction<{
  projectId: string;
}>('program/UNLINK_PROJECT');

export const setProgramProjectsTableSearch = createAction(
  'program/SET_PROJECTS_SEARCH',
  (search: string) => {
    return { payload: search };
  }
);

export const setProgramProjectsSortingOptions = createAction(
  'program/SET_ORDERS',
  (column: string, order: SortingType) => {
    return { payload: { order: order, orderBy: column } };
  }
);

export const setProjectsFilters = createAction<filter[]>(
  'program/SET_projects_FILTERS'
);

export const setProjectsFiltersStatus = createAction<boolean>(
  'program/SET_projects_FILTERS_STATUS'
);

export const updateLinkedProject = createAction<{
  project: ProgramLinkedProject;
}>('program/UPDATE_LINKED_PROJECT');

/* ============================== REDUX THUNK =============================== */
export const fetchProgram = createAsyncThunk(
  'program/FETCH_PROGRAM',
  async (programId: string) => {
    const response = await programAPI.fetchProgram(programId);
    return response;
  }
);

export const fetchProjectsForProgram = createAsyncThunk(
  'programs/FETCH_PROJECTS_FOR_PROGRAM',
  async (programId: string) => {
    const { data } = await programAPI.fetchProjectsForProgram(programId);
    return data;
  }
);

export const updateProgram = createAsyncThunk(
  'programs/UPDATE_PROGRAM',
  async ({
    programId,
    updateFields,
  }: {
    programId: string;
    updateFields: Partial<Program>;
  }) => {
    const response = await programAPI.updateProgram(programId, updateFields);
    if (response.success) {
      return response.data;
    }
    return response;
  }
);

export const updateProgramOwners = createAsyncThunk(
  'programs/SET_OWNERS',
  async ({
    programId,
    ownersIds,
  }: {
    programId: string;
    ownersIds: string[];
  }) => {
    const response = await programAPI.updateProgramOwners(programId, ownersIds);
    if (response.success) {
      return response.data;
    }
    return response;
  }
);

export const updateProgramProjects = createAsyncThunk(
  'programs/SET_PROJECTS',
  async ({
    programId,
    projectIds,
  }: {
    programId: string;
    projectIds: string[];
  }) => {
    const response = await programAPI.updateProgramProjects(
      programId,
      projectIds
    );
    if (response.success) {
      return response.data;
    }
    return response;
  }
);

/* ================================= REDUCER ================================ */
const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgram.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProgram.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProgram.fulfilled, (state, action) => {
        state.value = action.payload.data;
        const apiCallSucceeded = get(action, 'payload.success', false);

        if (apiCallSucceeded) {
          state.status = SLICE_STATUS.IDLE;
        } else {
          state.status = SLICE_STATUS.FAILED;
        }
      })
      .addCase(fetchProjectsForProgram.fulfilled, (state, action) => {
        state.projectsForProgram = action.payload;
      })
      .addCase(setLinkedProjects, (state, action) => {
        if (!state.value.updatedProjects) {
          state.value.updatedProjects = [
            ...state.value.programProjects,
            ...action.payload.projects,
          ];
        } else {
          state.value.updatedProjects = [
            ...state.value.updatedProjects,
            ...action.payload.projects,
          ];
        }
      })
      .addCase(updateLinkedProject, (state, action) => {
        if (!state.value.updatedProjects) {
          state.value.updatedProjects = [...state.value.programProjects];
        }
        const oldProject = state.value.updatedProjects?.filter(
          (project) => project.id === action.payload.project.id
        );
        state.value.updatedProjects = state.value.updatedProjects?.filter(
          (project) => project.id !== action.payload.project.id
        );
        state.value.updatedProjects.unshift({
          ...oldProject[0],
          ...action.payload.project,
        });
      })
      .addCase(unlinkProjectFromProgram, (state, action) => {
        if (!state.value.updatedProjects) {
          state.value.updatedProjects = state.value.programProjects.filter(
            (project) => project.id !== action.payload.projectId
          );
        } else {
          state.value.updatedProjects = state.value.updatedProjects.filter(
            (project) => project.id !== action.payload.projectId
          );
        }
      })
      .addCase(resetProgram, (state) => {
        state.value = defaultValues;
        state.projectsSearchValue = '';
        state.sorting = {
          order: 'asc',
          orderBy: '',
        };
      })
      .addCase(setProgramProjectsSortingOptions, (state, action) => {
        state.sorting = action.payload;
      })
      .addCase(setProgramProjectsTableSearch, (state, action) => {
        state.projectsSearchValue = action.payload;
      })
      .addCase(setProjectsFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(setProjectsFiltersStatus, (state, action) => {
        state.isFiltersOn = action.payload;
      })
      .addCase(updateProgram.fulfilled, (state, action) => {
        if (get(action, 'payload.updatedProgram', null)) {
          state.value = { ...state.value, ...action.payload.updatedProgram };
        }
      })
      .addCase(updateProgramOwners.fulfilled, (state, action) => {
        if (get(action, 'payload.programOwners', null)) {
          state.value.programOwners = action.payload.programOwners;
        }
      })
      .addCase(updateProgramProjects.fulfilled, (state, action) => {
        if (get(action, 'payload.programProjects', null)) {
          state.value.programProjects = action.payload.programProjects;
          state.value.updatedProjects = undefined;
        }
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectProgramSliceStatus = (state: RootState) =>
  state.program.status;
export const getProgramData = (state: RootState) => {
  const updatedProjects = get(state, 'program.value.updatedProjects');
  if (!updatedProjects) {
    return {
      ...state.program.value,
      updatedProjects: state.program.value.programProjects,
    };
  }
  return state.program.value;
};

export const getProgramId = (state: RootState) => state.program.value.id;

export const selectProgramProjectsSorting = (state: RootState) =>
  state.program.sorting;
export const selectProgramProjectsTableSearch = (state: RootState) =>
  state.program.projectsSearchValue;
export const selectProgramProjectsTableFilters = (state: RootState) =>
  state.program.filters;
export const selectProgramProjectsTableFiltersStatus = (state: RootState) =>
  state.program.isFiltersOn;

export const selectProgramStatus = (state: RootState) => state.program.status;

export const selectProgramWithFilteredProjects = createSelector(
  [
    getProgramData,
    selectProgramProjectsTableSearch,
    selectProgramProjectsSorting,
    selectProgramProjectsTableFilters,
  ],
  (program, searchValue, sorting, filters) => {
    let filteredProgram = { ...program };
    let linkedProjectsList = program.updatedProjects || [];
    if (!isEmpty(linkedProjectsList)) {
      const filteredProjects = filterProgramProjects(
        linkedProjectsList,
        searchValue,
        filters
      );
      const sortedProjects = orderProjectsBy(
        filteredProjects,
        sorting.orderBy,
        sorting.order
      );
      filteredProgram.updatedProjects = sortedProjects;
    }

    return filteredProgram;
  }
);

export const getAllProjectsForProgram = (state: RootState) =>
  state.program.projectsForProgram;

export const getAvailabeProjectsForLinking = createSelector(
  [getProgramData, getAllProjectsForProgram],
  (program: Program, projects: ProgramLinkedProject[]) =>
    projects.filter(
      (project) =>
        !program.updatedProjects!.some(
          (programProject) => programProject.id === project.id
        )
    )
);

export default programSlice.reducer;
