import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { selectOrganizationId, selectUserId } from 'state/User/userSlice';
import { RootState } from 'state/store';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Project,
  ProjectsTableTab,
  SortingType,
  SortingOrderType,
  objKeyAsString,
  NewProject,
  ProjectsListViewMode,
  ProjectProcess,
  ProjectProcessStage,
  ProjectsBoardTabs,
  Status,
} from 'utils/customTypes';
import { ProjectFilters } from 'utils/types/filters';
import {
  PROJECTS_LIST_VIEW_MODE,
  PROJECTS_BOARD_TABS,
  SLICE_STATUS,
  PENDO_EVENTS,
  PROJECT_PRIVACY,
} from 'utils/constants';
import { trackEvent } from 'Services/pendo';
import {
  groupProjectsByStage,
  filterProjects,
  filterOutInactiveProjects,
  processNewProjectData,
  formatProjectsList,
  orderProjectsBy,
} from './helpers';
import { selectProjectProcesses } from 'state/Processes/processesSlice';
import { selectProjectCategories } from 'state/Organization/organizationSlice';
import ProjectsAPI from './projectsAPI';
import exportAPI from 'Services/exportAPI';
import ProjectAPI from '../Project/projectAPI';

interface ProjectsState {
  viewMode: ProjectsListViewMode;
  projects: Project[];
  userProjects: Project[];
  teamProjectsTable: {
    filters: ProjectFilters;
    pagination: {
      limit: number;
      offset: number;
    };
    sorting: {
      orderBy: string;
      order: SortingType;
    };
    searchParam: string;
  };
  myProjectsTable: {
    filters: ProjectFilters;
    pagination: {
      limit: number;
      offset: number;
    };
    sorting: {
      orderBy: string;
      order: SortingType;
    };
    searchParam: string;
  };
  selectedProjectsBoard: ProjectsBoardTabs;
  teamProjectsBoard: {
    filters: ProjectFilters;
    filterStatus: boolean;
    process: string;
    searchParam: string;
    sorting: {
      orderBy: string;
      order: SortingType;
    };
  };
  myProjectsBoard: {
    filters: ProjectFilters;
    filterStatus: boolean;
    process: string;
    searchParam: string;
    sorting: {
      orderBy: string;
      order: SortingType;
    };
  };
  status: Status;
  exportStatus: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ProjectsState = {
  viewMode: PROJECTS_LIST_VIEW_MODE.TABLE,
  projects: [],
  userProjects: [],
  teamProjectsTable: {
    filters: {} as ProjectFilters,
    pagination: {
      limit: 15,
      offset: 0,
    },
    sorting: {
      order: 'asc',
      orderBy: '',
    },
    searchParam: '',
  },
  myProjectsTable: {
    filters: {} as ProjectFilters,
    pagination: {
      limit: 15,
      offset: 0,
    },
    sorting: {
      order: 'asc',
      orderBy: '',
    },
    searchParam: '',
  },
  selectedProjectsBoard: PROJECTS_BOARD_TABS.TEAM_BOARD,
  teamProjectsBoard: {
    filters: {} as ProjectFilters,
    filterStatus: false,
    process: '',
    searchParam: '',
    sorting: {
      order: 'desc',
      orderBy: 'createdAt',
    },
  },
  myProjectsBoard: {
    filters: {} as ProjectFilters,
    filterStatus: false,
    process: '',
    searchParam: '',
    sorting: {
      order: 'desc',
      orderBy: 'createdAt',
    },
  },
  status: SLICE_STATUS.IDLE,
  exportStatus: SLICE_STATUS.IDLE,
};

const projectsAPI = ProjectsAPI;

/* ============================== REDUX THUNK =============================== */
export const createNewProject = createAsyncThunk(
  'projects/CREATE_PROJECT',
  async (newProjectData: NewProject, { getState }) => {
    const state = getState() as RootState;
    const organizationId = selectOrganizationId(state);
    const processedProjectData = processNewProjectData(newProjectData);
    let programs = null;
    if (processedProjectData.programs) {
      programs = processedProjectData.programs;
      delete processedProjectData.programs;
    }
    const response = await projectsAPI.createProject({
      ...processedProjectData,
      organization_id: organizationId,
    });
    if (programs && processedProjectData.privacy !== PROJECT_PRIVACY.PRIVATE) {
      await ProjectAPI.updateProjectPrograms(response.data.id, programs);
    }
    trackEvent(PENDO_EVENTS.PROJECT_CREATED, {
      title: newProjectData.title,
    });
    return response;
  }
);

export const bulkImportProjects = createAsyncThunk(
  'projects/BULK_IMPORT_PROJECTS',
  async (newProjectsData: NewProject[]) => {
    const response = await projectsAPI.bulkCreateProjects(newProjectsData);
    newProjectsData.forEach((project) => {
      trackEvent(PENDO_EVENTS.PROJECT_CREATED, {
        title: project.title,
      });
    });
    return response;
  }
);

export const duplicateProject = createAsyncThunk(
  'projects/DUPLICATE_PROJECT',
  async (params: { projectId: string; data: NewProject }, { getState }) => {
    const state = getState() as RootState;
    const organizationId = selectOrganizationId(state);
    const duplicatedProject = await projectsAPI.duplicateProject(
      params.projectId,
      { ...params.data, organization_id: organizationId }
    );
    trackEvent(PENDO_EVENTS.PROJECT_CREATED, {
      title: params.data.title,
    });
    return duplicatedProject;
  }
);

export const searchByTitle = createAction(
  'projects/SEARCH_BY_TITLE',
  (searchParam: string, table: ProjectsTableTab) => {
    return { payload: { searchParam, table } };
  }
);

export const fetchProjects = createAsyncThunk(
  'projects/FETCH_PROJECTS',
  async () => {
    const response = await projectsAPI.fetchTeamProjects();
    return response;
  }
);

export const fetchUserProjects = createAsyncThunk(
  'projects/FETCH_MY_PROJECTS',
  async () => {
    const response = await projectsAPI.fetchUserProjects();
    return response;
  }
);

export const deleteProject = createAsyncThunk(
  'projects/DELETE_PROJECT',
  async (projectId: string) => {
    await projectsAPI.deleteProject(projectId);
    return { projectId };
  }
);

export const exportProjects = createAsyncThunk(
  'projects/EXPORT_LIST',
  async (
    {
      projectIds,
      isTeamModule,
    }: {
      projectIds: string[];
      isTeamModule: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await exportAPI.exportProjects(projectIds, isTeamModule);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportCsv = createAsyncThunk(
  'projects/EXPORT_CSV',
  async (exportData: {
    csvHeaders: string[];
    csvData: objKeyAsString;
    fileName: string;
  }) => {
    const { csvHeaders, csvData, fileName } = exportData;
    const response = await exportAPI.exportCsv(csvHeaders, csvData, fileName);
    return response.data.fileLocation;
  }
);

export const moveProjectToAnotherStage = createAsyncThunk(
  'project/MOVE_PROJECT_TO_ANOTHER_STAGE',
  async (updateData: {
    projectId: string;
    newStageId: string | null;
    newStatus: string | null;
  }) => {
    ProjectAPI.updateProject(updateData.projectId, {
      stage_id: updateData.newStageId,
    });
    if (updateData.newStatus !== null) {
      ProjectAPI.setProjectStatus(updateData.projectId, {
        status: updateData.newStatus,
      });
    }
    return { ...updateData };
  }
);

/* ================================= ACTIONS ================================ */
export const updatePagination = createAction(
  'projects/UPDATE_PAGINATION',
  (pagination, table: ProjectsTableTab) => {
    return { payload: { pagination, table } };
  }
);

export const setFilters = createAction(
  'projects/SET_FILTERS',
  (filters: ProjectFilters, table: ProjectsTableTab) => {
    return { payload: { filters, table } };
  }
);

export const setSortingOrders = createAction(
  'projects/SET_ORDERS',
  (order: SortingOrderType, table: ProjectsTableTab) => {
    return { payload: { order, table } };
  }
);

export const setProjectsListViewMode = createAction<ProjectsListViewMode>(
  'projects/SET_PROJECTS_LIST_VIEW_MODE'
);

export const resetBoardView = createAction('projects/RESET_BOAR_VIEW');

export const resetTableSearch = createAction('projects/RESET_TABLE_SEARCH');

export const setProjectsBoard =
  createAction<ProjectsBoardTabs>('projects/SET_BOARD');

export const setBoardProcess = createAction(
  'projects/SET_BOARD_PROCESS',
  (processId: string, board: ProjectsBoardTabs) => {
    return { payload: { processId, board } };
  }
);

export const setBoardSorting = createAction(
  'projects/SET_BOARD_SORTING',
  (order: SortingOrderType, board: ProjectsBoardTabs) => {
    return { payload: { order, board } };
  }
);

export const setBoardSearchParam = createAction(
  'projects/SET_BOARD_SEARCH',
  (searchParam: string, board: ProjectsBoardTabs) => {
    return { payload: { searchParam, board } };
  }
);

export const setBoardState = createAction(
  'projects/SET_SELECTED_FILTERS_STATE',
  (value: boolean, board: ProjectsBoardTabs) => {
    return { payload: { value, board } };
  }
);

export const setBoardFilters = createAction(
  'projects/SET_BOARD_FILTERS',
  (filters: ProjectFilters, board: ProjectsBoardTabs) => {
    return { payload: { filters, board } };
  }
);

/* ================================= REDUCER ================================ */
const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewProject.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createNewProject.fulfilled, (state, action) => {
        const newProject = get(action, 'payload.data', {});
        state.projects = !isEmpty(newProject)
          ? state.projects.concat(newProject)
          : state.projects;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(duplicateProject.pending, (state, action) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(duplicateProject.fulfilled, (state, action) => {
        state.projects = state.projects.concat(action.payload);
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = [...get(action, 'payload.data', [])];
      })
      .addCase(fetchUserProjects.fulfilled, (state, action) => {
        state.userProjects = [...get(action, 'payload.data', [])];
      })
      .addCase(bulkImportProjects.fulfilled, (state, action) => {
        state.projects = state.projects.concat(action.payload.data);
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects = state.projects.filter(
          (project) => project.id !== action.payload.projectId
        );
      })
      .addCase(searchByTitle, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          searchParam: action.payload.searchParam,
        };
      })
      .addCase(resetTableSearch, (state, action) => {
        state.myProjectsTable.searchParam = '';
        state.myProjectsTable.searchParam = '';
      })
      .addCase(updatePagination, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          pagination: action.payload.pagination,
        };
      })
      .addCase(setFilters, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          filters: action.payload.filters,
        };
      })
      .addCase(setSortingOrders, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          sorting: action.payload.order,
        };
      })
      .addCase(exportCsv.fulfilled, (state, action) => {
        window.location.href = action.payload;
      })
      .addCase(exportProjects.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportProjects.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportProjects.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      })
      .addCase(setProjectsListViewMode, (state, action) => {
        state.viewMode = action.payload;
      })
      .addCase(setProjectsBoard, (state, action) => {
        state.selectedProjectsBoard = action.payload;
      })
      .addCase(resetBoardView, (state, action) => {
        state.selectedProjectsBoard = initialState.selectedProjectsBoard;
        state.teamProjectsBoard = initialState.teamProjectsBoard;
        state.myProjectsBoard = initialState.myProjectsBoard;
      })
      .addCase(setBoardProcess, (state, action) => {
        state[action.payload.board] = {
          ...state[action.payload.board],
          process: action.payload.processId,
        };
      })
      .addCase(setBoardSorting, (state, action) => {
        state[action.payload.board] = {
          ...state[action.payload.board],
          sorting: { ...action.payload.order },
        };
      })
      .addCase(setBoardSearchParam, (state, action) => {
        state[action.payload.board] = {
          ...state[action.payload.board],
          searchParam: action.payload.searchParam,
        };
      })
      .addCase(setBoardFilters, (state, action) => {
        state[action.payload.board] = {
          ...state[action.payload.board],
          filters: action.payload.filters,
        };
      })
      .addCase(setBoardState, (state, action) => {
        state[action.payload.board] = {
          ...state[action.payload.board],
          filterStatus: action.payload.value,
        };
      })
      .addCase(moveProjectToAnotherStage.fulfilled, (state, action) => {
        state.projects = state.projects.map((project) => {
          if (project.id === action.payload.projectId) {
            let updatedProject = { ...project };
            updatedProject.stage_id = action.payload.newStageId;
            if (action.payload.newStatus !== null) {
              updatedProject.status = action.payload.newStatus;
            }
            return updatedProject;
          }
          return project;
        });
        state.userProjects = state.userProjects.map((project) => {
          if (project.id === action.payload.projectId) {
            let updatedProject = { ...project };
            updatedProject.stage_id = action.payload.newStageId;
            if (action.payload.newStatus !== null) {
              updatedProject.status = action.payload.newStatus;
            }
            return updatedProject;
          }
          return project;
        });
      });
  },
});

/* =============================== SELECTORS ================================ */
export const projectsListViewMode = (state: RootState) =>
  state.projectsState.viewMode;
export const teamSearch = (state: RootState) =>
  state.projectsState.teamProjectsTable.searchParam;
export const mySearch = (state: RootState) =>
  state.projectsState.myProjectsTable.searchParam;
export const teamSorting = (state: RootState) =>
  state.projectsState.teamProjectsTable.sorting;
export const mySorting = (state: RootState) =>
  state.projectsState.myProjectsTable.sorting;
export const teamProjectsTableFilters = (state: RootState) =>
  state.projectsState.teamProjectsTable.filters;
export const myProjectsTableFilters = (state: RootState) =>
  state.projectsState.myProjectsTable.filters;
export const teamBoardProcess = (state: RootState) =>
  state.projectsState.teamProjectsBoard.process;
export const myBoardProcess = (state: RootState) =>
  state.projectsState.myProjectsBoard.process;
export const teamBoardSearchParam = (state: RootState) =>
  state.projectsState.teamProjectsBoard.searchParam;
export const myBoardSearchParam = (state: RootState) =>
  state.projectsState.myProjectsBoard.searchParam;
export const teamBoardFilters = (state: RootState) =>
  state.projectsState.teamProjectsBoard.filters;
export const myBoardFilters = (state: RootState) =>
  state.projectsState.myProjectsBoard.filters;
export const teamBoardSorting = (state: RootState) =>
  state.projectsState.teamProjectsBoard.sorting;
export const myBoardSorting = (state: RootState) =>
  state.projectsState.myProjectsBoard.sorting;
export const projectsBoard = (state: RootState) =>
  state.projectsState.selectedProjectsBoard;
export const myBoardFilterState = (state: RootState) =>
  state.projectsState.myProjectsBoard.filterStatus;
export const teamBoardFilterState = (state: RootState) =>
  state.projectsState.teamProjectsBoard.filterStatus;
export const selectProjectStatus = (state: RootState) =>
  state.projectsState.status;
export const exportProjectsStatus = (state: RootState) =>
  state.projectsState.exportStatus;
export const selectTableSearchParam =
  (table: ProjectsTableTab) => (state: RootState) =>
    state.projectsState[table].searchParam;

const teamBoardStages = createSelector(
  [selectProjectProcesses, teamBoardProcess],
  (processes, processId) => {
    let stages: ProjectProcessStage[] = [];
    const process = processes.find(
      (process: ProjectProcess) => process.id === processId
    );
    if (process) {
      stages = process.projectStages;
    }
    return stages;
  }
);

const myBoardStages = createSelector(
  [selectProjectProcesses, myBoardProcess],
  (processes, processId) => {
    let stages: ProjectProcessStage[] = [];
    const process = processes.find(
      (process: ProjectProcess) => process.id === processId
    );
    if (process) {
      stages = process.projectStages;
    }
    return stages;
  }
);

export const selectAllProjects = (state: RootState) =>
  state.projectsState.projects;

const selectAllActiveProjects = createSelector(
  selectAllProjects,
  (allProjects) => filterOutInactiveProjects(allProjects)
);

const selectMyActiveProjects = createSelector(
  (state: RootState) => state.projectsState.userProjects,
  (myProjects) => filterOutInactiveProjects(myProjects)
);

export const selectTeamProjects = createSelector(
  [
    selectAllProjects,
    selectProjectCategories,
    selectProjectProcesses,
    teamSearch,
    teamSorting,
    (state: RootState) => state.projectsState.teamProjectsTable.pagination,
    (state: RootState) => state.projectsState.teamProjectsTable.filters,
  ],
  (
    projects,
    projectCategories,
    projectProcesses,
    search,
    sortingParams,
    pagination,
    filters
  ) => {
    const filteredProjectsList = filterProjects(projects, search, filters);
    const formattedProjectsList = formatProjectsList(
      filteredProjectsList,
      projectProcesses,
      projectCategories
    );
    const orderedProjects = orderProjectsBy(
      formattedProjectsList,
      sortingParams.orderBy,
      sortingParams.order
    );
    return {
      data: orderedProjects.slice(pagination.offset, pagination.limit),
      total: filteredProjectsList.length,
      all: filteredProjectsList,
    };
  }
);

export const selectUserProjects = createSelector(
  [
    (state: RootState) => state.projectsState.userProjects,
    selectProjectCategories,
    selectProjectProcesses,
    mySearch,
    mySorting,
    (state: RootState) => state.projectsState.myProjectsTable.pagination,
    (state: RootState) => state.projectsState.myProjectsTable.filters,
    selectUserId,
  ],
  (
    userProjects,
    projectCategories,
    projectProcesses,
    search,
    sortingParams,
    pagination,
    filters,
    userId
  ) => {
    const filteredProjectsList = filterProjects(
      userProjects,
      search,
      filters,
      userId!
    );
    const formattedProjectsList = formatProjectsList(
      filteredProjectsList,
      projectProcesses,
      projectCategories
    );
    const orderedProjects = orderProjectsBy(
      formattedProjectsList,
      sortingParams.orderBy,
      sortingParams.order
    );
    return {
      data: orderedProjects.slice(pagination.offset, pagination.limit),
      total: filteredProjectsList.length,
      all: filteredProjectsList,
    };
  }
);

export const teamBoard = createSelector(
  [
    selectAllActiveProjects,
    teamBoardProcess,
    teamBoardStages,
    teamBoardSorting,
    teamBoardSearchParam,
    teamBoardFilters,
  ],
  (projects, processId, processStages, sortingParams, searchParam, filters) => {
    const filteredProjectsByProcess = projects.filter(
      (project: Project) => project?.process_id === processId
    );

    const filteredProjects = filterProjects(
      filteredProjectsByProcess,
      searchParam,
      filters
    );

    const orderedProjects = orderBy(
      filteredProjects,
      [sortingParams.orderBy],
      [sortingParams.order]
    );

    return groupProjectsByStage(processStages, orderedProjects);
  }
);

export const myBoard = createSelector(
  [
    selectMyActiveProjects,
    myBoardProcess,
    myBoardStages,
    myBoardSorting,
    myBoardSearchParam,
    myBoardFilters,
  ],
  (projects, processId, processStages, sortingParams, searchParam, filters) => {
    const filteredProjectsByProcess = projects.filter(
      (project: Project) => project?.process_id === processId
    );
    const filteredProjects = filterProjects(
      filteredProjectsByProcess,
      searchParam,
      filters
    );
    const orderedProjects = orderBy(
      filteredProjects,
      [sortingParams.orderBy],
      [sortingParams.order]
    );
    return groupProjectsByStage(processStages, orderedProjects);
  }
);

export const selectAssociatedProcessesAndStages = createSelector(
  [selectAllProjects],
  (projects: Project[]) => {
    const associatedProcesses: objKeyAsString = {};
    for (const project of projects) {
      if (!associatedProcesses[project.process_id!]) {
        associatedProcesses[project.process_id!] = [project.stage_id];
      } else {
        if (
          !associatedProcesses[project.process_id!].includes(project.stage_id)
        ) {
          associatedProcesses[project.process_id!].push(project.stage_id);
        }
      }
    }
    return associatedProcesses;
  }
);

export default projectsSlice.reducer;
