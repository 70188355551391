import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { InsightsIntakeActiveFilter } from 'utils/customTypes';

class InsightsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(`${config.get('insightsBackendURL')}intakes`);
  }

  fetchReceivedRequests = async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await this.instance.get('/requests/received', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchApprovedRequests = async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await this.instance.get('/requests/approved', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchInReviewRequests = async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await this.instance.get('/requests/in-review', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchResponseRatios = async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await this.instance.get('/requests/response-ratio', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchAggregatesBy = async (
    filter: string,
    activeFilters: InsightsIntakeActiveFilter
  ) => {
    const { data } = await this.instance.get(`/requests/aggregates/${filter}`, {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };

  fetchFilters = async () => {
    const { data } = await this.instance.get(`/requests/filters`);
    return data.data;
  };

  fetchDeclinedRequests = async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await this.instance.get('/requests/declined', {
      params: {
        ...activeFilters,
      },
    });
    return data;
  };
}

export default new InsightsAPI();
