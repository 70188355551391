import intl from 'react-intl-universal';
import {
  TableFilterProps,
  Typography,
  NumericInput,
  Dropdown,
  TextField,
} from '@getsynapse/design-system';
import { FormOption } from 'utils/customTypes';
import {
  EMPLOYMENT_TYPE,
  COUNTRIES,
  MEMBER_VALUES,
  COLUMN_OPTION_TYPES,
  USER_TYPES,
  USER_STATUS,
} from 'utils/constants';

type FilterPropsNewParams = TableFilterProps['filterInputElement'] extends (
  ...oldParams: infer U
) => infer R
  ? (learningTeamOptions: FormOption[], ...oldParams: U) => R
  : never;

const userFilterInput: FilterPropsNewParams = (
  learningTeamOptions,
  column,
  filterInput,
  setInput
) => {
  const userStatus = [
    USER_STATUS.REGISTERED,
    USER_STATUS.INVITED,
    USER_STATUS.REGISTERED_DISABLED,
    'pending',
  ];

  const options: { [key: string]: FormOption[] } = {
    [MEMBER_VALUES.EMPLOYMENT_TYPE]: EMPLOYMENT_TYPE.map((type) => ({
      value: intl.get(`TEAMS.EMPLOYMENT_TYPE.${type}`),
      label: intl.get(`TEAMS.EMPLOYMENT_TYPE.${type}`),
    })),
    [MEMBER_VALUES.COUNTRY]: COUNTRIES.map((country) => ({
      value: intl.get(`COUNTRIES.${country.toLocaleUpperCase()}`),
      label: intl.get(`COUNTRIES.${country.toLocaleUpperCase()}`),
    })),
    [MEMBER_VALUES.TYPE]: Object.keys(USER_TYPES).map((key) => ({
      value: intl.get(
        `USERS_PAGE.TABLE.USER_TYPE.${USER_TYPES[key].toUpperCase()}`
      ),
      label: intl.get(
        `USERS_PAGE.TABLE.USER_TYPE.${USER_TYPES[key].toUpperCase()}`
      ),
    })),
    [MEMBER_VALUES.TEAM]: learningTeamOptions.map((element) => ({
      value: element.label,
      label: element.label,
    })),
    [MEMBER_VALUES.STATUS]: userStatus.map((element) => ({
      value: intl.get(
        `${
          element !== 'pending'
            ? 'USERS_PAGE.TABLE.USER_STATUS'
            : 'USERS_PAGE.TABLE.USER_PENDING'
        }.${element.toUpperCase()}`
      ),
      label: intl.get(
        `${
          element !== 'pending'
            ? 'USERS_PAGE.TABLE.USER_STATUS'
            : 'USERS_PAGE.TABLE.USER_PENDING'
        }.${element.toUpperCase()}`
      ),
    })),
  };

  return (
    <div className='flex-grow self-baseline'>
      <Typography
        variant='label'
        weight='medium'
        className='inline-block mb-2 leading-4 tracking-px02'
      >
        {intl.get('FILTER_GENERAL.VALUE')}
      </Typography>
      {column.type === COLUMN_OPTION_TYPES.NUMBER && (
        <NumericInput
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInput(event.target.value);
          }}
          data-testid='filter__number-input'
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.TEXT && (
        <TextField
          name='table-header__search-input'
          placeholder={intl.get('FILTER_GENERAL.INPUT_VALUE')}
          state='default'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInput(event.target.value);
          }}
          value={filterInput as string}
          data-testid='filter__text-inputs'
          height='small'
          inputClassName='py-1'
        />
      )}
      {column.type === COLUMN_OPTION_TYPES.OPTIONS && (
        <Dropdown
          options={options[column.value]}
          filterable
          listProps={{ 'data-testid': 'dropdow-list' }}
          triggerProps={{
            'data-testid': 'dropdown-option-values',
            size: 'sm',
            className: 'py-1',
          }}
          onChange={(value: FormOption) => {
            setInput(value.value);
          }}
          values={filterInput ? [filterInput] : []}
        />
      )}
    </div>
  );
};

export default userFilterInput;
