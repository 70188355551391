import {
  NewProject,
  ImportingProject,
  BusinessTeam,
  LearningTeam,
  ProjectCategory,
  CurrentUser,
  ProjectProcess,
} from 'utils/customTypes';
import { FlatfileResults } from '@flatfile/react';
import {
  PROJECT_IMPORT_DEFAULT_VALUES,
  PROJECT_IMPORT_ATTRIBUTES,
  ALLOWED_FIELD_TYPES,
  DATE,
} from 'utils/constants';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { ProjectTemplateField } from 'utils/types/templates';
import { FieldTemplateType } from 'utils/types/fields';

const defaultImportedProject: NewProject = {
  title: '',
  owners: [],
  description: '',
  businessTeams: [],
  category_id: '',
  startDate: null,
  targetCompletionDate: null,
  priority: PROJECT_IMPORT_DEFAULT_VALUES.PRIORITY,
  privacy: PROJECT_IMPORT_DEFAULT_VALUES.PRIVACY,
  process_id: '',
  stage_id: '',
  status: PROJECT_IMPORT_DEFAULT_VALUES.STATUS,
  health: PROJECT_IMPORT_DEFAULT_VALUES.HEALTH,
  learningTeams: [],
};

export const cleanImportedProjects = async (
  result: FlatfileResults,
  businessTeams: BusinessTeam[],
  projectCategories: ProjectCategory[],
  currentUser: CurrentUser,
  projectProcesses: ProjectProcess[],
  learningTeams: LearningTeam[],
  templateFields: ProjectTemplateField[],
  templateId?: string
) => {
  const projectsData = result.validData;

  if (isEmpty(projectsData)) {
    return [];
  }

  const getDefaultValue: (
    project: ImportingProject,
    key: keyof ImportingProject,
    defautlValue: string
  ) => string = (project, key, defautlValue) => {
    if (project[key]) {
      return project[key] as string;
    } else {
      return defautlValue;
    }
  };

  const getCustomFieldValue: (
    importedValue: string,
    templateField: FieldTemplateType
  ) => void = (importedValue, templateField) => {
    let convertedValue: boolean | number | string | null;
    switch (templateField.type) {
      case ALLOWED_FIELD_TYPES.BOOLEAN:
        convertedValue = Boolean(importedValue === 'true');
        break;
      case ALLOWED_FIELD_TYPES.FLOAT:
      case ALLOWED_FIELD_TYPES.INT:
      case ALLOWED_FIELD_TYPES.NUMERIC:
        convertedValue = Number(importedValue);
        break;
      case ALLOWED_FIELD_TYPES.DATE:
        if (importedValue) {
          const momentDate = moment(importedValue);
          if (momentDate.isValid()) {
            convertedValue = momentDate.format(DATE.DATE_STAMP);
          } else {
            convertedValue = null;
          }
        } else {
          convertedValue = null;
        }
        break;
      default:
        convertedValue = importedValue;
        break;
    }

    if (templateField.allowed_values) {
      const foundValue = templateField.allowed_values.find(
        (value) => value.val === convertedValue
      );
      if (!!foundValue) {
        return foundValue;
      } else {
        return { val: null };
      }
    } else {
      return { val: convertedValue };
    }
  };

  const cleanProjectsData = projectsData.map((project: ImportingProject) => {
    const baseProject = { ...defaultImportedProject };
    if (templateId) {
      baseProject.project_template_id = templateId;
    }
    for (const key of Object.keys(defaultImportedProject)) {
      switch (key) {
        case PROJECT_IMPORT_ATTRIBUTES.TITLE:
        case PROJECT_IMPORT_ATTRIBUTES.HEALTH:
        case PROJECT_IMPORT_ATTRIBUTES.DESCRIPTION:
          project[key] && (baseProject[key] = project[key] as string);
          break;
        case PROJECT_IMPORT_ATTRIBUTES.BUSINESS_TEAMS:
          baseProject[key] = [
            getDefaultValue(project, key, businessTeams[0].id),
          ];
          break;
        case PROJECT_IMPORT_ATTRIBUTES.LEARNING_TEAMS:
          baseProject[key] = [
            getDefaultValue(project, key, learningTeams[0].id),
          ];
          break;
        case PROJECT_IMPORT_ATTRIBUTES.CATEGORY:
          baseProject[key] = getDefaultValue(
            project,
            key,
            projectCategories[0].id
          );
          break;
        case PROJECT_IMPORT_ATTRIBUTES.STATUS:
          baseProject[key] = getDefaultValue(
            project,
            key,
            PROJECT_IMPORT_DEFAULT_VALUES.STATUS
          );
          break;
        case PROJECT_IMPORT_ATTRIBUTES.PRIORITY:
          baseProject[key] = getDefaultValue(
            project,
            key,
            PROJECT_IMPORT_DEFAULT_VALUES.PRIORITY
          );
          break;
        case PROJECT_IMPORT_ATTRIBUTES.PRIVACY:
          baseProject[key] = getDefaultValue(
            project,
            key,
            PROJECT_IMPORT_DEFAULT_VALUES.PRIVACY
          );
          break;
        case PROJECT_IMPORT_ATTRIBUTES.OWNERS:
          baseProject[key] = [
            getDefaultValue(project, key, currentUser.id || ''),
          ];
          break;
        case PROJECT_IMPORT_ATTRIBUTES.PROCESS:
          baseProject[key] = getDefaultValue(
            project,
            key,
            projectProcesses[0].id
          );
          break;
        default:
          break;
      }
    }

    for (const templateField of templateFields) {
      const fieldName = templateField.field_template.name;
      baseProject[fieldName] = getCustomFieldValue(
        project[fieldName],
        templateField.field_template
      );
    }

    const selectedProcess = projectProcesses.find(
      (process) => process.id === baseProject.process_id
    );

    if (!isEmpty(selectedProcess)) {
      if (
        project.stage_id &&
        selectedProcess?.projectStages.some(
          (stage) => stage.id === project.stage_id
        )
      ) {
        baseProject.stage_id = project.stage_id as string;
      } else {
        if (
          baseProject.status &&
          !['new', 'in_planning'].includes(baseProject.status)
        ) {
          baseProject.stage_id = selectedProcess?.projectStages[0].id;
        }
      }
    }
    const startDate = moment(project.startDate).hour(4);
    const dueDate = moment(project.targetCompletionDate).hour(4);

    if (project.startDate && startDate.isValid()) {
      baseProject.startDate = moment(project.startDate)
        .hour(4)
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      baseProject.startDate = moment().hour(4).format('YYYY-MM-DD HH:mm:ss');
    }
    if (
      project.targetCompletionDate &&
      dueDate.isValid() &&
      moment(project.targetCompletionDate)
        .hour(4)
        .isAfter(baseProject.startDate)
    ) {
      baseProject.targetCompletionDate = moment(project.targetCompletionDate)
        .hour(4)
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      baseProject.targetCompletionDate = moment(baseProject.startDate)
        .add(30, 'days')
        .hour(4)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!baseProject.stage_id) {
      delete baseProject.stage_id;
    }
    return baseProject;
  });
  return cleanProjectsData;
};
