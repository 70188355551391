import React from 'react';
import intl from 'react-intl-universal';
import { Tabs, tailwindOverride } from '@getsynapse/design-system';
import { ProjectTemplate } from 'utils/types/templates';
import Overview from './Overview';
import Build from './Build';

type size = 'small' | 'medium' | 'large' | 'extraLarge';

const SkeletonBar: React.FC<{ size: size; className?: string }> = ({
  size,
  className = '',
}) => {
  const heightMap: Record<size, string> = {
    small: 'h-3',
    medium: 'h-4',
    large: 'h-4',
    extraLarge: 'h-4',
  };
  const widthMap: Record<size, string> = {
    small: 'w-1/6',
    medium: 'w-1/4',
    large: 'w-1/2',
    extraLarge: 'w-full',
  };
  return (
    <div
      className={tailwindOverride(
        'bg-neutral-lighter rounded-sm animate-pulse self-start',
        widthMap[size],
        heightMap[size],
        className
      )}
    />
  );
};

const SkeletonLoader = () => {
  return (
    <div className='grid grid-cols-2 mt-8 gap-y-6 gap-x-10%'>
      <div>
        <SkeletonBar size='large' className='mb-2' />
      </div>
      <div />
      <SkeletonBar size='small' />
      <SkeletonBar size='small' />
      <SkeletonBar size='large' className='mb-2' />
      <SkeletonBar size='large' className='mb-2' />
      <SkeletonBar size='small' />
      <SkeletonBar size='small' />
      <div>
        <SkeletonBar size='extraLarge' className='mb-2' />
        <SkeletonBar size='extraLarge' className='mb-2' />
        <SkeletonBar size='medium' />
      </div>
      <SkeletonBar size='large' />
    </div>
  );
};

const TemplateContentTabs: React.FC<{
  templateData: ProjectTemplate;
  updateTemplateData: (key: keyof ProjectTemplate, value: any) => void;
  isLoading?: boolean;
}> = ({ templateData, updateTemplateData, isLoading = false }) => {
  return (
    <Tabs
      tabListProps={{ className: 'w-full flex-grow' }}
      tabPanelsProps={{
        className: 'max-h-page-body pb-10 overflow-y-auto',
        'data-cy': 'template-content-tabs',
      }}
      data={[
        {
          label: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_TAB'),
          content: isLoading ? (
            <SkeletonLoader />
          ) : (
            <Overview
              templateData={templateData}
              updateTemplateData={updateTemplateData}
            />
          ),
        },
        {
          label: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_TAB'),
          content: (
            <Build
              templateData={templateData}
              updateTemplateData={updateTemplateData}
            />
          ),
        },
      ]}
    />
  );
};

export default TemplateContentTabs;
