import TeamActions from './TeamActions';
import { BusinessTeam, LearningTeam } from 'utils/customTypes';

const EditTeamHeader = ({
  isBusinessTeam,
  team,
}: {
  isBusinessTeam: boolean;
  team: Partial<BusinessTeam> | Partial<LearningTeam>;
}) => {
  return (
    <div className='relative z-5'>
      <div className='flex items-center justify-end'>
        <TeamActions isBusinessTeam={isBusinessTeam} team={team} />
      </div>
    </div>
  );
};

export default EditTeamHeader;
