import { Fragment, useMemo, ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  FormItem,
  Typography,
  TextField,
  TextArea,
  Dropdown,
  UsersPicker,
  Tooltip,
  Icon,
} from '@getsynapse/design-system';
import {
  Option,
  BusinessTeam,
  LearningTeam,
  UserOption,
  User,
} from 'utils/customTypes';
import { SHORT_INPUTS_LENGTH } from 'utils/constants';
import get from 'lodash/get';
import { selectLearningTeamsForDropdown } from 'state/LearningTeams/learningTeamsSlice';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { useState } from 'react';

const BasicInformation = ({
  team,
  isBusinessTeam,
  handleChangeField,
  hasError,
}: {
  team: Partial<BusinessTeam> | Partial<LearningTeam>;
  isBusinessTeam: boolean;
  handleChangeField: (
    eventTargetValue: string | string[],
    pathToUpdate: string
  ) => void;
  hasError: boolean;
}) => {
  const allLearningTeams = useSelector(selectLearningTeamsForDropdown);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const learningTeamsOptions = useMemo(
    () => allLearningTeams.filter((ldTeam) => ldTeam.value !== team.id),
    [allLearningTeams, team.id]
  );
  const teamName = isBusinessTeam ? get(team, 'title') : get(team, 'name');
  const managers: User[] = get(team, 'teamManagers') || [];
  const [selectedManagers, setSelectedManagers] = useState<UserOption[]>(
    managers.map((manager) => ({
      value: manager.id,
      label: `${get(manager, 'data.firstName')} ${get(
        manager,
        'data.lastName'
      )}`,
      avatar: {
        initial: `${get(manager, 'data.firstName[0]')}${get(
          manager,
          'data.lastName[0]'
        )}`,
        imageSrc: get(manager, 'avatar_url'),
      },
    }))
  );

  const changeManager = (users: UserOption[]) => {
    setSelectedManagers(users);
    handleChangeField(
      users.map((user) => user.value),
      'teamManagers'
    );
  };

  return (
    <div className='grid grid-cols-2 gap-x-20 gap-y-4'>
      <div className='col-span-2'>
        <Typography variant='h5' className='mt-8 text-neutral-black'>
          {intl.get('TITLES.BASIC_INFORMATION_TITLE')}
        </Typography>
      </div>
      <FormItem
        helpText={hasError && intl.get('ERRORS.MISSING_INFORMATION')}
        helpTextProps={{ state: hasError ? 'error' : 'default' }}
      >
        <TextField
          label={intl.get('TEAM_NAME', { num: 1 })}
          labelProps={{
            required: true,
            state: hasError ? 'error' : 'default',
          }}
          variant='text'
          length='medium'
          data-cy='team-name_input'
          placeholder={intl.get('ADD_TEAM_MODAL.TEAM_NAME_PLACEHOLDER')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeField(
              e.target.value,
              isBusinessTeam ? 'title' : 'name'
            );
          }}
          defaultValue={teamName}
          displayCharactersCount
          maxLength={SHORT_INPUTS_LENGTH}
          state={hasError ? 'error' : 'default'}
        />
      </FormItem>
      <TextArea
        className='row-span-2 flex flex-col'
        label={intl.get('DESCRIPTION', { num: 1 })}
        textAreaProps={{
          className: 'h-30 flex-grow',
          placeholder: intl.get('ADD_TEAM_MODAL.DESCRIPTION_PLACEHOLDER'),
          defaultValue: get(team, 'description'),
        }}
        onChange={(e) => {
          handleChangeField(e.target.value, 'description');
        }}
      />
      {!isBusinessTeam && (
        <Fragment>
          <FormItem
            label={intl.get(
              'ORG_SETTINGS_PAGE.LEARNING_TEAMS.ADD_TEAM_MODAL.REPORTS_TO'
            )}
            className='mt-1 col-start-1 col-end-1 col-span-2'
          >
            <Dropdown
              options={learningTeamsOptions}
              values={[
                {
                  label: get(team, 'parentTeam.name'),
                  value: get(team, 'parent_id'),
                },
              ]}
              triggerProps={{
                className: 'h-6',
                placeholder: intl.get(
                  'ORG_SETTINGS_PAGE.LEARNING_TEAMS.ADD_TEAM_MODAL.SELECT_TEAM'
                ),
              }}
              onChange={(option: Option) => {
                handleChangeField(option.value, 'parent_id');
              }}
            />
          </FormItem>
          <FormItem
            label={
              <div className='flex items-center'>
                <span className='mr-1'>
                  {intl.get('ENTITIES.TEAM_MANAGER')}
                </span>
                <Tooltip
                  trigger={
                    <Icon
                      name='information-circle'
                      className='text-2xl text-primary-dark'
                    />
                  }
                  ariaId='review-allocation-warning'
                  openMode='hover2'
                  timeout={10}
                  position='topRight'
                >
                  <Typography className='text-neutral-white' variant='body2'>
                    {intl.get(
                      'PEOPLE.RESOURCE_ALLOCATION.TABLE.TEAM_MANAGER_ASSIGNMENT'
                    )}
                  </Typography>
                </Tooltip>
              </div>
            }
          >
            <UsersPicker
              usersList={ldUsers}
              selectedUsersList={
                selectedManagers as ComponentProps<
                  typeof UsersPicker
                >['selectedUsersList']
              }
              onChange={(users: UserOption[]) => changeManager(users)}
              triggerText={intl.get(
                'ORG_SETTINGS_PAGE.LEARNING_TEAMS.ADD_TEAM_MODAL.ADD_MANAGER'
              )}
              noSelectedUsersText={intl.get(
                'ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.NO_MANAGER_ASSIGNED'
              )}
              triggerProps={{ 'data-cy': 'add-managers_trigger' }}
            />
          </FormItem>
        </Fragment>
      )}
    </div>
  );
};

export default BasicInformation;
