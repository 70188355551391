import { useEffect, useMemo, useState } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProgram,
  fetchProjectsForProgram,
  selectProgramWithFilteredProjects,
  resetProgram,
  getProgramData,
  updateProgram,
  updateProgramOwners,
  updateProgramProjects,
  selectProgramStatus,
} from 'state/Program/programSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { PATHS, PROGRAMS_STATUS, SLICE_STATUS } from 'utils/constants';
import {
  isUserOrganizationAdmin,
  selectUserId,
  selectIsUserLd,
} from 'state/User/userSlice';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import {
  getLearningTeams,
  selectLearningTeamStatus,
  selectLearningTeamIsFetched,
} from 'state/LearningTeams/learningTeamsSlice';
import { Button, useElevation } from '@getsynapse/design-system';
import ProgramLeftPanel from './components/ProgramLeftPanel';
import ProgramContent from './components/ProgramContent';
import { Program } from 'utils/customTypes';
import { set, isEmpty, get } from 'lodash';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';
import Loader from 'Molecules/Loader/Loader';

const ProgramPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { programId } = useParams<{ programId: string }>();
  const program = useSelector(selectProgramWithFilteredProjects);
  const originalProgram = useSelector(getProgramData);
  const footerElevation = useElevation(1);
  const currentUserId = useSelector(selectUserId);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const isUserLd = useSelector(selectIsUserLd);
  const programStatus = useSelector(selectProgramStatus);

  const userHasWriteAccess = useMemo(() => {
    if (!program.id || program.status === PROGRAMS_STATUS.CLOSED) {
      return false;
    }
    if (isUserAdmin) {
      return true;
    }
    return program.programOwners.some((owner) => owner.id === currentUserId);
  }, [
    isUserAdmin,
    program.programOwners,
    program.id,
    currentUserId,
    program.status,
  ]);

  const ldTeamStatus = useSelector(selectLearningTeamStatus);
  const ldTeamsFetched = useSelector(selectLearningTeamIsFetched);
  const [updatedProgram, setUpdatedProgram] = useState<Partial<Program>>(
    {} as Program
  );
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);

  const ownersChanges: boolean = useMemo(() => {
    if (!isEmpty(updatedProgram.programOwners)) {
      const originalOwnersIds = originalProgram.programOwners.map(
        (owner) => owner.id
      );
      if (
        JSON.stringify(updatedProgram.programOwners) !==
        JSON.stringify(originalOwnersIds)
      ) {
        return true;
      }
    }
    return false;
  }, [updatedProgram.programOwners, originalProgram.programOwners]);

  const updatedProjectsIds = useMemo(
    () => originalProgram.updatedProjects?.map((project) => project.id),
    [originalProgram.updatedProjects]
  );

  const projectChanges: boolean = useMemo(() => {
    const originalProjectsIds = originalProgram.programProjects.map(
      (project) => project.id
    );
    if (
      JSON.stringify(updatedProjectsIds) !== JSON.stringify(originalProjectsIds)
    ) {
      return true;
    }
    return false;
  }, [updatedProjectsIds, originalProgram.programProjects]);

  const programChanges = useMemo(() => {
    if (
      updatedProgram.title &&
      originalProgram.title !== updatedProgram.title
    ) {
      return true;
    }
    if (
      updatedProgram.description &&
      originalProgram.description !== updatedProgram.description
    ) {
      return true;
    }

    if (
      updatedProgram.status &&
      originalProgram.status !== updatedProgram.status
    ) {
      return true;
    }
    if (
      updatedProgram.delivery_type &&
      originalProgram.delivery_type !== updatedProgram.delivery_type
    ) {
      return true;
    }
    return false;
  }, [
    originalProgram,
    updatedProgram.title,
    updatedProgram.description,
    updatedProgram.status,
    updatedProgram.delivery_type,
  ]);

  const changesDetected: boolean = useMemo(
    () => programChanges || ownersChanges || projectChanges,
    [ownersChanges, projectChanges, programChanges]
  );

  useEffect(() => {
    if (!ldTeamsFetched && ldTeamStatus !== SLICE_STATUS.LOADING) {
      dispatch(getLearningTeams());
    }
  }, [ldTeamStatus, ldTeamsFetched, dispatch]);

  useEffect(() => {
    if (!isUserAdmin && !isUserLd) {
      history.push(PATHS.ROOT);
    } else if (programId) {
      dispatch(fetchProgram(programId));
      dispatch(getAllUsers());
      dispatch(fetchProjectsForProgram(programId));
    }
    return () => {
      dispatch(resetProgram());
    };
  }, [dispatch, programId, history, isUserAdmin, isUserLd]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation, {
        from: `${PATHS.PROGRAM_PAGE}/${programId}`,
      });
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation, programId]);

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
  }, [changesDetected]);

  const handleFieldChange = (path: string, value: any) => {
    const newData = {};
    set(newData, path, value);
    setUpdatedProgram((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const updateProgramData = async () => {
    let success = true;

    if (ownersChanges) {
      const updateOwnersResult = await dispatch(
        updateProgramOwners({
          programId,
          ownersIds: get(updatedProgram, 'programOwners') as string[],
        })
      );
      success =
        success && get(updateOwnersResult, 'payload.programOwners', false);
      delete updatedProgram.programOwners;
    }
    if (projectChanges) {
      const updateProjectsResult = await dispatch(
        updateProgramProjects({
          programId,
          projectIds: updatedProjectsIds!,
        })
      );
      success =
        success && get(updateProjectsResult, 'payload.programProjects', false);
    }
    if (programChanges) {
      const updateProgramResult = await dispatch(
        updateProgram({ programId, updateFields: updatedProgram })
      );
      success =
        success && get(updateProgramResult, 'payload.updatedProgram', false);
    }

    if (success) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get('PROGRAM_PAGE.UPDATE_SUCCESS'),
        })
      );
      setUpdatedProgram({} as Program);
    } else {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get('PROGRAM_PAGE.UPDATE_ERROR'),
        })
      );
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(location.pathname);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  const cancelUpdateProgram = () => {
    setConfirmedNavigation(true);
    history.push(`${PATHS.PROGRAMS_LIST_PAGE}`);
  };

  return (
    <div className='h-full'>
      <Prompt when={changesDetected} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <PageTitle titleComponent={program.title} />
      {programStatus !== SLICE_STATUS.LOADING && get(program, 'id') ? (
        <div className='max-h-details-without-bar h-full px-6 flex'>
          <ProgramLeftPanel
            program={program}
            userHasWriteAccess={userHasWriteAccess}
            handleFieldChange={handleFieldChange}
          />
          <ProgramContent
            program={program}
            userHasWriteAccess={userHasWriteAccess}
            changesDetected={changesDetected}
          />
        </div>
      ) : (
        <Loader />
      )}
      <div
        className={classnames(
          'w-full bg-neutral-white flex py-2 z-5',
          footerElevation
        )}
      >
        <div className='flex ml-auto mr-12'>
          <Button
            variant='tertiary'
            className='mr-4'
            onClick={cancelUpdateProgram}
            data-cy='program-cancel-button'
          >
            {intl.get('CANCEL')}
          </Button>
          <Button
            onClick={updateProgramData}
            disabled={!changesDetected}
            data-cy='program-update-button'
          >
            {intl.get('PROGRAM_PAGE.UPDATE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProgramPage;
