import React, { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  Datepicker,
  NumericInput,
  UsersPicker,
  Checkbox,
} from '@getsynapse/design-system';
import {
  CENTRALIZED_TASKS_FILTERS,
  CENTRALIZED_TASKS_TABLE_TABS,
} from 'utils/constants/centralizedTasks';
import { Option, rangeDateType, UserAvatars } from 'utils/customTypes';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import {
  AdditionalFilterKeys,
  CentralizedTasksFilters,
  CentralizedTasksFiltersKey,
  RangeFilter,
  RangeFilterKeys,
} from 'utils/types/filters';
import { getInitialValueForDropDown } from 'utils/functions';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import useTasksFilters from './hooks/useTasksFilters';
import { TASK_ADDITONAL_FLAGS } from 'utils/constants';

const DATE_FILTERS: string[] = [
  CENTRALIZED_TASKS_FILTERS.START_DATE,
  CENTRALIZED_TASKS_FILTERS.DUE_DATE,
  CENTRALIZED_TASKS_FILTERS.COMPLETION_DATE,
];

const isDateRangeFilterEmpty = (filter: RangeFilter) =>
  filter.from === null && filter.to === null;

const isRangeFilterEmpty = (filter: RangeFilter) =>
  filter.from === '' && filter.to === '';

const getOptionFilters = (
  filters: CentralizedTasksFilters,
  filterKey: CentralizedTasksFiltersKey,
  value: Option[]
): CentralizedTasksFilters => {
  let filtersCopy = { ...filters };

  if (value.length === 0) {
    delete filtersCopy[filterKey];
  } else {
    filtersCopy[filterKey] = value.map((option) => option.value);
  }

  return filtersCopy;
};

const getRangeFilters = (
  filters: CentralizedTasksFilters,
  filterKey: CentralizedTasksFiltersKey,
  value: rangeDateType,
  rangeFilterKey: 'from' | 'to'
): CentralizedTasksFilters => {
  let filtersCopy = { ...filters };

  const filterValue = value as rangeDateType;
  filtersCopy[filterKey] = {
    ...(filtersCopy[filterKey] as RangeFilter),
    [rangeFilterKey as string]: filterValue.startDate
      ? filterValue.startDate.toString()
      : null,
  };
  if (isDateRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
    delete filtersCopy[filterKey];
  }

  return filtersCopy;
};

const getStringRangeFilters = (
  filters: CentralizedTasksFilters,
  filterKey: CentralizedTasksFiltersKey,
  value: string,
  attribute?: RangeFilterKeys | AdditionalFilterKeys | null
): CentralizedTasksFilters => {
  let filtersCopy = { ...filters };

  if (Boolean(attribute)) {
    filtersCopy[filterKey] = {
      ...(filtersCopy[filterKey] as RangeFilter),
      [attribute as string]: value,
    };
    if (isRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
      delete filtersCopy[filterKey];
    }
  } else {
    filtersCopy[filterKey] = [value];
  }

  return filtersCopy;
};

const FiltersForm: React.FC<{
  filters: CentralizedTasksFilters;
  taskTable: CentralizedTasksTableTab;
  onUpdateFilters: (newFilters: CentralizedTasksFilters) => void;
}> = ({ filters, taskTable, onUpdateFilters }) => {
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const shouldBlockProgramPicker = useMemo<boolean>(
    () => selectedProjects.length > 0 && selectedPrograms.length === 0,
    [selectedPrograms, selectedProjects]
  );

  useEffect(() => {
    const filteredPrograms = filters[
      CENTRALIZED_TASKS_FILTERS.PROGRAM
    ] as string[];
    const filteredProjects = filters[
      CENTRALIZED_TASKS_FILTERS.PROJECT
    ] as string[];
    setSelectedPrograms(filteredPrograms || []);
    setSelectedProjects(filteredProjects || []);
  }, [filters]);

  const { getFilterOptionsByKey } = useTasksFilters(selectedPrograms);
  const statusOptions = getFilterOptionsByKey(CENTRALIZED_TASKS_FILTERS.STATUS);
  const typeOptions = getFilterOptionsByKey(CENTRALIZED_TASKS_FILTERS.TYPE);

  const assigneeUsers = getFilterOptionsByKey(
    CENTRALIZED_TASKS_FILTERS.ASSIGNEE_USERS
  ) as UserAvatars[];

  const programOptions = getFilterOptionsByKey(
    CENTRALIZED_TASKS_FILTERS.PROGRAM
  );

  const projectOptions = getFilterOptionsByKey(
    CENTRALIZED_TASKS_FILTERS.PROJECT
  );

  const getRangeInititalValue = (
    filterKey: CentralizedTasksFiltersKey,
    filterRange: RangeFilterKeys
  ) => {
    const filter = (filters[filterKey] as RangeFilter) || {};
    const value = filter[filterRange] || '';
    if (DATE_FILTERS.includes(filterKey)) {
      return value ? new Date(value) : null;
    }
    return value;
  };

  const getInitialAssigneeUsers = (filterKey: CentralizedTasksFiltersKey) => {
    const assigneeUserIds = (filters[filterKey] as string[]) || [];
    return assigneeUsers.filter((user: UserAvatars) =>
      assigneeUserIds.includes(user.value)
    );
  };

  return (
    <div className='mt-8 flex flex-col space-y-5'>
      <FormItem>
        <Checkbox
          defaultChecked={(
            filters[CENTRALIZED_TASKS_FILTERS.ADDITIONAL_FLAGS] as string[]
          )?.includes('disabled')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedFilters = getOptionFilters(
              filters,
              CENTRALIZED_TASKS_FILTERS.ADDITIONAL_FLAGS,
              e.target.checked
                ? [{ value: TASK_ADDITONAL_FLAGS.DISABLED, label: 'Disabled' }]
                : []
            );
            onUpdateFilters(updatedFilters);
          }}
          label={intl.get('TASK.INCLUDE_DISABLED')}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters[CENTRALIZED_TASKS_FILTERS.STATUS] as string[]
          )}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          onChange={(options: Option[]) => {
            const updatedFilters = getOptionFilters(
              filters,
              CENTRALIZED_TASKS_FILTERS.STATUS,
              options
            );
            onUpdateFilters(updatedFilters);
          }}
          triggerProps={{
            'aria-label': intl.get('TASK.STATUS'),
            'data-cy': 'tasks-filters__status-picker',
          }}
          listProps={{ 'data-cy': 'tasks-filters__status__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('ENTITIES.PROGRAM', { num: 1 })}>
        <MultiSelectDropdown
          options={programOptions}
          values={getInitialValueForDropDown(
            programOptions,
            filters[CENTRALIZED_TASKS_FILTERS.PROGRAM] as string[]
          )}
          onChange={(options: Option[]) => {
            const updatedFilters = getOptionFilters(
              filters,
              CENTRALIZED_TASKS_FILTERS.PROGRAM,
              options
            );
            onUpdateFilters(updatedFilters);

            setSelectedPrograms(options.map((option) => option.value));
          }}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('ENTITIES.PROGRAM', { num: 1 }),
            'data-cy': 'tasks-filters__program-picker',
            disabled: shouldBlockProgramPicker,
          }}
          listProps={{ 'data-cy': 'tasks-filters__program__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('ENTITIES.PROJECT', { num: 1 })}>
        <MultiSelectDropdown
          options={projectOptions}
          values={getInitialValueForDropDown(
            projectOptions,
            filters[CENTRALIZED_TASKS_FILTERS.PROJECT] as string[]
          )}
          onChange={(options: Option[]) => {
            const updatedFilters = getOptionFilters(
              filters,
              CENTRALIZED_TASKS_FILTERS.PROJECT,
              options
            );
            onUpdateFilters(updatedFilters);

            setSelectedProjects(options.map((option) => option.value));
          }}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('ENTITIES.PROJECT', { num: 1 }),
            'data-cy': 'tasks-filters__project-picker',
          }}
          listProps={{ 'data-cy': 'tasks-filters__project__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.TYPE')}>
        <MultiSelectDropdown
          options={typeOptions}
          values={getInitialValueForDropDown(
            typeOptions,
            filters[CENTRALIZED_TASKS_FILTERS.TYPE] as string[]
          )}
          onChange={(options: Option[]) => {
            const updatedFilters = getOptionFilters(
              filters,
              CENTRALIZED_TASKS_FILTERS.TYPE,
              options
            );
            onUpdateFilters(updatedFilters);
          }}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('TASK.TYPE'),
            'data-cy': 'tasks-filters__type-picker',
          }}
          listProps={{ 'data-cy': 'tasks-filters__type__options-list' }}
        />
      </FormItem>
      {taskTable === CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS && (
        <FormItem label={intl.get('TASK.ASSIGNEE')}>
          <UsersPicker
            triggerText={intl.get('FILTER_GENERAL.PLACEHOLDER')}
            usersList={assigneeUsers}
            selectedUsersList={getInitialAssigneeUsers(
              CENTRALIZED_TASKS_FILTERS.ASSIGNEE_USERS
            )}
            onChange={(users) => {
              const updatedFilters = getOptionFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.ASSIGNEE_USERS,
                users
              );
              onUpdateFilters(updatedFilters);
            }}
            triggerProps={{
              'aria-label': intl.get('TASK.ASSIGNEE'),
              'data-cy': 'tasks-filters__assignee-picker',
            }}
            popperProps={{ 'data-cy': 'tasks-filters__assignee__options-list' }}
          />
        </FormItem>
      )}
      <FormItem label={intl.get('TASK.START_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.START_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.START_DATE,
                date,
                'from'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get('TASK.START_DATE')}__from-range`,
              'data-cy': 'tasks-filters__start-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.START_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.START_DATE,
                date,
                'to'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get('TASK.START_DATE')}__to-range`,
              'data-cy': 'tasks-filters__start-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASK.DUE_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.DUE_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.DUE_DATE,
                date,
                'from'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get('TASK.DUE_DATE')}__from-range`,
              'data-cy': 'tasks-filters__due-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.DUE_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.DUE_DATE,
                date,
                'to'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get('TASK.DUE_DATE')}__to-range`,
              'data-cy': 'tasks-filters__due-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASK.ACTUAL_COMPLETION_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.COMPLETION_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.COMPLETION_DATE,
                date,
                'from'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get(
                'TASK.ACTUAL_COMPLETION_DATE'
              )}__from-range`,
              'data-cy': 'tasks-filters__actual-completion-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.COMPLETION_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) => {
              const updatedFilters = getRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.COMPLETION_DATE,
                date,
                'to'
              );
              onUpdateFilters(updatedFilters);
            }}
            inputProps={{
              'aria-label': `${intl.get(
                'TASK.ACTUAL_COMPLETION_DATE'
              )}__to-range`,
              'data-cy': 'tasks-filters__actual-completion-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASK.ESTIMATED_HOURS')}>
        <div className='w-full flex space-x-4'>
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.FROM')}
            divProps={{ className: 'w-full' }}
            value={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.ESTIMATED_HOURS,
              'from'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = getStringRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.ESTIMATED_HOURS,
                event.target.value,
                'from'
              );
              onUpdateFilters(updatedFilters);
            }}
            aria-label={`${intl.get('TASK.ESTIMATED_HOURS')}__from-range`}
            data-cy='tasks-filters__estimated-hours__from-range'
          />
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.TO')}
            divProps={{ className: 'w-full' }}
            value={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.ESTIMATED_HOURS,
              'to'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = getStringRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.ESTIMATED_HOURS,
                event.target.value,
                'to'
              );
              onUpdateFilters(updatedFilters);
            }}
            aria-label={`${intl.get('TASK.ESTIMATED_HOURS')}__to-range`}
            data-cy='tasks-filters__estimated-hours__to-range'
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASK.ACTUAL_HOURS')}>
        <div className='w-full flex space-x-4'>
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.FROM')}
            divProps={{ className: 'w-full' }}
            value={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.ACTUAL_HOURS,
              'from'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = getStringRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.ACTUAL_HOURS,
                event.target.value,
                'from'
              );
              onUpdateFilters(updatedFilters);
            }}
            aria-label={`${intl.get('TASK.ACTUAL_HOURS')}__from-range`}
            data-cy='tasks-filters__actual-hours__from-range'
          />
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.TO')}
            divProps={{ className: 'w-full' }}
            value={getRangeInititalValue(
              CENTRALIZED_TASKS_FILTERS.ACTUAL_HOURS,
              'to'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = getStringRangeFilters(
                filters,
                CENTRALIZED_TASKS_FILTERS.ACTUAL_HOURS,
                event.target.value,
                'to'
              );
              onUpdateFilters(updatedFilters);
            }}
            aria-label={`${intl.get('TASK.ACTUAL_HOURS')}__to-range`}
            data-cy='tasks-filters__actual-hours__to-range'
          />
        </div>
      </FormItem>
    </div>
  );
};

export default FiltersForm;
