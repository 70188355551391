import {
  Typography,
  TextField,
  TextArea,
  FormItem,
  Dropdown,
  Checkbox,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import TableAvatar from 'Molecules/TableAvatar';
import { getFieldTypeLabel } from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TemplatePage/AddTemplateFieldsModal/FieldTemplateCard';
import get from 'lodash/get';
import {
  FieldTemplateType,
  FieldValueType,
  BaseValue,
} from 'utils/types/fields';
import { Dispatch, SetStateAction, useMemo, ChangeEvent } from 'react';
import {
  ALLOWED_FIELD_VALUE_SELECTORS,
  ALLOWED_FIELD_TYPES,
  FIELD_CONSOLIDATED_TYPES,
  CUSTOM_FIELDS,
} from 'utils/constants';
import FieldOptions from './FieldOptions';
import ToggleField from './ToggleField';
import { Option } from 'utils/customTypes';
import { useSelector } from 'react-redux';
import { selectUser } from 'state/User/userSlice';

const FieldsOverview = ({
  data,
  setData,
  onUpdatePage,
  titleError,
  setTitleError,
}: {
  data: FieldTemplateType;
  setData: Dispatch<SetStateAction<FieldTemplateType>>;
  onUpdatePage: boolean;
  titleError: boolean;
  setTitleError: Dispatch<SetStateAction<boolean>>;
}) => {
  const currentUser = useSelector(selectUser);
  const avatarNameData = get(data, 'owner.data') || currentUser;
  const typeFieldPlaceholderText = data.id
    ? getFieldTypeLabel(data)
    : intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TYPE_FIELD_PLACEHOLDER');

  const showOptions =
    data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN ||
    data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO ||
    data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX;

  const fieldsTypes = useMemo(
    () =>
      Object.values(FIELD_CONSOLIDATED_TYPES).map((type) => ({
        label: intl.get(
          `SETTINGS_PAGE.FIELDS_PAGE.FIELD_NAMES.${type.toUpperCase()}`
        ),
        value: type,
      })),
    []
  );
  const typeCheckboxTextAndValue = useMemo(() => {
    if (data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN) {
      return {
        text: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_TYPE_CHECKBOX.DROPDOWN'
        ),
        checked: data.collection,
      };
    } else if (
      data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER
    ) {
      return {
        text: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_TYPE_CHECKBOX.DATE'
        ),
        checked: data.type === ALLOWED_FIELD_TYPES.DATERANGE,
      };
    } else if (
      data.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      (data?.type === ALLOWED_FIELD_TYPES.SINGLE_LINE ||
        data?.type === ALLOWED_FIELD_TYPES.LABEL ||
        data?.type === ALLOWED_FIELD_TYPES.MULTILINE)
    ) {
      return {
        text: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_TYPE_CHECKBOX.TEXT'
        ),
        checked: data.type === ALLOWED_FIELD_TYPES.MULTILINE,
      };
    } else if (
      data.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      (data?.type === ALLOWED_FIELD_TYPES.NUMERIC ||
        data?.type === ALLOWED_FIELD_TYPES.FLOAT ||
        data?.type === ALLOWED_FIELD_TYPES.INT)
    ) {
      return {
        text: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_TYPE_CHECKBOX.NUMERIC'
        ),
        checked: data.type === ALLOWED_FIELD_TYPES.FLOAT,
      };
    }
    return null;
  }, [data?.value_selector, data?.collection, data?.type]);

  const handleFieldChange = (
    fieldName: string,
    fieldValue: FieldValueType[] | string | BaseValue | boolean
  ) => {
    setData((prevData) => ({ ...prevData, [fieldName]: fieldValue }));
  };

  const changeFieldType = (option: Option) => {
    switch (option.value) {
      case FIELD_CONSOLIDATED_TYPES.CHECKBOX:
      case FIELD_CONSOLIDATED_TYPES.RADIO:
      case FIELD_CONSOLIDATED_TYPES.DROPDOWN:
        handleFieldChange(CUSTOM_FIELDS.VALUE_SELECTOR, option.value);
        handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, []);
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.SINGLE_LINE);
        if (option.value === FIELD_CONSOLIDATED_TYPES.CHECKBOX) {
          handleFieldChange(CUSTOM_FIELDS.COLLECTION, true);
        } else if (option.value === FIELD_CONSOLIDATED_TYPES.DROPDOWN) {
          handleFieldChange(CUSTOM_FIELDS.COLLECTION, false);
        }
        break;
      case FIELD_CONSOLIDATED_TYPES.TOGGLE:
        handleFieldChange(
          CUSTOM_FIELDS.VALUE_SELECTOR,
          ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM
        );
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.BOOLEAN);
        handleFieldChange(CUSTOM_FIELDS.DEFAULT_VALUE, { val: false });
        handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, [
          {
            val: true,
            display: '',
          },
          {
            val: false,
            display: '',
          },
        ]);
        break;
      case FIELD_CONSOLIDATED_TYPES.TEXT_FIELD:
        handleFieldChange(
          CUSTOM_FIELDS.VALUE_SELECTOR,
          ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM
        );
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.SINGLE_LINE);
        break;
      case FIELD_CONSOLIDATED_TYPES.DATE_PICKER:
        handleFieldChange(
          CUSTOM_FIELDS.VALUE_SELECTOR,
          ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER
        );
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.DATE);
        break;
      case FIELD_CONSOLIDATED_TYPES.NUMERICAL_FIELD:
        handleFieldChange(
          CUSTOM_FIELDS.VALUE_SELECTOR,
          ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM
        );
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.INT);
        break;
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN) {
      handleFieldChange(CUSTOM_FIELDS.COLLECTION, event.target.checked);
      return;
    }
    switch (data?.type) {
      case ALLOWED_FIELD_TYPES.MULTILINE:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.SINGLE_LINE);
        break;
      case ALLOWED_FIELD_TYPES.SINGLE_LINE:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.MULTILINE);
        break;
      case ALLOWED_FIELD_TYPES.INT:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.FLOAT);
        break;
      case ALLOWED_FIELD_TYPES.FLOAT:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.INT);
        break;
      case ALLOWED_FIELD_TYPES.DATE:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.DATERANGE);
        break;
      case ALLOWED_FIELD_TYPES.DATERANGE:
        handleFieldChange(CUSTOM_FIELDS.TYPE, ALLOWED_FIELD_TYPES.DATE);
        break;
    }
  };

  return (
    <div className='flex flex-col px-6 pt-4 overflow-y-auto max-h-details-with-bar flex-grow'>
      <Typography variant='h5' data-testid='custom-fields-page-title'>
        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.TITLE')}
      </Typography>
      <Typography
        variant='label'
        className='text-neutral-dark leading-6'
        data-testid='custom-fields-page-subtitle'
      >
        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.SUB_TITLE')}
      </Typography>
      <div
        data-cy='custom-field-details'
        className='mt-4 grid gap-y-6 gap-x-10% grid-cols-2'
      >
        <FormItem
          label={intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TITLE_FIELD_NAME')}
          labelProps={{
            required: true,
            state: titleError ? 'error' : 'default',
          }}
        >
          <TextField
            placeholder={intl.get(
              'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TITLE_FIELD_PLACEHOLDER'
            )}
            defaultValue={data?.name}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setTitleError(false);
              handleFieldChange(CUSTOM_FIELDS.TITLE, event.target.value);
            }}
            state={titleError ? 'error' : 'default'}
            helpText={
              titleError &&
              'Oops! Title already used. Please enter a unique title.'
            }
            data-cy='custom-fields-name-input'
          />
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.CREATOR_FIELD_NAME'
          )}
        >
          <TableAvatar
            data-cy='creator-avatar'
            user={{
              data: {
                firstName: get(avatarNameData, 'firstName', ''),
                lastName: get(avatarNameData, 'lastName', ''),
                email: '',
              },
            }}
          />
        </FormItem>
        <div>
          <FormItem
            label={intl.get(
              'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TYPE_FIELD_NAME'
            )}
            labelProps={{
              required: true,
            }}
          >
            <Dropdown
              options={fieldsTypes}
              values={[]}
              disabled={onUpdatePage}
              placeholder={typeFieldPlaceholderText}
              triggerProps={{ 'data-cy': 'custom-fields-type' }}
              onChange={changeFieldType}
              listProps={{
                'data-cy': 'fields-types-list',
              }}
            />
          </FormItem>
          {typeCheckboxTextAndValue && (
            <Checkbox
              label={typeCheckboxTextAndValue.text}
              checked={typeCheckboxTextAndValue.checked}
              onChange={handleCheckboxChange}
              disabled={
                onUpdatePage ||
                (data.value_selector ===
                  ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN &&
                  data.type === ALLOWED_FIELD_TYPES.BOOLEAN)
              }
              className='mt-2'
              data-cy='custom-fields-type-checkbox'
            />
          )}
          {showOptions && (
            <FieldOptions
              data={data}
              onUpdatePage={onUpdatePage}
              handleFieldChange={handleFieldChange}
            />
          )}
          {data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
            data?.type === ALLOWED_FIELD_TYPES.BOOLEAN && (
              <ToggleField data={data} handleFieldChange={handleFieldChange} />
            )}
        </div>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.DESCRIPTION_FIELD_NAME'
          )}
        >
          <TextArea
            value={data?.description || ''}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              handleFieldChange(CUSTOM_FIELDS.DESCRIPTION, event.target.value)
            }
            textAreaProps={{
              placeholder: intl.get(
                'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.DESCRIPTION_FIELD_PLACEHOLDER'
              ),
              className: 'max-h-32 row-span-2',
              'data-cy': 'custom-fields-description',
            }}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default FieldsOverview;
