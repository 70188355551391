import { Option, AllUsersType } from './customTypes';
import get from 'lodash/get';

export const getInitialValueForDropDown = (
  options: Option[],
  values: string[] | string | undefined | null
) => {
  if (!values) {
    return [];
  }
  return options.filter(
    (option) => values === option.value || values.includes(option.value)
  );
};

export const parseURL = (text: string, linkClassName: string) => {
  const urlRegex =
    /((https?:\/\/)|(www\.))[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;
  return text.replace(urlRegex, (url) => {
    if (!url.match('^https?://')) {
      url = `http://${url}`;
    }
    return `<a href='${url}' class='${linkClassName}' target='_blank' rel='noreferrer'>${url}</a>`;
  });
};

export const parseMention = (value: string) => {
  return value.replace(
    new RegExp('@\\[([^\\]]+?)\\]\\(([^\\)]+?)\\)', 'g'),
    (match, p1, offset) => {
      return `<span class='text-neutral'>@${p1}</span>`;
    }
  );
};

export const extractContent = (html: string) => {
  var span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export const getUserInitials = (user: AllUsersType) => {
  const nameIntials = `${get(user, 'data.firstName', '').charAt(0)}${get(
    user,
    'data.lastName',
    ''
  ).charAt(0)}`;
  if (!nameIntials) {
    return get(user, 'data.email', '').charAt(0);
  }
  return nameIntials;
};

export const getUserDisplayName = (user: AllUsersType) => {
  if (!get(user, 'data.firstName') && !get(user, 'data.lastName')) {
    return get(user, 'data.email', '');
  }

  return `${user.data.firstName} ${user.data.lastName}`;
};

export const scrollToBottomSmoothly = (element: HTMLElement) => {
  const scrollHeight = element.scrollHeight;
  const from = element.scrollTop;
  const duration = 500;

  const startTime = performance.now();
  function scrollStep(currentTime: number) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    element.scrollTop = from + progress * (scrollHeight - from);
    if (elapsed < duration) {
      requestAnimationFrame(scrollStep);
    }
  }
  requestAnimationFrame(scrollStep);
};

export const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
