import { useCallback, useState } from 'react';
import ProgramsTableFilters from './ProgramsTableFilters';
import ProgramsTable from './ProgramsTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  updatePagination,
  selectProgramsPaginationData,
} from 'state/Programs/programsSlice';
import { TableRowDensity } from 'utils/customTypes';
import Pagination from 'Organisms/Pagination/ClientPagination';

const ProgramsList = ({ onAddProgram }: { onAddProgram: () => void }) => {
  const dispatch = useDispatch();
  const pagination = useSelector(selectProgramsPaginationData);
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');

  const handleUpdatePagination = useCallback(
    (pagination) => {
      dispatch(updatePagination(pagination));
    },
    [dispatch]
  );

  return (
    <div className='mt-4 px-6 max-h-programs-table overflow-y-auto'>
      <ProgramsTableFilters
        selectRowDensity={(rowDensity: TableRowDensity) =>
          setRowDensity(rowDensity)
        }
      />
      <ProgramsTable onAddProgram={onAddProgram} rowDensity={rowDensity} />
      <Pagination total={pagination.count} onChange={handleUpdatePagination} />
    </div>
  );
};

export default ProgramsList;
