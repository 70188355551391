import { useEffect, useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { TableRowDensity } from 'utils/customTypes';
import { DATE, SLICE_STATUS } from 'utils/constants';
import { Table, TableOperationHeader, Button } from '@getsynapse/design-system';
import moment from 'moment';
import { FieldsFilter } from 'utils/types/filters';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import FieldsAppliedFiltersTags from './FieldsAppliedFiltersTags';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import {
  fetchCustomFields,
  selectAllFields,
  selectFieldsSliceStatus,
  selectFieldsTableSearchText,
  setCustomFieldsSearch,
  selectFieldsTablePagination,
  updatePagination,
  resetCustomFields,
} from 'state/CustomFields/customFieldsSlice';
import TableSkeletonLoader from 'Organisms/TableSkeletonLoader/TableSkeletonLoader';
import debounce from 'lodash/debounce';
import FieldsFiltersSidePanel from './FieldsFiltersSidePanel';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import emptyProgramsTable from 'assets/images/programs-table-empty.svg';
import TableEmptyErrorState from 'Molecules/TableEmptyErrorState/TableEmptyErrorState';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import TableAvatar from 'Molecules/TableAvatar';
import { getQueryParamsFromFilters } from './helpers';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import noSearchResults from 'assets/images/no-projects-found-table.svg';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { PATHS, SETTINGS_FILTERS } from 'utils/constants';
import { isEmpty } from 'lodash';
import { FieldTemplateType } from 'utils/types/fields';
import { getFieldTypeLabel } from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TemplatePage/AddTemplateFieldsModal/FieldTemplateCard';

const FieldsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchValueState = useSelector(selectFieldsTableSearchText);
  const fieldsTablePagination = useSelector(selectFieldsTablePagination);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.FIELDS_TABLE)
  );
  const customFields = useSelector(selectAllFields);
  const sliceStatus = useSelector(selectFieldsSliceStatus);

  const [rowDensity, setRowDensity] = useState('default');
  const [areFilterReady, setAreFilterReady] = useState<boolean>(false);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(searchValueState);

  const isFetchingNewData = useMemo(
    () =>
      sliceStatus === SLICE_STATUS.LOADING ||
      !areFilterReady ||
      (sliceStatus === SLICE_STATUS.IDLE && !isDataReady),
    [sliceStatus, areFilterReady, isDataReady]
  );
  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as FieldsFilter)
        : ({} as FieldsFilter),
    [filtersSettings]
  );
  const formmatedFiltersForQuery = useMemo(
    () => getQueryParamsFromFilters(appliedFilters),
    [appliedFilters]
  );

  const isFiltersApplied = !isEmpty(formmatedFiltersForQuery);
  const isFilteringOrSearching = isFiltersApplied || searchValueState;

  useEffect(() => {
    return () => {
      dispatch(resetCustomFields());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCustomFields(searchFiltersAndPagination));
      setIsDataReady(true);
    };
    let searchFiltersAndPagination = {
      searchText: searchValueState,
      filters: formmatedFiltersForQuery,
      ...fieldsTablePagination,
    };
    if (areFilterReady) {
      fetchData();
    }
  }, [
    dispatch,
    searchValueState,
    fieldsTablePagination,
    appliedFilters,
    formmatedFiltersForQuery,
    areFilterReady,
  ]);

  useEffect(() => {
    const fetchFilterSetting = async () => {
      await dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.FIELDS_TABLE));
      setAreFilterReady(true);
    };

    fetchFilterSetting();
  }, [dispatch]);

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prevState) => !prevState);

  const handleSearchAndResetPagination = useCallback(
    (value: string) => {
      batch(() => {
        dispatch(setCustomFieldsSearch(value));
        dispatch(updatePagination({ ...fieldsTablePagination, offset: 0 }));
      });
    },
    [dispatch, fieldsTablePagination]
  );

  const debouncedSearch = useMemo(
    () => debounce(handleSearchAndResetPagination, 300),
    [handleSearchAndResetPagination]
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const handleUpdateFilters = useCallback(
    (filters: FieldsFilter) => {
      batch(() => {
        if (filtersSettings && filtersSettings.id) {
          dispatch(
            updateFilterSetting({
              id: filtersSettings.id,
              updateFields: { filter_settings: filters },
            })
          );
        } else {
          dispatch(
            createFilterSetting({
              filter_type: SETTINGS_FILTERS.FIELDS_TABLE,
              filter_settings: filters,
            })
          );
        }
        dispatch(updatePagination({ ...fieldsTablePagination, offset: 0 }));
      });
    },
    [dispatch, fieldsTablePagination, filtersSettings]
  );

  return (
    <>
      <FieldsFiltersSidePanel
        open={shouldDisplayFilterComponent || false}
        onClose={toggleFilterComponent}
        filters={appliedFilters}
        onUpdateFilters={(filters) => handleUpdateFilters(filters)}
      />
      <TableOperationHeader
        className='border-0 rounded-b-none bg-neutral-white'
        inputValue={searchValue}
        setInputValue={handleSearch}
        searchProps={{
          'data-testid': 'custom-fields-table-header__search-input',
        }}
        actionButtons={
          <>
            <RowDensityPopup
              onSelectRowDensity={(rowDensity: TableRowDensity) =>
                setRowDensity(rowDensity)
              }
              disabled={customFields.length === 0}
            />
            <FilterButton
              onToggleFilter={() => {
                setShouldDisplayFilterComponent((prevState) => !prevState);
              }}
              disabled={false}
              aria-pressed={shouldDisplayFilterComponent}
            />
          </>
        }
      />
      <FieldsAppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
        className='border-b-0'
      />
      {isFetchingNewData ? (
        <TableSkeletonLoader />
      ) : (
        <div
          className={classnames(
            'overflow-auto w-full',
            { 'max-h-no-filtered-table-body': isFiltersApplied },
            { 'max-h-small-table': !isFiltersApplied }
          )}
        >
          <Table
            className='w-full mb-5 relative'
            canSelectRows={false}
            isSelectRowCellSticky
            data-cy='custom-fields-table-body'
            data-testid='custom-fields-table-body'
            rowDensity={rowDensity}
            data={{
              headData: {
                stickyHeader: true,
                rowProps: {
                  className: 'w-full table-fixed h-auto min-h-10 z-2',
                },
                headCells: [
                  {
                    content: intl.get(
                      'SETTINGS_PAGE.FIELDS_PAGE.TABLE_COLUMNS.TITLE'
                    ),
                    className: 'font-semibold w-1/4 py-3',
                    'data-cy': 'header__field-name',
                  },
                  {
                    content: intl.get(
                      'SETTINGS_PAGE.FIELDS_PAGE.TABLE_COLUMNS.TYPE'
                    ),
                    className: 'font-semibold w-1/4 py-3',
                    'data-cy': 'header__field-type',
                  },
                  {
                    content: intl.get(
                      'SETTINGS_PAGE.FIELDS_PAGE.TABLE_COLUMNS.CREATOR'
                    ),
                    className: 'font-semibold w-1/4 py-3',
                    'data-cy': 'header__field-creator',
                  },
                  {
                    content: intl.get(
                      'SETTINGS_PAGE.FIELDS_PAGE.TABLE_COLUMNS.LAST_UPDATED'
                    ),
                    className: 'font-semibold w-1/4 py-3',
                    'data-cy': 'header__program-owners',
                  },
                ],
              },
              rows: customFields.map((field: FieldTemplateType) => {
                const avatarNameData = get(field, 'owner.data');
                return {
                  id: field.id,
                  onClick: () =>
                    history.push(
                      `${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}/${field.id}`
                    ),
                  className: 'cursor-pointer w-1/4 table-fixed',
                  cells: [
                    {
                      content: (
                        <Link
                          to={`${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}/${field.id}`}
                        >
                          {field.name}
                        </Link>
                      ),
                      className: 'max-w-94 truncate w-1/4',
                    },
                    {
                      content: getFieldTypeLabel(field),
                    },
                    {
                      content: (
                        <TableAvatar
                          user={{
                            data: {
                              firstName: get(avatarNameData, 'firstName', ''),
                              lastName: get(avatarNameData, 'lastName', ''),
                              email: '',
                            },
                          }}
                        />
                      ),
                      className: 'truncate w-1/4',
                    },
                    {
                      content: moment(new Date(field.updatedAt!)).format(
                        DATE.SHORT_FORMAT
                      ),
                      className: 'truncate w-1/4',
                    },
                  ],
                };
              }),
            }}
            emptyComponent={
              sliceStatus === SLICE_STATUS.FAILED ? (
                <TableEmptyErrorState />
              ) : (
                <RenderNoRecords
                  imageSrc={
                    isFilteringOrSearching
                      ? noSearchResults
                      : emptyProgramsTable
                  }
                  className='h-empty-table-body'
                  caption={
                    isFilteringOrSearching
                      ? intl.get('NO_RECORDS')
                      : intl.get('SETTINGS_PAGE.FIELDS_PAGE.TABLE_EMPTY')
                  }
                  labelClassName={classnames({
                    'mt-0': !isFilteringOrSearching,
                  })}
                  imageClassName={classnames({
                    'ml-2': !isFilteringOrSearching,
                  })}
                >
                  {!isFilteringOrSearching && (
                    <div className='mx-auto mt-2'>
                      <Button
                        onClick={() =>
                          history.push(PATHS.NEW_CUSTOM_FIELD_PAGE)
                        }
                        data-cy='table-add-fields-button'
                      >
                        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.BUTTON_TITLE')}
                      </Button>
                    </div>
                  )}
                </RenderNoRecords>
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default FieldsTable;
