import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {
  Popup,
  Button,
  Icon,
  List,
  Tooltip,
  Typography,
} from '@getsynapse/design-system';
import classnames from 'classnames';
import { TableRowDensity, Option } from 'utils/customTypes';
import { TABLE_ROW_DENSITY } from 'utils/constants';
import DefaultRowDensityIcon from 'assets/icons/default-row-density.svg';
import ShortRowDensityIcon from 'assets/icons/short-row-density.svg';
import TallRowDensityIcon from 'assets/icons/tall-row-density.svg';

type RowDensityOption = Option & { icon: string };

const RowDensityIconMap: Record<TableRowDensity, string> = {
  default: DefaultRowDensityIcon,
  short: ShortRowDensityIcon,
  tall: TallRowDensityIcon,
};

const PopupTrigger: React.FC<{
  isOpen: boolean;
  rowDensity: TableRowDensity;
  disabled?: boolean;
  tableTestId?: string;
}> = ({ isOpen, rowDensity, disabled = false, tableTestId }) => {
  return (
    <Tooltip
      timeout={0}
      className='w-full'
      openMode='hover2'
      position='topCenter'
      showPopper
      trigger={
        <Button
          variant='tertiary'
          size='small'
          className={classnames('border', {
            'bg-neutral-lightest': isOpen,
            'cursor-not-allowed': disabled,
          })}
          disabled={disabled}
          data-testid={`${tableTestId}__row-density__trigger-button`}
          aria-label={intl.get('TABLE_ROW_DENSITY.TOOLTIP_CONTENT')}
        >
          <Icon src={RowDensityIconMap[rowDensity]} className='text-xl mr-2' />
          <Icon name={isOpen ? 'caret-up' : 'caret-down'} />
        </Button>
      }
      contentProps={{
        className: classnames(
          'bg-neutral-darker shadow-tooltip',
          'rounded-lg px-3 py-1',
          'w-max absolute',
          'text-body',
          { hidden: disabled }
        ),
      }}
    >
      <Typography variant='label' className='text-neutral-white'>
        {intl.get('TABLE_ROW_DENSITY.TOOLTIP_CONTENT')}
      </Typography>
    </Tooltip>
  );
};

const RowDensityPopup: React.FC<{
  onSelectRowDensity: (rowDensity: TableRowDensity) => void;
  disabled?: boolean;
  tableTestId?: string;
}> = ({ onSelectRowDensity, disabled = false, tableTestId }) => {
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');

  const rowDensityOptions: RowDensityOption[] = Object.entries(
    TABLE_ROW_DENSITY
  ).map(([key, value]) => ({
    label: intl.get(`TABLE_ROW_DENSITY.${key}`),
    value,
    icon: RowDensityIconMap[value],
  }));

  const handleSelectRowDensity = (
    rowDensity: TableRowDensity,
    callback: () => void
  ) => {
    onSelectRowDensity(rowDensity);
    setRowDensity(rowDensity);
    callback();
  };

  return (
    <Popup
      disabled={disabled}
      trigger={({ isOpen }) => (
        <PopupTrigger
          isOpen={isOpen}
          rowDensity={rowDensity}
          disabled={disabled}
          tableTestId={tableTestId}
        />
      )}
      popperProps={{ className: 'z-20' }}
    >
      {({ close }) => (
        <List
          options={rowDensityOptions}
          onSelectOption={(option: Option) => {
            handleSelectRowDensity(option.value, close);
          }}
          data-testid={`${tableTestId}__row-density__options-list`}
          renderOption={(
            option: RowDensityOption,
            isSelected,
            selectOption: (option: RowDensityOption) => void,
            { className, ...otherProps }
          ) => (
            <li
              onClick={() => selectOption(option.value)}
              {...otherProps}
              className={classnames(className, {
                'text-neutral-dark shadow-list-item-hover bg-neutral-lighter-two':
                  rowDensity === option.value,
              })}
              data-testid={`row-density__option--${option.value}`}
              role='option'
              tabIndex={-1}
              aria-selected={rowDensity === option.value}
            >
              <div className='flex items-center w-full'>
                <Icon src={option.icon} className='mr-2 text-lg' />
                <Typography variant='label' className='cursor-pointer'>
                  {option.label}
                </Typography>
              </div>
            </li>
          )}
        />
      )}
    </Popup>
  );
};

export default RowDensityPopup;
