import moment from 'moment';
import {
  ParticipantsCapacityByType,
  ParticipantCapacity,
  ProjectLevelAllocation,
  Allocation,
  Participant,
} from 'utils/types/tasksAssignmentsResourcesCapacity';
import {
  ParticipantsCapacityApiResponse,
  ParticipantCapacityApiResponse,
  Pagination,
} from './types';
import { ParticipantTypes } from 'utils/constants';

type UserType = 'business' | 'ld' | 'external';

export const mapApiResponseToParticipantsCapacity: (
  apiResponse: ParticipantsCapacityApiResponse
) => ParticipantsCapacityByType = (apiResponse) => {
  const { projectParticipantAllocations } = apiResponse;
  const participantsCapacity = {} as ParticipantsCapacityByType;
  for (const participantType in projectParticipantAllocations) {
    const participantsByType =
      projectParticipantAllocations[participantType as ParticipantTypes];
    const capacityByType = participantsByType.reduce(
      (capacity: ParticipantCapacity, participant) => {
        const participantCapacity =
          mapApiResponseToParticipantCapacity(participant);
        return { ...capacity, ...participantCapacity };
      },
      {}
    );
    participantsCapacity[participantType as ParticipantTypes] = capacityByType;
  }
  return participantsCapacity;
};

export const userTypeToParticipantType: Record<UserType, ParticipantTypes> = {
  business: ParticipantTypes.BUSINESS,
  ld: ParticipantTypes.LEARNING,
  external: ParticipantTypes.EXTERNAL,
};

const mapProjectLevelAllocations: (
  projectLevelAllocations: Record<
    string,
    {
      allocation: number;
      allocatedCapacityWithoutCurrentProject: number;
      totalWeeklyTimeOff: number;
    }
  >,
  participant: Participant
) => Allocation<ProjectLevelAllocation> = (
  projectLevelAllocations,
  participant
) => {
  const respose = {} as Allocation<ProjectLevelAllocation>;
  for (const week of Object.keys(projectLevelAllocations)) {
    const allocation = projectLevelAllocations[week];
    const weeklyCapacity = participant.default_capacity;
    const totalAllocation =
      allocation.allocation + allocation.allocatedCapacityWithoutCurrentProject;
    const remainingAvailability =
      weeklyCapacity - totalAllocation - allocation.totalWeeklyTimeOff;
    respose[week] = {
      allocation: allocation.allocation,
      timeOff: projectLevelAllocations[week].totalWeeklyTimeOff,
      availability: remainingAvailability,
    };
  }
  return respose;
};

export const mapApiResponseToParticipantCapacity: (
  apiResponse: ParticipantCapacityApiResponse
) => ParticipantCapacity = (apiResponse) => {
  const capacity: ParticipantCapacity = {};
  const projectLevelAllocations =
    apiResponse.allocations.projectLevelAllocations;
  const taskLevelAllocations = apiResponse.allocations.taskLevelAllocations;
  const { project_roles, ...userData } = apiResponse.user;
  capacity[apiResponse.user.id] = {
    data: {
      ...userData,
      job_roles: project_roles,
    },
    allocations: mapProjectLevelAllocations(
      projectLevelAllocations,
      apiResponse.user
    ),
    tasks: taskLevelAllocations.map((taskLevelAllocation) => ({
      data: taskLevelAllocation.task,
      allocations: taskLevelAllocation.taskLevelAllocations,
    })),
  };
  return capacity;
};

export const mapSingleParticipantApiResponseToParticipantCapacity: (
  apiResponse: ParticipantsCapacityApiResponse
) => ParticipantCapacity = (apiResponse) => {
  const { projectParticipantAllocations } = apiResponse;
  let capacity = {} as ParticipantCapacity;
  for (const participantType in projectParticipantAllocations) {
    const participantsByType =
      projectParticipantAllocations[participantType as ParticipantTypes];
    if (participantsByType.length > 0) {
      const participant = participantsByType[0];
      capacity = mapApiResponseToParticipantCapacity(participant);
      break;
    }
  }
  return capacity;
};

export const addCapacityToParticipants: (
  apiResponse: ParticipantsCapacityApiResponse,
  currentCapacity: ParticipantsCapacityByType,
  currentRage: { from: string; to: string },
  newOffset: string
) => ParticipantsCapacityByType = (
  apiResponse,
  currentCapacity,
  currentRage,
  newOffset
) => {
  const shouldAddCapacityAtTheEnd = moment(new Date(newOffset)).isAfter(
    new Date(currentRage.from)
  );
  const shouldAddCapacityAtTheBeginning = moment(new Date(newOffset)).isBefore(
    new Date(currentRage.from)
  );
  const updatedParticipantsCapacity = { ...currentCapacity };
  const participantsCapacityByType = apiResponse.projectParticipantAllocations;
  for (const participantType of Object.keys(participantsCapacityByType)) {
    const participantsByType =
      participantsCapacityByType[participantType as ParticipantTypes];
    for (const participant of participantsByType) {
      const participantId = participant.user.id;
      const projectLevelAllocations =
        participant.allocations.projectLevelAllocations;
      const taskLevelAllocations =
        participant.allocations.taskLevelAllocations.reduce(
          (resp: Record<string, any>, { task, taskLevelAllocations }) => {
            resp[task.id] = taskLevelAllocations;
            return resp;
          },
          {}
        );
      const capacity = {
        ...currentCapacity[participantType as ParticipantTypes][participantId],
      };
      if (shouldAddCapacityAtTheEnd) {
        capacity.allocations = {
          ...capacity.allocations,
          ...mapProjectLevelAllocations(
            projectLevelAllocations,
            participant.user
          ),
        };
        capacity.tasks = capacity.tasks.map((task) => ({
          ...task,
          allocations: {
            ...task.allocations,
            ...taskLevelAllocations[task.data.id],
          },
        }));
      }
      if (shouldAddCapacityAtTheBeginning) {
        capacity.allocations = {
          ...mapProjectLevelAllocations(
            projectLevelAllocations,
            participant.user
          ),
          ...capacity.allocations,
        };
        capacity.tasks = capacity.tasks.map((task) => ({
          ...task,
          allocations: {
            ...taskLevelAllocations[task.data.id],
            ...task.allocations,
          },
        }));
      }
      updatedParticipantsCapacity[participantType as ParticipantTypes] = {
        ...updatedParticipantsCapacity[participantType as ParticipantTypes],
        [participantId]: capacity,
      };
    }
  }
  return updatedParticipantsCapacity;
};

export const calculateNewPagination: (
  pagination: Pagination,
  newStartDate: string,
  newEndDate: string,
  fromSidePanel?: boolean
) => Pagination = (
  pagination,
  newStartDate,
  newEndDate,
  fromSidePanel = false
) => {
  const currentPagination = { ...pagination };
  const updatePagination = { ...currentPagination };
  const from = moment(new Date(newStartDate.replace(/-/g, '/')));
  const to = moment(new Date(newEndDate.replace(/-/g, '/')));
  const diff = to.diff(from, 'weeks');
  if (currentPagination.total === 0 || fromSidePanel) {
    updatePagination.from = from
      .clone()
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    updatePagination.to = to
      .clone()
      .subtract(1, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    updatePagination.total = diff;
    updatePagination.index = 12;
  } else {
    updatePagination.total = currentPagination.total + diff;
    if (from.isBefore(new Date(currentPagination.from!))) {
      updatePagination.from = from
        .clone()
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      updatePagination.index = currentPagination.index! + diff + 1;
    }
    if (to.isAfter(new Date(currentPagination.to!))) {
      updatePagination.to = to
        .clone()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
    }
  }
  return updatePagination;
};
