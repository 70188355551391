import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import requestAPI from 'state/Requests/requestAPI';
import { trackEvent } from 'Services/pendo';
import { RootState } from 'state/store';
import { selectOrganizationId, selectUserId } from 'state/User/userSlice';
import { selectFormsOptions } from 'state/Forms/formSlice';
import { addRequest } from 'state/Requests/requestSlice';
import {
  Project,
  Request,
  RequestQuestion,
  Status,
  LDUser,
  UserAvatars,
} from 'utils/customTypes';
import {
  SLICE_STATUS,
  QUESTIONNAIRE_TYPE,
  PENDO_EVENTS,
  REQUEST_STATUS,
} from 'utils/constants';
import { updatePublicDraftSettings } from 'state/UserSetting/userSettingsSlice';

interface RequestState {
  value: {
    request: Request;
    requestQuestions: RequestQuestion[];
  };
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: RequestState = {
  value: {
    request: {},
    requestQuestions: [],
  },
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const newRequest = createAsyncThunk(
  'activeRequest/CREATE_REQUEST',
  async (
    {
      formId,
      requestTypeId,
      businessTeams,
      questions,
      description,
      title,
      shouldUpdateActiveRequest = true,
      status = REQUEST_STATUS.DRAFT,
    }: {
      formId: string;
      requestTypeId: string;
      businessTeams?: string[];
      questions?: { [key: string]: RequestQuestion };
      description?: string;
      title?: string;
      shouldUpdateActiveRequest?: boolean;
      status?: string;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const userId = selectUserId(state);
    const organizationId = selectOrganizationId(state);
    if (userId && organizationId) {
      const response = await requestAPI.createRequest(
        formId,
        requestTypeId,
        userId,
        organizationId,
        businessTeams,
        questions,
        {},
        title,
        description,
        status
      );
      trackEvent(PENDO_EVENTS.REQUEST_SUBMITTED, {
        title: response.data.title,
      });
      dispatch(addRequest(response.data));
      return { data: response.data, shouldUpdateActiveRequest };
    } else {
      return { data: '' };
    }
  }
);

export const getRequest = createAsyncThunk(
  'activeRequest/GET_REQUEST',
  async (requestId: string) => {
    const response = await requestAPI.fetchRequest(requestId);
    return response.data;
  }
);

export const getRequestQuestions = createAsyncThunk(
  'activeRequest/GET_REQUEST_QUESTIONS',
  async (requestId: string) => {
    const response = await requestAPI.fetchRequestQuestions(requestId);
    return response.data;
  }
);

export const editRequest = createAsyncThunk(
  'activeRequest/EDIT_REQUEST',
  async ({
    request,
    updateData,
  }: {
    request: Request;
    updateData: Request;
  }) => {
    const requestId = request.id;
    const updatedRequest = { ...request, ...updateData };
    if (requestId) {
      const response = await requestAPI.updateRequest(
        requestId,
        updatedRequest
      );
      return response.data;
    }
  }
);

export const updateRequestQuestions = createAsyncThunk(
  'activeRequest/UPDATE_REQUEST_QUESTIONS',
  async ({ requestId, updateData }: { requestId: string; updateData: any }) => {
    const { data } = await requestAPI.updateQuestions(requestId, {
      data: Object.values(updateData),
    });
    return data[0];
  }
);

export const createRequestQuestions = createAsyncThunk(
  'activeRequest/CREATE_REQUEST_QUESTIONS',
  async ({
    requestId,
    questionType,
  }: {
    requestId: string;
    questionType: string;
  }) => {
    const response = await requestAPI.createQuestion(requestId, questionType);
    return response;
  }
);

export const deleteRequestQuestions = createAsyncThunk(
  'activeRequest/DELETE_REQUEST_QUESTIONS',
  async ({
    questionId,
    requestId,
  }: {
    questionId: string;
    requestId: string;
  }) => {
    const response = await requestAPI.deleteQuestion(questionId);
    return response;
  }
);

export const updateOwners = createAsyncThunk(
  'activeRequest/UPDATE_OWNERS',
  async ({
    requestId,
    ownersIds,
  }: {
    requestId: string;
    ownersIds: string[];
  }) => {
    await requestAPI.setOwners(requestId, ownersIds);
    const response = await requestAPI.fetchRequest(requestId);
    return response.data;
  }
);

export const updateReviewers = createAsyncThunk(
  'activeRequest/UPDATE_BUSINESS_REVIEWERS',
  async ({
    requestId,
    reviewersId,
  }: {
    requestId: string;
    reviewersId: string[];
  }) => {
    await requestAPI.setReviewers(requestId, reviewersId);
    const response = await requestAPI.fetchRequest(requestId);
    return response.data;
  }
);

export const linkProjectsToRequest = createAsyncThunk(
  'projects/LINK_REQUESTS',
  async ({
    projects,
    requestId,
  }: {
    projects: Project[];
    requestId: string;
  }) => {
    const projectIds = projects.map((project) => project.id);
    await requestAPI.linkProjectsToRequest(projectIds, requestId);
    return { projects };
  }
);

export const updateRequestLinkedProjects = createAsyncThunk(
  'activeRequest/UPDATE_REQUEST_LINKED_PROJECT',
  async (project: Project, { getState }) => {
    const state = getState() as RootState;
    const projects =
      state.activeRequest.value.request.requestProjects?.concat(project);
    return { projects };
  }
);

/* ================================= REDUCER ================================ */
const activeRequestSlice = createSlice({
  name: 'activeRequest',
  initialState,
  reducers: {
    RESET_ACTIVE_REQUEST: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newRequest.fulfilled, (state, action) => {
        if (action.payload.shouldUpdateActiveRequest) {
          state.value.request = action.payload.data as Request;
        }
      })
      .addCase(getRequest.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getRequest.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getRequest.fulfilled, (state, action) => {
        state.value.request = action.payload as Request;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getRequestQuestions.fulfilled, (state, action) => {
        state.value.requestQuestions = action.payload;
      })
      .addCase(editRequest.fulfilled, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(editRequest.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(createRequestQuestions.fulfilled, (state, action) => {
        state.value.requestQuestions = [
          ...state.value.requestQuestions,
          action.payload,
        ];
      })
      .addCase(deleteRequestQuestions.fulfilled, (state, action) => {
        state.value.requestQuestions = state.value.requestQuestions.filter(
          (question) => question.id !== action.payload.id
        );
      })
      .addCase(updateRequestQuestions.fulfilled, (state, action) => {
        state.value.requestQuestions = state.value.requestQuestions.map(
          (question) =>
            question.id === action.payload.id ? action.payload : question
        );
      })
      .addCase(updateOwners.fulfilled, (state, action) => {
        state.value.request = action.payload;
      })
      .addCase(linkProjectsToRequest.fulfilled, (state, action) => {
        state.value.request = {
          ...state.value.request,
          requestProjects: [...action.payload.projects],
        };
      })
      .addCase(updateRequestLinkedProjects.fulfilled, (state, action) => {
        state.value.request = {
          ...state.value.request,
          requestProjects: action.payload.projects,
        };
      })
      .addCase(updatePublicDraftSettings.fulfilled, (state, action) => {
        state.value.request = {
          ...state.value.request,
          draft_public_setting: action.payload.draft_public_setting,
        };
      });
  },
});

/* ================================ ACTIONS ================================= */
export const { RESET_ACTIVE_REQUEST: resetActiveRequest } =
  activeRequestSlice.actions;

/* =============================== SELECTORS ================================ */
export const selectActiveRequestSliceStatus = (state: RootState) =>
  state.activeRequest.status;

export const selectActiveRequest = (state: RootState) =>
  state.activeRequest.value.request;

export const selectActiveRequestQuestions = (state: RootState) =>
  state.activeRequest.value.requestQuestions;

export const selectActiveRequestMentionableUsers = createSelector(
  [selectActiveRequest],
  (request: Request) => {
    return request.owners
      ? (request.owners.map((user: LDUser) => ({
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data.firstName.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          value: user.id,
        })) as UserAvatars[])
      : [];
  }
);

export const selectActiveRequestId = createSelector(
  [selectActiveRequest],
  (request) => request.id
);

export const selectIsActiveRequestAForm = createSelector(
  [selectActiveRequest],
  (request) => request.type === QUESTIONNAIRE_TYPE.FORM
);

export const selectActiveRequestForm = createSelector(
  [selectActiveRequest, selectFormsOptions, selectIsActiveRequestAForm],
  (request, forms, isForm) => {
    if (isForm) {
      return {
        label: request.formTitle,
        value: request.id || '',
        description: request.description,
      };
    } else {
      if (request.form_id) {
        const form = forms.find((form) => form.value === request.form_id);
        if (form !== undefined) {
          return {
            label: form.label,
            value: form.value,
            description: form.formDescription,
          };
        }
      } else {
        return { label: '', value: '', description: '' };
      }
    }
  }
);

export const selectActiveRequestType = createSelector(
  [selectActiveRequest],
  (request) => request.request_type
);

export default activeRequestSlice.reducer;
