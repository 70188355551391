import get from 'lodash/get';
import {
  Project,
  ProjectParticipant,
  ProjectCollaborator,
  AllUsersType,
  LDTeam,
  LearningTeam,
} from 'utils/customTypes';
import isEmpty from 'lodash/isEmpty';
import {
  PROJECT_PARTICIPANT_TYPE,
  USER_TYPES,
  USER_ROLES,
  PROJECT_PRIVACY,
  PROJECT_USER_ACTIONS,
  PROJECT_PERMISSIONS_BY_LEVEL,
  PROJECT_STATUS,
} from 'utils/constants';

export const getUserParticipantType = (
  project: Project,
  currentUserId: string | undefined
) => {
  if (!currentUserId) {
    return PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
  }
  const owners = project.owners.map((owner) =>
    get(owner, 'project_owners.userId')
  );
  const members =
    project.participants?.map((participant: ProjectParticipant) =>
      get(participant, 'project_participants.userId')
    ) || [];
  const collaborators =
    project.collaborators?.map((collaborator: ProjectCollaborator) =>
      get(collaborator, 'project_collaborators.userId')
    ) || [];

  if (owners.length === 0) {
    return undefined;
  }

  const isCurrentUserOwner = owners.find(
    (ownerId: string) => ownerId === currentUserId
  );
  if (isCurrentUserOwner) {
    return PROJECT_PARTICIPANT_TYPE.OWNER;
  }

  const isCurrentUserMember = members.find(
    (memberId: string) => memberId === currentUserId
  );
  if (isCurrentUserMember) {
    return PROJECT_PARTICIPANT_TYPE.MEMBER;
  }

  const isCurrentUserCollaborator = collaborators.find(
    (collaboratorId: string) => collaboratorId === currentUserId
  );
  if (isCurrentUserCollaborator) {
    return PROJECT_PARTICIPANT_TYPE.COLLABORATOR;
  }

  return PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
};

export const getUserAccessOnProject = (
  projectData: Project,
  userData: AllUsersType | undefined,
  ldTeams: LearningTeam[]
) => {
  const userType = get(userData, 'type');
  const userRole = get(userData, 'role', USER_ROLES.USER);
  const currentUserId = get(userData, 'id');
  let hasAccess = true;
  const projectParticipantType = getUserParticipantType(
    projectData,
    currentUserId
  );
  let permissions = projectParticipantType;

  if (
    (userType === USER_TYPES.BUSINESS || userType === USER_TYPES.EXTERNAL) &&
    projectParticipantType === PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT
  ) {
    hasAccess = false;
  }

  if (userType === USER_TYPES.L_D && userRole !== USER_ROLES.ADMIN) {
    const projectPrivacyMode = get(projectData, 'privacy');
    const isUserProjectParticipant =
      projectParticipantType !== PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
    if (projectPrivacyMode && projectPrivacyMode === PROJECT_PRIVACY.PRIVATE) {
      hasAccess = isUserProjectParticipant;
    }

    if (projectPrivacyMode && projectPrivacyMode === PROJECT_PRIVACY.TEAM) {
      const allowedProjectTeams = get(projectData, 'ldteams', []).map(
        (team: LDTeam) => team.id
      );
      const userLearningTeams = get(userData, 'registeredLearningTeams', []);
      const managedTeams = get(userData, 'teamsManaged', []) as LearningTeam[];
      const userBelongsToAllowedTeam = userLearningTeams.some((team: LDTeam) =>
        allowedProjectTeams.includes(team.id)
      );

      const getSubTeamIds = (ldTeamId?: string) => {
        if (!ldTeamId) {
          return [];
        }
        return ldTeams
          .filter((team: LearningTeam) => {
            return team.parent_id === ldTeamId;
          })
          .map((team: LearningTeam) => team.id);
      };

      const checkSubTeamsAccess = (baseTeamIds: string[]) => {
        const exploredLearningTeamIds = new Set();
        while (!isEmpty(baseTeamIds)) {
          const learningTeamId = baseTeamIds.shift();
          exploredLearningTeamIds.add(learningTeamId);
          if (learningTeamId && allowedProjectTeams.includes(learningTeamId)) {
            return true;
          }
          const subTeamIds = getSubTeamIds(learningTeamId);
          for (const childTeam of subTeamIds) {
            if (!exploredLearningTeamIds.has(childTeam)) {
              baseTeamIds.push(childTeam);
            }
          }
        }
        return false;
      };

      const userRegisteredTeamIds = userLearningTeams.map(
        (team: LearningTeam) => team.id
      );
      const userRegisteredInAllowedOrParentTeam = checkSubTeamsAccess(
        userRegisteredTeamIds
      );

      const userManagedTeamIds = managedTeams.map(
        (team: LearningTeam) => team.id
      );
      const userManagesAllowedTeam = checkSubTeamsAccess(userManagedTeamIds);

      if (userManagesAllowedTeam) {
        permissions = PROJECT_PARTICIPANT_TYPE.OWNER;
      }

      hasAccess =
        userBelongsToAllowedTeam ||
        isUserProjectParticipant ||
        userManagesAllowedTeam ||
        userRegisteredInAllowedOrParentTeam;
    }

    if (projectPrivacyMode && projectPrivacyMode === PROJECT_PRIVACY.PUBLIC) {
      hasAccess = true;
    }
  }

  if (userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN) {
    permissions = PROJECT_PARTICIPANT_TYPE.OWNER;
  }

  const availableActions = PROJECT_PERMISSIONS_BY_LEVEL[permissions];

  let canUpdateProject = false;
  if (!isEmpty(availableActions)) {
    canUpdateProject = availableActions.includes(
      PROJECT_USER_ACTIONS.UPDATE_PROJECT
    );
  }

  const isReadOnly =
    [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      projectData.status
    ) || !canUpdateProject;

  return { hasAccess, isReadOnly, permissionLevel: permissions };
};
