import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import {
  getOriginalProjectData,
  getCurrentUserParticipantType,
  isCurrentUserTakingPartInProject,
} from 'state/Project/projectSlice';
import { selectUserById } from 'state/UsersManagement/usersManagementSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import {
  USER_TYPES,
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_PRIVACY,
  USER_ROLES,
} from 'utils/constants';
import {
  LDTeam,
  LearningTeam,
  ProjectPermissionsLevel,
  Owner,
} from 'utils/customTypes';

const useHasUserAccess = () => {
  const [hasUserAccess, setHasUserAccess] = useState<boolean>(true);
  const [permissionsLevel, setPermissionsLevel] =
    useState<ProjectPermissionsLevel>(PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT);
  const projectData = useSelector(getOriginalProjectData);
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const isCurrentUserProjectParticipant = useSelector(
    isCurrentUserTakingPartInProject
  );
  let projectParticipantType = useSelector(getCurrentUserParticipantType);
  const userData = useSelector(selectUserById);
  const ldTeams = useSelector(selectLearningTeams);
  const userType = get(userData, 'type');
  const userRole = get(userData, 'role', USER_ROLES.USER);

  const getTeamsAndSubTeams = useCallback(
    (initialTeams: string[], ldTeams: LearningTeam[]) => {
      return ldTeams.filter(
        (team: LearningTeam) =>
          initialTeams.includes(team.id) ||
          initialTeams.includes(get(team, 'parent_id') || '')
      );
    },
    []
  );

  if (
    tasksAssignmentsBasedResourcesCapacity &&
    isCurrentUserProjectParticipant
  ) {
    projectParticipantType =
      userType === USER_TYPES.L_D
        ? PROJECT_PARTICIPANT_TYPE.MEMBER
        : PROJECT_PARTICIPANT_TYPE.COLLABORATOR;
  }

  const validateUserAccess = useCallback(() => {
    let hasAccess = true;
    let permissions = projectParticipantType;
    const owners = projectData.owners.map(
      (owner) => get(owner, 'project_owners.userId') || ''
    );

    if (
      (userType === USER_TYPES.BUSINESS || userType === USER_TYPES.EXTERNAL) &&
      projectParticipantType === PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT
    ) {
      hasAccess = false;
    }

    if (userType === USER_TYPES.L_D && userRole !== USER_ROLES.ADMIN) {
      const projectPrivacyMode = get(projectData, 'privacy');
      const isUserProjectParticipant =
        projectParticipantType !== PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
      if (
        projectPrivacyMode &&
        projectPrivacyMode === PROJECT_PRIVACY.PRIVATE
      ) {
        hasAccess = isUserProjectParticipant;
      }

      if (projectPrivacyMode && projectPrivacyMode === PROJECT_PRIVACY.TEAM) {
        const allowedProjectTeams = get(projectData, 'ldteams', []).map(
          (team: LDTeam) => team.id
        );
        const userLearningTeams = get(userData, 'registeredLearningTeams', []);
        const managedTeams = get(
          userData,
          'teamsManaged',
          []
        ) as LearningTeam[];
        const userBelongsToAllowedTeam = userLearningTeams.some(
          (team: LDTeam) => allowedProjectTeams.includes(team.id)
        );

        const userRegisteredTeamIds = userLearningTeams.map(
          (team: LearningTeam) => team.id
        );
        const userRegisteredTeamsSubTeams = getTeamsAndSubTeams(
          userRegisteredTeamIds,
          ldTeams
        ).map((team) => team.id);

        const userManagedTeamIds = managedTeams.map(
          (team: LearningTeam) => team.id
        );
        const userManagedTeamsSubTeams = getTeamsAndSubTeams(
          userManagedTeamIds,
          ldTeams
        ).map((team) => team.id);

        const userRegisteredInAllowedOrParentTeam =
          userRegisteredTeamsSubTeams.some((teamId) =>
            allowedProjectTeams.includes(teamId)
          );
        const userManagesAllowedTeam = userManagedTeamsSubTeams.some((teamId) =>
          allowedProjectTeams.includes(teamId)
        );

        if (userManagesAllowedTeam) {
          permissions = PROJECT_PARTICIPANT_TYPE.OWNER;
        }

        hasAccess =
          userBelongsToAllowedTeam ||
          isUserProjectParticipant ||
          userManagesAllowedTeam ||
          userRegisteredInAllowedOrParentTeam;
      }

      if (projectPrivacyMode && projectPrivacyMode === PROJECT_PRIVACY.PUBLIC) {
        hasAccess = true;
        const managedTeamIds = get(userData, 'teamsManaged', []).map(
          (team: LearningTeam) => team.id
        );
        const userLdTeams = getTeamsAndSubTeams(managedTeamIds, ldTeams);
        const teamMembersIds = userLdTeams
          .reduce(
            (acc: Owner[], team: LearningTeam) => [
              ...acc,
              ...team.ldTeamMembers,
            ],
            []
          )
          .map((member: Owner) => member.id);

        const ownersArePartOfManagedTeams = owners.some((ownerId) =>
          teamMembersIds.includes(ownerId)
        );
        if (ownersArePartOfManagedTeams) {
          permissions = PROJECT_PARTICIPANT_TYPE.OWNER;
        }
      }
    }

    if (
      (userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN) ||
      owners.includes(get(userData, 'id'))
    ) {
      permissions = PROJECT_PARTICIPANT_TYPE.OWNER;
    }

    setHasUserAccess(hasAccess);
    setPermissionsLevel(permissions);
  }, [
    projectParticipantType,
    userType,
    userRole,
    projectData,
    userData,
    getTeamsAndSubTeams,
    ldTeams,
  ]);

  useEffect(() => {
    if (projectData && userData && projectParticipantType) {
      validateUserAccess();
    }
  }, [projectData, userData, validateUserAccess, projectParticipantType]);

  return { hasUserAccess, permissionsLevel };
};

export default useHasUserAccess;
