/**
 * Default page size for tables
 * @constant
 */
export const DEFAULT_PAGE_SIZE = 15;

/**
 * Initial offset for pagination
 * @constant
 */
export const DEFAULT_OFFSET = 0;

/**
 * Old paths that are no longer in use
 * @constant
 */
export const LEGACY_PATHS = {
  REGISTER: '/register',
} as const;
