import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { FlatfileSettings, FlatfileResults } from '@flatfile/react';
import {
  USER_IMPORT_ATTRIBUTES,
  COUNTRIES,
  EMPLOYMENT_TYPE,
  JOB_FUNCTIONS,
  LANGUAGES,
} from 'utils/constants';
import { LDUser, Option, BusinessUser } from 'utils/customTypes';

export const flatfileBusinessUserSettings = (businessTeams: Option[]) => ({
  type: 'Users',
  title: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_BUSINESS_USERS'),
  disableManualInput: true,
  devMode: process.env.REACT_APP_APP_ENV !== 'prod',
  fields: [
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.EMAIL'),
      key: USER_IMPORT_ATTRIBUTES.EMAIL,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.FIRST_NAME'),
      key: USER_IMPORT_ATTRIBUTES.FIRST_NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.LAST_NAME'),
      key: USER_IMPORT_ATTRIBUTES.LAST_NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY'),
      key: USER_IMPORT_ATTRIBUTES.COUNTRY,
      validators: [{ validate: 'required' }],
      type: 'select',
      options: COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROVINCE'),
      key: USER_IMPORT_ATTRIBUTES.STATE,
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.JOB_TITLE'),
      key: USER_IMPORT_ATTRIBUTES.JOB_TITLE,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.BUSINESS_TEAM'),
      key: USER_IMPORT_ATTRIBUTES.BUSINESS_TEAM,
      type: 'select',
      options: businessTeams,
    },
  ],
});

export const flatfileLDUserSettings: (
  ldTeams: Option[],
  importingBusinessUsers?: boolean
) => FlatfileSettings = (ldTeams) => ({
  type: 'Users',
  title: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_LEARNING_USERS'),
  disableManualInput: true,
  devMode: process.env.REACT_APP_APP_ENV !== 'prod',
  fields: [
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.EMAIL'),
      key: USER_IMPORT_ATTRIBUTES.EMAIL,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('USERS_PAGE.TABLE.USER_ROLE.ADMIN'),
      key: USER_IMPORT_ATTRIBUTES.IS_ADMIN,
      type: 'checkbox',
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.FIRST_NAME'),
      key: USER_IMPORT_ATTRIBUTES.FIRST_NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.LAST_NAME'),
      key: USER_IMPORT_ATTRIBUTES.LAST_NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY'),
      key: USER_IMPORT_ATTRIBUTES.COUNTRY,
      validators: [{ validate: 'required' }],
      type: 'select',
      options: COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROVINCE'),
      key: USER_IMPORT_ATTRIBUTES.STATE,
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.JOB_TITLE'),
      key: USER_IMPORT_ATTRIBUTES.JOB_TITLE,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get(
        'SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.EMPLOYMENT_TYPE'
      ),
      key: USER_IMPORT_ATTRIBUTES.EMPLOYMENT_TYPE,
      type: 'select',
      options: EMPLOYMENT_TYPE.map((empType) => ({
        value: empType,
        label: intl.get(`TEAMS.EMPLOYMENT_TYPE.${empType}`),
      })),
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.TEAM'),
      key: USER_IMPORT_ATTRIBUTES.TEAM,
      type: 'select',
      options: ldTeams,
    },
    {
      label: intl.get(
        'SETTINGS_PAGE.USER_PAGE.CAPACITY_MANAGEMENT.WEEKLY_CAPACITY'
      ),
      key: USER_IMPORT_ATTRIBUTES.DEFAULT_CAPACITY,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.JOB_FUNCTION'),
      key: USER_IMPORT_ATTRIBUTES.JOB_FUNCTION,
      type: 'select',
      options: JOB_FUNCTIONS.map((jobFunctions) => ({
        value: jobFunctions,
        label: intl.get(`TEAMS.JOB_FUNCTIONS.${jobFunctions}`),
      })),
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.LANGUAGES'),
      key: USER_IMPORT_ATTRIBUTES.LANGUAGES,
      type: 'select',
      options: LANGUAGES.map((language) => ({
        value: language,
        label: intl.get(`LANGUAGES.${language}`),
      })),
    },
    {
      label: intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.HOURLY_RATE'),
      key: USER_IMPORT_ATTRIBUTES.HOURLY_RATE,
    },
  ],
});

export const defaultUser: Partial<LDUser> = {
  country_iso_3166_1_alpha_2_code: '',
  type: 'ld',
  role: 'user',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
  },
  default_capacity: 0,
};

export const defaultBusinessUser: Partial<BusinessUser> = {
  country_iso_3166_1_alpha_2_code: '',
  type: 'business',
  role: 'user',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
  },
};

export const cleanImportedUsers = async (
  result: FlatfileResults,
  organizationId: string,
  importingBusinessUsers?: boolean
) => {
  const usersData = result.validData;

  if (isEmpty(usersData)) {
    return [];
  }
  const cleanUsersData = usersData.map((user) => {
    let cleanUser: Partial<LDUser | BusinessUser> = importingBusinessUsers
      ? { ...defaultBusinessUser }
      : { ...defaultUser };

    for (const key of Object.keys(cleanUser)) {
      if (key === 'data') {
        cleanUser = {
          ...cleanUser,
          data: {
            ...cleanUser.data,
            firstName: get(user, 'data.firstName'),
            lastName: get(user, 'data.lastName'),
            email: get(user, 'data.email'),
            jobTitle: get(user, 'data.jobTitle'),
          },
        };
        const province = get(user, 'data.province');
        if (province) {
          cleanUser.data!.province = province;
        }
        if (!importingBusinessUsers) {
          const jobFunctions = get(user, 'data.jobFunctions');
          const defaultCapacity = get(user, 'default_capacity') || 0;
          const employmentType =
            get(user, 'data.employmentType') || 'FULL_TIME';
          const rateHour = get(user, 'data.rateHour') || 0;
          const languages = get(user, 'data.languages');
          const registeredLearningTeams =
            get(user, 'registeredLearningTeams') || null;
          (cleanUser as LDUser).registeredLearningTeams =
            registeredLearningTeams;
          (cleanUser as LDUser).default_capacity = defaultCapacity;
          (cleanUser as LDUser).data.jobFunctions = jobFunctions
            ? [jobFunctions]
            : [];
          (cleanUser as LDUser).data.employmentType = employmentType;
          (cleanUser as LDUser).data.rateHour = rateHour;
          (cleanUser as LDUser).data.languages = languages ? [languages] : [];
        } else {
          (cleanUser as BusinessUser).businessTeam_id =
            get(user, 'businessTeams.id') || null;
        }
      } else {
        cleanUser = { ...cleanUser, [key]: get(user, key) };
      }
    }

    if (get(user, 'role')) {
      cleanUser.role = 'admin';
    } else {
      cleanUser.role = 'user';
    }
    cleanUser = {
      ...cleanUser,
      type: importingBusinessUsers ? 'business' : 'ld',
      organization_id: organizationId,
      status: 'invited',
    };
    return cleanUser;
  });
  return cleanUsersData;
};
