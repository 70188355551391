import intl from 'react-intl-universal';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Option, UserOption, Task } from 'utils/customTypes';
import { TASK_FIELDS, TASK_TYPES, LONG_INPUTS_LENGTH } from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import {
  Datepicker,
  FormItem,
  NumericInput,
  TextArea,
  TextField,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { getAvailableUsersForTaskAssignees } from 'state/SingleTask/singleTaskSlice';
import TypePicker from 'Molecules/TypePicker/TypePicker';

const TaskForm: React.FC<{
  canCreateTask: boolean;
  duplicateTaskCheck: boolean;
  taskData: Task;
  projectId: string;
  setTaskFields: React.Dispatch<React.SetStateAction<any>>;
  setCanCreateTask: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  canCreateTask,
  duplicateTaskCheck,
  taskData,
  projectId,
  setCanCreateTask,
  setTaskFields = () => {},
}) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const availableUsers = useSelector(
    tasksAssignmentsBasedResourcesCapacity
      ? selectAllUsersForDropdown
      : getAvailableUsersForTaskAssignees
  );

  const [fieldsValues, setFieldsValues] = useState({
    name: duplicateTaskCheck
      ? intl.get('TASKS.TABLE.COPY_OF_PLACEHOLDER').concat(' ', taskData?.name)
      : taskData?.name,
    type: taskData?.type,
    description: taskData?.description,
    start_date: '',
    due_date: '',
    estimate_hours: taskData?.estimate_hours || 0,
    project_id: projectId,
    assignedUserIds: [],
  });

  useEffect(() => {
    setFieldsValues((prevData) => ({
      ...prevData,
      name: duplicateTaskCheck
        ? intl
            .get('TASKS.TABLE.COPY_OF_PLACEHOLDER')
            .concat(' ', taskData?.name)
        : taskData?.name,
      type: taskData.type,
      description: taskData.description,
      estimate_hours: taskData?.estimate_hours || 0,
    }));
  }, [setFieldsValues, taskData, duplicateTaskCheck]);

  const updateValue = (newValue: any) => {
    setFieldsValues((prevData) => ({ ...prevData, ...newValue }));
  };

  const hasAssingees = fieldsValues.assignedUserIds?.length > 0;

  const handlePickOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    updateValue({ [TASK_FIELDS.ASSIGNEE_ADD]: ownersIds });
  };

  useEffect(() => {
    if (
      ((hasAssingees && fieldsValues.start_date && fieldsValues.due_date) ||
        !hasAssingees) &&
      fieldsValues.name
    ) {
      !canCreateTask && setCanCreateTask(true);
    } else {
      canCreateTask && setCanCreateTask(false);
    }
  }, [canCreateTask, fieldsValues, setCanCreateTask, hasAssingees]);

  useEffect(() => {
    setTaskFields(fieldsValues);
  }, [setTaskFields, fieldsValues]);

  return (
    <div className='flex flex-col'>
      <Typography>{intl.get('TASKS.ADD_TASK_MODAL.SUBTITLE')}</Typography>

      <div className='mt-4 flex w-full gap-x-10 px-px'>
        <div className='grid gap-y-4 w-2/4'>
          <FormItem>
            <TextField
              labelProps={{ required: true }}
              label={intl.get('TASKS.ADD_TASK_MODAL.TASK_TITLE')}
              displayCharactersCount
              maxLength={LONG_INPUTS_LENGTH}
              defaultValue={fieldsValues.name}
              onChange={(e: any) =>
                updateValue({ [TASK_FIELDS.NAME]: e.target.value })
              }
              placeholder={intl.get(
                'TASKS.ADD_TASK_MODAL.TASK_TITLE_PLACEHOLDER'
              )}
              className='w-full'
              data-cy='task_title-name'
            />
          </FormItem>

          <TypePicker
            formItemProps={{
              label: intl.get('TASKS.ADD_TASK_MODAL.TASK_TYPE'),
            }}
            dropdownProps={{
              onChange: (option: Option) =>
                updateValue({ [TASK_FIELDS.TASK_TYPE]: option.label }),
              triggerProps: {
                'data-cy': 'task-type',
                placeholder: intl.get(
                  'TASKS.ADD_TASK_MODAL.TASK_TYPE_PLACEHOLDER'
                ),
              },
            }}
            currentValue={get(taskData, 'type') || ''}
            options={Object.keys(TASK_TYPES)}
            labelRoute='TASKS.TASK_TYPES'
          />

          <FormItem>
            <Datepicker
              className='flex justify-evenly w-full'
              canSelectRange={true}
              startDateLabel={intl.get('TASKS.ADD_TASK_MODAL.START_DATE')}
              size='large'
              onPickDate={(dates: any) =>
                updateValue({
                  [TASK_FIELDS.START_DATE]: dates.startDate,
                  [TASK_FIELDS.DUE_DATE]: dates.endDate,
                })
              }
              endDateLabel={intl.get('TASKS.ADD_TASK_MODAL.END_DATE')}
              data-cy='task-input_date'
              startDateLabelProps={{
                required: hasAssingees,
              }}
              endDateLabelProps={{
                required: hasAssingees,
              }}
            />
          </FormItem>

          <FormItem
            component='div'
            label={intl.get('TASKS.ADD_TASK_MODAL.ESTIMATED_TIME')}
            className='pb-px'
          >
            <div className='flex items-center'>
              <NumericInput
                data-cy='estimated_time'
                placeholder={intl.get(
                  'TASKS.ADD_TASK_MODAL.ESTIMATED_TIME_PLACEHOLDER'
                )}
                divProps={{ className: 'w-11/12' }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  updateValue({
                    [TASK_FIELDS.ESTIMATED_HOURS]: event.target.value,
                  });
                }}
                defaultValue={
                  fieldsValues.estimate_hours &&
                  parseFloat(fieldsValues.estimate_hours.toString())
                }
              />
              <span className='pl-4'>hrs</span>
            </div>
          </FormItem>
        </div>

        <div className='w-2/4'>
          <FormItem label={intl.get('TASKS.ADD_TASK_MODAL.TASK_DESCRIPTION')}>
            <TextArea
              textAreaProps={{
                className: 'max-h-30',
                placeholder: intl.get(
                  'TASKS.ADD_TASK_MODAL.TASK_DESCRIPTION_PLACEHOLDER'
                ),
                'data-cy': 'task_description',
                defaultValue: fieldsValues.description,
              }}
              onChange={(e) =>
                updateValue({ [TASK_FIELDS.DESCRIPTION]: e.target.value })
              }
            />
          </FormItem>

          <FormItem
            label={intl.get('TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE')}
            className='text-primary-light font-medium mt-4'
          >
            <UsersPicker
              triggerText={intl.get(
                'TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE_PLACEHOLDER'
              )}
              usersList={availableUsers}
              triggerProps={{
                'data-cy': 'user_picker',
              }}
              onChange={handlePickOwners}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
