import { Fragment, useState, MouseEvent } from 'react';
import intl from 'react-intl-universal';
import { useHistory, Link } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  Table,
  Tag,
  TableOperationHeader,
  OverflowMenu,
  OverflowMenuItem,
  Button,
  tailwindOverride,
} from '@getsynapse/design-system';
import {
  Form,
  SortingOrderType,
  SortingType,
  TableRowDensity,
} from 'utils/customTypes';
import {
  DATE,
  FORMS_FILTER_OPTIONS,
  PATHS,
  FORMS_TABLE_OPTIONS,
} from 'utils/constants';
import noFormsFound from 'assets/images/no-requests-found.svg';
import EmptyForms from './EmptyForms';
import UserAvatar from 'Atoms/UserAvatar';
import DuplicateFormModal from './DuplicateFormModal';
import DeleteFormModal from './DeleteFormModal';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import RowDensityPopup from 'Organisms/TableHeaderActionButtons/RowDensityPopup';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectOrganizationId } from 'state/User/userSlice';
type FormsTableProps = {
  forms: Form[];
  onSearch: (title: string) => void;
  onSort: (sorting: SortingOrderType) => void;
  onDuplicate: (title: string, formId: string, redirect: boolean) => void;
  onDelete: (formId: string) => void;
};
const FormsTable = ({
  forms,
  onSearch,
  onSort,
  onDuplicate,
  onDelete,
}: FormsTableProps) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [rowDensity, setRowDensity] = useState<TableRowDensity>('default');
  const [modalData, setModalData] = useState<{
    formName?: string;
    formId: string;
    openOption: string;
    isPublished?: boolean;
  }>({
    formId: '',
    openOption: '',
  });
  const status: Record<string, string> = {
    true: intl.get('SETTINGS_PAGE.FORMS.PUBLISHED'),
    false: intl.get('SETTINGS_PAGE.FORMS.UN_PUBLISHED'),
  };

  const statusColor: Record<
    string,
    { className: string; textClassName: string }
  > = {
    true: {
      className: tailwindOverride(
        'bg-success-lightest border border-transparent py-0.5',
        'group-hover:border-success-dark'
      ),
      textClassName: 'text-success-darker',
    },
    false: {
      className: tailwindOverride(
        'bg-tertiary-lightest border border-transparent py-0.5',
        'group-hover:border-tertiary'
      ),
      textClassName: 'text-tertiary-darker',
    },
  };

  const onTitleSearch = (value: string) => {
    setSearch(value);
    onSearch(value);
  };

  const handleSort = (orderByParam: string, order: SortingType) => {
    onSort({ order, orderBy: orderByParam });
  };

  const handleMenuSelect = (
    option: typeof FORMS_TABLE_OPTIONS[number],
    form: Form
  ) => {
    switch (option) {
      case FORMS_TABLE_OPTIONS[0]:
        setModalData({
          formName: get(form, 'title'),
          formId: get(form, 'id') || '',
          openOption: FORMS_TABLE_OPTIONS[0],
        });
        break;
      case FORMS_TABLE_OPTIONS[1]:
        setModalData({
          formId: get(form, 'id') || '',
          openOption: FORMS_TABLE_OPTIONS[1],
          isPublished: get(form, 'data.published'),
        });
        break;
      default:
        break;
    }
  };

  const isTableEmpty = forms.length === 0;
  const organizationId = useSelector(selectOrganizationId);

  return (
    <Fragment>
      <DuplicateFormModal
        {...modalData}
        isOpen={modalData.openOption === FORMS_TABLE_OPTIONS[0]}
        onClose={() =>
          setModalData((prevData) => ({ ...prevData, openOption: '' }))
        }
        onDuplicate={onDuplicate}
      />
      <DeleteFormModal
        {...modalData}
        isOpen={modalData.openOption === FORMS_TABLE_OPTIONS[1]}
        onClose={() =>
          setModalData((prevData) => ({ ...prevData, openOption: '' }))
        }
        onDelete={onDelete}
      />
      <div className='relative mt-6'>
        <TableOperationHeader
          inputValue={search}
          setInputValue={onTitleSearch}
          className='border-0 bg-neutral-white'
        />
        <div className='absolute top-2.5 right-32'>
          <RowDensityPopup
            onSelectRowDensity={(rowDensity: TableRowDensity) =>
              setRowDensity(rowDensity)
            }
            disabled={isTableEmpty}
            tableTestId='formsTable'
          />
        </div>
        <Link
          to={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}`}
          className='absolute top-2 right-4'
        >
          <Button data-cy='add-form_button'>
            {intl.get('SETTINGS_PAGE.FORMS.ADD_FORM')}
          </Button>
        </Link>
      </div>
      <div
        className={tailwindOverride(
          'w-full max-h-small-table-body overflow-auto',
          'bg-neutral-white rounded-b',
          { 'border-t border-b border-neutral-lighter-two': !isTableEmpty }
        )}
      >
        <Table
          className={tailwindOverride('w-full', {
            'border-t-0 border-b-0': !isTableEmpty,
          })}
          canSelectRows={false}
          bodyProps={{
            className: classnames('max-h-table-body overflow-y-auto', {
              block: !isTableEmpty,
            }),
          }}
          rowDensity={rowDensity}
          data-testid='forms-table'
          data={{
            headData: {
              className: 'w-full block h-auto min-h-10',
              rowProps: {
                className: 'table w-full table-fixed h-auto min-h-10',
              },
              stickyHeader: true,
              handleSort,
              headCells: [
                {
                  columnName: FORMS_FILTER_OPTIONS.TITLE,
                  content: intl.get('SETTINGS_PAGE.FORMS.FORM_TITLE'),
                  'data-cy': 'form-title',
                  className: tailwindOverride('w-2/12 2xl:w-3/12'),
                },
                {
                  content: intl.get(
                    'ORG_SETTINGS_PAGE.BUSINESS_TEAMS.TABLE.DESCRIPTION'
                  ),
                  'data-cy': 'form-description',
                  className: 'w-2/12 2xl:w-3/12',
                },
                {
                  columnName: FORMS_FILTER_OPTIONS.REQUEST_TYPE,
                  content: intl.get('SETTINGS_PAGE.FORMS.REQUEST_TYPE'),
                  'data-cy': 'form-request-type',
                  className: 'w-1/12 2xl:w-2/12',
                },
                {
                  columnName: FORMS_FILTER_OPTIONS.FORM_CREATOR,
                  content: intl.get('SETTINGS_PAGE.FORMS.FORM_CREATOR'),
                  'data-cy': 'form-creator',
                  className: tailwindOverride('w-48'),
                },
                {
                  columnName: FORMS_FILTER_OPTIONS.CREATION_DATE,
                  content: intl.get(
                    'ORG_SETTINGS_PAGE.BUSINESS_TEAMS.TABLE.CREATION_DATE'
                  ),
                  'data-cy': 'form-creation-date',
                  className: 'w-30',
                },
                {
                  columnName: FORMS_FILTER_OPTIONS.LAST_UPDATE,
                  content: intl.get('SETTINGS_PAGE.FORMS.LAST_UPDATE'),
                  'data-cy': 'form-last-update',
                  className: 'w-30',
                },
                {
                  columnName: FORMS_FILTER_OPTIONS.STATUS,
                  content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS'),
                  'data-cy': 'form-status',

                  className: tailwindOverride('w-30'),
                },
                {
                  className: 'w-20',
                },
              ],
            },
            rows: forms.map((form) => {
              const menuOptions = [...FORMS_TABLE_OPTIONS];
              if (get(form, 'data.published', false)) {
                menuOptions.pop();
              }
              return {
                id: form.id,
                'data-cy': `form-table__row-${form.id}`,
                className: 'cursor-pointer table w-full table-fixed',
                onClick: (event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  history.push(
                    `${PATHS.SETTINGS}${PATHS.FORM_PAGE}/${form.id}?accountId=${organizationId}`
                  );
                },
                cells: [
                  {
                    content: (
                      <Link
                        to={`${PATHS.SETTINGS}${PATHS.FORM_PAGE}/${form.id}`}
                        title={get(form, 'title') || '-'}
                      >
                        <span>{get(form, 'title') || '-'}</span>
                      </Link>
                    ),
                    className: 'w-2/12 2xl:w-3/12 truncate',
                  },
                  {
                    content: get(form, 'form_description') || '-',
                    title: get(form, 'form_description') || '-',
                    className: 'w-2/12 2xl:w-3/12 truncate',
                  },
                  {
                    content:
                      intl.get(
                        `REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.${get(
                          form,
                          'request_type'
                        )}`
                      ) || '-',
                    title:
                      intl.get(
                        `REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.${get(
                          form,
                          'request_type'
                        )}`
                      ) || '-',
                    className: 'w-1/12 2xl:w-2/12 truncate',
                  },
                  {
                    content: get(form, 'formCreator', false) ? (
                      <div className='flex items-center'>
                        <UserAvatar user={get(form, 'formCreator')} />
                        <span className='ml-2.5'>
                          {`${get(
                            form,
                            'formCreator.data.firstName',
                            ''
                          )} ${get(form, 'formCreator.data.lastName', '')}`}
                        </span>
                      </div>
                    ) : (
                      '-'
                    ),
                    className: 'w-48',
                  },
                  {
                    content: moment(form.createdAt).format(DATE.SHORT_FORMAT),
                    className: 'w-30',
                  },
                  {
                    content: moment(form.updatedAt).format(DATE.SHORT_FORMAT),
                    className: 'w-30',
                  },
                  {
                    content: (
                      <Tag
                        label={status[get(form, 'data.published', false)]}
                        {...statusColor[get(form, 'data.published', false)]}
                      />
                    ),
                    className: 'w-30',
                  },
                  {
                    onClick: (e: MouseEvent<HTMLDivElement>) =>
                      e.stopPropagation(),
                    content: (
                      <div className='w-full h-full text-center relative'>
                        <OverflowMenu
                          menuButtonProps={{
                            className: 'h-5',
                            'data-cy': 'form-actions_menu',
                          }}
                        >
                          {menuOptions.map((option) => (
                            <OverflowMenuItem
                              key={option}
                              onSelect={() => handleMenuSelect(option, form)}
                              data-cy={`${option}-${form.id}`}
                            >
                              {intl.get(
                                `SETTINGS_PAGE.FORMS.TABLE_MENU.${option}`
                              )}
                            </OverflowMenuItem>
                          ))}
                        </OverflowMenu>
                      </div>
                    ),
                    className: 'w-20',
                  },
                ],
              };
            }),
          }}
          emptyComponent={
            !isEmpty(search) ? (
              <RenderNoRecords
                imageSrc={noFormsFound}
                caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
                className='h-empty-forms-table-body'
                imageClassName='-ml-3'
              />
            ) : (
              <EmptyForms />
            )
          }
        />
      </div>
    </Fragment>
  );
};

export default FormsTable;
