import orderBy from 'lodash/orderBy';
import {
  PROGRAM_PROJECT_FIELDS,
  COLUMN_OPTION_TYPES,
  TABLE_FILTERS_OPERATORS,
} from 'utils/constants';
import {
  ProgramLinkedProject,
  SortingType,
  filter,
  rangeDate,
  FormOption,
} from 'utils/customTypes';
import moment from 'moment';
import intl from 'react-intl-universal';

export const orderProjectsBy = (
  projects: ProgramLinkedProject[],
  column: string,
  order: SortingType
) => {
  switch (column) {
    case PROGRAM_PROJECT_FIELDS.PROGRESS:
      return orderBy(
        projects,
        [
          (project: ProgramLinkedProject) => {
            const {
              number_of_completed_tasks: completedTasksCount,
              number_of_not_completed_tasks: incompletedTasksCount,
            } = project;

            const percentage =
              completedTasksCount > 0
                ? completedTasksCount /
                  (completedTasksCount + incompletedTasksCount)
                : 0;
            return percentage;
          },
        ],
        [order]
      );
    default:
      return orderBy(projects, [column], [order]);
  }
};

export const checkColumnMatchDateFilter = (columnData: any, filter: filter) => {
  const requestDate = moment(new Date(columnData));
  const rangeDate = filter.value as rangeDate;
  const startDate = new Date(rangeDate.startDate);
  const endDate = new Date(rangeDate.endDate);

  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return requestDate.isSame(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER:
      return requestDate.isAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return requestDate.isSameOrAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS:
      return requestDate.isBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return requestDate.isSameOrBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return requestDate.isBetween(startDate, endDate, 'days', '[]');
    default:
      return false;
  }
};

const checkColumnContainsValue = (
  column: string | undefined,
  columnData: any,
  value: string,
  shouldContain: boolean = true
) => {
  let formattedValue = value.toLocaleLowerCase();
  let formattedColumn = columnData;

  if (column === PROGRAM_PROJECT_FIELDS.STATUS) {
    formattedColumn = intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${columnData}`);
  }

  if (column === PROGRAM_PROJECT_FIELDS.PRIORITY) {
    formattedColumn = intl.get(
      `PROJECT_DETAIL.PRIORITY_OPTIONS.${columnData.toUpperCase()}`
    );
  }

  return formattedColumn
    ? shouldContain
      ? formattedColumn.toLowerCase().includes(formattedValue)
      : !formattedColumn.toLowerCase().includes(formattedValue)
    : false;
};

export const checkColumnMatchFilter = (columnData: any, filter: filter) => {
  const value =
    filter.type === COLUMN_OPTION_TYPES.OPTIONS
      ? (filter.value as FormOption).value
      : (filter.value as string);
  if (!value) {
    return false;
  }
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.CONTAINS:
      return checkColumnContainsValue(filter.column, columnData, value);
    case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
      return checkColumnContainsValue(filter.column, columnData, value, false);
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData === value
        : columnData === +value;
    case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData !== value
        : columnData !== +value;
    case TABLE_FILTERS_OPERATORS.GREATER:
      return columnData > +value;
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return columnData >= +value;
    case TABLE_FILTERS_OPERATORS.LESS:
      return columnData < +value;
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return columnData <= +value;
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return false;
    default:
      return false;
  }
};

const projectMatchAllFilters: (
  project: ProgramLinkedProject,
  filters: filter[]
) => boolean = (project, filters) => {
  let allFiltersMatched = false;
  for (const filter of filters) {
    if (filter.column) {
      let columnMatchFilter = false;

      type key = keyof ProgramLinkedProject;
      let columnData;
      if (filter.column === PROGRAM_PROJECT_FIELDS.PROGRESS) {
        const {
          number_of_completed_tasks: completedTasksCount,
          number_of_not_completed_tasks: incompletedTasksCount,
        } = project;
        let percentage =
          completedTasksCount > 0
            ? completedTasksCount /
              (completedTasksCount + incompletedTasksCount)
            : 0;
        if (percentage) {
          percentage = percentage * 100;
        }
        columnData = percentage;
      } else {
        columnData = project[filter.column as key];
      }

      if (columnData === 0 || columnData) {
        columnMatchFilter =
          filter.type === COLUMN_OPTION_TYPES.DATE
            ? checkColumnMatchDateFilter(columnData, filter)
            : checkColumnMatchFilter(columnData, filter);
      }

      if (filter.logic !== undefined) {
        allFiltersMatched =
          filter.logic === 'AND'
            ? allFiltersMatched && columnMatchFilter
            : allFiltersMatched || columnMatchFilter;
      } else {
        allFiltersMatched = columnMatchFilter;
      }
    }
  }
  return allFiltersMatched;
};

export const filterProgramProjects: (
  projects: ProgramLinkedProject[],
  search: string,
  filters: filter[]
) => ProgramLinkedProject[] = (projects, search, filters) => {
  let filteredProjects = projects;
  const filtersActive = filters.length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredProjects = projects.filter((project: ProgramLinkedProject) => {
      let projectMatchSearch = false;
      let projectMatchFilters = false;
      if (filtersActive) {
        projectMatchFilters = projectMatchAllFilters(project, filters);
      }
      if (searchActive) {
        projectMatchSearch = project.title
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      }

      if (searchActive && filtersActive) {
        return projectMatchSearch && projectMatchFilters;
      } else if (searchActive && !filtersActive) {
        return projectMatchSearch;
      } else if (!searchActive && filtersActive) {
        return projectMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredProjects;
};
